// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import List from './List'

import classes from './ListCatalog.scss'

const ScriptListCatalog = ({ projectId, onClickAdd, permitCreate }) => (
  <div className={classes.container}>
    <div className={classes.title}>質問スクリプト</div>
    <List projectId={projectId} />
    {permitCreate && (
      <div className={classes.addButton} onClick={e => onClickAdd(e)}>
        <div className={classes.addIcon} />
      </div>
    )}
  </div>
)

ScriptListCatalog.propTypes = {
  projectId: PropTypes.string.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  permitCreate: PropTypes.bool
}

ScriptListCatalog.defaultProps = {
  permitCreate: true
}

export default ScriptListCatalog
