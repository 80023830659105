// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import classes from './UserListRow.scss'
import { MenuButton } from '../Ui/Button'
import { clipBoardCopy } from '../../common/Util'
import { useSnackbar } from 'notistack'
import UserState from '../Ui/UserState'

export function ListRow({ user, onClickUser, onClickMenu, permitUpdate }) {
  const { enqueueSnackbar } = useSnackbar()
  const onClickLineSyncCode = code => {
    clipBoardCopy(code)
    enqueueSnackbar('LINE IDがコピーされました', { variant: 'default' })
  }

  return (
    <div className={classes.container}>
      <div
        className={classes.row}
        onClick={e => {
          onClickUser(user.id)
        }}
      >
        <div className={classes.name}>{user.name}</div>
        <div className={classes.duration}>
          {Moment(user.startAt).format('YYYY/MM/DD')}〜
          {Moment(user.endAt).format('YYYY/MM/DD')}
        </div>
        <div className={classes.userState}>
          <UserState user={user} />
        </div>
        <div className={classes.lineState}>
          {user.lineUserId !== '' ? (
            <div>
              <div className={classes.lineIcon} />
              <div className={classes.stateText}>LINE連携済</div>
            </div>
          ) : (
            <div>
              <div className={classes.stateText}>未連携</div>
              <div
                className={classes.lineInfo}
                onClick={e => {
                  e.stopPropagation()
                  onClickLineSyncCode(user.lineSyncCode)
                }}
              >
                {user.lineSyncCode}
              </div>
            </div>
          )}
        </div>
        {permitUpdate && (
          <div className={classes.menuButton}>
            <MenuButton
              onClick={e => {
                e.stopPropagation()
                onClickMenu(e, user.id)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

ListRow.defaultProps = {}

export default ListRow
