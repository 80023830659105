// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import Moment from 'moment'
import React from 'react'
import { useDispatch } from 'react-redux'
import { userOperations } from '../../modules/user'

import classes from './Info.scss'

export function UserInfo({ user, recordList }) {
  const dispatch = useDispatch()

  const onChangeValue = (key, value) => {
    dispatch(userOperations.updateUser({ [key]: value }))
  }

  return (
    <div className={classes.container}>
      <div className={classes.line} />
      <div className={classes.row}>
        <div className={classes.column}>
          <div className={classes.label}>LINE認証コード</div>
          <div className={classes.state}>{user.lineSyncCode}</div>
        </div>
        <div className={classes.column}>
          <div className={classes.label}>LINE連携状態</div>
          <div className={classes.state}>
            {user.lineUserId !== '' ? <div>連携済み</div> : <div>未連携</div>}
          </div>
        </div>
        <div className={classes.column}>
          <div className={classes.label}>回答回数</div>
          <div className={classes.state}>{recordList.length} 回</div>
        </div>
        <div className={classes.column}>
          <div className={classes.label}>最終回答日</div>
          <div className={classes.state}>
            {recordList.length === 0 ? (
              <div>----/--/-- --:--</div>
            ) : (
              <div>
                {Moment(recordList[0].createdAt).format('YYYY/MM/DD HH:mm')}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.line} />
      <div
        className={classes.isExcludedRecord}
        onClick={e => onChangeValue('isExcludedRecord', !user.isExcludedRecord)}
      >
        <div
          className={ClassNames(classes.checkbox, {
            [classes.checked]: user.isExcludedRecord
          })}
        />
        <div className={classes.text}>回答結果の集計から除外する</div>
      </div>
    </div>
  )
}

export default UserInfo
