// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".B_Ihl6x9khi2vN4G-yWGF{position:relative;width:100%}.B_Ihl6x9khi2vN4G-yWGF h1{height:64px;line-height:64px;padding:0 24px}.B_Ihl6x9khi2vN4G-yWGF ._1wvQWOBiFShrDE1qFK9Bb3{position:absolute;top:0px;right:0px;width:224px;height:64px;color:#fff;background-color:#EAAD68;font-size:16px;text-align:center;line-height:64px;letter-spacing:0.08rem;border-radius:32px;cursor:pointer;filter:drop-shadow(0px 8px 12px rgba(0,0,0,0.1))}.B_Ihl6x9khi2vN4G-yWGF ._1wvQWOBiFShrDE1qFK9Bb3 ._3czubbtUqTx3UdWMrEOrEr{scale:1.2}", ""]);
// Exports
exports.locals = {
	"container": "B_Ihl6x9khi2vN4G-yWGF",
	"newButton": "_1wvQWOBiFShrDE1qFK9Bb3",
	"hover": "_3czubbtUqTx3UdWMrEOrEr"
};
module.exports = exports;
