// Takram Confidential
// Copyright (C) 2019-Present Takram

import React from 'react'

import classes from './Frame.scss'

export default function Frame({ children }) {
  return (
    <div className={classes.root}>
      {children}
      <div className={classes.border} />
    </div>
  )
}
