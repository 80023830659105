// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import _ from 'lodash'

export const SCRIPT_TYPE_FREE = 'condition/TYPE_FREE'
export const SCRIPT_TYPE_REQUIRED = 'condition/TYPE_REQUIRED'
export const SCRIPT_TYPE_BEACON = 'condition/TYPE_BEACON'
export const SCRIPT_TYPE_LABELS = [
  { type: SCRIPT_TYPE_FREE, label: '自由入力' },
  { type: SCRIPT_TYPE_REQUIRED, label: '必須入力' },
  { type: SCRIPT_TYPE_BEACON, label: 'ビーコン' }
]
export const INPUT_TERM_TYPE_EVERYDAY = 'condition/INPUT_TERM_TYPE_EVERYDAY'
export const INPUT_TERM_TYPE_ELAPSED_DAYS =
  'condition/INPUT_TERM_TYPE_ELAPSED_DAYS'
export const INPUT_TERM_TYPE_LABELS = [
  { type: INPUT_TERM_TYPE_EVERYDAY, label: '毎日' },
  { type: INPUT_TERM_TYPE_ELAPSED_DAYS, label: '経過日数' }
]
export const NOTIFY_TYPE_NONE = 'condition/ITEM_TYPE_NONE'
export const NOTIFY_TYPE_EVERYDAY = 'condition/ITEM_TYPE_EVERYDAY'
export const NOTIFY_TYPE_START_DATE = 'condition/ITEM_TYPE_START_DATE'
export const NOTIFY_TYPE_MIDDLE_DATE = 'condition/ITEM_TYPE_MIDDLE_DATE'
export const NOTIFY_TYPE_END_DATE = 'condition/ITEM_TYPE_END_DATE'
export const NOTIFY_TYPE_ELAPSED_DAYS = 'condition/NOTIFY_TYPE_ELAPSED_DAYS'
export const NOTIFY_TYPE_DAYS_OF_WEEK = 'condition/NOTIFY_TYPE_DAYS_OF_WEEK'

export const NOTIFY_TYPE_LABELS = [
  { type: NOTIFY_TYPE_EVERYDAY, label: '毎日' },
  { type: NOTIFY_TYPE_START_DATE, label: '開始日' },
  { type: NOTIFY_TYPE_MIDDLE_DATE, label: '途中日' },
  { type: NOTIFY_TYPE_END_DATE, label: '最終日' },
  { type: NOTIFY_TYPE_DAYS_OF_WEEK, label: '曜日' },
  { type: NOTIFY_TYPE_ELAPSED_DAYS, label: '経過日数' }
]

export const NOTIFY_DAYS = [
  { value: 1, name: '月' },
  { value: 2, name: '火' },
  { value: 3, name: '水' },
  { value: 4, name: '木' },
  { value: 5, name: '金' },
  { value: 6, name: '土' },
  { value: 0, name: '日' }
]

export const NOTYFY_PRESET_3H_ALL = 'condition/NOTYFY_PRESET_3H_ALL'
export const NOTYFY_PRESET_3H_BIZ = 'condition/NOTYFY_PRESET_3H_BIZ'
export const NOTYFY_PRESET_4H_ALL = 'condition/NOTYFY_PRESET_4H_ALL'
export const NOTYFY_PRESET_4H_BIZ = 'condition/NOTYFY_PRESET_4H_BIZ'
export const NOTIFY_PRESET_LABELS = [
  {
    preset: NOTYFY_PRESET_3H_ALL,
    label: '終日3時間おき（9, 12, 15, 18, 21時）'
  },
  { preset: NOTYFY_PRESET_3H_BIZ, label: '終日4時間おき（8, 12, 16, 20時）' },
  { preset: NOTYFY_PRESET_4H_ALL, label: '業務時間内3時間おき（9, 12, 15時）' },
  { preset: NOTYFY_PRESET_4H_BIZ, label: '業務時間内4時間おき（10, 14時）' }
]

// Templates

export const getInitialItem = type => {
  switch (type) {
    case SCRIPT_TYPE_FREE:
      return _.cloneDeep(INITIAL_FREE_ITEM)
    case SCRIPT_TYPE_REQUIRED:
      return _.cloneDeep(INITIAL_REQUIRED_ITEM)
    case SCRIPT_TYPE_BEACON:
      return _.cloneDeep(INITIAL_BEACON_ITEM)
    default:
      return _.cloneDeep(INITIAL_FREE_ITEM)
  }
}

export const getPresetItems = (preset, type) => {
  if (type !== SCRIPT_TYPE_FREE && type !== SCRIPT_TYPE_REQUIRED) return []
  const ITEM =
    type === SCRIPT_TYPE_FREE ? INITIAL_FREE_ITEM : INITIAL_REQUIRED_ITEM
  switch (preset) {
    case NOTYFY_PRESET_3H_ALL:
      return [
        { ..._.cloneDeep(ITEM), ...{ hours: 9 } },
        { ..._.cloneDeep(ITEM), ...{ hours: 12 } },
        { ..._.cloneDeep(ITEM), ...{ hours: 15 } },
        { ..._.cloneDeep(ITEM), ...{ hours: 18 } },
        { ..._.cloneDeep(ITEM), ...{ hours: 21 } }
      ]
    case NOTYFY_PRESET_3H_BIZ:
      return [
        { ..._.cloneDeep(ITEM), ...{ hours: 8 } },
        { ..._.cloneDeep(ITEM), ...{ hours: 12 } },
        { ..._.cloneDeep(ITEM), ...{ hours: 16 } },
        { ..._.cloneDeep(ITEM), ...{ hours: 20 } }
      ]
    case NOTYFY_PRESET_4H_ALL:
      return [
        { ..._.cloneDeep(ITEM), ...{ hours: 9 } },
        { ..._.cloneDeep(ITEM), ...{ hours: 12 } },
        { ..._.cloneDeep(ITEM), ...{ hours: 15 } }
      ]
    case NOTYFY_PRESET_4H_BIZ:
      return [
        { ..._.cloneDeep(ITEM), ...{ hours: 10 } },
        { ..._.cloneDeep(ITEM), ...{ hours: 14 } }
      ]
    default:
      return []
  }
}

export const getCaption = type => {
  switch (type) {
    case SCRIPT_TYPE_FREE:
      return {
        description: '自由入力スクリプトは入力期間中に何度でも回答が可能です。',
        addLabel: '＋ 通知を追加する'
      }
    case SCRIPT_TYPE_REQUIRED:
      return {
        description: '必須入力スクリプトは回答入力の時間に通知が送られます',
        addLabel: '＋ 入力条件を追加する'
      }
    case SCRIPT_TYPE_BEACON:
      return {
        description:
          'ビーコンが設置されている場所の近くにいると通知が送られます',
        addLabel: '+ ビーコンを追加する'
      }
    default:
      return { description: '', addLabel: '' }
  }
}

export const INITIAL_FREE_ITEM = {
  type: NOTIFY_TYPE_EVERYDAY,
  hours: 0,
  minutes: 0,

  elapsedDays: 0,
  daysOfWeek: [],

  // repeat: false,
  // repeatTimes: 0,
  // repeatInterval: 0,

  answerLink: true,
  notify: true,
  message: 'アンケートにご協力ください'
}

export const INITIAL_REQUIRED_ITEM = {
  type: NOTIFY_TYPE_EVERYDAY,
  hours: 0,
  minutes: 0,
  elapsedDays: 0,
  daysOfWeek: [],
  answerLink: true,
  notify: true,
  message: '入力必須アンケートにご協力ください'
}

export const INITIAL_BEACON_ITEM = {
  type: NOTIFY_TYPE_NONE,
  hours: 0,
  minutes: 0,
  elapsedDays: 0,
  daysOfWeek: [],
  answerLink: true,
  notify: true,
  hwid: '',
  message: 'アンケートにご協力ください'
}

export const ITEM_TYPES = {
  type: PropTypes.string.isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  elapsedDays: PropTypes.number,
  notify: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    }).isRequired
  ]),
  hwid: PropTypes.string
}

export const INITIAL_CONDITION = {
  type: SCRIPT_TYPE_FREE,
  items: [],
  inputTerm: {
    type: INPUT_TERM_TYPE_EVERYDAY,
    from: 0,
    to: 0
  }
}

export const CONDITION_TYPES = {
  type: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(ITEM_TYPES)).isRequired,
  inputTerm: PropTypes.shape({
    type: PropTypes.string.isRequired,
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired
  })
}
