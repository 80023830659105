// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import * as scriptConfig from '../../../constants/scriptConfig'
import * as projectTypes from '../../../modules/project/types'
import * as recordTypes from '../../../modules/record/types'
import * as scriptTypes from '../../../modules/script/types'
import DateLog from './DateLog'
import NumberLog from './NumberLog'
import StringArrayLog from './StringArrayLog'
import StringLog from './StringLog'
import TimeLog from './TimeLog'

import classes from './RecordLog.scss'

const getLog = ({ type, value }) => {
  switch (type) {
    case scriptConfig.INPUT_CHECK:
      if (!Array.isArray(value)) {
        console.warn('script answer should be array but is not array.')
        return
      }
      return <StringArrayLog stringArray={value} />
    case scriptConfig.INPUT_DATE:
      return <DateLog date={value} />
    case scriptConfig.INPUT_NUMBER:
      return <NumberLog number={value} />
    case scriptConfig.INPUT_RADIO:
    case scriptConfig.INPUT_SLIDE_SEL:
    case scriptConfig.INPUT_TEXT:
      if (Array.isArray(value)) {
        console.warn('script type should not be array but is array.')
        return
      }
      return <StringLog string={value} />
    case scriptConfig.INPUT_TIME:
      return <TimeLog time={value} />
    default:
      return <div />
  }
}

const RecordLog = ({ onClose, record, script, userItems, visibility }) => (
  <div
    className={ClassNames(classes.container, {
      [classes.hidden]: !visibility
    })}
  >
    <div className={classes.title}>回答した内容を確認</div>
    <div className={classes.date}>
      {Moment(record.createdAt).format('YYYY.MM.DD HH:mm')}に回答しました
    </div>
    {script.data.map(entory => {
      if (!entory.value.find(item => record.data[item.id])) return
      return (
        <div key={entory.id}>
          <div className={classes.entryTitle}>
            <div className={classes.entryTitleIcon} />
            <div>{entory.title}</div>
          </div>
          <div>
            {entory.value.map(item => {
              const answerType = record.dataTypes[item.id]
              if (item.type !== answerType) {
                console.warn('script type is not equal answer type.')
                return
              }
              let answer = record.data[item.id]
              if (answer === undefined) return
              if (item.type === scriptConfig.INPUT_USER_INFO) {
                const { type, attrId } = item.value[0]
                const isIndivisual =
                  userItems[attrId].type ===
                  projectTypes.USER_ATTR_TYPE_MASKING_LIST
                if (isIndivisual) {
                  answer = answer.map(d => d.split(':')[0])
                }
                item = { ...item, type }
              }
              return (
                <div key={item.id} className={classes.itemTitle}>
                  {item.title}
                  <div className={classes.answer}>
                    {getLog({ type: item.type, value: answer })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    })}
    <div className={classes.close} onClick={onClose} />
  </div>
)

RecordLog.propTypes = {
  onClose: PropTypes.func.isRequired,
  record: PropTypes.shape(recordTypes.RECORD_TYPES),
  script: PropTypes.shape(scriptTypes.SCRIPT_TYPES),
  userItems: PropTypes.object.isRequired,
  visibility: PropTypes.bool.isRequired
}

RecordLog.defaultProps = {
  record: recordTypes.DEFAULT_RECORD_STATE,
  script: scriptTypes.DEFAULT_SCRIPT_STATE,
  visibility: false
}

export default RecordLog
