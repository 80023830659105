// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { userOperations } from '../../modules/user'
import DatePicker from 'react-date-picker'
import Moment from 'moment'
import PropTypes from 'prop-types'

import classes from './Forms.scss'

import TextInput from '../Ui/TextInput'
import SectionRow from '../Ui/SectionRow'

export function Form({ user }) {
  const dispatch = useDispatch()
  const menuRef = useRef()

  const onChangeValue = (key, value) => {
    dispatch(userOperations.updateUser({ [key]: value }))
  }

  return (
    <div className={classes.container}>
      <SectionRow label='被験者ID'>
        <TextInput
          placeholder='被験者IDを入力'
          value={user.name}
          onChange={e => onChangeValue('name', e.target.value)}
        />
      </SectionRow>
      <SectionRow label='調査期間'>
        <div className={classes.term}>
          <DatePicker
            className={classes.startAt}
            value={user.startAt}
            onChange={e => {
              if (Moment(e).isAfter(user.endAt, 'day'))
                onChangeValue('endAt', e)
              onChangeValue('startAt', e)
            }}
          />
          <div className={classes.hyphen}>ー</div>
          <DatePicker
            className={classes.endAt}
            value={user.endAt}
            onChange={e => {
              if (Moment(e).isBefore(user.startAt, 'day')) {
                onChangeValue('startAt', e)
              }
              onChangeValue('endAt', e)
            }}
          />
        </div>
      </SectionRow>
    </div>
  )
}
export default Form
