// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2tXB00kZx2Tmb_2PgnIif9{float:left;width:100%;margin:40px 0 40px 16px}._2tXB00kZx2Tmb_2PgnIif9 ._1aQxE8brtrLOf5Vhh4NoAm{display:inline-block;margin-bottom:32px}._2tXB00kZx2Tmb_2PgnIif9 ._18XCBjUn_Ttot_qu9ijvZR{display:inline-block;float:right;top:0;right:0}._2tXB00kZx2Tmb_2PgnIif9 ._18JWawF2-SnxpUcxoub-dY{background-color:#fff;box-sizing:border-box;border-radius:10px}._2tXB00kZx2Tmb_2PgnIif9 ._18JWawF2-SnxpUcxoub-dY ._3KzfIPO3KwHO9gbaFmT1j1{padding:24px}._2tXB00kZx2Tmb_2PgnIif9 ._18JWawF2-SnxpUcxoub-dY ._2v-Vr5vaJ4QpPFiPAjmfP7{padding:24px;border-top:solid 0.5px #E8ECEE}._2tXB00kZx2Tmb_2PgnIif9 ._18JWawF2-SnxpUcxoub-dY ._2v-Vr5vaJ4QpPFiPAjmfP7 ._2swNVVjqEV8DMkT4qluOgk{width:200px}._2tXB00kZx2Tmb_2PgnIif9 a{text-decoration:none}", ""]);
// Exports
exports.locals = {
	"container": "_2tXB00kZx2Tmb_2PgnIif9",
	"title": "_1aQxE8brtrLOf5Vhh4NoAm",
	"sort": "_18XCBjUn_Ttot_qu9ijvZR",
	"catalogContainer": "_18JWawF2-SnxpUcxoub-dY",
	"listContainer": "_3KzfIPO3KwHO9gbaFmT1j1",
	"buttonContainer": "_2v-Vr5vaJ4QpPFiPAjmfP7",
	"button": "_2swNVVjqEV8DMkT4qluOgk"
};
module.exports = exports;
