// Takram Confidential
// Copyright (C) 2019-Present Takram

import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import * as reducers from './modules'

export const history = createBrowserHistory()

/* eslint-disable */
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore(preloadedState) {
  const routerReducers = { ...reducers, ...{ router: connectRouter(history) } }

  return createStore(
    combineReducers(routerReducers),
    preloadedState,
    composeEnhancer(applyMiddleware(routerMiddleware(history), thunk))
  )
}
