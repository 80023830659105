// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon/admin/setting-circle.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._2Unf9JTRpIhFu8kSPu16TS a{text-decoration:none;color:#384241}._2Unf9JTRpIhFu8kSPu16TS ._2fNQcdb03VBjxp3a7aalDx{position:relative;background-color:#fff;box-sizing:border-box;border-radius:10px;padding:24px;margin:0 0 12px 0;cursor:pointer}._2Unf9JTRpIhFu8kSPu16TS ._2fNQcdb03VBjxp3a7aalDx .gZGsqVbwAHrLm7o_qazTW{font-size:18px;color:#384241;margin-bottom:8px}._2Unf9JTRpIhFu8kSPu16TS ._2fNQcdb03VBjxp3a7aalDx ._1VTY-Srftgqtua5hK-XKE6{font-size:11px;color:#919D99}._2Unf9JTRpIhFu8kSPu16TS ._2fNQcdb03VBjxp3a7aalDx .B7DBJ0oBKVbW7kwSrq9F-{font-size:11px;color:#919D99;margin:16px 0 0 0}._2Unf9JTRpIhFu8kSPu16TS ._2fNQcdb03VBjxp3a7aalDx .B7DBJ0oBKVbW7kwSrq9F- ._120k9U_8m8WbUpoHu4YvwU{display:inline-block}._2Unf9JTRpIhFu8kSPu16TS ._2fNQcdb03VBjxp3a7aalDx .B7DBJ0oBKVbW7kwSrq9F- ._7NQsgu_g1H0iED6FjRx-U{display:inline-block;margin:0 0 0 24px}._2Unf9JTRpIhFu8kSPu16TS ._2fNQcdb03VBjxp3a7aalDx .B7DBJ0oBKVbW7kwSrq9F- ._7NQsgu_g1H0iED6FjRx-U ._2jNCly_hSylxqXEsbkGQpB{display:inline-block}._2Unf9JTRpIhFu8kSPu16TS ._2fNQcdb03VBjxp3a7aalDx .B7DBJ0oBKVbW7kwSrq9F- ._7NQsgu_g1H0iED6FjRx-U ._2jNCly_hSylxqXEsbkGQpB .krnXhmHJjQZnLp5GCMfYI{display:inline-block;margin:0 8px 0 0}._2Unf9JTRpIhFu8kSPu16TS ._2fNQcdb03VBjxp3a7aalDx ._2ACP-1-Ay9vH0zGTT0F_Ny{position:absolute;top:23px;right:23px;width:32px;height:32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:center;border:solid 1px #fff}._2Unf9JTRpIhFu8kSPu16TS ._2fNQcdb03VBjxp3a7aalDx ._2ACP-1-Ay9vH0zGTT0F_Ny:hover{border:solid 1px #E8ECEE;color:#384241}._2Unf9JTRpIhFu8kSPu16TS ._2fNQcdb03VBjxp3a7aalDx:hover{background-color:#e2ebea}", ""]);
// Exports
exports.locals = {
	"container": "_2Unf9JTRpIhFu8kSPu16TS",
	"script": "_2fNQcdb03VBjxp3a7aalDx",
	"title": "gZGsqVbwAHrLm7o_qazTW",
	"description": "_1VTY-Srftgqtua5hK-XKE6",
	"states": "B7DBJ0oBKVbW7kwSrq9F-",
	"scriptType": "_120k9U_8m8WbUpoHu4YvwU",
	"pushState": "_7NQsgu_g1H0iED6FjRx-U",
	"pushCondition": "_2jNCly_hSylxqXEsbkGQpB",
	"pushTime": "krnXhmHJjQZnLp5GCMfYI",
	"menuIcon": "_2ACP-1-Ay9vH0zGTT0F_Ny"
};
module.exports = exports;
