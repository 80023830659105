// Takram Confidential
// Copyright (C) 2019-Present Takram

import { combineReducers } from 'redux'

import * as types from './types'

const currentRecordDate = (
  state = types.DEFAULT_FORM_STATE.currentRecordDate,
  action
) => {
  if (action.type === types.SET_CURRENT_RECORD_DATE) {
    return action.payload
  }
  return state
}

const popupVisibility = (
  state = types.DEFAULT_FORM_STATE.popupVisibility,
  action
) => {
  switch (action.type) {
    case types.SET_CANCELLATION_POPUP_VISIBILITY:
      return { ...state, cancellation: action.payload }
    case types.SET_RECORD_LOG_VISIBILITY:
      return { ...state, recordLog: action.payload }
    case types.SET_REQUIRED_ITEM_ALERT_VISIBILITY:
      return { ...state, requiredItemAlert: action.payload }
    case types.SET_REQUIRED_OTHERS_ITEM_ALERT_VISIBILITY:
      return { ...state, requiredOthersItemAlert: action.payload }
    case types.SET_SCRIPT_SELECTOR_POPUP_VISIBILITY:
      return { ...state, scriptSelector: action.payload }
    case types.SET_USER_INFO_POPUP_VISIBILITY:
      return { ...state, userInfo: action.payload }
    default:
      return state
  }
}

const formReducer = combineReducers({
  currentRecordDate,
  popupVisibility
})

export default formReducer
