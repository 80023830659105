// Takram Confidential
// Copyright (C) 2019-Present Takram

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Item from './Item'
import ItemDate from './ItemDate'
import ItemDescription from './ItemDescription'
import ItemList from './ItemList'
import ItemListSlider from './ItemListSlider'
import ItemNumeric from './ItemNumeric'
import ItemOption from './ItemOption'
import ItemText from './ItemText'
import ItemUserInfo from './ItemUserInfo'
import { PERMIT_UPDATE } from '../../../constants/permission'
import * as scriptConfig from '../../../constants/scriptConfig'
import { appSelectors, appTypes } from '../../../modules/app'
import { scriptOperations } from '../../../modules/script'

export function EntryPageItem({ item, index }) {
  const dispatch = useDispatch()
  const extraUserAttr = useSelector(
    state => state.projectState.project.extraUserAttr
  )
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_SCRIPT)
  )
  const itemType =
    item.type !== scriptConfig.INPUT_USER_INFO ? item.type : item.value[0].type
  const {
    canChangeRequired,
    canTwoColumn,
    defaultRequired
  } = scriptConfig.getItemOption(itemType)

  const onChangeValue = (key, val) => {
    item[key] = val
    dispatch(scriptOperations.updateScriptItem(item))
  }

  const renderItem = () => {
    if (item.required === !canChangeRequired || item.required === undefined) {
      item.required !== defaultRequired &&
        onChangeValue('required', defaultRequired)
    }
    switch (item.type) {
      case scriptConfig.INPUT_RADIO:
      case scriptConfig.INPUT_CHECK:
        return (
          <ItemList
            onChangeValue={(key, val) => onChangeValue(key, val)}
            permitUpdate={isPermit(PERMIT_UPDATE)}
            {...item}
          />
        )
      case scriptConfig.INPUT_SLIDE_SEL:
        return (
          <ItemListSlider
            onChangeValue={(key, val) => onChangeValue(key, val)}
            permitUpdate={isPermit(PERMIT_UPDATE)}
            {...item}
          />
        )
      case scriptConfig.INPUT_DATE:
      case scriptConfig.INPUT_TIME:
        return (
          <ItemDate
            onChangeValue={(key, val) => onChangeValue(key, val)}
            {...item}
          />
        )
      case scriptConfig.INPUT_NUMBER:
      case scriptConfig.INPUT_SLIDER:
        return (
          <ItemNumeric
            {...item}
            onChangeValue={(key, val) => onChangeValue(key, val)}
            permitUpdate={isPermit(PERMIT_UPDATE)}
          />
        )
      case scriptConfig.INPUT_TEXT:
        return (
          <ItemText
            {...item}
            onChangeValue={(key, val) => onChangeValue(key, val)}
            permitUpdate={isPermit(PERMIT_UPDATE)}
          />
        )
      case scriptConfig.INPUT_DESCRIPTION:
        return (
          <ItemDescription
            {...item}
            onChangeValue={(key, val) => onChangeValue(key, val)}
            permitUpdate={isPermit(PERMIT_UPDATE)}
          />
        )
      case scriptConfig.INPUT_USER_INFO:
        return (
          <ItemUserInfo
            {...item}
            extraUserAttr={extraUserAttr}
            onChangeValue={(key, val) => onChangeValue(key, val)}
            permitUpdate={isPermit(PERMIT_UPDATE)}
          />
        )
      default:
        return <div />
    }
  }

  return (
    <Item item={item} index={index}>
      {renderItem()}
      <ItemOption
        canTwoColumn={canTwoColumn}
        canChangeRequired={canChangeRequired}
        {...item}
        onChangeValue={(key, val) => onChangeValue(key, val)}
      />
    </Item>
  )
}

export default EntryPageItem
