// Takram Confidential
// Copyright (C) 2019-Present Takram

import projectReducer from './reducer'

export { default as projectOperations } from './operations'
export { default as projectSelectors } from './selectors'
export { default as projectTypes } from './types'

export default projectReducer
