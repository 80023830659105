// Takram Confidential
// Copyright (C) 2019-Present Takram

import {
  isPermitLevel,
  PERMIT_CREATE,
  PERMIT_DELETE,
  PERMIT_READ,
  PERMIT_UPDATE
} from '../../constants/permission'
import * as config from '../../constants/scriptConfig'
import firebase from '../../Firebase'
import { appTypes } from '../app'
import scriptParser from './parser/parser'
import * as types from './types'
import util from './util'

//= == Script

const fetchScript = scriptId => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    const ref = firebase
      .firestore()
      .collection('fave-scripts')
      .doc(scriptId)
    ref
      .get()
      .then(doc => {
        if (!doc.exists) return
        const script = deriveScriptFromDoc(doc)
        const parser = scriptParser.getParser(getState().scriptState)
        if (script.data.length === 0) script.data = parser.addEntry()
        dispatch({ type: types.FETCH_SCRIPT, script })
      })
      .catch(err => {
        console.log('Error getting document', err)
      })
  }
}

const asyncFetchScript = scriptId => {
  return async (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    await firebase
      .firestore()
      .collection('fave-scripts')
      .doc(scriptId)
      .get()
      .then(doc => {
        if (!doc.exists) return
        dispatch({
          type: types.FETCH_SCRIPT,
          script: deriveScriptFromDoc(doc)
        })
      })
      .catch(err => {
        console.log('Error getting document', err)
      })
  }
}

const exportScript = scriptId => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    const ref = firebase
      .firestore()
      .collection('fave-scripts')
      .doc(scriptId)
    ref
      .get()
      .then(doc => {
        if (!doc.exists) return
        const script = deriveScriptFromDoc(doc)
        const binaryData = [JSON.stringify(script)]
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(
          new global.Blob(binaryData, { type: 'text/json' })
        )
        a.download = script.title + '.json'
        a.click()
      })
      .catch(err => {
        console.log('Error exporting document', err)
      })
  }
}

const duplicateScript = (scriptId, destProjectId = null) => {
  return async (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_CREATE)) return
    const db = firebase.firestore().collection('fave-scripts')
    const doc = await db.doc(scriptId).get()
    if (doc.exists) {
      const newScript = util.reassignScriptIds(deriveScriptFromDoc(doc))
      newScript.title = 'Copy of ' + newScript.title
      if (destProjectId != null) newScript.projectId = destProjectId
      dispatch(addScript(newScript))
    }
  }
}

const duplicateScripts = (projectId, newProjectId) => {
  return async (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_CREATE)) return
    const db = firebase.firestore().collection('fave-scripts')
    const docs = await db
      .where('projectId', '==', projectId)
      .orderBy('updatedAt', 'desc')
      .get()
    docs.forEach(async doc => dispatch(duplicateScript(doc.id, newProjectId)))
  }
}

const importScript = projectId => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_CREATE)) return
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.json, text/plain'
    input.onchange = event => {
      const reader = new global.FileReader()
      reader.readAsText(event.target.files[0])
      reader.onload = () => {
        const script = util.reassignScriptIds(JSON.parse(reader.result))
        script.projectId = projectId
        dispatch(addScript(script))
      }
    }
    input.click()
  }
}

const addPresetScript = (projectId, name) => {
  console.log('add Preset Script!!!!')
  return async (dispatch, getState) => {
    const data = require(`../../../assets/scripts/${name}`)
    const script = util.reassignScriptIds(data)
    script.projectId = projectId
    dispatch(addScript(script))
  }
}

const updateScript = script => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    dispatch({
      type: types.UPDATE_SCRIPT,
      script
    })
  }
}

const deleteScript = id => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_DELETE)) return
    firebase
      .firestore()
      .collection('fave-scripts')
      .doc(id)
      .delete()
    dispatch({
      type: types.DELETE_SCRIPT
    })
  }
}

const clearScript = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.CLEAR_SCRIPT
    })
  }
}

const addScript = script => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_CREATE)) return
    const db = firebase.firestore().collection('fave-scripts')
    script.updatedAt = script.createdAt = new Date()
    if (Object.prototype.hasOwnProperty.call(script, 'id')) {
      delete script.id
    }
    db.add(script).catch(error => {
      console.error('Error adding document: ', error)
    })
    dispatch({
      type: types.ADD_SCRIPT
    })
  }
}

const saveScript = script => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    script.updatedAt = new Date()
    const db = firebase.firestore().collection('fave-scripts')
    const ref = db.doc(script.id)
    ref.get().then(doc => {
      ref.update(script)
    })
    dispatch({
      type: types.SAVE_SCRIPT,
      script
    })
  }
}

//= == ScriptList

let unsubScriptList = null

const subscribeScriptList = projectId => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    let db = firebase.firestore().collection('fave-scripts')
    db = db.orderBy('updatedAt', 'desc')
    db = db.where('projectId', '==', projectId)
    unsubScriptList = db.onSnapshot(snapshot => {
      dispatch({
        type: types.FETCH_SCRIPT_LIST,
        scriptList: snapshot.docs.map(doc => deriveScriptFromDoc(doc))
      })
    })
  }
}

const unsubscribeScriptList = () => {
  return async (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    if (unsubScriptList !== null) unsubScriptList()
    dispatch({ type: types.DETOUCH_SCRIPT_LIST })
  }
}

//= == ScriptPlay

const play = () => {
  return (dispatch, getState) => {
    const { scriptState } = getState()
    const parser = scriptParser.getParser(scriptState)
    const entry = parser.getFirstEntry()
    if (entry === null) return
    dispatch({
      type: types.INIT_CURRENT_ENTRY,
      entry
    })
  }
}

const stop = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.CLEAR_CURRENT_ENTRY
    })
  }
}

const next = () => {
  return (dispatch, getState) => {
    const { scriptState, recordState } = getState()
    const currentEntry = scriptState.currentEntryData
    const parser = scriptParser.getParser(scriptState)
    const nextEntry = parser.deriveNextEntry(recordState, currentEntry)
    dispatch({
      type: types.MOVE_NEXT_ENTRY,
      entry: nextEntry
    })
  }
}

const prev = () => {
  return (dispatch, getState) => {
    const { scriptState } = getState()
    const parser = scriptParser.getParser(scriptState)
    const prevEntry = parser.derivePrevEntry()
    dispatch({
      type: types.MOVE_PREV_ENTRY,
      entry: prevEntry
    })
  }
}

//= == ScriptEdit ===

const addScriptEntry = (entryType = config.TYPE_PAGE, index = -1) => {
  return (dispatch, getState) => {
    const parser = scriptParser.getParser(getState().scriptState)
    dispatch(updateScript({ data: parser.addEntry(entryType, index) }))
  }
}

const addScriptItem = entryId => {
  return (dispatch, getState) => {
    const parser = scriptParser.getParser(getState().scriptState)
    dispatch(updateScript({ data: parser.addItem(entryId) }))
  }
}

const addScriptCondition = entryId => {
  return (dispatch, getState) => {
    const parser = scriptParser.getParser(getState().scriptState)
    dispatch(updateScript({ data: parser.addCondition(entryId) }))
  }
}

const updateScriptCondition = condition => {
  return (dispatch, getState) => {
    const parser = scriptParser.getParser(getState().scriptState)
    dispatch(updateScript({ data: parser.updateCondition(condition) }))
  }
}

const deleteScriptCondition = conditionId => {
  return (dispatch, getState) => {
    const parser = scriptParser.getParser(getState().scriptState)
    dispatch(updateScript({ data: parser.deleteCondition(conditionId) }))
  }
}

const updateScriptEntry = entry => {
  return (dispatch, getState) => {
    const parser = scriptParser.getParser(getState().scriptState)
    dispatch(updateScript({ data: parser.updateEntry(entry) }))
  }
}

const updateScriptItem = item => {
  return (dispatch, getState) => {
    const parser = scriptParser.getParser(getState().scriptState)
    dispatch(updateScript({ data: parser.updateItem(item) }))
  }
}

const deleteScriptEntry = entryId => {
  return (dispatch, getState) => {
    const parser = scriptParser.getParser(getState().scriptState)
    dispatch(updateScript({ data: parser.deleteEntry(entryId) }))
  }
}

const deleteScriptItem = itemId => {
  return (dispatch, getState) => {
    const parser = scriptParser.getParser(getState().scriptState)
    dispatch(updateScript({ data: parser.deleteItem(itemId) }))
  }
}

const swapScriptEntry = (entryId, num) => {
  return (dispatch, getState) => {
    const parser = scriptParser.getParser(getState().scriptState)
    dispatch(updateScript({ data: parser.swapEntry(entryId, num) }))
  }
}

const swapScriptItem = (itemId, num) => {
  return (dispatch, getState) => {
    const parser = scriptParser.getParser(getState().scriptState)
    dispatch(updateScript({ data: parser.swapItem(itemId, num) }))
  }
}

export default {
  addScript,
  addScriptCondition,
  addScriptEntry,
  addScriptItem,
  addPresetScript,
  asyncFetchScript,
  clearScript,
  deleteScript,
  deleteScriptCondition,
  deleteScriptEntry,
  deleteScriptItem,
  duplicateScript,
  duplicateScripts,
  exportScript,
  fetchScript,
  importScript,
  next,
  play,
  prev,
  saveScript,
  stop,
  subscribeScriptList,
  swapScriptEntry,
  swapScriptItem,
  unsubscribeScriptList,
  updateScript,
  updateScriptEntry,
  updateScriptItem,
  updateScriptCondition
}

// =============================
// ===== Private Functions =====
// =============================

const isPermit = (state, permit) => {
  const adminLevel = state.appState.auth.adminLevel
  return isPermitLevel(adminLevel, appTypes.MODULE_SCRIPT, permit)
}

const deriveScriptFromDoc = doc => {
  const data = doc.data()
  const def = types.DEFAULT_SCRIPT_STATE
  return {
    id: doc.id,
    projectId: data.projectId == null ? def.projectId : data.projectId,
    title: data.title == null ? def.title : data.title,
    description: data.description == null ? def.description : data.description,
    data: data.data == null ? def.data : data.data,
    version: data.version == null ? def.version : data.version,
    createdAt:
      data.createdAt == null || data.createdAt.seconds == null
        ? def.createdAt
        : new Date(data.createdAt.seconds * 1000),
    updatedAt:
      data.updatedAt == null || data.updatedAt.seconds == null
        ? def.updatedAt
        : new Date(data.updatedAt.seconds * 1000),
    condition: data.condition == null ? def.condition : data.condition
  }
}
