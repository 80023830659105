// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PERMIT_UPDATE } from '../../constants/permission'
import { appSelectors, appTypes } from '../../modules/app'
import { scriptOperations } from '../../modules/script'
import OverlayFrame from '../App/OverlayFrame'
import ScriptForms from './Forms'

export function AddOverlay({ projectId, onCloseOverlay }) {
  const dispatch = useDispatch()
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_SCRIPT)
  )
  const script = useSelector(state => state.scriptState.script)
  useEffect(() => {
    dispatch(scriptOperations.clearScript())
    dispatch(scriptOperations.updateScript({ projectId }))
    return () => dispatch(scriptOperations.clearScript())
  }, [])

  const onChangeValue = (key, value) => {
    dispatch(scriptOperations.updateScript({ [key]: value }))
  }

  const onClickCreate = e => {
    dispatch(scriptOperations.addScript(script))
    onCloseOverlay()
  }

  return (
    <div>
      <OverlayFrame
        title='新しくスクリプトを作成する'
        onClickClose={e => onCloseOverlay(e)}
        buttons={[
          {
            label: 'スクリプトの作成',
            onClick: () => onClickCreate(),
            accent: true
          }
        ]}
      >
        <ScriptForms
          onChangeValue={(key, value) => onChangeValue(key, value)}
          permitUpdate={isPermit(PERMIT_UPDATE)}
          script={script}
        />
      </OverlayFrame>
    </div>
  )
}

export default AddOverlay
