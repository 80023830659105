// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userOperations } from '../../modules/user'
import { useSnackbar } from 'notistack'

import DataGrid from '../Ui/DataGrid'

const AttrMaskingTextGrid = forwardRef(({ attrId }, ref) => {
  const dispatch = useDispatch()
  const { userList } = useSelector(state => state.userState)
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    let attrMax = 0
    setData(
      userList.map(user => {
        const buf = { user: user, name: user.name }
        if (!(attrId in user.extraUserInfo)) return buf
        attrMax = Math.max(attrMax, user.extraUserInfo[attrId].length)
        return {
          ...buf,
          ...user.extraUserInfo[attrId].reduce(
            (hash, el, i) => ({
              ...hash,
              [`day${i + 1}`]: el
            }),
            {}
          )
        }
      })
    )
    const values = [...Array(attrMax).keys()].map(i => `day${i + 1}`)
    setColumns(['name', ...values])
  }, [userList])

  useImperativeHandle(
    ref,
    () => ({
      save() {
        data.forEach(row => {
          const user = row['user']
          const buf = columns.filter(c => c !== 'name').map(k => row[k])
          user.extraUserInfo[attrId] = buf.filter(e => e != null)
          dispatch(userOperations.saveUser(user))
        })
        enqueueSnackbar('変更を保存しました', { variant: 'default' })
      },
      addColumn() {
        console.log(columns)
        setColumns([...columns, `day${columns.length}`])
      }
    }),
    [data, columns]
  )
  return <DataGrid data={data} columns={columns} />
})

AttrMaskingTextGrid.propTypes = {
  attrId: PropTypes.string.isRequired
}

export default AttrMaskingTextGrid
