// Takram Confidential
// Copyright (C) 2019-Present Takram

import 'firebase/auth'

import firebase from 'firebase/app'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'

import { history } from '../../configureStore'
import Header from './Header'
import {
  getLoginLevelFromType,
  LOGIN_SYSTEM_ADMIN
} from '../../constants/permission'
import { appOperations } from '../../modules/app'
import Admin from '../AdminUser/Admin'
import Auth from './Auth'
import AppProject from './AppProject'
import Project from '../Project/Project'
import ScriptPage from '../Script/ScriptPage'

import UserAddPage from '../User/Add'
import UserPage from '../User/UserPage'
import MuiContextMenu from '../Ui/MuiContextMenu'

export function AppAdmin() {
  const auth = useSelector(state => state.appState.auth)
  const { uid, admin } = auth
  const dispatch = useDispatch()
  const menuRef = useRef()
  const [menuItems, setMenuItems] = useState([])

  const onClickLogout = () => {
    firebase.auth().signOut()
    dispatch(appOperations.logOut())
  }

  const onClickAdmin = () => {
    history.push('/admin/')
  }

  const onClickName = e => {
    const items = [{ name: 'ログアウト', onClick: e => onClickLogout() }]
    if (auth.adminLevel === getLoginLevelFromType(LOGIN_SYSTEM_ADMIN)) {
      items.push({ name: '管理画面', onClick: e => onClickAdmin() })
    }
    setMenuItems(items)
    menuRef.current.handleOpen(e)
  }

  return (
    <div>
      <MuiContextMenu ref={menuRef} items={menuItems} />
      {uid && admin ? (
        <div>
          <Header {...auth} onClickName={e => onClickName(e)} />
          <Switch>
            <Route exact path='/' render={() => <Project />} />
            <Route exact path='/admin/' render={({ match }) => <Admin />} />
            <Route
              path='/project/:pid/'
              render={({ match }) => (
                <AppProject projectId={match.params.pid} />
              )}
            />
            <Route
              path='/script/:pid/:sid'
              render={({ match }) => (
                <ScriptPage
                  projectId={match.params.pid}
                  scriptId={match.params.sid}
                />
              )}
            />
            <Route
              path='/user/:pid/:uid'
              render={({ match }) => (
                <UserPage
                  userId={match.params.uid}
                  projectId={match.params.pid}
                />
              )}
            />
            <Route
              path='/adduser/:pid'
              render={({ match }) => (
                <UserAddPage projectId={match.params.pid} />
              )}
            />
          </Switch>
        </div>
      ) : (
        <Auth />
      )}
    </div>
  )
}

export default AppAdmin
