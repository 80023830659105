// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'

export const LOGIN_OK = 'app/LOGIN_OK'
export const LOGOUT = 'app/LOGOUT'

export const SET_SIMULATOR_VISIBILITY = 'app/SET_SIMULATOR_VISIBILITY'
export const SET_IS_DEBUG_USER_MODE = 'app/SET_IS_DEBUG_USER_MODE'

export const PROJECT_HOME = 'app/PROJECT_HOME'
export const PROJECT_COUNTING = 'app/PROJECT_COUNTING'
export const PROJECT_SETTING = 'app/PROJECT_SETTING'
export const PROJECT_MESSAGE = 'app/PROJECT_MESSAGE'

export const MODULE_PROJECT = 'project'
export const MODULE_SCRIPT = 'script'
export const MODULE_RECORD = 'record'
export const MODULE_USER = 'user'
export const MODULE_MESSAGE = 'message'
export const MODULE_ADMIN_USER = 'admin-user'

export const DEFAULT_AUTH_STATE = {
  admin: false,
  adminLevel: 0,
  displayName: null,
  email: null,
  uid: null
}

export const DEFAULT_MODAL_STATE = {
  buttons: [],
  isOpen: false,
  text: ''
}

export const MODAL_TYPES = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    }).isRequired
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
}

export default {}
