// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './UserInfo.scss'

const getPeriod = (from, to) => {
  if (from === undefined || to === undefined) return ''
  return (
    Moment(from).format('YYYY/MM/DD') + ' - ' + Moment(to).format('YYYY/MM/DD')
  )
}

const UserInfo = ({ currentLoginUser, onCloseUserInfo, visibility }) => (
  <div
    className={ClassNames(classes.container, {
      [classes.hidden]: !visibility
    })}
  >
    <div className={classes.back} onClick={onCloseUserInfo} />
    <div className={classes.item}>
      <div className={classes.username}>
        <div className={classes.title}>ユーザー ID</div>
        <div className={classes.content}>{currentLoginUser.name}</div>
      </div>
      <div className={classes.period}>
        <div className={classes.title}>実施期間</div>
        <div className={classes.content}>
          {getPeriod(currentLoginUser.startAt, currentLoginUser.endAt)}
        </div>
      </div>
    </div>
  </div>
)

UserInfo.propTypes = {
  currentLoginUser: PropTypes.shape({
    endAt: PropTypes.instanceOf(Date),
    name: PropTypes.string,
    startAt: PropTypes.instanceOf(Date)
  }).isRequired,
  onCloseUserInfo: PropTypes.func.isRequired,
  visibility: PropTypes.bool.isRequired
}

UserInfo.defaultProps = {
  visibility: false
}

export default UserInfo
