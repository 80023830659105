// Takram Confidential
// Copyright (C) 2019-Present Takram

import _ from 'lodash'
import { combineReducers } from 'redux'

import * as types from './types'

const adminUser = (
  state = _.cloneDeep(types.DEFAULT_ADMIN_USER_STATE),
  action
) => {
  switch (action.type) {
    case types.FETCH_ADMIN_USER:
      return { ...state, ...action.adminUser }
    case types.UPDATE_ADMIN_USER:
      return { ...state, ...action.adminUser }
    case types.SAVE_ADMIN_USER:
      return state
    case types.CLEAR_ADMIN_USER:
    case types.DELETE_ADMIN_USER:
      return _.cloneDeep(types.DEFAULT_ADMIN_USER_STATE)
    default:
      return state
  }
}

const adminUserList = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_ADMIN_USER_LIST:
      return action.adminUserList
    case types.DETOUCH_ADMIN_USER_LIST:
      return []
    default:
      return state
  }
}

const adminUserListOrder = (state = types.ORDER_BY_NAME_ASC, action) => {
  switch (action.type) {
    case types.SET_ADMIN_USER_LIST_ORDER:
      return action.adminUserListOrder
    default:
      return state
  }
}

const adminRequestList = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_ADMIN_REQUEST_LIST:
      return action.adminRequestList
    case types.DETOUCH_ADMIN_REQUEST_LIST:
      return []
    default:
      return state
  }
}

const isExistAdminRequest = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_ADMIN_REQUEST_EXISTANCE:
      return action.exists
    default:
      return state
  }
}

const adminUserReducer = combineReducers({
  adminUser,
  adminUserList,
  adminUserListOrder,
  adminRequestList,
  isExistAdminRequest
})

export default adminUserReducer
