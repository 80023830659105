// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Forms from './Forms'
import OverlayFrame from '../App/OverlayFrame'
import {
  adminUserOperations,
  adminUserSelectors
} from '../../modules/admin_user'
import MuiContextMenu from '../Ui/MuiContextMenu'
import MuiDialog from '../Ui/MuiDialog'
import classes from './List.scss'

export function List() {
  const dispatch = useDispatch()
  const adminUser = useSelector(state => state.adminUserState.adminUser)
  const adminUserList = useSelector(state =>
    adminUserSelectors.deriveDisplayAdminUserList(state)
  )
  // state.adminUserState.adminUserList)
  const [visibleEditOverlay, setVisibleEditOverlay] = useState(false)
  const menuRef = useRef()
  const dialogRef = useRef()
  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    dispatch(adminUserOperations.subscribeAdminUserList())
    return () => {
      dispatch(adminUserOperations.unsubscribeAdminUserList())
    }
  }, [])

  const onDeleteUser = projectId => {
    dispatch(adminUserOperations.deleteAdminUser(projectId))
  }

  const onClickMenu = (e, id) => {
    setMenuItems([
      { name: '削除', onClick: e => dialogRef.current.handleOpen(id) }
    ])
    menuRef.current.handleOpen(e)
  }

  const onClickUser = uid => {
    dispatch(adminUserOperations.fetchAdminUser(uid))
    setVisibleEditOverlay(true)
  }

  return (
    <div className={classes.container}>
      <MuiContextMenu ref={menuRef} items={menuItems} />
      <MuiDialog
        title='ユーザー削除'
        text='ユーザーを削除してもよろしいですか？'
        ref={dialogRef}
        buttons={[
          { name: '削除する', value: id => onDeleteUser(id) },
          { name: '削除しない', value: () => {} }
        ]}
      />
      {adminUserList.map(user => (
        <div
          key={user.id}
          className={classes.row}
          onClick={e => {
            onClickUser(user.id)
          }}
        >
          <div className={classes.name}>{user.adminName}</div>
          <div className={classes.level}>Level : {user.level}</div>
          <div className={classes.uid}>uid : {user.uid}</div>

          <div
            className={classes.menuIcon}
            onClick={e => {
              e.stopPropagation()
              onClickMenu(e, user.id)
            }}
          />
        </div>
      ))}
      {visibleEditOverlay && (
        <OverlayFrame
          title='管理者編集'
          onClickClose={e => setVisibleEditOverlay(false)}
          buttons={[
            {
              label: '保存',
              onClick: () => {
                dispatch(adminUserOperations.saveAdminUser())
                setVisibleEditOverlay(false)
              },
              accent: true
            }
          ]}
        >
          <Forms
            adminUser={adminUser}
            onChangeValue={(key, val) => {
              dispatch(adminUserOperations.updateAdminUser({ [key]: val }))
            }}
          />
        </OverlayFrame>
      )}
    </div>
  )
}

export default List
