// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon/user/close-pannel.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._56kOxhwae8iq7KRLKDfME{position:relative}._56kOxhwae8iq7KRLKDfME ._19Cec4qh1BAcPDCh1tUV8u{font-size:18px;height:48px;width:calc(100% - 32px)}._56kOxhwae8iq7KRLKDfME ._19Cec4qh1BAcPDCh1tUV8u._3n2YKDRxUNjiS3c9RzYPA2{font-size:24px;text-align:center;width:100%}._56kOxhwae8iq7KRLKDfME ._37HC_tTLyMwQMfPNLw7F_V{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;height:24px;position:absolute;right:0px;top:0px;-webkit-tap-highlight-color:transparent;width:24px}._56kOxhwae8iq7KRLKDfME .n4c26qQe2Rb46dclVtV-c{background-color:#fff;box-sizing:border-box;height:calc(100% - 48px);overflow-x:hidden;overflow-y:scroll;-ms-overflow-style:none;padding-bottom:64px;scrollbar-width:none}._56kOxhwae8iq7KRLKDfME .n4c26qQe2Rb46dclVtV-c._2Zfa8FLnqmHXo0k270NQ8{align-items:center;display:flex;justify-content:center;overflow:hidden;text-align:center}._56kOxhwae8iq7KRLKDfME .n4c26qQe2Rb46dclVtV-c::-webkit-scrollbar{display:none}._56kOxhwae8iq7KRLKDfME ._1KR5LK0Pb5cFU7UXMKMMzL{background:linear-gradient(rgba(255,255,255,0), #fff);bottom:0px;height:64px;position:absolute;width:100%}", ""]);
// Exports
exports.locals = {
	"container": "_56kOxhwae8iq7KRLKDfME",
	"title": "_19Cec4qh1BAcPDCh1tUV8u",
	"doneTitle": "_3n2YKDRxUNjiS3c9RzYPA2",
	"close": "_37HC_tTLyMwQMfPNLw7F_V",
	"content": "n4c26qQe2Rb46dclVtV-c",
	"doneContent": "_2Zfa8FLnqmHXo0k270NQ8",
	"gradationBox": "_1KR5LK0Pb5cFU7UXMKMMzL"
};
module.exports = exports;
