// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { scriptSelectors } from '../../modules/script'

import classes from './InputRadio.scss'

const InputRadio = ({
  column: columnCount,
  id,
  inputData,
  isIndivisual,
  required,
  setInputData,
  value: choices
}) => {
  const renderItem = (d, i) => (
    <div
      className={ClassNames(classes.button, {
        [classes.isSelected]: d.text === inputData,
        [classes.twoColumn]: columnCount > 1
      })}
      key={id + i}
      onClick={() => {
        setInputData({
          [id]: !required && d.text === inputData ? undefined : d.text
        })
      }}
    >
      <div
        className={ClassNames(classes.icon, {
          [classes.selectedIcon]: d.text === inputData
        })}
      />
      <div className={classes.inputText}>
        {isIndivisual ? d.text.split(':')[0] : d.text}
      </div>
    </div>
  )

  const [othersText, setOthersText] = useState('')
  const othersInputField = React.useRef(null)
  const renderOthers = (d, i) => (
    <div
      className={ClassNames(classes.button, {
        [classes.isSelected]: scriptSelectors.recordTextIsOthers(inputData),
        [classes.twoColumn]: columnCount > 1
      })}
      key={id + i}
      onClick={() => {
        const newData = scriptSelectors.generateOthersRecordText(othersText)
        const isSelected = scriptSelectors.recordTextIsOthers(inputData)
        setInputData({
          [id]: !required && isSelected ? undefined : newData
        })
        othersInputField.current.focus()
      }}
    >
      <div
        className={ClassNames(classes.icon, {
          [classes.selectedIcon]: scriptSelectors.recordTextIsOthers(inputData)
        })}
      />
      その他
      <input
        ref={othersInputField}
        className={ClassNames(classes.inputOthers, {
          [classes.isSelected]: scriptSelectors.recordTextIsOthers(inputData)
        })}
        type='text'
        onChange={e => {
          setOthersText(e.target.value)
          setInputData({
            [id]: scriptSelectors.generateOthersRecordText(e.target.value)
          })
        }}
        value={othersText}
      />
    </div>
  )

  return (
    <div className={classes.content}>
      {choices.map((d, i) =>
        d.isOthers ? renderOthers(d, i) : renderItem(d, i)
      )}
    </div>
  )
}

InputRadio.propTypes = {
  column: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  inputData: PropTypes.string.isRequired,
  isIndivisual: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  setInputData: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired
    })
  ).isRequired
}

InputRadio.defaultProps = {
  column: 1,
  inputData: '',
  isIndivisual: false,
  required: false,
  value: []
}

export default InputRadio
