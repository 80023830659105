// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userOperations } from '../../modules/user'
import { useSnackbar } from 'notistack'

import DataGrid from '../Ui/DataGrid'

const AttrMaskingListGrid = forwardRef(({ attrId }, ref) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { userList } = useSelector(state => state.userState)
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const colBuf = []
    setData(
      userList.map(user => {
        const buf = { user: user, name: user.name }
        if (!(attrId in user.extraUserInfo)) return buf
        colBuf.push(
          ...user.extraUserInfo[attrId].map(item => item.split(':')[0])
        )
        return {
          ...buf,
          ...user.extraUserInfo[attrId].reduce(
            (hash, el) => ({
              ...hash,
              [el.split(':')[0]]: el.split(':')[1]
            }),
            {}
          )
        }
      })
    )
    setColumns([...new Set(['name', ...colBuf])])
  }, [userList])

  useImperativeHandle(
    ref,
    () => ({
      save() {
        data.forEach(row => {
          const user = row['user']
          const keys = Object.keys(row).filter(
            el => el != 'user' && el != 'name'
          )
          user.extraUserInfo[attrId] = keys.map(key => `${key}:${row[key]}`)
          dispatch(userOperations.saveUser(user))
        })
        enqueueSnackbar('変更を保存しました', { variant: 'default' })
      }
    }),
    [data, columns]
  )
  return <DataGrid data={data} columns={columns} />
})

AttrMaskingListGrid.propTypes = {
  attrId: PropTypes.string.isRequired
}

export default AttrMaskingListGrid
