// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import {
  getLoginLevelFromType,
  getLoginTypeFromLevel,
  LOGIN_OPERATION_ADMIN,
  LOGIN_PROJECT_ADMIN,
  LOGIN_SCRIPT_ADMIN,
  LOGIN_SYSTEM_ADMIN,
  LOGIN_USER
} from '../../constants/permission'
import * as types from '../../modules/admin_user/types'

import commonCls from '../App/Common.scss'
import classes from './Forms.scss'

const Forms = ({ adminUser, onChangeValue, isEditableUID }) => (
  <div className={classes.container}>
    <div className={`${commonCls.form} ${commonCls.formThin} ${classes.row}`}>
      <div className={classes.label}>管理者名</div>
      <div className={classes.body}>
        <input
          type='text'
          value={adminUser.adminName === undefined ? '' : adminUser.adminName}
          onChange={e => onChangeValue('adminName', e.target.value)}
        />
      </div>
    </div>
    <div className={`${commonCls.form} ${commonCls.formThin} ${classes.row}`}>
      <div className={classes.label}>レベル</div>
      <div className={classes.body}>
        <select
          onChange={e => {
            onChangeValue('level', getLoginLevelFromType(e.target.value))
          }}
          value={getLoginTypeFromLevel(adminUser.level)}
        >
          <option value={LOGIN_SYSTEM_ADMIN}>Lv5. システム管理</option>
          <option value={LOGIN_PROJECT_ADMIN}>Lv3. プロジェクト管理者</option>
          <option value={LOGIN_SCRIPT_ADMIN}>Lv2. スクリプト管理者</option>
          <option value={LOGIN_OPERATION_ADMIN}>Lv1. オペレーション管理者</option>
          <option value={LOGIN_USER}>Lv0. 非管理者</option>
        </select>
      </div>
    </div>
    <div className={`${commonCls.form} ${commonCls.formThin} ${classes.row}`}>
      <div className={classes.label}>UID</div>
      <div className={classes.body}>
        {isEditableUID ? (
          <input
            type='text'
            value={adminUser.uid === undefined ? '' : adminUser.uid}
            onChange={e => onChangeValue('uid', e.target.value)}
          />
        ) : (
          <>{adminUser.uid}</>
        )}
      </div>
    </div>
  </div>
)

Forms.propTypes = {
  adminUser: PropTypes.shape(types.ADMIN_USER_TYPES).isRequired,
  onChangeValue: PropTypes.func.isRequired,
  isEditableUID: PropTypes.bool
}

Forms.defaultProps = {
  isEditableUID: false
}

export default Forms
