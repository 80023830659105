// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import _ from 'lodash'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { messageOperations, messageSelectors } from '../../modules/message'
import {
  USER_SORT_CREATED_AT,
  USER_SORT_DAYS_LEFT,
  USER_SORT_LAST_RECORD,
  USER_SORT_NUM_RECORD_TDAY,
  USER_SORT_NUM_RECORD_YDAY
} from '../../modules/message/types'
import { userOperations } from '../../modules/user'
import useKeyEvent from './useKeyEvent'

import classes from './UserList.scss'

export function UserList() {
  const dispatch = useDispatch()
  const { ctrlPressed, shiftPressed } = useKeyEvent()
  const { currentMessagingUserIds } = useSelector(state => state.messageState)
  const { setCurrentMessagingUserIds } = messageOperations
  const displayUserList = useSelector(state =>
    messageSelectors.displayUserListSelector(state)
  )
  const unreadMessageCounts = useSelector(state =>
    messageSelectors.unreadMessageCountsSelector(state)
  )
  const sort = useSelector(state => state.messageState.currentUserSort)

  const [lastSelectIdx, setLastSelectIdx] = useState(0)
  const numSelectedIds = currentMessagingUserIds.length

  useEffect(() => {
    if (numSelectedIds === 0 && displayUserList[0] != null) {
      dispatch(setCurrentMessagingUserIds([displayUserList[0].id]))
    }
  }, [displayUserList, numSelectedIds, setCurrentMessagingUserIds, dispatch])

  const onClickUser = user => {
    const clickIdx = displayUserList.findIndex(u => u.id === user.id)
    if (shiftPressed) {
      const minIdx = Math.min(clickIdx, lastSelectIdx)
      const maxIdx = Math.max(clickIdx, lastSelectIdx)
      const ids = displayUserList.map(u => u.id).slice(minIdx, maxIdx + 1)
      dispatch(setCurrentMessagingUserIds(ids))
    } else {
      if (ctrlPressed) {
        const ids = _.xor(currentMessagingUserIds, [user.id])
        dispatch(setCurrentMessagingUserIds(ids))
      } else {
        dispatch(setCurrentMessagingUserIds([user.id]))
        dispatch(userOperations.updateMessageReadAt(user.id))
      }
      setLastSelectIdx(clickIdx)
    }
  }

  const now = new Date()
  const timeLeft = user => user.endAt.getTime() - now.getTime()
  const getSortInfo = user => {
    const now = new Date()
    switch (sort) {
      case USER_SORT_CREATED_AT:
        return Moment(user.createdAt).format('YYYY/MM/DD')
      case USER_SORT_DAYS_LEFT:
        return Math.floor(timeLeft(user) / 3600000 / 24) + ' days'
      case USER_SORT_LAST_RECORD:
        return user.lastRecordAt == null
          ? 'No Record'
          : Math.floor((now - user.lastRecordAt) / 86400000) + ' days ago'
      case USER_SORT_NUM_RECORD_TDAY:
        return user.todayCount
      case USER_SORT_NUM_RECORD_YDAY:
        return user.yesterdayCount
      default:
        return ''
    }
  }

  return (
    <div className={classes.container}>
      {displayUserList.map(user => (
        <div
          key={user.id}
          className={ClassNames(classes.user, {
            [classes.selected]: currentMessagingUserIds.includes(user.id)
          })}
          onClick={e => onClickUser(user)}
        >
          <div className={classes.userIcon} />
          <div
            className={ClassNames(classes.lineIcon, {
              [classes.enabled]: user.lineUserId !== ''
            })}
          />
          <div className={classes.userName}>{user.name}</div>

          <div className={classes.sortInfo}>{getSortInfo(user)}</div>
          {unreadMessageCounts[user.lineUserId] != null &&
            unreadMessageCounts[user.lineUserId] !== 0 && (
              <div className={classes.badge}>
                {unreadMessageCounts[user.lineUserId]}
              </div>
            )}
        </div>
      ))}
    </div>
  )
}

export default UserList
