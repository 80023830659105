import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Popper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CloseImage from '../../../assets/images/icon/admin/close.svg'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: '10 20 20 20',
    width: 200,
    zIndex: 1000,
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
  },
  head: {
    color: '#384241',
    fontSize: 14,
    margin: '10 0',
    padding: 2
  },
  row: {
    color: '#384241',
    fontSize: 12,
    padding: 2,
    textAlign: 'right',
    width: '100%'
  },
  closeButton: {
    backgroundImage: `url("${CloseImage}")`,
    backgroundSize: '100%',
    cursor: 'pointer',
    float: 'right',
    height: 18,
    width: 18
  },
  inputText: {
    border: 'solid 1px #E8ECEE',
    marginLeft: 8,
    width: 155
  },
  insertButton: {
    backgroundColor: '#f1f1f1',
    border: 'solid 1px #E8ECEE',
    borderRadius: 8,
    cursor: 'pointer',
    margin: '10 0 0 auto',
    fontSize: 14,
    lineHeight: '14px',
    padding: 4,
    textAlign: 'center',
    width: 40
  }
}))

export const InsertLinkPopper = ({
  handlePopper,
  linkAnchorEl,
  onClickLinkInsertButton
}) => {
  const [linkLabel, setLinkLabel] = useState('')
  const [linkUrl, setLinkUrl] = useState('')
  const classes = useStyles()
  return (
    <Popper
      anchorEl={linkAnchorEl}
      className={classes.root}
      open={Boolean(linkAnchorEl)}
      placement='top'
    >
      <div className={classes.head}>
        リンクボタンを挿入
        <div onClick={e => handlePopper(e)} className={classes.closeButton} />
      </div>
      <div className={classes.row}>
        ラベル
        <input
          className={classes.inputText}
          onChange={e => setLinkLabel(e.target.value)}
          type='text'
          value={linkLabel}
        />
      </div>
      <div className={classes.row}>
        URL
        <input
          className={classes.inputText}
          onChange={e => setLinkUrl(e.target.value)}
          type='text'
          value={linkUrl}
        />
      </div>
      <div className={classes.row}>
        <div
          className={classes.insertButton}
          onClick={e => {
            handlePopper(e)
            onClickLinkInsertButton({ label: linkLabel, url: linkUrl })
          }}
        >
          挿入
        </div>
      </div>
    </Popper>
  )
}

InsertLinkPopper.propTypes = {
  handlePopper: PropTypes.func.isRequired,
  linkAnchorEl: PropTypes.object,
  onClickLinkInsertButton: PropTypes.func.isRequired
}

export default InsertLinkPopper
