// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as formTypes from '../modules/form/types'
import * as scriptConfig from '../constants/scriptConfig'
import FormAfterEndPopup from '../components/Form/Popup/AfterEnd'
import FormBeforeStartPopup from '../components/Form/Popup/BeforeStart'
import FormCancellationPopup from '../components/Form/Popup/Cancellation'
import FormFrame from '../components/Form/Frame'
import FormHeader from './FormHeader'
import FormPage from '../components/Form/Page'
import FormPages from '../components/Form/Pages'
import FormRecordList from './FormRecordList'
import FormRecordLog from './FormRecordLog'
import FormRequiredItemAlert from '../components/Form/Popup/RequiredItemAlert'
import FormRequiredScriptAlert from '../components/Form/Popup/RequiredScriptAlert'
import FormScriptSelector from './FormScriptSelector'
import FormUserInfoPopup from '../components/Form/Popup/UserInfo'
import { formOperations, formSelectors } from '../modules/form'
import { scriptOperations } from '../modules/script'
import { userSelectors } from '../modules/user'

class FormMobile extends Component {
  componentDidMount() {
    const { formOperations, loginUserId, requiredId, scriptId } = this.props
    formOperations.initializeFormMobile({ loginUserId, scriptId, requiredId })
  }

  buildPage() {
    const { formOperations, attrAppliedEntryData } = this.props
    if (attrAppliedEntryData === null) return <div />
    const pageArr = []
    pageArr.push(
      <div key={'page-' + attrAppliedEntryData.id}>
        <FormPage
          entry={attrAppliedEntryData}
          onCancellationPopup={() =>
            formOperations.setCancellationPopupVisibility(true)
          }
        />
      </div>
    )
    return <div>{pageArr}</div>
  }

  buildPages() {
    const { currentEntryData } = this.props
    return (
      <FormPages hidden={currentEntryData === null}>
        {this.buildPage()}
      </FormPages>
    )
  }

  render() {
    const {
      dailyRecordAndRequiredList,
      formOperations,
      isAfterEnd,
      isBeforeStart,
      popupVisibility,
      scriptOperations,
      currentLoginUser
    } = this.props
    const {
      cancellation,
      requiredItemAlert,
      requiredOthersItemAlert,
      userInfo
    } = popupVisibility
    return (
      <FormFrame>
        <FormHeader />
        <FormRecordList />
        {this.buildPages()}
        <FormRecordLog />
        <FormRequiredItemAlert
          onClose={() => {
            formOperations.setRequiredItemAlertVisibility(false)
            formOperations.setRequiredOthersItemAlertVisibility(false)
          }}
          visibility={requiredItemAlert || requiredOthersItemAlert}
          message={
            requiredOthersItemAlert
              ? 'その他の内容が入力されていません'
              : '必須の項目が入力されていません'
          }
        />
        <FormRequiredScriptAlert
          visibility={dailyRecordAndRequiredList.some(
            ({ id, requiredDate }) =>
              id === undefined && Moment().isAfter(requiredDate)
          )}
        />
        <FormCancellationPopup
          onClosePopup={() =>
            formOperations.setCancellationPopupVisibility(false)
          }
          onStop={() => scriptOperations.stop()}
          visibility={cancellation}
        />
        <FormUserInfoPopup
          onCloseUserInfo={() =>
            formOperations.setUserInfoPopupVisibility(false)
          }
          currentLoginUser={currentLoginUser}
          visibility={userInfo}
        />
        <FormAfterEndPopup visibility={isAfterEnd} />
        <FormBeforeStartPopup visibility={isBeforeStart} />
        <FormScriptSelector />
      </FormFrame>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentEntryData: state.scriptState.currentEntryData,
    currentLoginUser: state.userState.currentLoginUser,
    dailyRecordAndRequiredList: formSelectors.dailyRecordAndRequiredListSelector(
      state
    ),
    attrAppliedEntryData: formSelectors.attrAppliedEntrySelector(state),
    isAfterEnd: userSelectors.isAfterEndSelector(state),
    isBeforeStart: userSelectors.isBeforeStartSelector(state),
    popupVisibility: state.formState.popupVisibility
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    formOperations: bindActionCreators(formOperations, dispatch),
    scriptOperations: bindActionCreators(scriptOperations, dispatch)
  }
}

FormMobile.propTypes = {
  currentEntryData: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.arrayOf(
      PropTypes.shape(scriptConfig.ITEM_STYLE).isRequired
    ).isRequired
  }),
  currentLoginUser: PropTypes.shape({
    endAt: PropTypes.instanceOf(Date),
    name: PropTypes.string,
    startAt: PropTypes.instanceOf(Date)
  }).isRequired,
  dailyRecordAndRequiredList: PropTypes.arrayOf(
    PropTypes.shape({
      coordinates: PropTypes.object,
      createdAt: PropTypes.instanceOf(Date),
      data: PropTypes.object,
      dataTypes: PropTypes.object,
      id: PropTypes.string,
      projectId: PropTypes.string,
      requiredDate: PropTypes.instanceOf(Date),
      requiredId: PropTypes.string.isRequired,
      scriptId: PropTypes.string.isRequired,
      userId: PropTypes.string
    })
  ).isRequired,
  formOperations: PropTypes.objectOf(PropTypes.func).isRequired,
  isAfterEnd: PropTypes.bool.isRequired,
  isBeforeStart: PropTypes.bool.isRequired,
  loginUserId: PropTypes.string.isRequired,
  popupVisibility: PropTypes.shape(formTypes.POPUP_VISIBILITY_TYPES).isRequired,
  requiredId: PropTypes.string,
  scriptId: PropTypes.string,
  scriptOperations: PropTypes.objectOf(PropTypes.func).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(FormMobile)
