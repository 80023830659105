// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3uhjzNlkLPK1Rmb6QaRHU7{width:1120px;margin:60px auto}", ""]);
// Exports
exports.locals = {
	"container": "_3uhjzNlkLPK1Rmb6QaRHU7"
};
module.exports = exports;
