// Takram Confidential
// Copyright (C) 2019-Present Takram

import actions from './actions'

const loginOk = user => {
  return actions.loginOk(user)
}

const logOut = () => {
  return actions.logOut()
}

const setSimulatorVisibility = bool => {
  return actions.setSimulatorVisibility(bool)
}

const setIsDebugUserMode = bool => {
  return actions.setIsDebugUserMode(bool)
}

export default {
  logOut,
  loginOk,
  setIsDebugUserMode,
  setSimulatorVisibility
}
