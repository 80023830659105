// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import classes from './AppFrame.scss'

export default function AppFrame({ children }) {
  return <div className={classes.root}>{children}</div>
}

AppFrame.propTypes = {
  children: PropTypes.node.isRequired
}
