// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import RecordList from '../components/Form/RecordList'
import { formOperations, formSelectors } from '../modules/form'
import { recordOperations } from '../modules/record'
import * as scriptTypes from '../modules/script/types'

class FormRecordList extends Component {
  onStartRequiredScript(record) {
    const { formOperations } = this.props
    formOperations.startScript({
      scriptId: record.scriptId,
      requiredId: record.requiredId
    })
  }

  onSelectRecordLog({ record, script }) {
    const { formOperations, recordOperations } = this.props
    recordOperations.setLog({ record, script })
    formOperations.setRecordLogVisibility(true)
  }

  render() {
    const { dailyRecordAndRequiredList, scriptList } = this.props
    return (
      <RecordList
        onSelectRecordLog={d => this.onSelectRecordLog(d)}
        onStartRequiredScript={d => this.onStartRequiredScript(d)}
        dailyRecordAndRequiredList={dailyRecordAndRequiredList}
        scriptList={scriptList}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dailyRecordAndRequiredList: formSelectors.dailyRecordAndRequiredListSelector(
      state
    ),
    scriptList: state.scriptState.scriptList
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    formOperations: bindActionCreators(formOperations, dispatch),
    recordOperations: bindActionCreators(recordOperations, dispatch)
  }
}

FormRecordList.propTypes = {
  dailyRecordAndRequiredList: PropTypes.arrayOf(
    PropTypes.shape({
      coordinates: PropTypes.object,
      createdAt: PropTypes.instanceOf(Date),
      data: PropTypes.object,
      dataTypes: PropTypes.object,
      id: PropTypes.string,
      projectId: PropTypes.string,
      requiredDate: PropTypes.instanceOf(Date),
      requiredId: PropTypes.string.isRequired,
      scriptId: PropTypes.string.isRequired,
      userId: PropTypes.string
    })
  ).isRequired,
  formOperations: PropTypes.objectOf(PropTypes.func).isRequired,
  recordOperations: PropTypes.objectOf(PropTypes.func).isRequired,
  scriptList: PropTypes.arrayOf(
    PropTypes.shape(scriptTypes.SCRIPT_TYPES).isRequired
  ).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(FormRecordList)
