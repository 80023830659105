// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as scriptConfig from '../../constants/scriptConfig'
import FormInput from '../../containers/FormInput'

import classes from './Page.scss'

const getPage = entry => {
  if (entry.type === scriptConfig.TYPE_DONE) {
    return (
      <div className={ClassNames(classes.content, classes.doneContent)}>
        アンケートは以上になります
        <br />
        回答を送信してください
      </div>
    )
  }
  return (
    <div className={classes.content}>
      {entry.value.map(item => (
        <FormInput key={item.id} item={item} />
      ))}
    </div>
  )
}

const Page = ({ entry, onCancellationPopup }) => (
  <div className={classes.container}>
    <div
      className={ClassNames(classes.title, {
        [classes.doneTitle]: entry.type === scriptConfig.TYPE_DONE
      })}
    >
      {entry.title}
    </div>
    <div className={classes.close} onClick={onCancellationPopup} />
    {getPage(entry)}
    <div className={classes.gradationBox} />
  </div>
)

Page.propTypes = {
  entry: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.arrayOf(
      PropTypes.shape(scriptConfig.ITEM_STYLE).isRequired
    ).isRequired
  }),
  onCancellationPopup: PropTypes.func.isRequired
}

Page.defaultProps = {}

export default Page
