// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ECqO1WVXeVwe0du1J-Rlf{width:100%}.ECqO1WVXeVwe0du1J-Rlf ._10x3i3XNuzTXpF-XeKkOW2{margin-bottom:16px}.ECqO1WVXeVwe0du1J-Rlf ._10x3i3XNuzTXpF-XeKkOW2 ._1BeVYZ7vg2M_Oh8gX8xSLW{display:inline-block;text-align:right;box-sizing:border-box;width:100px;vertical-align:top;margin:14px 16px 0 0}.ECqO1WVXeVwe0du1J-Rlf ._10x3i3XNuzTXpF-XeKkOW2 ._3puA0_d_gSVhiwkp71OLZ6{display:inline-block;width:calc(100% - 160px)}.ECqO1WVXeVwe0du1J-Rlf ._10x3i3XNuzTXpF-XeKkOW2 input{width:100%}.ECqO1WVXeVwe0du1J-Rlf ._10x3i3XNuzTXpF-XeKkOW2 textarea{width:100%}", ""]);
// Exports
exports.locals = {
	"container": "ECqO1WVXeVwe0du1J-Rlf",
	"row": "_10x3i3XNuzTXpF-XeKkOW2",
	"label": "_1BeVYZ7vg2M_Oh8gX8xSLW",
	"body": "_3puA0_d_gSVhiwkp71OLZ6"
};
module.exports = exports;
