// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { recordOperations } from '../../modules/record'
import { userOperations } from '../../modules/user'

import Form from './Form'
import SelectUserList from './SelectUserList'
import Timeline from './Timeline'
import UserList from './UserList'
import UserOrder from './UserOrder'

import classes from './Message.scss'

export function Message({ projectId }) {
  const dispatch = useDispatch()
  const userList = useSelector(state => state.userState.userList)
  const sendIds = useSelector(
    state => state.messageState.currentMessagingUserIds
  )
  const sendUsers = userList.filter(user => sendIds.includes(user.id))
  useEffect(() => {
    dispatch(userOperations.subscribeUserList(projectId))
    dispatch(recordOperations.subscribeRecordList({ projectId }))
    return () => {
      dispatch(userOperations.unsubscribeUserList())
      dispatch(recordOperations.unsubscribeRecordList())
    }
  }, [projectId, dispatch])

  return (
    <div className={classes.container}>
      <div className={classes.conditions}>
        <UserOrder />
      </div>
      <div className={classes.messengerContainer}>
        <div className={classes.userListColumn}>
          <UserList />
        </div>
        <div className={classes.messageColumn}>
          {sendUsers.length === 1 ? (
            <Timeline projectId={projectId} sendUsers={sendUsers} />
          ) : (
            <SelectUserList sendUsers={sendUsers} />
          )}
          <Form sendUsers={sendUsers} />
        </div>
      </div>
    </div>
  )
}

Message.propTypes = {
  projectId: PropTypes.string.isRequired
}

export default Message
