// Takram Confidential
// Copyright (C) 2019-Present Takram

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import EntryPageItem from './EntryPageItem'
import { appSelectors, appTypes } from '../../../modules/app'
import { scriptOperations } from '../../../modules/script'
import { PERMIT_UPDATE } from '../../../constants/permission'
import AttrSelector from './AttrSelector'
import classes from './EntryPage.scss'

export function EntryPage({ entry, index }) {
  const dispatch = useDispatch()
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_SCRIPT)
  )

  const onChangeValue = (key, value) => {
    entry[key] = value
    dispatch(scriptOperations.updateScriptEntry(entry))
  }

  const onInsertAttr = text => {
    onChangeValue('title', `${entry.title}{{${text}}}`)
  }

  return (
    <div className={classes.container}>
      <div className={classes.entryIndex}>
        {(index + 1).toString().padStart(3, '0')}
      </div>
      <div className={classes.entryTitle}>
        <input
          disabled={!isPermit(PERMIT_UPDATE)}
          onChange={e => onChangeValue('title', e.target.value)}
          placeholder=''
          type='text'
          value={entry.title === undefined ? '' : entry.title}
        />
        <div className={classes.attrSelector}>
          <AttrSelector onSelectAttr={text => onInsertAttr(text)} />
        </div>
      </div>
      {entry.value.map((item, index) => (
        <EntryPageItem key={item.id} item={item} index={index} />
      ))}
    </div>
  )
}

export default EntryPage
