// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon/admin/backarrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._3IEM4utNBJ54RKAOvichPW{position:relative;background-color:#fff;width:100%;border-radius:10px;padding:48px;box-sizing:border-box;text-align:center}._3IEM4utNBJ54RKAOvichPW ._3uUv9zG3buMNPxiRQJ6ktR{position:fixed;top:88px;left:24px;width:32px;height:32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;cursor:pointer}._3IEM4utNBJ54RKAOvichPW ._2d2-ZUnlRA2C18Ke1r5BZN{font-size:16px;width:360;background-color:#EAAD68}", ""]);
// Exports
exports.locals = {
	"container": "_3IEM4utNBJ54RKAOvichPW",
	"backButton": "_3uUv9zG3buMNPxiRQJ6ktR",
	"button": "_2d2-ZUnlRA2C18Ke1r5BZN"
};
module.exports = exports;
