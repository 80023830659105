// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './ScriptSelector.scss'

const ScriptSelector = ({
  isOutOfPeriod,
  onClickSelectButton,
  onSelectScript,
  scriptList,
  visibility
}) => (
  <div className={classes.container}>
    {!isOutOfPeriod ? (
      <div>
        <div className={ClassNames({ [classes.back]: visibility })} />
        <div
          className={ClassNames(classes.scriptList, {
            [classes.hidden]: !visibility
          })}
        >
          {scriptList.map(script => (
            <div
              key={script.id}
              className={classes.script}
              onClick={e => onSelectScript(script.id)}
            >
              {script.title}
            </div>
          ))}
        </div>
        <div className={classes.button} onClick={onClickSelectButton}>
          +
        </div>
      </div>
    ) : (
      ''
    )}
  </div>
)

ScriptSelector.propTypes = {
  isOutOfPeriod: PropTypes.bool.isRequired,
  onClickSelectButton: PropTypes.func.isRequired,
  onSelectScript: PropTypes.func.isRequired,
  scriptList: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  visibility: PropTypes.bool.isRequired
}

ScriptSelector.defaultProps = {
  isOutOfPeriod: false,
  scriptList: [],
  visibility: false
}

export default ScriptSelector
