// Takram Confidential
// Copyright (C) 2019-Present Takram

/* eslint-disable */
import firebase from 'firebase/app'
import firestore from 'firebase/firestore'

const settings = {}

const config = {
  apiKey: __FB_API_KEY__,
  authDomain: __FB_AUTH_DOMAIN__,
  databaseURL: __FB_DATABASE_URL__,
  projectId: __FB_PROJECT_ID__,
  storageBucket: __FB_STORAGE_BUCKET__
}
firebase.initializeApp(config)

firebase.firestore().settings(settings)

export default firebase
