// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".uDhj3DK7dfnXA-pLTlipd{height:100%;left:0;position:absolute;top:0;width:100%;z-index:1}.uDhj3DK7dfnXA-pLTlipd ._2ObNfR-tr8yufBPxd6TxNA{background-color:#425c5b;height:100%;left:0;opacity:0.8;position:absolute;top:0;width:100%}.uDhj3DK7dfnXA-pLTlipd ._3_qFZNiMzrijo8kn5VX3YP{background-color:white;border-radius:24px;box-sizing:border-box;height:190px;left:28px;padding-top:36px;position:absolute;right:28px;top:calc(50% - 68px);font-size:16px;padding-left:24px}.uDhj3DK7dfnXA-pLTlipd ._3_qFZNiMzrijo8kn5VX3YP ._2HXEzehxHB2lgOWv1tToy1{color:#919D99}.uDhj3DK7dfnXA-pLTlipd ._3_qFZNiMzrijo8kn5VX3YP .D4t0ajRO3Cnwg71a-Wylf{margin-top:3px;color:#384241}.uDhj3DK7dfnXA-pLTlipd ._3_qFZNiMzrijo8kn5VX3YP ._20irMERpsqzdd-3gnotL4j{margin-top:24px}.uDhj3DK7dfnXA-pLTlipd._1PcoaJNa1EJMW6tSBuDq2d{display:none}", ""]);
// Exports
exports.locals = {
	"container": "uDhj3DK7dfnXA-pLTlipd",
	"back": "_2ObNfR-tr8yufBPxd6TxNA",
	"item": "_3_qFZNiMzrijo8kn5VX3YP",
	"title": "_2HXEzehxHB2lgOWv1tToy1",
	"content": "D4t0ajRO3Cnwg71a-Wylf",
	"period": "_20irMERpsqzdd-3gnotL4j",
	"hidden": "_1PcoaJNa1EJMW6tSBuDq2d"
};
module.exports = exports;
