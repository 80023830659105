// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import Moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import FlexMessagePreview from '../Ui/FlexMessagePreview'
import classes from './Timeline.scss'
import { messageSelectors } from '../../modules/message'

const formatDate = date => {
  const dateStr = Moment(date).format('MM/DD HH:mm')
  return <span className={classes.date}>{dateStr}</span>
}

export function Timeline({ sendUsers }) {
  const messagesEndRef = useRef(null)
  const displayMessages = useSelector(state =>
    messageSelectors.displayMessagesSelector(state)
  )
  const userList = useSelector(state => state.userState.userList)
  const [lastReadAt, setlastReadAt] = useState(new Date())

  useEffect(() => {
    if (messagesEndRef) messagesEndRef.current.scrollIntoView({})
  }, [displayMessages])

  useEffect(() => {
    if (sendUsers.length !== 1) return
    const user = sendUsers[0]
    const now = new Date()
    if (now.getTime() - user.lastMessageReadAt.getTime() > 1000) {
      setlastReadAt(user.lastMessageReadAt)
    }
  }, [userList, sendUsers])

  let isNew = true
  return (
    <div className={classes.container}>
      {displayMessages.map((message, i) => {
        return (
          <div
            key={'message' + i}
            className={ClassNames(classes.message, {
              [classes.recieve]: message.isRecieve
            })}
          >
            {lastReadAt.getTime() < message.createdAt && isNew && (
              <>
                {(isNew = false)}
                <div className={classes.newLine} />
                <div className={classes.newMark}> New</div>
              </>
            )}
            <div
              className={ClassNames(classes.messageContainer, {
                [classes.recieve]: message.isRecieve,
                [classes.mymessage]: message.isMyMessage,
                [classes.flex]: message.type === 'flex'
              })}
            >
              {message.type === 'flex' ? (
                <FlexMessagePreview messageContents={message.contents} />
              ) : (
                message.text
              )}
            </div>
            <div className={classes.dateContainer}>
              {formatDate(message.createdAt)}
            </div>
          </div>
        )
      })}
      <div ref={messagesEndRef} />
    </div>
  )
}

Timeline.propTypes = {
  sendUsers: PropTypes.array.isRequired
}

export default Timeline
