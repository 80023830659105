import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export function MuiContextMenu({ items, onClose }, ref) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [args, setArgs] = useState(null)

  useImperativeHandle(
    ref,
    () => ({
      handleOpen(e, args) {
        setArgs(args)
        setAnchorEl(e.currentTarget)
      }
    }),
    []
  )

  const handleClose = () => {
    setAnchorEl(null)
    if (onClose != null) onClose()
  }

  return (
    <div>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map(item => (
          <MenuItem
            key={item.name}
            onClick={e => {
              handleClose()
              item.onClick(args)
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default forwardRef(MuiContextMenu)
