// Takram Confidential
// Copyright (C) 2019-Present Takram

import actions from './actions'

const clearUser = () => {
  return actions.clearUser()
}

const fetchUser = userId => {
  return actions.fetchUser(userId)
}

const subscribeUserList = projectId => dispatch => {
  dispatch(actions.unsubscribeUserList()).then(() => {
    dispatch(actions.subscribeUserList(projectId))
  })
}

const unsubscribeUserList = () => {
  return actions.unsubscribeUserList()
}

const subscribeLoginUser = userId => dispatch => {
  dispatch(actions.unsubscribeLoginUser()).then(() => {
    dispatch(actions.subscribeLoginUser(userId))
  })
}

const asyncSubscribeLoginUser = userId => {
  return async dispatch => {
    dispatch(actions.unsubscribeLoginUser())
    await dispatch(actions.asyncSubscribeLoginUser(userId))
  }
}

const unsubscribeLoginUser = () => {
  return actions.unsubscribeLoginUser()
}

const addUser = user => {
  return actions.addUser(user)
}

const addUsers = users => {
  return actions.addUsers(users)
}

const updateUser = user => {
  return actions.updateUser(user)
}

const updateMessageReadAt = userId => {
  return actions.updateMessageReadAt(userId)
}

const saveUser = user => {
  return actions.saveUser(user)
}

const releaseLineUserId = user => {
  return actions.releaseLineUserId(user)
}

const deleteUser = userId => {
  return actions.deleteUser(userId)
}

const setUserListOrder = order => {
  return actions.setUserListOrder(order)
}

export default {
  addUser,
  addUsers,
  asyncSubscribeLoginUser,
  clearUser,
  deleteUser,
  fetchUser,
  saveUser,
  subscribeLoginUser,
  subscribeUserList,
  unsubscribeLoginUser,
  unsubscribeUserList,
  updateUser,
  updateMessageReadAt,
  setUserListOrder,
  releaseLineUserId
}
