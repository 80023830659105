// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'
import PropTypes from 'prop-types'

export const ADD_USER = 'user/ADD_USER'
export const CLEAR_USER = 'user/CLEAR_USER'
export const DELETE_USER = 'user/DELETE_USER'
export const DETOUCH_LOGIN_USER = 'user/DETOUCH_LOGIN_USER'
export const DETOUCH_USER_LIST = 'user/DETOUCH_USER_LIST'
export const FETCH_LOGIN_USER = 'user/FETCH_LOGIN_USER'
export const FETCH_USER = 'user/FETCH_USER'
export const FETCH_USER_LIST = 'user/FETCH_USER_LIST'
export const SAVE_USER = 'user/SAVE_USER'
export const SET_CURRENT_USER = 'user/SET_CURRENT_USER'
export const UPDATE_USER = 'user/UPDATE_USER'

export const SET_USER_LIST_ORDER = 'user/SET_USER_LIST_ORDER'
export const ORDER_BY_USER_NAME_ASC = 'user/SORT_USER_NAME_ASC'
export const ORDER_BY_USER_NAME_DESC = 'user/SORT_USER_NAME_DESC'
export const ORDER_BY_CREATED_AT_ASC = 'user/SORT_CREATE_AT_ASC'
export const ORDER_BY_CREATED_AT_DESC = 'user/SORT_CREATE_AT_DESC'

export const DEFAULT_USER_STATE = {
  createdAt: new Date(),
  endAt: Moment()
    .endOf('date')
    .toDate(),
  extraUserInfo: {},
  id: '',
  isExcludedRecord: false,
  lastMessageReadAt: new Date(0),
  lineSyncCode: '',
  lineUserId: '',
  name: '',
  projectId: '',
  startAt: Moment()
    .startOf('date')
    .toDate()
}

export const USER_TYPES = {
  createdAt: PropTypes.instanceOf(Date).isRequired,
  endAt: PropTypes.instanceOf(Date).isRequired,
  extraUserInfo: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  isExcludedRecord: PropTypes.bool,
  lastMessageReadAt: PropTypes.instanceOf(Date).isRequired,
  lineSyncCode: PropTypes.string.isRequired,
  lineUserId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  startAt: PropTypes.instanceOf(Date).isRequired
}

export default {}
