// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as scriptConfig from '../../../constants/scriptConfig'

import classes from './Item.scss'

const ItemDescription = ({ value, type, onChangeValue, permitUpdate }) => (
  <div className={classes.dateContainer}>
    {value[0] !== undefined && type === scriptConfig.INPUT_DESCRIPTION && (
      <textarea
        disabled={!permitUpdate}
        onChange={e => {
          const v = e.target.value
          value[0].text = v
          onChangeValue('value', value)
        }}
        rows='3'
        value={value[0].text}
      />
    )}

    {type === scriptConfig.INPUT_DESCRIPTION && (
      <div className={classes.emphasizeContainer}>
        <div className={classes.column}>
          <div className={classes.emphasizeTitle}>文字色</div>
          <div className={classes.emphasizeItem}>
            <div
              className={ClassNames(classes.radio, {
                [classes.checked]:
                  value[0].color === 'gray' || value[0].color === undefined
              })}
              onClick={() => {
                value[0].color = 'gray'
                onChangeValue('value', value)
              }}
            />
            グレー
          </div>
          <div className={classes.emphasizeItem}>
            <div
              className={ClassNames(classes.radio, {
                [classes.checked]: value[0].color === 'black'
              })}
              onClick={() => {
                value[0].color = 'black'
                onChangeValue('value', value)
              }}
            />
            黒
          </div>
          <div className={classes.emphasizeItem}>
            <div
              className={ClassNames(classes.radio, {
                [classes.checked]: value[0].color === 'red'
              })}
              onClick={() => {
                value[0].color = 'red'
                onChangeValue('value', value)
              }}
            />
            赤
          </div>
        </div>

        <div className={classes.column}>
          <div className={classes.emphasizeTitle}>文字サイズ</div>
          <div className={classes.emphasizeItem}>
            <div
              className={ClassNames(classes.radio, {
                [classes.checked]:
                  value[0].size == 'small' || value[0].size == undefined
              })}
              onClick={() => {
                value[0].size = 'small'
                onChangeValue('value', value)
              }}
            />
            小
          </div>
          <div className={classes.emphasizeItem}>
            <div
              className={ClassNames(classes.radio, {
                [classes.checked]: value[0].size === 'medium'
              })}
              onClick={() => {
                value[0].size = 'medium'
                onChangeValue('value', value)
              }}
            />
            中
          </div>
          <div className={classes.emphasizeItem}>
            <div
              className={ClassNames(classes.radio, {
                [classes.checked]: value[0].size === 'large'
              })}
              onClick={() => {
                value[0].size = 'large'
                onChangeValue('value', value)
              }}
            />
            大
          </div>
        </div>
      </div>
    )}
  </div>
)

ItemDescription.propTypes = {
  ...scriptConfig.ITEM_STYLE,
  onChangeValue: PropTypes.func.isRequired,
  permitUpdate: PropTypes.bool.isRequired
}

ItemDescription.defaultProps = {
  ...scriptConfig.INITIAL_ITEM_DATA
}

export default ItemDescription
