// Takram Confidential
// Copyright (C) 2019-Present Takram

import {
  isPermitLevel,
  PERMIT_CREATE,
  PERMIT_DELETE,
  PERMIT_READ,
  PERMIT_UPDATE
} from '../../constants/permission'
import firebase from '../../Firebase'
import { appTypes } from '../app'
import * as types from './types'

let unsubAdminUserList = null
let unsubAdminRequestList = null

const subscribeAdminUserList = () => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    const db = firebase.firestore().collection('fave-admin-users')
    unsubAdminUserList = db.onSnapshot(snapshot => {
      dispatch({
        type: types.FETCH_ADMIN_USER_LIST,
        adminUserList: snapshot.docs.map(doc => deriveAdminUserFromDoc(doc))
      })
    })
  }
}

const unsubscribeAdminUserList = () => {
  return (dispatch, getState) => {
    if (unsubAdminUserList !== null) unsubAdminUserList()
    dispatch({ type: types.DETOUCH_ADMIN_USER_LIST })
  }
}

const clearAdminUser = userId => {
  return (dispatch, getState) => {
    dispatch({
      type: types.CLEAR_ADMIN_USER
    })
  }
}

const fetchAdminUser = adminUserId => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    const ref = firebase
      .firestore()
      .collection('fave-admin-users')
      .doc(adminUserId)
    ref
      .get()
      .then(doc => {
        if (!doc.exists) return
        dispatch({
          type: types.FETCH_ADMIN_USER,
          adminUser: deriveAdminUserFromDoc(doc)
        })
      })
      .catch(err => {
        console.log('Error getting document', err)
      })
  }
}

const addAdminUser = adminUser => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_CREATE)) return
    const db = firebase.firestore().collection('fave-admin-users')
    adminUser.createdAt = new Date()
    db.add(adminUser).catch(error => {
      console.error('Error adding document: ', error)
    })
    dispatch({ type: types.ADD_ADMIN_USER })
  }
}

const updateAdminUser = adminUser => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    dispatch({
      type: types.UPDATE_ADMIN_USER,
      adminUser
    })
  }
}

const saveAdminUser = () => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    const { adminUser } = getState().adminUserState
    const ref = firebase
      .firestore()
      .collection('fave-admin-users')
      .doc(adminUser.id)
    ref.get().then(doc => {
      ref.update(adminUser)
    })
    dispatch({
      type: types.SAVE_ADMIN_USER,
      adminUser
    })
  }
}

const deleteAdminUser = adminUserId => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_DELETE)) return
    firebase
      .firestore()
      .collection('fave-admin-users')
      .doc(adminUserId)
      .delete()
    dispatch({
      type: types.DELETE_ADMIN_USER
    })
  }
}

const subscribeAdminRequestList = () => {
  return (dispatch, getState) => {
    const db = firebase.firestore().collection('fave-admin-request')
    unsubAdminRequestList = db.onSnapshot(snapshot => {
      dispatch({
        type: types.FETCH_ADMIN_REQUEST_LIST,
        adminRequestList: snapshot.docs.map(doc =>
          deriveAdminRequestFromDoc(doc)
        )
      })
    })
  }
}

const unsubscribeAdminRequestList = () => {
  return (dispatch, getState) => {
    if (unsubAdminRequestList !== null) unsubAdminRequestList()
    dispatch({ type: types.DETOUCH_ADMIN_REQUEST_LIST })
  }
}

const addAdminRequest = (uid, adminName) => {
  return async (dispatch, getState) => {
    await firebase
      .firestore()
      .collection('fave-admin-request')
      .doc(uid)
      .set(
        {
          adminName,
          createdAt: new Date(),
          uid
        },
        { merge: true }
      )
    await firebase
      .firestore()
      .collection('mail')
      .add({
        to: 'habireco@gmail.com',
        message: {
          subject: '[HABIRECO] New Admin Account Request',
          text: `[ ${adminName} ] required an admin account`,
          html: `<b>[ ${adminName} ]</b> required an admin account.`
        }
      })
  }
}

const deleteAdminRequest = id => {
  return (dispatch, getState) => {
    firebase
      .firestore()
      .collection('fave-admin-request')
      .doc(id)
      .delete()
  }
}

const fetchAdminRequestExistance = uid => {
  return (dispatch, getState) => {
    if (uid == null || uid === '') return
    const ref = firebase
      .firestore()
      .collection('fave-admin-request')
      .doc(uid)
    ref.get().then(doc => {
      dispatch({
        type: types.FETCH_ADMIN_REQUEST_EXISTANCE,
        exists: doc.exists != null && doc.exists
      })
    })
  }
}

const setAdminUserListOrder = order => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_ADMIN_USER_LIST_ORDER,
      adminUserListOrder: order
    })
  }
}

export default {
  subscribeAdminUserList,
  unsubscribeAdminUserList,
  clearAdminUser,
  fetchAdminUser,
  addAdminUser,
  updateAdminUser,
  saveAdminUser,
  deleteAdminUser,
  subscribeAdminRequestList,
  unsubscribeAdminRequestList,
  addAdminRequest,
  deleteAdminRequest,
  fetchAdminRequestExistance,
  setAdminUserListOrder
}

// =============================
// ===== Private Functions =====
// =============================

const deriveAdminUserFromDoc = doc => {
  const data = doc.data()
  const def = types.DEFAULT_ADMIN_USER_STATE
  return {
    id: doc.id,
    uid: data.uid == null ? def.uid : data.uid,
    createdAt:
      data.createdAt == null || data.createdAt.seconds == null
        ? def.createdAt
        : new Date(data.createdAt.seconds * 1000),
    adminName: data.adminName == null ? def.adminName : data.adminName,
    level: data.level == null ? def.level : data.level
  }
}

const deriveAdminRequestFromDoc = doc => {
  const data = doc.data()
  const def = types.DEFAULT_ADMIN_REQUEST_STATE
  return {
    uid: data.uid == null ? def.uid : data.uid,
    createdAt:
      data.createdAt == null || data.createdAt.seconds == null
        ? def.createdAt
        : new Date(data.createdAt.seconds * 1000),
    adminName: data.adminName == null ? def.adminName : data.adminName
  }
}

const isPermit = (state, permit) => {
  const adminLevel = state.appState.auth.adminLevel
  return isPermitLevel(adminLevel, appTypes.MODULE_ADMIN_USER, permit)
}
