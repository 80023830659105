// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './Cancellation.scss'

const Cancellation = ({ visibility, onClosePopup, onStop }) => (
  <div
    className={ClassNames(classes.container, {
      [classes.hidden]: !visibility
    })}
  >
    <div className={classes.back} />
    <div className={classes.item}>
      ホーム画面にもどりますか？
      <br />
      （入力内容は保存されません）
      <div
        className={ClassNames(classes.button, classes.buttonLeft)}
        onClick={onClosePopup}
      >
        いいえ
      </div>
      <div
        className={ClassNames(classes.button, classes.buttonRight)}
        onClick={() => {
          onClosePopup()
          onStop()
        }}
      >
        はい
      </div>
    </div>
  </div>
)

Cancellation.propTypes = {
  onClosePopup: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  visibility: PropTypes.bool.isRequired
}

Cancellation.defaultProps = {
  visibility: false
}

export default Cancellation
