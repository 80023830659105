// Takram Confidential
// Copyright (C) 2019-Present Takram

import appReducer from './reducer'
import * as appTypes from './types'

export { default as appOperations } from './operations'
export { default as appSelectors } from './selectors'
export { appTypes }

export default appReducer
