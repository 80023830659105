// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './AfterEnd.scss'

const AfterEnd = ({ visibility }) => (
  <div
    className={ClassNames(classes.container, {
      [classes.hidden]: !visibility
    })}
  >
    <div className={classes.item}>
      <div className={classes.content}>
        <div className={classes.icon} />
        アンケートの実施期間が終了しました
      </div>
    </div>
  </div>
)

AfterEnd.propTypes = {
  visibility: PropTypes.bool.isRequired
}

AfterEnd.defaultProps = {
  visibility: false
}

export default AfterEnd
