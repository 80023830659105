// import PropTypes from 'prop-types'
import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
// import classes from './SelectUserList.scss'
import { mergeClasses } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 120,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-root': {
      height: 44,
      borderRadius: 8,
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.1)'
      },
      '&:hover fieldset': {
        borderColor: '#425c5b'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425c5b'
      },
      '&.MuiPaper-root': {
        zIndex: 10000
      }
    }
  }
}))

export function MuiSelect(props) {
  const classes = useStyles()
  return (
    <FormControl
      {...props}
      hiddenLabel
      variant='outlined'
      classes={mergeClasses({
        baseClasses: classes,
        newClasses: props.classes
      })}
    >
      <InputLabel
        // className={classes.inputLabel}
        id='demo-simple-select-outlined-label'
      >
        {props.label}
      </InputLabel>
      <Select
        labelId='demo-simple-select-outlined-label'
        id='demo-simple-select-outlined'
        value={props.value}
        onChange={props.onChange}
        label='Age'
        className={classes.select}
      >
        {props.items.map(item => (
          <MenuItem value={item.value} key={item.name}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

MuiSelect.defaultProps = {
  color: 'primary',
  fullWidth: false,
  size: 'medium',
  label: '',
  items: [],
  value: '',
  disabled: false,
  onChange: () => {}
}

export default MuiSelect
