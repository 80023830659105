import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import * as scriptCondition from '../../constants/scriptCondition'
import * as types from '../../modules/script/types'
import MuiContextMenu from '../Ui/MuiContextMenu'
import MuiMultipleSelect from '../Ui/MuiMultipleSelect'
import NotificationMessage from './NotificationMessage'
import NumberInput from '../Ui/NumberInput'
import Select from '../Ui/Select'
import TextInput from '../Ui/TextInput'
import classes from './ConditionItems.scss'

const smallSelectStyle = {
  margin: '0 4px 0 0',
  display: 'inline-block',
  padding: '4px 8px',
  width: 56,
  height: 38,
  fontSize: 14
}

export function ConditionItems({ onChangeValue, permitUpdate, script }) {
  const scriptType = script.condition.type
  const menuRef = useRef()
  const [menuItems, setMenuItems] = useState([])

  const onChangeItemAttr = (i, attr, value) => {
    script.condition.items[i][attr] = value
    onChangeValue('condition', script.condition)
  }

  const renderDateBlock = (item, i) => (
    <div className={classes.block}>
      <div className={classes.word}>
        {scriptType === scriptCondition.SCRIPT_TYPE_REQUIRED
          ? '回答入力の時間'
          : 'LINEでの通知'}
      </div>
      <Select
        disabled={!permitUpdate}
        value={item.type}
        items={scriptCondition.NOTIFY_TYPE_LABELS.map(el => ({
          name: el.label,
          value: el.type
        }))}
        style={{ ...smallSelectStyle, ...{ width: 80 } }}
        onChange={e => onChangeItemAttr(i, 'type', e.target.value)}
      />
      {item.type === scriptCondition.NOTIFY_TYPE_ELAPSED_DAYS && (
        <>
          <NumberInput
            disabled={!permitUpdate}
            value={item.elapsedDays + 1}
            style={smallSelectStyle}
            min={1}
            onChange={e =>
              onChangeItemAttr(i, 'elapsedDays', parseInt(e.target.value - 1))
            }
          />
          {` 日目 `}
        </>
      )}
      {item.type === scriptCondition.NOTIFY_TYPE_DAYS_OF_WEEK && (
        <>
          <MuiMultipleSelect
            items={scriptCondition.NOTIFY_DAYS}
            value={item.daysOfWeek}
            onChange={e => onChangeItemAttr(i, 'daysOfWeek', e.target.value)}
          />
        </>
      )}
      <Select
        disabled={!permitUpdate}
        value={item.hours}
        items={[...new Array(24)].map((_, ti) => ({
          name: `${ti}時`,
          value: ti
        }))}
        style={smallSelectStyle}
        onChange={e => onChangeItemAttr(i, 'hours', parseInt(e.target.value))}
      />
      <Select
        disabled={!permitUpdate}
        value={item.minutes}
        items={[...new Array(12)].map((_, ti) => ({
          name: `${ti * 5}分`,
          value: ti * 5
        }))}
        style={smallSelectStyle}
        onChange={e => onChangeItemAttr(i, 'minutes', parseInt(e.target.value))}
      />
    </div>
  )

  const renderNotifyBlock = (item, i) => (
    <div className={classes.block}>
      <div className={classes.word}>通知</div>
      <Select
        disabled={!permitUpdate}
        onChange={e => onChangeItemAttr(i, 'notify', e.target.value === 'true')}
        value={item.notify}
        style={smallSelectStyle}
        items={[
          { value: true, name: 'あり' },
          { value: false, name: 'なし' }
        ]}
      />
    </div>
  )

  const renderAnswerLinkBlock = (item, i) => (
    <div className={classes.block}>
      <div className={classes.word}>回答画面へのリンク</div>
      <Select
        disabled={!permitUpdate}
        onChange={e =>
          onChangeItemAttr(i, 'answerLink', e.target.value === 'true')
        }
        value={item.answerLink}
        style={smallSelectStyle}
        items={[
          { value: true, name: 'あり' },
          { value: false, name: 'なし' }
        ]}
      />
    </div>
  )

  const renderHwIdBlock = (item, i) => (
    <div className={classes.block}>
      <div className={classes.word}>ハードウェアID</div>
      <TextInput
        disabled={!permitUpdate}
        onChange={e => onChangeItemAttr(i, 'hwid', e.target.value)}
        value={item.hwid}
      />
    </div>
  )

  const onAddConditionItem = (preset = null) => {
    const { condition } = script
    const { getInitialItem, getPresetItems } = scriptCondition
    const newCondition =
      preset == null
        ? { items: [...condition.items, getInitialItem(scriptType)] }
        : { items: [...condition.items, ...getPresetItems(preset, scriptType)] }
    onChangeValue('condition', { ...condition, ...newCondition })
  }

  const renderAddConditionItemBtn = () => {
    const presetItems = scriptCondition.NOTIFY_PRESET_LABELS.map(el => ({
      name: el.label,
      onClick: () => onAddConditionItem(el.preset)
    }))
    return (
      <>
        <div className={classes.add} onClick={e => onAddConditionItem()}>
          {scriptCondition.getCaption(scriptType).addLabel}
        </div>
        {scriptType !== scriptCondition.SCRIPT_TYPE_BEACON && (
          <div
            className={classes.add}
            onClick={e => {
              setMenuItems(presetItems)
              menuRef.current.handleOpen(e)
            }}
          >
            ＋ プリセットから追加する
          </div>
        )}
      </>
    )
  }

  const onDeleteItem = i => {
    script.condition.items.splice(i, 1)
    onChangeValue('condition', script.condition)
  }

  const arrangeMessage = msg =>
    msg == null
      ? {
          type: 'text',
          text: ''
        }
      : typeof msg === 'string'
      ? {
          type: 'text',
          text: msg
        }
      : msg

  return (
    <div className={classes.container}>
      <MuiContextMenu ref={menuRef} items={menuItems} />
      <div className={classes.conditionItemContainer}>
        {script.condition.items.map((item, i) => (
          <div className={classes.conditionItemRow} key={'conditionitem' + i}>
            <div className={classes.conditionIndex}>{i + 1}</div>
            {scriptType === scriptCondition.SCRIPT_TYPE_FREE ? (
              <>
                {renderDateBlock(item, i)}
                {renderAnswerLinkBlock(item, i)}
                <NotificationMessage
                  answerLink={item.answerLink}
                  isNotify={item.notify}
                  message={arrangeMessage(item.message)}
                  onChangeMessage={message =>
                    onChangeItemAttr(i, 'message', message)
                  }
                  permitUpdate={permitUpdate}
                />
              </>
            ) : scriptType === scriptCondition.SCRIPT_TYPE_REQUIRED ? (
              <>
                {renderDateBlock(item, i)}
                {renderNotifyBlock(item, i)}
                {item.notify && (
                  <NotificationMessage
                    answerLink={item.answerLink}
                    isNotify={item.notify}
                    message={arrangeMessage(item.message)}
                    onChangeMessage={message =>
                      onChangeItemAttr(i, 'message', message)
                    }
                    permitUpdate={permitUpdate}
                  />
                )}
              </>
            ) : scriptType === scriptCondition.SCRIPT_TYPE_BEACON ? (
              <>
                {renderHwIdBlock(item, i)}
                <NotificationMessage
                  answerLink={true}
                  isNotify={item.notify}
                  message={arrangeMessage(item.message)}
                  onChangeMessage={message =>
                    onChangeItemAttr(i, 'message', message)
                  }
                  permitUpdate={permitUpdate}
                />
              </>
            ) : (
              <></>
            )}
            {permitUpdate && (
              <div className={classes.delete} onClick={e => onDeleteItem(i)} />
            )}
          </div>
        ))}
      </div>
      {permitUpdate && renderAddConditionItemBtn()}
    </div>
  )
}

ConditionItems.propTypes = {
  onChangeValue: PropTypes.func.isRequired,
  permitUpdate: PropTypes.bool.isRequired,
  script: PropTypes.shape(types.SCRIPT_TYPES).isRequired
}

export default ConditionItems
