import PropTypes from 'prop-types'
import React from 'react'

import classes from './SelectUserList.scss'

export function SelectUserList({ sendUsers }) {
  const enableUsers = sendUsers.filter(u => u.lineUserId !== '')
  const disableUsers = sendUsers.filter(u => u.lineUserId === '')
  const renderList = users => (
    <div className={classes.userList}>
      {users.map(user => (
        <div className={classes.userChip} key={user.name}>
          {user.name}
        </div>
      ))}
    </div>
  )
  return (
    <div className={classes.container}>
      {enableUsers.length === 0 ? (
        <></>
      ) : (
        <>
          <div className={classes.title}>
            以下の{enableUsers.length}名に同時にメッセージを送信します
          </div>
          {renderList(enableUsers)}
        </>
      )}
      {disableUsers.length === 0 ? (
        <></>
      ) : (
        <>
          <div className={classes.title}>
            以下の{disableUsers.length}
            名はLINEと連携していないためメッセージが送信できません
          </div>
          {renderList(disableUsers)}
        </>
      )}
    </div>
  )
}

SelectUserList.propTypes = {
  sendUsers: PropTypes.array.isRequired
}

export default SelectUserList
