// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1R5tGDTxBzP4erGS9Kps91{background-color:#425c5b;width:100%;height:100%;z-index:1;position:absolute;opacity:1.0;transition:all 400ms cubic-bezier(0, 0.2, 0.2, 1)}._1R5tGDTxBzP4erGS9Kps91 ._3fcwK2NsSCELOfaipaHwuQ{background-color:#fff;border-radius:36px;bottom:0px;left:0px;margin:8px;padding:24px 24px 64px;position:absolute;right:0px;top:0px;transition:all 400ms cubic-bezier(0, 0.2, 0.2, 1);z-index:1}@media screen and (max-height: 450px){._1R5tGDTxBzP4erGS9Kps91 ._3fcwK2NsSCELOfaipaHwuQ{top:-100px;bottom:-100px}}._1R5tGDTxBzP4erGS9Kps91 ._3fcwK2NsSCELOfaipaHwuQ._9vpuU8B615pZOc-zAZEe4{bottom:-100%;pointer-events:none;top:100%}._1R5tGDTxBzP4erGS9Kps91._9vpuU8B615pZOc-zAZEe4{bottom:-100%;pointer-events:none;top:100%;opacity:0}", ""]);
// Exports
exports.locals = {
	"page": "_1R5tGDTxBzP4erGS9Kps91",
	"container": "_3fcwK2NsSCELOfaipaHwuQ",
	"hidden": "_9vpuU8B615pZOc-zAZEe4"
};
module.exports = exports;
