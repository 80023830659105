// Takram Confidential
// Copyright (C) 2019-Present Takram

import * as types from './types'

export const loginOk = user => {
  return (dispatch, getState) => {
    user.getIdTokenResult(true).then(idTokenResult => {
      const admin = !!idTokenResult.claims.admin
      const adminLevel = idTokenResult.claims.level
      dispatch({
        type: types.LOGIN_OK,
        auth: {
          admin: admin == null ? false : admin,
          adminLevel: adminLevel == null ? 0 : adminLevel,
          displayName: user.displayName == null ? '' : user.displayName,
          email: user.email == null ? '' : user.email,
          uid: user.uid == null ? '' : user.uid
        }
      })
    })
  }
}

export const logOut = () => {
  return {
    type: types.LOGOUT
  }
}

export const setSimulatorVisibility = bool => {
  return {
    type: types.SET_SIMULATOR_VISIBILITY,
    visibility: bool
  }
}

export const setIsDebugUserMode = bool => {
  return {
    type: types.SET_IS_DEBUG_USER_MODE,
    isDebugUserMode: bool
  }
}

export default {
  logOut,
  loginOk,
  setIsDebugUserMode,
  setSimulatorVisibility
}
