// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import * as scriptConfig from '../../../constants/scriptConfig'

import classes from './Item.scss'

const ItemNumeric = ({ onChangeValue, permitUpdate, value: inputValue }) => {
  const { max, min, value } = inputValue[0]
  return (
    <div className={classes.valueContainer}>
      <div>
        <input
          disabled={!permitUpdate}
          onChange={e => {
            let newValue = parseInt(e.target.value)
            if (isNaN(newValue)) newValue = 0
            newValue = Math.max(newValue, min)
            newValue = Math.min(newValue, max)
            inputValue[0].value = newValue
            onChangeValue('value', inputValue)
          }}
          placeholder='初期値'
          type='number'
          value={value}
        />
        <input
          disabled={!permitUpdate}
          onChange={e => {
            let newMin = parseInt(e.target.value)
            if (isNaN(newMin)) newMin = -Infinity
            newMin = Math.min(newMin, max)
            inputValue[0].min = newMin
            inputValue[0].value = Math.max(newMin, value)
            onChangeValue('value', inputValue)
          }}
          placeholder='最小値'
          type='number'
          value={min === -Infinity ? '' : min}
        />
        <input
          disabled={!permitUpdate}
          onChange={e => {
            let newMax = parseInt(e.target.value)
            if (isNaN(newMax)) newMax = Infinity
            newMax = Math.max(newMax, min)
            inputValue[0].max = newMax
            inputValue[0].value = Math.min(newMax, value)
            onChangeValue('value', inputValue)
          }}
          placeholder='最大値'
          type='number'
          value={max === Infinity ? '' : max}
        />
      </div>
    </div>
  )
}

ItemNumeric.propTypes = {
  ...scriptConfig.ITEM_STYLE,
  onChangeValue: PropTypes.func.isRequired,
  permitUpdate: PropTypes.bool.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      max: PropTypes.number.isRequired,
      min: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
}

ItemNumeric.defaultProps = {
  ...scriptConfig.INITIAL_ITEM_DATA,
  value: [
    {
      max: Infinity,
      min: -Infinity,
      value: 0
    }
  ]
}

export default ItemNumeric
