// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'

import classes from './Auth.scss'
import commonCls from './Common.scss'

import firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { adminUserOperations } from '../../modules/admin_user'
import { appOperations } from '../../modules/app'

export function Auth() {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const uid = useSelector(state => state.appState.auth.uid)
  const displayName = useSelector(state => state.appState.auth.displayName)
  const authEmail = useSelector(state => state.appState.auth.email)
  const isExistAdminRequest = useSelector(
    state => state.adminUserState.isExistAdminRequest
  )

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user) return
      dispatch(appOperations.loginOk(user))
    })
  }, [])

  useEffect(() => {
    dispatch(adminUserOperations.fetchAdminRequestExistance(uid))
  }, [uid])

  const onClickGoogleLogin = async e => {
    const provider = new firebase.auth.GoogleAuthProvider()
    try {
      setErrorMessage('')
      await firebase.auth().signInWithPopup(provider)
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const onClickLogin = async e => {
    try {
      setErrorMessage('')
      await firebase.auth().signInWithEmailAndPassword(email, password)
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const onClickSignUp = async e => {
    try {
      setErrorMessage('')
      await firebase.auth().createUserWithEmailAndPassword(email, password)
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const onClickLout = e => {
    firebase.auth().signOut()
    dispatch(appOperations.logOut())
  }

  const onClickAdminRequest = e => {
    dispatch(
      adminUserOperations.addAdminRequest(
        uid,
        displayName.trim() === '' ? authEmail : displayName
      )
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.loginContainer}>
        <div className={classes.loginElements}>
          {uid ? (
            <div>
              <div className={classes.loginTitle}>
                {isExistAdminRequest
                  ? '管理権限を申請中です'
                  : '管理者に登録されていません'}
              </div>
              {!isExistAdminRequest && (
                <>
                  <div
                    className={ClassNames(
                      commonCls.roundButton,
                      commonCls.buttonThin,
                      classes.loginButton
                    )}
                    onClick={e => onClickAdminRequest(e)}
                  >
                    管理権限を申請
                  </div>
                  <br />
                </>
              )}
              <div
                className={ClassNames(
                  commonCls.roundButton,
                  commonCls.buttonThin,
                  classes.loginButton
                )}
                onClick={e => onClickLout(e)}
              >
                ログアウト
              </div>
            </div>
          ) : (
            <div>
              <div className={classes.loginTitle}>ログイン</div>
              {errorMessage !== '' && (
                <div className={classes.errorMessage}>
                  Error: {errorMessage}
                </div>
              )}
              <div
                className={ClassNames(
                  commonCls.form,
                  commonCls.formThin,
                  classes.form
                )}
              >
                <label htmlFor='email'>Email</label>
                <br />
                <input
                  id='email'
                  value={email}
                  type='text'
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div
                className={ClassNames(
                  commonCls.form,
                  commonCls.formThin,
                  classes.form
                )}
              >
                <label htmlFor='password'>password</label>
                <br />
                <input
                  id='password'
                  value={password}
                  type='password'
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div
                className={ClassNames(
                  commonCls.roundButton,
                  commonCls.buttonThin,
                  classes.loginButton
                )}
                onClick={e => onClickLogin(e)}
              >
                ログイン
              </div>
              <br />
              <div
                className={ClassNames(
                  commonCls.roundButton,
                  commonCls.buttonThin,
                  classes.loginButton
                )}
                onClick={e => onClickSignUp(e)}
              >
                サインアップ
              </div>
              <br />
              <div
                className={ClassNames(
                  commonCls.roundButton,
                  commonCls.buttonThin,
                  classes.loginButton
                )}
                onClick={e => onClickGoogleLogin(e)}
              >
                Googleアカウントでログイン
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Auth
