// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { history } from '../../configureStore'
import OverlayFrame from '../App/OverlayFrame'
import Forms from './Forms'
import { PERMIT_UPDATE } from '../../constants/permission'
import { appSelectors, appTypes } from '../../modules/app'
import { projectOperations } from '../../modules/project'

export function AddOverlay({ onClickCancel }) {
  const dispatch = useDispatch()
  const project = useSelector(state => state.projectState.project)
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_PROJECT)
  )

  useEffect(() => {
    dispatch(projectOperations.clearProject())
    return () => dispatch(projectOperations.clearProject())
  }, [])

  const onClickCreate = async () => {
    await dispatch(projectOperations.addProject(project))
    onClickCancel()
    history.push('/project/' + project.id)
  }

  return (
    <div>
      <OverlayFrame
        title='新しくプロジェクトを作成する'
        onClickClose={e => onClickCancel()}
        buttons={[
          {
            label: 'プロジェクトの作成',
            onClick: () => onClickCreate(),
            accent: true
          }
        ]}
      >
        <Forms permitUpdate={isPermit(PERMIT_UPDATE)} project={project} />
      </OverlayFrame>
    </div>
  )
}

export default AddOverlay
