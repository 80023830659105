// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".-A96JnCX8ClWS3qy1Eia7{left:0px;position:absolute;right:0px;top:78px}.-A96JnCX8ClWS3qy1Eia7 ._2J97kVy5M10EO4cPL0MH6e{align-items:center;background-color:#DA957F;display:flex;justify-content:center;height:36px;border-radius:30px;width:240px;margin:18px auto 0px auto}.-A96JnCX8ClWS3qy1Eia7 ._2J97kVy5M10EO4cPL0MH6e ._2ONWMUZxnFNF1ff_yk8ETQ{color:white;display:inline;font-size:11px}.-A96JnCX8ClWS3qy1Eia7._3n0Qa9HpoA76NadnImjIAs{display:none}", ""]);
// Exports
exports.locals = {
	"container": "-A96JnCX8ClWS3qy1Eia7",
	"item": "_2J97kVy5M10EO4cPL0MH6e",
	"content": "_2ONWMUZxnFNF1ff_yk8ETQ",
	"hidden": "_3n0Qa9HpoA76NadnImjIAs"
};
module.exports = exports;
