// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2DxUKzDbGoFPGFzOGJF-FC{background-color:#384241;width:420px;height:calc(100vh - 144px);position:fixed;right:0;top:144px;display:flex;justify-content:center;align-items:center}._2DxUKzDbGoFPGFzOGJF-FC .yHzjkjaILJjDRxcgFcnir{background-color:#fff;z-index:1000;width:281px;height:calc(100vh - 164px);max-height:609px}._2DxUKzDbGoFPGFzOGJF-FC .yHzjkjaILJjDRxcgFcnir iframe{width:134%;height:134%;transform:scale(0.75, 0.75);transform-origin:top left;border:none}", ""]);
// Exports
exports.locals = {
	"container": "_2DxUKzDbGoFPGFzOGJF-FC",
	"simulator": "yHzjkjaILJjDRxcgFcnir"
};
module.exports = exports;
