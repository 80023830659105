// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'
import { createSelector } from 'reselect'

import * as userTypes from '../../modules/user/types'

const currentLoginUserSelector = state => state.userState.currentLoginUser
const userListSelector = state => state.userState.userList
const userListOrderSelector = state => state.userState.userListOrder

const deriveTermStatus = user => {
  const date = new Date()
  const startUtime = user.startAt.getTime() / 1000
  const endUtime = user.endAt.getTime() / 1000
  const baseStartDate = new Date(startUtime * 1000)
  baseStartDate.setHours(0)
  baseStartDate.setMinutes(0)
  baseStartDate.setSeconds(0)
  const baseStartUtime = baseStartDate.getTime() / 1000
  const baseEndDate = new Date(endUtime * 1000)
  baseEndDate.setHours(23)
  baseEndDate.setMinutes(59)
  baseEndDate.setSeconds(59)
  const baseEndUtime = baseEndDate.getTime() / 1000
  const nowUtime = date.getTime() / 1000
  const isBefore = nowUtime < startUtime
  const isAfter = endUtime < nowUtime
  const isOnGoing = !isBefore && !isAfter
  const isStartDate =
    baseStartUtime < nowUtime && nowUtime - baseStartUtime < 3600 * 24
  const isEndDate =
    nowUtime < baseEndUtime && baseEndUtime - nowUtime < 3600 * 24
  const isMidDate = isOnGoing && !isStartDate && !isEndDate
  const elapsedDays = parseInt((nowUtime - baseStartUtime) / (3600 * 24))
  return {
    isBefore,
    isAfter,
    isOnGoing,
    isStartDate,
    isEndDate,
    isMidDate,
    elapsedDays
  }
}

const deriveDisplayUserList = createSelector(
  [userListSelector, userListOrderSelector],
  (userList, userListOrder) => {
    const list = userList.map(user => ({ ...user, ...deriveTermStatus(user) }))
    switch (userListOrder) {
      case userTypes.ORDER_BY_USER_NAME_ASC:
        return list.sort((a, b) => (a.name > b.name ? 1 : -1))
      case userTypes.ORDER_BY_USER_NAME_DESC:
        return list.sort((a, b) => (a.name < b.name ? 1 : -1))
      case userTypes.ORDER_BY_CREATED_AT_ASC:
        return list.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
      case userTypes.ORDER_BY_CREATED_AT_DESC:
        return list.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      default:
        return list.sort((a, b) => (a.name > b.name ? 1 : -1))
    }
  }
)

const deriveUserTermCountByProject = createSelector(
  userListSelector,
  userList => {
    const userState = {}
    userList.forEach(user => {
      if (!Object.prototype.hasOwnProperty.call(userState, user.projectId)) {
        userState[user.projectId] = { before: 0, onGoing: 0, after: 0 }
      }
      const term = deriveTermStatus(user)
      if (term.isOnGoing) userState[user.projectId].onGoing += 1
      else if (term.isBefore) userState[user.projectId].before += 1
      else userState[user.projectId].after += 1
    })
    return userState
  }
)

const isAfterEndSelector = createSelector(
  currentLoginUserSelector,
  currentLoginUser => {
    if (currentLoginUser.id === undefined) return false
    const term = deriveTermStatus(currentLoginUser)
    return term.isAfter
  }
)

const isBeforeStartSelector = createSelector(
  currentLoginUserSelector,
  currentLoginUser => {
    if (currentLoginUser.id === undefined) return false
    const term = deriveTermStatus(currentLoginUser)
    return term.isBefore
  }
)

const isOutOfPeriodSelector = createSelector(
  [isAfterEndSelector, isBeforeStartSelector],
  (isAfterEnd, isBeforeStart) => isAfterEnd || isBeforeStart
)

const userDateListSelector = createSelector(
  currentLoginUserSelector,
  currentLoginUser => {
    const { endAt, startAt } = currentLoginUser
    if (startAt === undefined || endAt === undefined) return []
    const maxDateCount = 100
    const refDate = Moment(startAt).startOf('date')

    // Check maxDateCount
    if (Moment(refDate).add(maxDateCount, 'd') < endAt) {
      console.error(
        'maxDateCount is ' + maxDateCount + '. You should change maxDateCount.'
      )
    }

    const dateList = []
    for (let i = 0; i < maxDateCount; i++) {
      dateList.push(refDate.toDate())
      refDate.add(1, 'd')
      if (refDate > endAt) break
    }
    return dateList
  }
)

const exportDateFormat = d => Moment(d).format('YYYY/MM/DD')
const userListForExport = createSelector(userListSelector, userList => {
  const out = userList.map(user => [
    user.name,
    user.lineSyncCode,
    exportDateFormat(user.startAt),
    exportDateFormat(user.endAt)
  ])
  out.unshift(['Name', 'LineSyncCode', 'DateFrom', 'DateTo'])
  return out
})

export default {
  deriveTermStatus,
  isAfterEndSelector,
  isBeforeStartSelector,
  isOutOfPeriodSelector,
  userDateListSelector,
  deriveDisplayUserList,
  deriveUserTermCountByProject,
  userListForExport
}
