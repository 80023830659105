// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import * as types from '../../modules/user/types'

import classes from './UserState.scss'
import UserStateChip from '../Ui/UserState'

const UserState = ({ userRecordCount }) => (
  <div className={classes.container}>
    <h3>User Records</h3>
    <table>
      <thead>
        <tr>
          <th>被験者ID</th>
          <th>実施状況</th>
          <th>連携</th>
          <th>回答数</th>
          <th>最終回答</th>
        </tr>
      </thead>
      <tbody>
        {userRecordCount.map(userRecord => (
          <tr key={userRecord.id}>
            <td>{userRecord.name}</td>
            <td>
              <UserStateChip user={userRecord} />
            </td>
            <td>{userRecord.lineUserId !== '' ? 'LINE連携' : '未連携'}</td>
            <td>{userRecord.recordCount}</td>
            <td>
              {userRecord.lastRecordAt == null
                ? '----/--/-- --:--'
                : Moment(userRecord.lastRecordAt).format('YYYY/MM/DD HH:mm')}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

UserState.propTypes = {
  userRecordCount: PropTypes.arrayOf(
    PropTypes.shape({
      ...types.USER_TYPES,
      recordCount: PropTypes.number.isRequired,
      lastRecordAt: PropTypes.instanceOf(Date)
    })
  ).isRequired
}

export default UserState
