// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Forms from './Forms'
import OverlayFrame from '../App/OverlayFrame'
import { adminUserOperations } from '../../modules/admin_user'
import classes from './RequestList.scss'

export function RequestList() {
  const dispatch = useDispatch()
  const adminUser = useSelector(state => state.adminUserState.adminUser)
  const adminRequestList = useSelector(
    state => state.adminUserState.adminRequestList
  )
  const [visibleAddOverlay, setVisibleAddOverlay] = useState(false)
  useEffect(() => {
    dispatch(adminUserOperations.subscribeAdminRequestList())
    return () => {
      dispatch(adminUserOperations.unsubscribeAdminRequestList())
    }
  }, [])

  const onClickAccept = (uid, adminName) => {
    dispatch(adminUserOperations.updateAdminUser({ uid, adminName }))
    setVisibleAddOverlay(true)
  }

  const onClickDelete = uid => {
    dispatch(adminUserOperations.deleteAdminRequest(uid))
  }

  const onChangeValue = (key, value) => {
    dispatch(adminUserOperations.updateAdminUser({ [key]: value }))
  }

  const onClickRegist = () => {
    dispatch(adminUserOperations.registRequestedAdminUser(adminUser))
    setVisibleAddOverlay(false)
  }

  return (
    <div className={classes.container}>
      {adminRequestList.length === 0 && (
        <div className={classes.noRequest}>現在申請はありません</div>
      )}

      {adminRequestList.map(request => (
        <div key={request.uid} className={classes.row}>
          <div className={classes.name}>Name : {request.adminName}</div>
          <div className={classes.uid}>uid : {request.uid}</div>
          <div
            className={ClassNames(classes.button, classes.accept)}
            onClick={e => onClickAccept(request.uid, request.adminName)}
          >
            承認
          </div>
          <div
            className={ClassNames(classes.button, classes.delete)}
            onClick={e => onClickDelete(request.uid)}
          >
            削除
          </div>
        </div>
      ))}
      {visibleAddOverlay && (
        <OverlayFrame
          title='管理者登録'
          onClickClose={e => setVisibleAddOverlay(false)}
          buttons={[
            {
              label: '登録',
              onClick: () => onClickRegist(),
              accent: true
            }
          ]}
        >
          <Forms
            adminUser={adminUser}
            onChangeValue={(key, val) => onChangeValue(key, val)}
          />
        </OverlayFrame>
      )}
    </div>
  )
}

export default RequestList
