// Takram Confidential
// Copyright (C) 2019-Present Takram

import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { appSelectors, appTypes } from '../../../modules/app'

import { scriptOperations } from '../../../modules/script'
import { PERMIT_UPDATE } from '../../../constants/permission'

import AttrSelector from './AttrSelector'

import * as scriptConfig from '../../../constants/scriptConfig'

import classes from './Item.scss'

export function Item({ children, item, index }) {
  const dispatch = useDispatch()
  const onChangeValue = (key, val) => {
    item[key] = val
    dispatch(scriptOperations.updateScriptItem(item))
  }
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_SCRIPT)
  )

  const onChangeItemType = e => {
    const newItem = _.cloneDeep(item)
    newItem.type = e.target.value
    dispatch(scriptOperations.updateScriptItem(newItem))
  }

  const onClickDelete = e => {
    dispatch(scriptOperations.deleteScriptItem(item.id))
  }

  const onClickUp = e => {
    dispatch(scriptOperations.swapScriptItem(item.id, -1))
  }

  const onClickDown = e => {
    dispatch(scriptOperations.swapScriptItem(item.id, 1))
  }

  const onInsertAttr = text => {
    onChangeValue('title', `${item.title}{{${text}}}`)
  }

  return (
    <div className={classes.container}>
      <div className={classes.itemIndex}>
        {'abcdefghijklmnopqrstuvwxyz'.charAt(index)}
      </div>
      {isPermit(PERMIT_UPDATE) && (
        <>
          <div
            className={classes.deleteButton}
            onClick={e => onClickDelete(e)}
          />
          <div className={classes.downButton} onClick={e => onClickDown()} />
          <div className={classes.upButton} onClick={e => onClickUp()} />
        </>
      )}
      <div className={classes.label}>
        <input
          disabled={!isPermit(PERMIT_UPDATE)}
          onChange={e => onChangeValue('title', e.target.value)}
          placeholder='ラベル名'
          type='text'
          value={item.title === undefined ? '' : item.title}
        />
        <div className={classes.attrSelector}>
          <AttrSelector onSelectAttr={text => onInsertAttr(text)} />
        </div>
      </div>
      <div>
        <select
          disabled={!isPermit(PERMIT_UPDATE)}
          onChange={e => onChangeItemType(e)}
          value={item.type}
        >
          <option value={scriptConfig.INPUT_RADIO}>単一選択</option>
          <option value={scriptConfig.INPUT_CHECK}>複数選択</option>
          <option value={scriptConfig.INPUT_SLIDE_SEL}>スライドセレクタ</option>
          <option value={scriptConfig.INPUT_DATE}>日付</option>
          <option value={scriptConfig.INPUT_TIME}>時刻</option>
          <option value={scriptConfig.INPUT_NUMBER}>カウント</option>
          <option value={scriptConfig.INPUT_TEXT}>テキスト</option>
          <option value={scriptConfig.INPUT_DESCRIPTION}>説明文</option>
          {/* <option value={scriptConfig.INPUT_USER_INFO}>被験者属性</option> */}
        </select>
      </div>

      {children}
    </div>
  )
}

export default Item
