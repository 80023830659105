// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1izWO5EJLk4eTJNEgWaxsz{padding:24px;box-sizing:border-box}._1izWO5EJLk4eTJNEgWaxsz .mfu99QpE1iyBsCS5YQszp{margin-bottom:16px}._1izWO5EJLk4eTJNEgWaxsz ._3iBwVJKDVbchqbsDf-yqkL{margin-bottom:32px}._1izWO5EJLk4eTJNEgWaxsz ._3iBwVJKDVbchqbsDf-yqkL ._1aTmWP27Z3lENd4Mb_8nr{background-color:#f1f1f1;display:inline-block;padding:8px 16px;border-radius:16px;margin:0 8px 8px 0}", ""]);
// Exports
exports.locals = {
	"container": "_1izWO5EJLk4eTJNEgWaxsz",
	"title": "mfu99QpE1iyBsCS5YQszp",
	"userList": "_3iBwVJKDVbchqbsDf-yqkL",
	"userChip": "_1aTmWP27Z3lENd4Mb_8nr"
};
module.exports = exports;
