// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import classes from './InputNumber.scss'

const InputNumber = ({ id, inputData, setInputData, value }) => {
  let { max, min } = value[0]
  // TODO: It should validate in editor
  // Delete this "if sentence" after fixed all scripts.
  if (min === null) min = InputNumber.defaultProps.value[0].min
  if (max === null) max = InputNumber.defaultProps.value[0].max
  if (inputData === undefined) {
    const defaultVaue = value[0].value
    setInputData({ [id]: defaultVaue })
  }
  return (
    <div className={classes.content}>
      <div
        className={classes.buttonLeft}
        onClick={() => setInputData({ [id]: Math.max(--inputData, min) })}
      >
        -
      </div>
      <div className={classes.centerText}>{inputData}</div>
      <div
        className={classes.buttonRight}
        onClick={() => setInputData({ [id]: Math.min(++inputData, max) })}
      >
        +
      </div>
    </div>
  )
}

InputNumber.propTypes = {
  id: PropTypes.string.isRequired,
  inputData: PropTypes.number,
  setInputData: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      max: PropTypes.number.isRequired,
      min: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired
}

InputNumber.defaultProps = {
  value: [
    {
      max: Infinity,
      min: -Infinity,
      value: 0
    }
  ]
}

export default InputNumber
