// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'
import PropTypes from 'prop-types'

export const SET_CURRENT_RECORD_DATE = 'form/SET_CURRENT_RECORD_DATE'
export const SET_CANCELLATION_POPUP_VISIBILITY =
  'form/SET_CANCELLATION_POPUP_VISIBILITY'
export const SET_RECORD_LOG_VISIBILITY = 'form/SET_RECORD_LOG_VISIBILITY'
export const SET_REQUIRED_ITEM_ALERT_VISIBILITY =
  'form/SET_REQUIRED_ITEM_ALERT_VISIBILITY'
export const SET_REQUIRED_OTHERS_ITEM_ALERT_VISIBILITY =
  'form/SET_REQUIRED_OTHERS_ITEM_ALERT_VISIBILITY'
export const SET_SCRIPT_SELECTOR_POPUP_VISIBILITY =
  'form/SET_SCRIPT_SELECTOR_POPUP_VISIBILITY'
export const SET_USER_INFO_POPUP_VISIBILITY =
  'form/SET_USER_INFO_POPUP_VISIBILITY'

export const DEFAULT_FORM_STATE = {
  currentRecordDate: Moment()
    .startOf('date')
    .toDate(),
  popupVisibility: {
    cancellation: false,
    recordLog: false,
    requiredItemAlert: false,
    requiredOthersItemAlert: false,
    scriptSelector: false,
    userInfo: false
  }
}

export const POPUP_VISIBILITY_TYPES = {
  cancellation: PropTypes.bool.isRequired,
  recordLog: PropTypes.bool.isRequired,
  requiredItemAlert: PropTypes.bool.isRequired,
  requiredOthersItemAlert: PropTypes.bool.isRequired,
  scriptSelector: PropTypes.bool.isRequired,
  userInfo: PropTypes.bool.isRequired
}

export const FORM_TYPES = {
  currentRecordDate: PropTypes.instanceOf(Date).isRequired,
  popupVisibility: PropTypes.shape(DEFAULT_FORM_STATE).isRequired
}

export default {}
