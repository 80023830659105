// Takram Confidential
// Copyright (C) 2019-Present Takram

import actions from './actions'

const subscribeMessageList = lineUserId => dispatch => {
  dispatch(actions.unsubscribeMessageList()).then(() => {
    dispatch(actions.subscribeMessageList(lineUserId))
  })
}

const unsubscribeMessageList = () => {
  return actions.unsubscribeMessageList()
}

const setCurrentMessagingUserIds = userIds => {
  return actions.setCurrentMessagingUserIds(userIds)
}

const setCurrentUserOrder = order => {
  return actions.setCurrentUserOrder(order)
}

const setCurrentUserSort = sort => {
  return actions.setCurrentUserSort(sort)
}

const setCurrentUserFilter = filter => {
  return actions.setCurrentUserFilter(filter)
}

const updateCurrentInputMessage = (userId, message) => {
  return actions.updateCurrentInputMessage(userId, message)
}

const sendTextMessage = (user, message) => {
  return actions.sendTextMessage(user, message)
}

export default {
  subscribeMessageList,
  unsubscribeMessageList,
  setCurrentMessagingUserIds,
  setCurrentUserOrder,
  setCurrentUserSort,
  setCurrentUserFilter,
  updateCurrentInputMessage,
  sendTextMessage
}
