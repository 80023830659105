// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import React from 'react'

import * as messageTypes from '../../modules/message/types'
import classes from './FlexMessagePreview.scss'

export function FlexMessagePreview({ messageContents }) {
  return (
    <div className={classes.container}>
      {messageContents.body.contents.map((paragraph, paragraphIndex) => (
        <div key={'text' + paragraphIndex}>
          {paragraph.type === 'text' ? (
            paragraph.contents.map((part, partIndex) => (
              <span
                key={'text' + partIndex}
                style={{ fontWeight: part.weight, color: part.color }}
              >
                {part.text}
              </span>
            ))
          ) : paragraph.type === 'button' ? (
            <a
              className={ClassNames(classes.link, {
                [classes.disabled]: paragraph.action.uri === ''
              })}
              href={paragraph.action.uri}
              target='_blank'
            >
              <div className={classes.button}>{paragraph.action.label}</div>
            </a>
          ) : null}
        </div>
      ))}
    </div>
  )
}

FlexMessagePreview.propTypes = {
  messageContents: messageTypes.FLEX_MESSAGE_TYPES
}

export default FlexMessagePreview
