// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2slJJ5WV8v0niUF3fhWAxj{background-color:#425c5b;width:100%;height:100%}._2slJJ5WV8v0niUF3fhWAxj ._2aEPlxyHGpWgVGHOqzHnDK{position:absolute;background-color:#FFF;width:500px;left:50%;top:50%;transform:translate(-50%, -50%);border-radius:16px}._2slJJ5WV8v0niUF3fhWAxj ._2aEPlxyHGpWgVGHOqzHnDK ._1gn9zlgJBqeiM4zp78iQDr{position:relative;margin:48px 0}._2slJJ5WV8v0niUF3fhWAxj ._2aEPlxyHGpWgVGHOqzHnDK ._1gn9zlgJBqeiM4zp78iQDr ._2IfzEhP2xDWvu5NcuDd4mL{width:352px;margin:0 auto 16px auto}._2slJJ5WV8v0niUF3fhWAxj ._2aEPlxyHGpWgVGHOqzHnDK ._1gn9zlgJBqeiM4zp78iQDr ._2IfzEhP2xDWvu5NcuDd4mL label{font-size:12px}._2slJJ5WV8v0niUF3fhWAxj ._2aEPlxyHGpWgVGHOqzHnDK ._1gn9zlgJBqeiM4zp78iQDr ._2IfzEhP2xDWvu5NcuDd4mL input{width:352px;margin:8px 0}._2slJJ5WV8v0niUF3fhWAxj ._2aEPlxyHGpWgVGHOqzHnDK ._1gn9zlgJBqeiM4zp78iQDr ._28i_fS1vraUZWNhwr35rso{color:#384241;text-align:center;font-size:24px;font-weight:bold;margin-bottom:50px}._2slJJ5WV8v0niUF3fhWAxj ._2aEPlxyHGpWgVGHOqzHnDK ._1gn9zlgJBqeiM4zp78iQDr .tMx5IkmpLFNvmv-OetgRo{width:352px;margin:16px auto;color:#d9b389}._2slJJ5WV8v0niUF3fhWAxj ._2aEPlxyHGpWgVGHOqzHnDK ._1gn9zlgJBqeiM4zp78iQDr ._3z233Th7tqNpHeoIHN9Een{font-size:16px;width:352px;border:none}", ""]);
// Exports
exports.locals = {
	"container": "_2slJJ5WV8v0niUF3fhWAxj",
	"loginContainer": "_2aEPlxyHGpWgVGHOqzHnDK",
	"loginElements": "_1gn9zlgJBqeiM4zp78iQDr",
	"form": "_2IfzEhP2xDWvu5NcuDd4mL",
	"loginTitle": "_28i_fS1vraUZWNhwr35rso",
	"errorMessage": "tMx5IkmpLFNvmv-OetgRo",
	"loginButton": "_3z233Th7tqNpHeoIHN9Een"
};
module.exports = exports;
