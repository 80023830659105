// Takram Confidential
// Copyright (C) 2019-Present Takram

import actions from './actions'

const fetchScript = scriptId => {
  return actions.fetchScript(scriptId)
}

const asyncFetchScript = scriptId => {
  return async dispatch => {
    await dispatch(actions.asyncFetchScript(scriptId))
  }
}

const clearScript = () => {
  return actions.clearScript()
}

const exportScript = scriptId => {
  return actions.exportScript(scriptId)
}

const importScript = projectId => {
  return actions.importScript(projectId)
}

const addScript = script => {
  return actions.addScript(script)
}

const addPresetScript = (projectId, name) => {
  return actions.addPresetScript(projectId, name)
}

const saveScript = script => {
  return actions.saveScript(script)
}

const updateScript = script => {
  return actions.updateScript(script)
}

const deleteScript = id => {
  return actions.deleteScript(id)
}

const duplicateScript = (scriptId, destProjectId) => {
  return actions.duplicateScript(scriptId, destProjectId)
}

const duplicateScripts = (projectId, newProjectId) => {
  return actions.duplicateScripts(projectId, newProjectId)
}

//= == ScriptList

const subscribeScriptList = projectId => dispatch => {
  dispatch(actions.unsubscribeScriptList()).then(() => {
    dispatch(actions.subscribeScriptList(projectId))
  })
}

const unsubscribeScriptList = () => {
  return actions.unsubscribeScriptList()
}

//= == ScriptPlay

const play = () => {
  return actions.play()
}

const stop = () => {
  return actions.stop()
}

const prev = () => {
  return actions.prev()
}

const next = () => {
  return actions.next()
}

//= == ScriptEdit

const addScriptEntry = (entryType, index) => {
  return actions.addScriptEntry(entryType, index)
}

const addScriptItem = entryId => {
  return actions.addScriptItem(entryId)
}

const addScriptCondition = entryId => {
  return actions.addScriptCondition(entryId)
}

const deleteScriptCondition = conditionId => {
  return actions.deleteScriptCondition(conditionId)
}

const updateScriptCondition = condition => {
  return actions.updateScriptCondition(condition)
}

const updateScriptEntry = entry => {
  return actions.updateScriptEntry(entry)
}

const updateScriptItem = item => {
  return actions.updateScriptItem(item)
}

const deleteScriptEntry = entryId => {
  return actions.deleteScriptEntry(entryId)
}

const deleteScriptItem = itemId => {
  return actions.deleteScriptItem(itemId)
}

const swapScriptEntry = (entryId, num) => {
  return actions.swapScriptEntry(entryId, num)
}

const swapScriptItem = (itemId, num) => {
  return actions.swapScriptItem(itemId, num)
}

export default {
  addScript,
  addScriptCondition,
  addScriptEntry,
  addScriptItem,
  addPresetScript,
  asyncFetchScript,
  clearScript,
  deleteScript,
  deleteScriptCondition,
  deleteScriptEntry,
  deleteScriptItem,
  duplicateScript,
  duplicateScripts,
  exportScript,
  fetchScript,
  importScript,
  next,
  play,
  prev,
  saveScript,
  stop,
  subscribeScriptList,
  swapScriptEntry,
  swapScriptItem,
  unsubscribeScriptList,
  updateScript,
  updateScriptEntry,
  updateScriptItem,
  updateScriptCondition
}
