// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/icon/user/close-pannel.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/icon/user/question.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".OMW7gqOWTV4dimzfRn5Ao{background-color:#fff;bottom:0px;left:0px;margin:0px;padding:36px;position:absolute;right:0px;top:0px;opacity:1.0;transition:all 500ms cubic-bezier(0, 0.2, 0.2, 1);z-index:1;overflow:scroll}@media screen and (max-height: 450px){.OMW7gqOWTV4dimzfRn5Ao{opacity:0}}.OMW7gqOWTV4dimzfRn5Ao._1gcXSUdd0TyCdB5SyRxbEQ{bottom:-100%;pointer-events:none;top:0%;opacity:0}.OMW7gqOWTV4dimzfRn5Ao ._2EiaB_o6HBieKHN4zXbKwf{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;width:24px;height:24px;position:fixed;right:20px;top:20px;-webkit-tap-highlight-color:transparent}.OMW7gqOWTV4dimzfRn5Ao ._3_ib0IeR6AvTdhi4TOJLwk{font-size:18px;font-weight:bold}.OMW7gqOWTV4dimzfRn5Ao ._3A4sB-wTNQYIoyfSToJJY0{flood-color:#919D99;font-size:11px;opacity:.6;margin:4px 0 8px 0}.OMW7gqOWTV4dimzfRn5Ao ._279deOAHFGA-TN-Rdll3up{display:flex;align-items:center;margin:20px 0 15px 0;border-top:solid 1px #E8ECEE;padding:20px 0 0 0}.OMW7gqOWTV4dimzfRn5Ao ._279deOAHFGA-TN-Rdll3up ._3PSF7NK_3NDCaEzAgpQJ-N{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;width:22px;height:22px;margin:0 8px 0 0}.OMW7gqOWTV4dimzfRn5Ao ._2ufM5cwzoif3TTbt9vhkR3{font-size:11px;color:#919D99;margin:0px 0 16px 0}.OMW7gqOWTV4dimzfRn5Ao ._2ufM5cwzoif3TTbt9vhkR3 .S0uqs3MIjOR-cfc-srd26{font-size:18px;color:#384241;line-height:1.8rem;margin:4px 0}", ""]);
// Exports
exports.locals = {
	"container": "OMW7gqOWTV4dimzfRn5Ao",
	"hidden": "_1gcXSUdd0TyCdB5SyRxbEQ",
	"close": "_2EiaB_o6HBieKHN4zXbKwf",
	"title": "_3_ib0IeR6AvTdhi4TOJLwk",
	"date": "_3A4sB-wTNQYIoyfSToJJY0",
	"entryTitle": "_279deOAHFGA-TN-Rdll3up",
	"entryTitleIcon": "_3PSF7NK_3NDCaEzAgpQJ-N",
	"itemTitle": "_2ufM5cwzoif3TTbt9vhkR3",
	"answer": "S0uqs3MIjOR-cfc-srd26"
};
module.exports = exports;
