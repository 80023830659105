// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'

export const FETCH_MESSAGE_LIST = 'message/FETCH_MESSAGE_LIST'
export const DETOUCH_MESSAGE_LIST = 'message/DETOUCH_MESSAGE_LIST'
export const SET_CURRENT_MESSAGING_USER_ID =
  'message/SET_CURRENT_MESSAGING_USER_ID'
export const UPDATE_CURRENT_INPUT_MESSAGE =
  'message/UPDATE_CURRENT_INPUT_MESSAGE'
export const UPDATE_CURRENT_USER_ORDER = 'message/UPDATE_CURRENT_USER_ORDER'
export const UPDATE_CURRENT_USER_SORT = 'message/UPDATE_CURRENT_USER_SORT'
export const UPDATE_CURRENT_USER_FILTER = 'message/UPDATE_CURRENT_USER_FILTER'

export const USER_SORT_CREATED_AT = 'message/USER_SORT_CREATED_AT'
export const USER_SORT_NAME = 'message/USER_SORT_NAME'
export const USER_SORT_NUM_RECORD_TDAY = 'message/USER_SORT_NUM_RECORD_TDAY'
export const USER_SORT_NUM_RECORD_YDAY = 'message/USER_SORT_NUM_RECORD_YDAY'
export const USER_SORT_LAST_RECORD = 'message/USER_SORT_LAST_RECORD'
export const USER_SORT_DAYS_LEFT = 'message/USER_SORT_DAYS_LEFT'

export const USER_ORDER_DESC = 'message/USER_ORDER_DESC'
export const USER_ORDER_ASC = 'message/USER_ORDER_ASC'

export const USER_FILTER_NONE = 'message/USER_FILTER_NONE'
export const USER_FILTER_ON_GOING = 'message/USER_FILTER_ON_GOING'
export const USER_FILTER_BEFORE = 'message/USER_FILTER_BEFORE'
export const USER_FILTER_AFTER = 'message/USER_FILTER_AFTER'

export const DEFAULT_MESSAGE_STATE = {
  id: '',
  createdAt: new Date(),
  lineUserId: '',
  message: {},
  projectIds: [],
  type: ''
}

export const MESSAGE_TYPES = PropTypes.shape({
  createdAt: PropTypes.instanceOf(Date).isRequired,
  lineUserId: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  projectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired
})

export const FLEX_MESSAGE_TYPES = PropTypes.shape({
  body: PropTypes.shape({
    contents: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        contents: PropTypes.arrayOf(
          PropTypes.shape({
            weight: PropTypes.string,
            color: PropTypes.string,
            text: PropTypes.string.isRequired
          })
        ),
        action: PropTypes.shape({
          uri: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired
}).isRequired

export default {}
