// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { projectOperations } from '../../modules/project'

import * as projectTypes from '../../modules/project/types'
import MuiButton from '../Ui/MuiButton'
import classes from './ShareForms.scss'
import SectionRow from '../Ui/SectionRow'
import MuiAutoComplete from '../Ui/MuiAutoComplete'
import { DeleteButton } from '../Ui/Button'

export function ShareForms({ project, permitUpdate }) {
  const dispatch = useDispatch()
  const adminUserList = useSelector(state => state.adminUserState.adminUserList)
  const [ownerAdminUser, setOwnerAdminUser] = useState(null)

  useEffect(() => {
    const owner = adminUserList.find(u => u.uid === project.ownerAdminUid)
    setOwnerAdminUser(owner === undefined ? null : owner)
  }, [project, adminUserList])

  const onAddSharedAdminEntry = () => {
    dispatch(projectOperations.addSharedAdminEntry())
  }

  const onDeleteSharedAdminEntry = entryId => {
    dispatch(projectOperations.deleteSharedAdminEntry(entryId))
  }

  const onUpdateSharedAdminEntry = entry => {
    dispatch(projectOperations.updateSharedAdminEntry(entry))
  }

  const renderEntry = entry => {
    let adminList = [...adminUserList]
    if (ownerAdminUser != null) {
      adminList = adminList.filter(u => u.uid !== ownerAdminUser.uid)
    }
    const items = [
      { name: 'ユーザーを選択してください', value: '' },
      ...adminList.map(admin => ({ name: admin.adminName, value: admin.uid }))
    ]

    const options = adminList.map(admin => admin.adminName)
    const idMap = adminList.reduce(
      (hash, admin) => ({ ...hash, [admin.adminName]: admin.uid }),
      {}
    )
    const nameMap = adminList.reduce(
      (hash, admin) => ({ ...hash, [admin.uid]: admin.adminName }),
      {}
    )
    return (
      <div className={classes.entry}>
        <MuiAutoComplete
          disabled={!permitUpdate}
          options={options}
          value={nameMap[entry.uid]}
          onChange={(e, val) => {
            onUpdateSharedAdminEntry({ ...entry, ...{ uid: idMap[val] } })
          }}
        />
        {permitUpdate && (
          <DeleteButton onClick={e => onDeleteSharedAdminEntry(entry.id)} />
        )}
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <SectionRow label='共有設定' vertical>
        <div className={classes.ownerEntry}>
          <div className={classes.ownerName}>
            {ownerAdminUser == null ? (
              <>オーナーが設定されていません</>
            ) : (
              <>{ownerAdminUser.adminName}</>
            )}
          </div>
          <div className={classes.ownerRole}> オーナー </div>
        </div>
        <div className={classes.sharedEntries}>
          {project.sharedAdminEntries.map(entry => (
            <div key={entry.id}>{renderEntry(entry)}</div>
          ))}
        </div>
        {permitUpdate && (
          <MuiButton
            text='＋ 管理者を追加する'
            style={{ height: 20 }}
            onClick={e => onAddSharedAdminEntry()}
          />
        )}
      </SectionRow>
    </div>
  )
}

ShareForms.propTypes = {
  permitUpdate: PropTypes.bool.isRequired,
  project: PropTypes.shape(projectTypes.PROJECT_TYPES)
}

export default ShareForms
