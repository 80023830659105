// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

const style = {
  height: '100%',
  width: '100%'
}
export function DataGrid({ data, columns, onChange = () => {} }) {
  return (
    <div className='ag-theme-alpine' style={style}>
      <AgGridReact
        defaultColDef={{
          flex: 1,
          editable: true,
          resizable: true
        }}
        rowData={data}
        rowSelection='multiple'
        enableRangeSelection={true}
        onCellValueChanged={e => onChange(data)}
      >
        {columns.map(col => (
          <AgGridColumn key={col} field={col} />
        ))}
      </AgGridReact>
    </div>
  )
}

DataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
}

export default DataGrid
