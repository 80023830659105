// Takram Confidential
// Copyright (C) 2019-Present Takram

import uuidv4 from 'uuid'

import * as config from '../../constants/scriptConfig'

const reassignScriptIds = (script, assignMap = {}) => {
  const newScript = { ...script }
  newScript.data = newScript.data.map(entry => {
    const newEntry = { ...entry, id: uuidv4() }
    assignMap[entry.id] = newEntry.id
    newEntry.value = newEntry.value.map(item => {
      const newItem = { ...item, id: uuidv4() }
      assignMap[item.id] = newItem.id
      return newItem
    })
    return newEntry
  })
  newScript.data = newScript.data.map(entry => {
    entry.value = entry.value.map(item => {
      if (entry.type === config.TYPE_IF) {
        item.targetId =
          item.targetId === config.TARGET_ID_ANY
            ? config.TARGET_ID_ANY
            : assignMap[item.targetId]
        if (item.action.type === config.ACTION_JUMP) {
          item.action = { ...item.action, value: assignMap[item.action.value] }
        }
      }
      return item
    })
    return entry
  })
  return newScript
}

export default {
  reassignScriptIds
}
