// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import classes from './Navigator.scss'

const Navigator = ({ canNext, done, onClickNext }) => {
  const [isShrink, setIsShrink] = useState(false)
  return (
    <div className={classes.container}>
      <div
        className={ClassNames(classes.buttonArea, classes.buttonRight)}
        onClick={onClickNext}
        onTouchStart={() => setIsShrink(true)}
        onTouchEnd={() => setIsShrink(false)}
        style={{ display: done ? 'none' : '' }}
      >
        <div
          className={ClassNames(classes.button, {
            [classes.inactive]: !canNext
          })}
          style={{ transform: isShrink ? 'scale(0.95, 0.95)' : 'scale(1, 1)' }}
        >
          Next
          <div className={classes.arrowNext} />
        </div>
      </div>
      <div
        className={ClassNames(classes.buttonArea, classes.buttonCenter)}
        onClick={onClickNext}
        style={{ display: done ? '' : 'none' }}
      >
        <div className={classes.button}>回答を送信して終了する</div>
      </div>
    </div>
  )
}

Navigator.propTypes = {
  canNext: PropTypes.bool.isRequired,
  done: PropTypes.bool.isRequired,
  onClickNext: PropTypes.func.isRequired
}

Navigator.defaultProps = {}

export default Navigator
