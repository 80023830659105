// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import classes from './Frame.scss'

export default function Frame({ children }) {
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>{children}</div>
      <div className={classes.clearBoth} />
    </div>
  )
}

Frame.propTypes = {
  children: PropTypes.node.isRequired
}
