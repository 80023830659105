// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._19NDHZzB75i_wmb_meJDjs{width:360px;height:64px;margin:0 auto;color:#fff;background-color:#425c5b;border:solid 1px #E8ECEE;text-align:center;line-height:64px;font-weight:500;font-size:12px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;border-radius:32px;cursor:pointer}._19NDHZzB75i_wmb_meJDjs:hover{background-color:#546b68}._19NDHZzB75i_wmb_meJDjs:active{background-color:#e2ebea;color:#384241}.LNIO2Ub9q6WPpykXSp_ix{background-color:#fff;color:#384241}.LNIO2Ub9q6WPpykXSp_ix:hover{background-color:#FaFaFa}.LNIO2Ub9q6WPpykXSp_ix:active{background-color:#AAA}.cxrIsDUtg9HsFLqKX2JBI{height:48px;line-height:48px;border-radius:24px}._3si_74JCzSARC02u2gNvoI{color:#384241;height:48px;line-height:48px}._3si_74JCzSARC02u2gNvoI:hover{background-color:#FaFaFa;border-radius:0 0 12px 12px;color:#384241}._2x1lMSShggZNUA5vj_adVG input{margin:0;padding:0;background:none;border:solid 1px #E8ECEE;border-radius:8px;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;height:56px;font-size:16px;padding:0 16px}._2x1lMSShggZNUA5vj_adVG ::-webkit-input-placeholder{color:#919D99}._2x1lMSShggZNUA5vj_adVG textarea{margin:0;padding:0;background:none;border:solid 1px #E8ECEE;border-radius:8px;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;font-size:16px;padding:16px}._2x1lMSShggZNUA5vj_adVG select{margin:0;padding:12px 18px;background:none;border:none;border-radius:8;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;background-color:#e2ebea;height:48px;width:300px;font-size:16px}._3HYcxz2FdfPGUZcM1a7_Vt input{height:48px}", ""]);
// Exports
exports.locals = {
	"roundButton": "_19NDHZzB75i_wmb_meJDjs",
	"buttonLight": "LNIO2Ub9q6WPpykXSp_ix",
	"buttonThin": "cxrIsDUtg9HsFLqKX2JBI",
	"pannelButton": "_3si_74JCzSARC02u2gNvoI",
	"form": "_2x1lMSShggZNUA5vj_adVG",
	"formThin": "_3HYcxz2FdfPGUZcM1a7_Vt"
};
module.exports = exports;
