// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ListHeader from './ListHeader'
import { PERMIT_CREATE } from '../../constants/permission'
import { appSelectors, appTypes } from '../../modules/app'
import { projectOperations } from '../../modules/project'
import { userOperations } from '../../modules/user'

import AddOverlay from './AddOverlay'
import List from './List'
import classes from './Project.scss'

export function Project() {
  const dispatch = useDispatch()
  const [addProjectVisibility, setAddProjectVisibility] = useState(false)
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_PROJECT)
  )

  useEffect(() => {
    dispatch(projectOperations.subscribeProjectList())
    dispatch(userOperations.subscribeUserList())
    return () => {
      dispatch(projectOperations.unsubscribeProjectList())
      dispatch(userOperations.unsubscribeUserList())
    }
  }, [])

  return (
    <div className={classes.container}>
      <ListHeader
        onClickNew={e => setAddProjectVisibility(true)}
        permitCreate={isPermit(PERMIT_CREATE)}
      />
      <List />
      <br />
      {addProjectVisibility === true && (
        <AddOverlay onClickCancel={e => setAddProjectVisibility(false)} />
      )}
    </div>
  )
}

export default Project
