import ClassNames from 'classnames'
import React, { useState } from 'react'
import FlexMessagePreviewModal from './FlexMessagePreviewModal'
import InsertLinkPopper from './InsertLinkPopper'
import classes from './MessageConfig.scss'

export const MessageConfig = ({
  flagFlexMessage,
  messageContents,
  onClickEncloseText = () => {},
  onClickLinkButton = () => {},
  onClickLinkInsertButton = () => {},
  onClickRichButton = () => {},
  onClickSimpleButton = () => {}
}) => {
  // InsertLink
  const [linkAnchorEl, setLinkAnchorEl] = useState(null)
  const handlePopper = event => {
    setLinkAnchorEl(linkAnchorEl ? null : event.currentTarget)
  }
  // Preview
  const [openPreview, setOpenPreview] = useState(false)
  return (
    <div className={classes.container}>
      <div className={ClassNames(classes.textTypes)}>
        <div
          className={ClassNames(classes.button, classes.simpleTextButton, {
            [classes.active]: !flagFlexMessage
          })}
          onClick={() => onClickSimpleButton()}
        >
          Simple
        </div>
        <div
          className={ClassNames(classes.button, classes.richTextButton, {
            [classes.active]: flagFlexMessage
          })}
          onClick={() => onClickRichButton()}
        >
          Rich
        </div>
      </div>
      {flagFlexMessage ? (
        <div className={ClassNames(classes.textEdit)}>
          <div
            className={ClassNames(classes.button, classes.emphasis1)}
            onClick={() => onClickEncloseText('*')}
          >
            B
          </div>
          <div
            className={ClassNames(classes.button, classes.emphasis2)}
            onClick={() => onClickEncloseText('**')}
          >
            B
          </div>
          <div
            onClick={e => {
              handlePopper(e)
              onClickLinkButton()
            }}
            className={ClassNames(classes.button, classes.link)}
          />
          <InsertLinkPopper
            handlePopper={handlePopper}
            linkAnchorEl={linkAnchorEl}
            onClickLinkInsertButton={onClickLinkInsertButton}
          />
          <div
            className={ClassNames(classes.button, classes.preview)}
            onClick={() => setOpenPreview(true)}
          >
            Preview
          </div>
          <FlexMessagePreviewModal
            messageContents={messageContents}
            openPreview={openPreview}
            setOpenPreview={setOpenPreview}
          />
        </div>
      ) : null}
    </div>
  )
}

export default MessageConfig
