// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import OverView from './OverView'
import UserState from './UserState'
import { recordOperations, recordSelectors } from '../../modules/record'
import { scriptOperations } from '../../modules/script'
import { userOperations } from '../../modules/user'

import ScriptRecords from '../Record/ScriptRecords'

export function Record({ projectId }) {
  const dispatch = useDispatch()
  const scriptList = useSelector(state => state.scriptState.scriptList)
  const userRecordCount = useSelector(state =>
    recordSelectors.userRecordCountSelector(state)
  )

  useEffect(() => {
    dispatch(recordOperations.subscribeRecordList({ projectId }))
    dispatch(scriptOperations.subscribeScriptList(projectId))
    dispatch(userOperations.subscribeUserList(projectId))
    return () => {
      dispatch(recordOperations.unsubscribeRecordList())
      dispatch(scriptOperations.unsubscribeScriptList())
      dispatch(userOperations.unsubscribeUserList())
    }
  }, [])

  return (
    <div>
      <OverView projectId={projectId} />
      <UserState userRecordCount={userRecordCount} />
      {scriptList.map(script => (
        <ScriptRecords key={script.id} script={script} />
      ))}
    </div>
  )
}

Record.propTypes = {
  projectId: PropTypes.string.isRequired
}

export default Record
