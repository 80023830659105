// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './RequiredScriptAlert.scss'

const RequiredScriptAlert = ({ visibility }) => (
  <div
    className={ClassNames(classes.container, { [classes.hidden]: !visibility })}
  >
    <div className={classes.item}>
      <div className={classes.content}>未入力の必須アンケートがあります</div>
    </div>
  </div>
)

RequiredScriptAlert.propTypes = {
  visibility: PropTypes.bool.isRequired
}

RequiredScriptAlert.defaultProps = {
  visibility: true
}

export default RequiredScriptAlert
