// Takram Confidential
// Copyright (C) 2019-Present Takram

import React from 'react'

const style = {
  textAligh: 'left',
  red: { color: '#DA957F' },
  gray: { color: '#E1E7E5' },
  green: { color: '#6BC5CB' },
  orange: { color: '#EABC47' }
}

export function UserState({ user }) {
  return (
    <div style={style}>
      {user.isBefore ? (
        <>
          <span style={style.gray}>● </span>未実施
        </>
      ) : user.isOnGoing ? (
        <>
          <span style={style.orange}>● </span>実施中
        </>
      ) : (
        <>
          <span style={style.green}>● </span>完了
        </>
      )}
    </div>
  )
}

export default UserState
