// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'

export const SCRIPT_VERSION = 1

// Entry Types
export const TYPE_DONE = 'TYPE_DONE'
export const TYPE_IF = 'TYPE_IF'
export const TYPE_NONE = 'TYPE_NONE'
export const TYPE_PAGE = 'TYPE_PAGE'

// Item Types
export const INPUT_CHECK = 'INPUT_CHECK'
export const INPUT_DATE = 'INPUT_DATE'
export const INPUT_DESCRIPTION = 'INPUT_DESCRIPTION'
export const INPUT_NUMBER = 'INPUT_NUMBER'
export const INPUT_RADIO = 'INPUT_RADIO'
export const INPUT_SLIDER = 'INPUT_SLIDER'
export const INPUT_SLIDE_SEL = 'INPUT_SLIDE_SEL'
export const INPUT_TEXT = 'INPUT_TEXT'
export const INPUT_TIME = 'INPUT_TIME'
export const INPUT_USER_INFO = 'INPUT_USER_INFO'

// Item Value Types
export const VALUE_DATE = 'VALUE_DATE'
export const VALUE_LIST = 'VALUE_LIST'
export const VALUE_NUMERIC = 'VALUE_NUMERIC'
export const VALUE_TEXT = 'VALUE_TEXT'
export const VALUE_USER_INFO = 'VALUE_USER_INFO'
export const VALUE_DESCRIPTION = 'VALUE_DESCRIPTION'

const DEFAULT_ITEM_OPTION = {
  canChangeRequired: false,
  canTwoColumn: false,
  defaultRequired: false,
  valueType: VALUE_LIST
}

export const ITEM_OPTION_STYLES = {
  canChangeRequired: PropTypes.bool.isRequired,
  canTwoColumn: PropTypes.bool.isRequired,
  defaultRequired: PropTypes.bool.isRequired,
  valueType: PropTypes.string.isRequired
}

export const getItemOption = itemType => {
  switch (itemType) {
    case INPUT_CHECK:
      return {
        ...DEFAULT_ITEM_OPTION,
        canChangeRequired: true,
        canTwoColumn: true
      }
    case INPUT_DATE:
      return {
        ...DEFAULT_ITEM_OPTION,
        defaultRequired: true,
        valueType: VALUE_DATE
      }
    case INPUT_DESCRIPTION:
      return {
        ...DEFAULT_ITEM_OPTION,
        valueType: VALUE_DESCRIPTION
      }
    case INPUT_NUMBER:
      return {
        ...DEFAULT_ITEM_OPTION,
        defaultRequired: true,
        valueType: VALUE_NUMERIC
      }
    case INPUT_RADIO:
      return {
        ...DEFAULT_ITEM_OPTION,
        canChangeRequired: true,
        canTwoColumn: true
      }
    case INPUT_SLIDER:
      return {
        ...DEFAULT_ITEM_OPTION,
        valueType: VALUE_NUMERIC
      }
    case INPUT_SLIDE_SEL:
      return {
        ...DEFAULT_ITEM_OPTION,
        defaultRequired: true
      }
    case INPUT_TEXT:
      return {
        ...DEFAULT_ITEM_OPTION,
        canChangeRequired: true,
        valueType: VALUE_TEXT
      }
    case INPUT_TIME:
      return {
        ...DEFAULT_ITEM_OPTION,
        defaultRequired: true,
        valueType: VALUE_DATE
      }
    case INPUT_USER_INFO:
      return {
        ...DEFAULT_ITEM_OPTION,
        valueType: VALUE_USER_INFO
      }
    default:
      return DEFAULT_ITEM_OPTION
  }
}

export const getInitialValue = valueType => {
  switch (valueType) {
    case VALUE_LIST:
    case VALUE_DATE:
      return []
    case VALUE_NUMERIC:
      return [{ max: Infinity, min: -Infinity, value: 0 }]
    case VALUE_TEXT:
      return [{ text: '', formSize: 'medium' }]
    case VALUE_DESCRIPTION:
      return [{ text: '', size: 'small', color: 'gray' }]
    case VALUE_USER_INFO:
      return [{ attrId: '', type: '' }]
    default:
      return []
  }
}

// Target
export const TARGET_ID_ANY = 'TARGET_ID_ANY'

// Action Types
export const ACTION_JUMP = 'ACTION_JUMP'
export const ACTION_END = 'ACTION_END'

// Operator Types
export const OPERATOR_EQUAL = 'OPERATOR_EQUAL'
export const OPERATOR_NOT_EQUAL = 'OPERATOR_NOT_EQUAL'
export const OPERATOR_UNDER = 'OPERATOR_UNDER'
export const OPERATOR_OVER = 'OPERATOR_OVER'

// Condition Types
export const CONDITION_AND = 'CONDITION_AND'
export const CONDITION_OR = 'CONDITION_OR'

// Others
export const OTHERS_RECORD_PREFIX = 'その他:'
export const OTHERS_VALUE = 'OTHERS_VALUE'

// Templates
export const INITIAL_ENTRY_DATA = {
  id: '',
  type: TYPE_PAGE,
  title: '無題の質問タイトル',
  value: []
}

export const DONE_ENTRY_DATA = {
  id: 'done',
  type: TYPE_DONE,
  title: '',
  value: []
}

export const ENTRY_STYLES = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
}

export const INITIAL_ITEM_DATA = {
  column: 1,
  id: '',
  required: getItemOption(INPUT_RADIO).defaultRequired,
  title: '',
  type: INPUT_RADIO,
  value: []
}

export const ITEM_STYLE = {
  column: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired
}

export const INITIAL_CONDITION_DATA = {
  id: '',
  targetId: '',
  operator: OPERATOR_EQUAL,
  value: '',
  action: {
    type: ACTION_JUMP,
    value: ''
  }
}
