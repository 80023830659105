// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3J5J-pbgY3WcZ5FQuU-D1Z .FYSvdf5kipRHGxQy0CBMC .t61qtBRB80jDqdIpAH6IR{font-size:12px;margin-top:16px}._3J5J-pbgY3WcZ5FQuU-D1Z .FYSvdf5kipRHGxQy0CBMC .t61qtBRB80jDqdIpAH6IR ._1sKbsTJb6tG7cfYX0VtUnI{float:left;margin:0 5px 0 0}._3J5J-pbgY3WcZ5FQuU-D1Z .FYSvdf5kipRHGxQy0CBMC .t61qtBRB80jDqdIpAH6IR ._1zh7foa_eDYGxTrfPRfB1B{font-size:21px;color:#d9b389}._3J5J-pbgY3WcZ5FQuU-D1Z.r4Z8RS6ryEjDFlAezhFYG{align-items:center;display:flex;justify-content:center;min-height:100%}._3J5J-pbgY3WcZ5FQuU-D1Z.r4Z8RS6ryEjDFlAezhFYG .FYSvdf5kipRHGxQy0CBMC{margin:auto 0;width:100%}", ""]);
// Exports
exports.locals = {
	"container": "_3J5J-pbgY3WcZ5FQuU-D1Z",
	"item": "FYSvdf5kipRHGxQy0CBMC",
	"label": "t61qtBRB80jDqdIpAH6IR",
	"labeltitle": "_1sKbsTJb6tG7cfYX0VtUnI",
	"required": "_1zh7foa_eDYGxTrfPRfB1B",
	"middle": "r4Z8RS6ryEjDFlAezhFYG"
};
module.exports = exports;
