// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MessageConfig from '../Ui/MessageConfig'
import classes from './Form.scss'
import { messageOperations, messageSelectors } from '../../modules/message'
import { userOperations } from '../../modules/user'

export function Form({ sendUsers }) {
  const dispatch = useDispatch()
  const { updateCurrentInputMessage, sendTextMessage } = messageOperations
  const sendIds = sendUsers.map(u => u.id)
  const sendIdsStr = sendIds.sort().join(',')
  const msg = useSelector(
    state => state.messageState.currentInputMessages[sendIdsStr]
  )
  const currentInputMessage = msg == null ? '' : msg
  const sendDisabled =
    sendUsers.filter(user => user.lineUserId !== '').length === 0

  const [flagFlexMessage, setFlagFlexMessage] = useState(false)

  const onSendMessage = () => {
    if (sendDisabled) return
    sendUsers.forEach(user => {
      if (user.lineUserId === '') return
      const message = flagFlexMessage
        ? messageSelectors.generateFlexMessage(currentInputMessage)
        : messageSelectors.generateTextMessage(currentInputMessage)
      dispatch(sendTextMessage(user, message))
    })
    dispatch(updateCurrentInputMessage(sendIdsStr, ''))
  }

  const onUpdateMessage = message =>
    dispatch(updateCurrentInputMessage(sendIdsStr, message))

  const onClickMessageForm = () => {
    if (sendUsers.length !== 1) return
    dispatch(userOperations.updateMessageReadAt(sendUsers[0].id))
  }

  const insertSymbol = (target, symbol, index) => {
    return target.slice(0, index) + symbol + target.slice(index)
  }

  const textAreaField = React.useRef(null)

  const encloseSymbol = symbol => {
    const { selectionStart, selectionEnd } = textAreaField.current
    if (selectionStart === selectionEnd) return
    let newInputMessage = insertSymbol(
      currentInputMessage,
      symbol,
      selectionEnd
    )
    newInputMessage = insertSymbol(newInputMessage, symbol, selectionStart)
    onUpdateMessage(newInputMessage)
  }

  const insertLink = ({ label, url }) => {
    const { selectionStart } = textAreaField.current
    const newInputMessage = insertSymbol(
      currentInputMessage,
      '[' + label + '](' + url + ')',
      selectionStart
    )
    onUpdateMessage(newInputMessage)
  }

  return (
    <div className={classes.container}>
      <MessageConfig
        flagFlexMessage={flagFlexMessage}
        messageContents={
          messageSelectors.generateFlexMessage(currentInputMessage).contents
        }
        onClickEncloseText={symbol => encloseSymbol(symbol)}
        onClickLinkInsertButton={e => insertLink(e)}
        onClickRichButton={() => setFlagFlexMessage(true)}
        onClickSimpleButton={() => setFlagFlexMessage(false)}
      />
      <div className={classes.textAreaRow}>
        <textarea
          ref={textAreaField}
          rows='1'
          cols='100%'
          value={currentInputMessage}
          onChange={e => onUpdateMessage(e.target.value)}
          onClick={() => onClickMessageForm()}
          disabled={sendDisabled}
        />
        <div className={classes.sendButton} onClick={() => onSendMessage()}>
          送信
        </div>
      </div>
    </div>
  )
}

Form.propTypes = {
  sendUsers: PropTypes.array.isRequired
}

export default Form
