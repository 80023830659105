// Takram Confidential
// Copyright (C) 2019-Present Takram

import { combineReducers } from 'redux'

import * as types from './types'

const auth = (state = types.DEFAULT_AUTH_STATE, action) => {
  switch (action.type) {
    case types.LOGIN_OK:
      return { ...state, ...action.auth }
    case types.LOGOUT:
      return types.DEFAULT_AUTH_STATE
    default:
      return state
  }
}

const simulatorVisibility = (state = false, action) => {
  switch (action.type) {
    case types.SET_SIMULATOR_VISIBILITY:
      return action.visibility
    default:
      return state
  }
}

const isDebugUserMode = (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_DEBUG_USER_MODE:
      return action.isDebugUserMode
    default:
      return state
  }
}

const appReducer = combineReducers({
  auth,
  isDebugUserMode,
  simulatorVisibility
})

export default appReducer
