import React from 'react'

const verticalStyle = {
  minHeight: 56,
  position: 'relative',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '100px 1fr',
  gridColumnGap: 16,
  marginBottom: 16
}

const holizontalStyle = {
  minHeight: 56,
  position: 'relative',
  width: '100%',
  display: 'grid',
  gridTemplateRows: '16px 1fr',
  gridRowGap: 16,
  marginBottom: 16
}

const labelStyle = {
  color: '#384241',
  display: 'inline-block',
  marginBottom: 8,
  textAlign: 'left',
  width: '100%'
}

const contentStyle = {
  display: 'inline-block',
  width: '100%',
  textAlign: 'left'
}

export function SectionRow({ children, label, vertical = false }) {
  return (
    <div style={vertical ? verticalStyle : holizontalStyle}>
      <div style={labelStyle}>{label}</div>
      <div style={contentStyle}>{children}</div>
    </div>
  )
}

export default SectionRow
