// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'

export const FETCH_PROJECT = 'project/FETCH_PROJECT'
export const DETOUCH_PROJECT = 'project/DETOUCH_PROJECT'
export const FETCH_PROJECT_LIST = 'project/FETCH_PROJECT_LIST'
export const DETOUCH_PROJECT_LIST = 'project/DETOUCH_PROJECT_LIST'
export const ADD_PROJECT = 'project/ADD_PROJECT'
export const TOGGLE_PROJECT = 'project/TOGGLE_PROJECT'
export const SET_VISIBILITY_FILTER = 'project/SET_VISIBILITY_FILTER'
export const DUPLICATE_PROJECT = 'project/DUPLICATE_PROJECT'

export const CLEAR_PROJECT = 'project/CLEAR_PROJECT'
export const UPDATE_PROJECT = 'project/UPDATE_PROJECT'
export const SAVE_PROJECT = 'project/SAVE_PROJECT'
export const DELETE_PROJECT = 'project/DELETE_PROJECT'

export const ADD_EXTRA_USER_ATTR = 'project/ADD_EXTRA_USER_ATTR'
export const UPDATE_EXTRA_USER_ATTR = 'project/UPDATE_EXTRA_USER_ATTR'
export const DELETE_EXTRA_USER_ATTR = 'project/DELETE_EXTRA_USER_ATTR'

export const USER_ATTR_TYPE_TEXT = 'project/USER_ATTR_TYPE_TEXT'
export const USER_ATTR_TYPE_LIST = 'project/USER_ATTR_TYPE_LIST'
export const USER_ATTR_TYPE_MASKING_TEXT = 'project/USER_ATTR_TYPE_MASKING_TEXT'
export const USER_ATTR_TYPE_MASKING_LIST = 'project/USER_ATTR_TYPE_MASKING_LIST'
export const USER_ATTR_TYPES = [
  {
    type: USER_ATTR_TYPE_TEXT,
    name: '被験者別表示',
    description: '被験者毎に異なるサンプル名を表示する際に使います'
  },
  // { type: USER_ATTR_TYPE_LIST, name: 'リスト', description: '' },
  {
    type: USER_ATTR_TYPE_MASKING_TEXT,
    name: '被験者別日別表示',
    description: 'その日毎に異なるサンプル名を表示する際に使います'
  }
  // { type: USER_ATTR_TYPE_MASKING_LIST, name: 'マスキングリスト', description: '' }
]

export const getAttrTypeName = type => {
  const t = USER_ATTR_TYPES.find(t => t.type === type)
  return t == null ? '' : t.name
}

export const getAttrTypeDescription = type => {
  const t = USER_ATTR_TYPES.find(t => t.type === type)
  return t == null ? '' : t.description
}

export const SHOW_ALL = 'project/SHOW_ALL'
export const SHOW_FAVORITE = 'project/SHOW_FAVORITE'
export const SHOW_ARCHIVED = 'project/SHOW_ARCHIVED'

export const ADD_SHARED_ADMIN_ENTRY = 'project/ADD_SHARED_ADMIN_UID'
export const UPDATE_SHARED_ADMIN_ENTRY = 'project/UPDATE_SHARED_ADMIN_UID'
export const DELETE_SHARED_ADMIN_ENTRY = 'project/DELETE_SHARED_ADMIN_UID'

export const SHARED_ADMIN_TYPE_CAN_EDIT = 'project/SHARED_ADMIN_TYPE_CAN_EDIT'
export const SHARED_ADMIN_TYPE_CAN_VIEW = 'project/SHARED_ADMIN_TYPE_CAN_VIEW'

export const DEFAULT_PROJECT_STATE = {
  accessibleAdminUids: [],
  archived: false,
  createdAt: new Date(),
  description: '',
  extraUserAttr: [],
  favorite: false,
  id: '',
  ownerAdminUid: '',
  sharedAdminEntries: [],
  title: '',
  updatedAt: new Date()
}

export const DEFAULT_EXTRA_USER_ATTR = {
  id: '',
  title: '',
  type: USER_ATTR_TYPE_TEXT
}

export const EXTRA_USER_ATTR_TYPES = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export const DEFAULT_SHARED_ADMIN_ENTRY = {
  id: '',
  uid: '',
  type: SHARED_ADMIN_TYPE_CAN_EDIT // TODO: Not implimented
}

export const SHARED_ADMIN_ENTRY_TYPES = {
  id: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export const PROJECT_TYPES = {
  archived: PropTypes.bool.isRequired,
  favorite: PropTypes.bool.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  description: PropTypes.string.isRequired,
  extraUserAttr: PropTypes.arrayOf(PropTypes.shape(EXTRA_USER_ATTR_TYPES)),
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  updatedAt: PropTypes.instanceOf(Date).isRequired
}

export default {}
