// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'
import { createSelector } from 'reselect'

import * as projectTypes from '../project/types'
import * as scriptCondition from '../../constants/scriptCondition'
import * as scriptConfig from '../../constants/scriptConfig'
import { default as scriptSelectors } from '../script/selectors' // TODO: Should not import different selector
import { default as userSelectors } from '../user/selectors' // TODO: Should not import different selector

const currentEntryData = state => state.scriptState.currentEntryData
const currentLoginUserSelector = state => state.userState.currentLoginUser
const currentRecordDateSelector = state => state.formState.currentRecordDate
const currentRecordSelector = state => state.recordState.currentRecord
const recordListSelector = state => state.recordState.recordList
const scriptListSelector = state => state.scriptState.scriptList
const extraUserAttrSelector = state => state.projectState.project.extraUserAttr

const canNextSelector = createSelector(
  [currentEntryData, currentRecordSelector],
  (currentEntryData, currentRecord) => {
    if (currentEntryData === null) return { flag: false }
    for (const entry of currentEntryData.value) {
      if (entry.required) {
        const wasAnswerd = Object.prototype.hasOwnProperty.call(
          currentRecord.data,
          entry.id
        )
        if (!wasAnswerd) return { flag: false }
        switch (entry.type) {
          case scriptConfig.INPUT_CHECK:
            for (const a of currentRecord.data[entry.id]) {
              if (scriptSelectors.recordTextIsEmptyOthers(a))
                return { flag: false, recordHasEmptyOthers: true }
            }
            break
          case scriptConfig.INPUT_RADIO:
            const a = currentRecord.data[entry.id]
            if (scriptSelectors.recordTextIsEmptyOthers(a))
              return { flag: false, recordHasEmptyOthers: true }
            break
          default:
            break
        }
      }
    }
    return { flag: true }
  }
)

export const dailyFreeRecordListSelector = createSelector(
  [currentRecordDateSelector, recordListSelector, scriptListSelector],
  (currentRecordDate, recordList, scriptList) => {
    const newRecordList = recordList.filter(record => {
      const isSelectedDate = Moment(record.createdAt).isSame(
        Moment(currentRecordDate),
        'date'
      )
      const script = scriptList.find(script => script.id === record.scriptId)
      if (script === undefined) return false
      const isFreeScript =
        script.condition.type !== scriptCondition.SCRIPT_TYPE_REQUIRED
      return isSelectedDate && isFreeScript
    })
    return newRecordList.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
  }
)

const dailyRecordAndRequiredListSelector = createSelector(
  [
    currentLoginUserSelector,
    currentRecordDateSelector,
    dailyFreeRecordListSelector,
    recordListSelector,
    scriptListSelector
  ],
  (
    currentLoginUser,
    currentRecordDate,
    recordList,
    allRecordList,
    scriptList
  ) => {
    const newRecordList = recordList.concat()
    const requiredList = computeDailyRequiredList(
      currentLoginUser,
      allRecordList,
      scriptList,
      currentRecordDate
    )
    requiredList.forEach(required => {
      newRecordList.push(required)
    })
    return newRecordList.sort((a, b) => {
      const aDate = a.requiredDate !== undefined ? a.requiredDate : a.createdAt
      const bDate = b.requiredDate !== undefined ? b.requiredDate : b.createdAt
      return aDate > bDate ? 1 : -1
    })
  }
)

const dateCheckListSelector = createSelector(
  [
    currentLoginUserSelector,
    recordListSelector,
    scriptListSelector,
    userSelectors.userDateListSelector
  ],
  (currentLoginUser, allRecordList, scriptList, userDateList) => {
    const { endAt, startAt } = currentLoginUser
    if (startAt === undefined || endAt === undefined) return []
    const dateCheckList = []
    userDateList.forEach(date => {
      const requiredList = computeDailyRequiredList(
        currentLoginUser,
        allRecordList,
        scriptList,
        date
      )
      const done = requiredList.every(required => required.id !== undefined)
      dateCheckList.push({ date, done })
    })
    return dateCheckList
  }
)

export const computeDailyRequiredList = (
  currentLoginUser,
  allRecordList,
  scriptList,
  selectedDate
) => {
  const { endAt, startAt } = currentLoginUser
  if (startAt === undefined || endAt === undefined) return []
  const requireds = scriptList.filter(
    script => script.condition.type === scriptCondition.SCRIPT_TYPE_REQUIRED
  )
  const recordList = []
  requireds.forEach(script => {
    script.condition.items.forEach(item => {
      const requiredDate = Moment(selectedDate)
        .startOf('date')
        .set({
          hour: item.hours,
          minute: item.minutes
        })
        .toDate()
      let requiredId = ''
      if (item.type === scriptCondition.NOTIFY_TYPE_START_DATE) {
        requiredId += 'S'
        // TODO: Should it check user startAt?
        if (!Moment(selectedDate).isSame(Moment(startAt), 'date')) return
      }
      if (item.type === scriptCondition.NOTIFY_TYPE_END_DATE) {
        requiredId += 'E'
        // TODO: Should it check user endAt?
        if (!Moment(selectedDate).isSame(Moment(endAt), 'date')) return
      }
      if (item.type === scriptCondition.NOTIFY_TYPE_ELAPSED_DAYS) {
        requiredId += 'P'
        const targetDate = new Date(startAt)
        targetDate.setDate(targetDate.getDate() + item.elapsedDays)
        if (!Moment(selectedDate).isSame(Moment(targetDate), 'date')) return
      }
      if (item.type === scriptCondition.NOTIFY_TYPE_DAYS_OF_WEEK) {
        requiredId += 'W'
        const day = selectedDate.getDay()
        if (!item.daysOfWeek.includes(day)) return
      }
      if (item.type === scriptCondition.NOTIFY_TYPE_EVERYDAY) {
        requiredId += 'D'
        if (
          Moment(requiredDate).isBefore(Moment(startAt)) ||
          Moment(requiredDate).isAfter(Moment(endAt))
        ) {
          return
        }
      }
      if (item.type === scriptCondition.NOTIFY_TYPE_MIDDLE_DATE) {
        requiredId += 'M'
        if (
          Moment(requiredDate).isSameOrBefore(Moment(startAt), 'date') ||
          Moment(requiredDate).isSameOrAfter(Moment(endAt), 'date')
        ) {
          return
        }
      }
      requiredId +=
        Moment(selectedDate).format('YYYYMMDD') +
        ('00' + item.hours).slice(-2) +
        ('00' + item.minutes).slice(-2)
      const answeredRecord = allRecordList.find(
        record =>
          record.scriptId === script.id && record.requiredId === requiredId
      )
      recordList.push({
        id: undefined,
        requiredDate,
        requiredId,
        scriptId: script.id,
        ...answeredRecord
      })
    })
  })
  return recordList.sort((a, b) => (a.requiredDate > b.requiredDate ? 1 : -1))
}

const userItemsSelector = createSelector(
  [currentLoginUserSelector, extraUserAttrSelector],
  (currentLoginUser, extraUserAttr) => {
    const userItems = extraUserAttr.reduce((accum, { id, type }) => {
      const extraUserInfo = currentLoginUser.extraUserInfo[id]
      let value = []
      if (extraUserInfo !== undefined) {
        if (extraUserInfo.length !== 0) {
          value = extraUserInfo.map(text => ({ text }))
        }
      }
      return { ...accum, [id]: { value, type } }
    }, {})
    return userItems
  }
)

const attrAppliedEntrySelector = createSelector(
  [currentEntryData, currentLoginUserSelector, extraUserAttrSelector],
  (currentEntryData, currentLoginUser, extraUserAttr) => {
    if (currentEntryData == null) return null
    if (currentLoginUser == null) return currentEntryData
    const { extraUserInfo } = currentLoginUser

    const deriveAttrMaskingText = attr => {
      if (!(attr.id in extraUserInfo)) return 'Null'
      const info = extraUserInfo[attr.id]
      const { elapsedDays } = userSelectors.deriveTermStatus(currentLoginUser)
      if (info.length <= elapsedDays) return 'Null'
      return info[elapsedDays]
    }

    const deriveAttrText = attr => {
      if (!(attr.id in extraUserInfo)) return 'Null'
      return extraUserInfo[attr.id][0]
    }

    const replaceText = text => {
      extraUserAttr.forEach(attr => {
        const pattern = `{{${attr.title}}}`
        if (text.includes(pattern)) {
          const val =
            attr.type === projectTypes.USER_ATTR_TYPE_MASKING_TEXT
              ? deriveAttrMaskingText(attr)
              : attr.type === projectTypes.USER_ATTR_TYPE_TEXT
              ? deriveAttrText(attr)
              : 'Null'
          text = text.replace(pattern, val)
        }
      })
      return text
    }

    return {
      ...currentEntryData,
      title: replaceText(currentEntryData.title),
      value: currentEntryData.value.map(item => ({
        ...item,
        title: replaceText(item.title)
      }))
    }
  }
)

export default {
  attrAppliedEntrySelector,
  dailyRecordAndRequiredListSelector,
  dateCheckListSelector,
  canNextSelector,
  userItemsSelector
}
