// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon/user/arrow-next.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._3FUW5tnoyCx6G-8nzM7ZNU{bottom:32px;height:58px;left:24px;position:absolute;right:24px;z-index:2000}._3FUW5tnoyCx6G-8nzM7ZNU ._3s0obcf7akvnGSuTfdNmiF{box-sizing:border-box}._3FUW5tnoyCx6G-8nzM7ZNU ._3s0obcf7akvnGSuTfdNmiF._1AOjXMcIYorrr8Q2gYDBoY{padding-left:4px;right:0px}._3FUW5tnoyCx6G-8nzM7ZNU ._3s0obcf7akvnGSuTfdNmiF._1AOjXMcIYorrr8Q2gYDBoY .TZZSTdGeS73boom0iWZUd{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;height:24px;position:absolute;right:16px;top:17px;width:24px}._3FUW5tnoyCx6G-8nzM7ZNU ._3s0obcf7akvnGSuTfdNmiF.GQm29f3OcmbnPFaxPcV6W ._1ffXIjwsexbbgraxyX83wb{background:#d9b389}._3FUW5tnoyCx6G-8nzM7ZNU ._3s0obcf7akvnGSuTfdNmiF ._1ffXIjwsexbbgraxyX83wb{background:#425c5b;border-radius:100px;color:white;line-height:58px;text-align:center;transition:all 50ms ease-out}._3FUW5tnoyCx6G-8nzM7ZNU ._3s0obcf7akvnGSuTfdNmiF ._1ffXIjwsexbbgraxyX83wb._3ATQS5UcA96OMoexa34TpN{background:gray}", ""]);
// Exports
exports.locals = {
	"container": "_3FUW5tnoyCx6G-8nzM7ZNU",
	"buttonArea": "_3s0obcf7akvnGSuTfdNmiF",
	"buttonRight": "_1AOjXMcIYorrr8Q2gYDBoY",
	"arrowNext": "TZZSTdGeS73boom0iWZUd",
	"buttonCenter": "GQm29f3OcmbnPFaxPcV6W",
	"button": "_1ffXIjwsexbbgraxyX83wb",
	"inactive": "_3ATQS5UcA96OMoexa34TpN"
};
module.exports = exports;
