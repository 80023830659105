// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { projectOperations } from '../../modules/project'
import { userOperations } from '../../modules/user'

import commonCls from '../App/Common.scss'
import classes from './Add.scss'
import Forms from './Forms'
import AttrForms from './AttrForms'

export function Add({ projectId }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(state => state.userState.user)
  const extraUserAttr = useSelector(
    state => state.projectState.project.extraUserAttr
  )

  useEffect(() => {
    dispatch(userOperations.clearUser())
    dispatch(userOperations.updateUser({ projectId }))
    dispatch(projectOperations.fetchProject(projectId))
    return () => {
      dispatch(userOperations.clearUser())
      dispatch(projectOperations.clearProject())
    }
  }, [])

  const onClickCancel = e => {
    history.push(`/project/${projectId}/user/`)
  }

  const onSaveValue = e => {
    const nameArr = user.name.split(',').map(n => n.trim())
    dispatch(
      userOperations.addUsers(nameArr.map(name => ({ ...user, ...{ name } })))
    )
    history.push(`/project/${projectId}/user/`)
  }

  return (
    <div className={classes.container}>
      <div className={classes.backButton} onClick={e => onClickCancel(e)} />
      <h2>新しく被験者を追加する</h2>
      <br />
      <Forms user={user} />
      <AttrForms user={user} extraUserAttr={extraUserAttr} />
      <br />
      <div
        className={ClassNames(commonCls.roundButton, classes.button)}
        onClick={e => onSaveValue(e)}
      >
        追加する
      </div>
      <br />
    </div>
  )
}

export default Add
