// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3OMAD0lhJR_iMQfVCsT3Lz{margin:0;padding:0;display:grid;grid-template-columns:200px 200px 200px;grid-column-gap:16px}", ""]);
// Exports
exports.locals = {
	"container": "_3OMAD0lhJR_iMQfVCsT3Lz"
};
module.exports = exports;
