// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import classes from './AttrGridCatalog.scss'
import AttrTextGrid from './AttrTextGrid'
import AttrListGrid from './AttrListGrid'
import AttrMaskingTextGrid from './AttrMaskingTextGrid'
import AttrMaskingListGrid from './AttrMaskingListGrid'
import MuiButton from '../Ui/MuiButton'
import AttrOverlay from '../Project/AttrOverlay'

import {
  USER_ATTR_TYPE_TEXT,
  USER_ATTR_TYPE_LIST,
  USER_ATTR_TYPE_MASKING_TEXT,
  USER_ATTR_TYPE_MASKING_LIST,
  getAttrTypeName
} from '../../modules/project/types'

export function AttrGridCatalog({ projectId }) {
  const { extraUserAttr } = useSelector(state => state.projectState.project)
  const [attr, setAttr] = useState(null)
  const [attrSettingVisibility, setAttrSettingVisibility] = useState(false)
  const attrRef = useRef()

  useEffect(() => {
    if (extraUserAttr.length != 0) setAttr(extraUserAttr[0])
  }, [extraUserAttr])

  const onClickSave = () => attrRef.current.save()
  const onAddColumn = () => {
    attrRef.current.addColumn()
  }

  const canAddColumn =
    attr == null
      ? false
      : attr.type === USER_ATTR_TYPE_LIST ||
        attr.type === USER_ATTR_TYPE_MASKING_TEXT

  return (
    <div className={classes.container}>
      {attrSettingVisibility === true && (
        <AttrOverlay onClickCancel={e => setAttrSettingVisibility(false)} />
      )}
      <div className={classes.attrList}>
        {extraUserAttr.map(at => (
          <div
            key={at.id}
            className={ClassNames(classes.attrRow, {
              [classes.selected]: at === attr
            })}
            onClick={e => setAttr(at)}
          >
            <div className={classes.attrTitle}> {at.title} </div>
            <div className={classes.attrType}>{getAttrTypeName(at.type)}</div>
          </div>
        ))}
        <div className={classes.attrEditButton}>
          <MuiButton
            text='被験者属性を編集'
            style={{ height: 20, width: 160 }}
            onClick={e => setAttrSettingVisibility(true)}
          />
        </div>
      </div>
      <div className={classes.attrContainer}>
        <div className={classes.header}>
          <div className={classes.actions}>
            {canAddColumn && (
              <MuiButton text='列を追加' onClick={e => onAddColumn()} />
            )}
            <MuiButton text='保存' onClick={e => onClickSave()} />
          </div>
        </div>
        <div className={classes.grid}>
          {attr && attr.type === USER_ATTR_TYPE_TEXT ? (
            <AttrTextGrid ref={attrRef} attrId={attr.id} />
          ) : attr && attr.type === USER_ATTR_TYPE_LIST ? (
            <AttrListGrid ref={attrRef} attrId={attr.id} />
          ) : attr && attr.type === USER_ATTR_TYPE_MASKING_TEXT ? (
            <AttrMaskingTextGrid ref={attrRef} attrId={attr.id} />
          ) : attr && attr.type === USER_ATTR_TYPE_MASKING_LIST ? (
            <AttrMaskingListGrid ref={attrRef} attrId={attr.id} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

AttrGridCatalog.propTypes = {
  projectId: PropTypes.string.isRequired
}

export default AttrGridCatalog
