// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { recordSelectors } from '../../modules/record'
import { scriptSelectors } from '../../modules/script'
import {
  TYPE_PAGE,
  INPUT_TEXT,
  INPUT_DESCRIPTION
} from '../../constants/scriptConfig'
import classes from './ScriptRecords.scss'
import {
  RadialChart,
  HorizontalBarSeries,
  XAxis,
  XYPlot,
  YAxis
} from 'react-vis'
import * as recordTypes from '../../modules/record/types'
import MuiProgress from '../Ui/MuiProgress'
const COLORS = [
  '#429088',
  '#5D81B0',
  '#ECA6A6',
  '#F0D591',
  '#75B8DE',
  '#96DEE2',
  '#769EA4'
]

export function ScriptRecords({ script }) {
  const itemList = scriptSelectors.deriveItemList(script.data)
  const fetchStatus = useSelector(state => state.recordState.recordFetchStatus)
  const recordCount = useSelector(state =>
    recordSelectors.recordCountSelector(state)
  )

  const chartData = Object.entries(recordCount).reduce((hash, [key, val]) => {
    const dataArr = Object.keys(val).map(k => ({ x: val[k], y: k }))
    dataArr.sort((a, b) => (a.y < b.y ? 1 : -1))
    return { ...hash, [key]: dataArr }
  }, {})

  const typeMap = script.data
    .filter(e => e.type === TYPE_PAGE)
    .reduce((hash, entry) => {
      entry.value.forEach(item => (hash = { ...hash, [item.id]: item.type }))
      return hash
    }, {})

  const renderProgress = () => {
    return fetchStatus === recordTypes.FETCH_STATUS_IN_PROGRESS ? (
      <div className={classes.inProgress}>
        <MuiProgress />
      </div>
    ) : (
      <div className={classes.nograph}>データが見つかりません</div>
    )
  }

  const renderText = item => (
    <div className={classes.list}>
      {chartData[item.id]
        .sort((a, b) => b.x - a.x)
        .map((d, i) => (
          <div key={`${item.id}-${i}`} className={classes.row}>
            <div className={classes.text}>{d.y}</div>
            <div className={classes.num}>{d.x} 件</div>
          </div>
        ))}
    </div>
  )

  const renderBarChart = item => (
    <div className={classes.barChart}>
      <XYPlot
        yType='ordinal'
        width={464}
        height={chartData[item.id].length * 20 + 30}
        margin={{ left: 100, right: 10, top: 0, bottom: 30 }}
      >
        <HorizontalBarSeries color='#479F9E' data={chartData[item.id]} />
        <XAxis tickFormat={v => ((v * 10) % 10 == 0 ? Math.floor(v) : '')} />
        <YAxis />
      </XYPlot>
    </div>
  )

  const renderPieChart = item => {
    const buf = [...chartData[item.id]]
    buf.sort((a, b) => (a.x > b.x ? -1 : 1))
    const data = buf.map((d, i) => ({
      angle: d.x,
      label: d.y,
      color: COLORS[i]
    }))
    return (
      <div className={classes.pieChart}>
        <RadialChart
          radius={85}
          innerRadius={40}
          data={data}
          width={180}
          height={180}
          colorType='literal'
          animation={true}
          stroke={'#fff'}
          strokeWidth={2}
        />
        <div className={classes.legend}>
          {data.map(d => (
            <div key={d.label} className={classes.row}>
              <div
                className={classes.color}
                style={{ backgroundColor: d.color }}
              />
              <div className={classes.label}> {d.label} </div>
              <div className={classes.count}> {d.angle} 件 </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const typeFilter = item => typeMap[item.id] !== INPUT_DESCRIPTION
  return (
    <div className={classes.container}>
      <h3>{script.title}</h3>
      <div className={classes.itemContainer}>
        {itemList.filter(typeFilter).map(item => (
          <div key={item.id} className={classes.item}>
            <div className={classes.title}>{item.title}</div>
            {chartData[item.id] === undefined
              ? renderProgress()
              : typeMap[item.id] == INPUT_TEXT
              ? renderText(item)
              : chartData[item.id].length <= 7
              ? renderPieChart(item)
              : renderBarChart(item)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ScriptRecords
