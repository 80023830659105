// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon/user/checked.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._2W4h_kPlvPoDloqgUUJUi9{position:relative;background-color:#fff;width:100%;border-radius:10px;box-sizing:border-box;text-align:center}._2W4h_kPlvPoDloqgUUJUi9 ._2y-ZDSxAwf5SIFZW_VT9R_{border-bottom:1px solid #f1f1f1;margin:24px auto;width:800px}._2W4h_kPlvPoDloqgUUJUi9 ._1I9nfMWIjVRStrrGcfso_i{display:flex;justify-content:space-between;width:800px;margin:auto}._2W4h_kPlvPoDloqgUUJUi9 ._1I9nfMWIjVRStrrGcfso_i ._1nYymF-LJc84fyydy6ZkQj{width:167px}._2W4h_kPlvPoDloqgUUJUi9 ._1I9nfMWIjVRStrrGcfso_i ._1nYymF-LJc84fyydy6ZkQj ._38nIWRrUE7S8Zk8XPftkoW{color:#919D99;margin-bottom:12px;text-align:left}._2W4h_kPlvPoDloqgUUJUi9 ._1I9nfMWIjVRStrrGcfso_i ._1nYymF-LJc84fyydy6ZkQj ._33Q_4c6srLHXxarH3cCbrf{text-align:left}._2W4h_kPlvPoDloqgUUJUi9 .yhIVIHOfCB7u61dlmqHXH{height:44px;text-align:left;width:284px}._2W4h_kPlvPoDloqgUUJUi9 .yhIVIHOfCB7u61dlmqHXH ._1pkZarjiTMJg2BNKrVZFpv{border-radius:4px;border:1px solid #919D99;display:inline-block;height:16px;margin-right:10px;vertical-align:middle;width:16px}._2W4h_kPlvPoDloqgUUJUi9 .yhIVIHOfCB7u61dlmqHXH ._1pkZarjiTMJg2BNKrVZFpv.UiAfSebxw4vdLGFEUe7Yv{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}._2W4h_kPlvPoDloqgUUJUi9 .yhIVIHOfCB7u61dlmqHXH ._1xbhKLpBfDpDcKH7EXJm35{display:inline-block;font-size:16px;height:44px;line-height:44px;vertical-align:middle}", ""]);
// Exports
exports.locals = {
	"container": "_2W4h_kPlvPoDloqgUUJUi9",
	"line": "_2y-ZDSxAwf5SIFZW_VT9R_",
	"row": "_1I9nfMWIjVRStrrGcfso_i",
	"column": "_1nYymF-LJc84fyydy6ZkQj",
	"label": "_38nIWRrUE7S8Zk8XPftkoW",
	"state": "_33Q_4c6srLHXxarH3cCbrf",
	"isExcludedRecord": "yhIVIHOfCB7u61dlmqHXH",
	"checkbox": "_1pkZarjiTMJg2BNKrVZFpv",
	"checked": "UiAfSebxw4vdLGFEUe7Yv",
	"text": "_1xbhKLpBfDpDcKH7EXJm35"
};
module.exports = exports;
