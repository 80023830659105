// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1RbdU-K8Upxg3tHxoIs_iC{padding-left:24px;position:absolute;top:50%}._1RbdU-K8Upxg3tHxoIs_iC .B_QKpb7CyJ9j-VoGsYtMP ._39v6jSHDCWZBAFSsN3OMS_{color:white;font-size:16px}._1RbdU-K8Upxg3tHxoIs_iC.VFlFO0j8KgqimASKY8kcD{display:none}", ""]);
// Exports
exports.locals = {
	"container": "_1RbdU-K8Upxg3tHxoIs_iC",
	"item": "B_QKpb7CyJ9j-VoGsYtMP",
	"content": "_39v6jSHDCWZBAFSsN3OMS_",
	"hidden": "VFlFO0j8KgqimASKY8kcD"
};
module.exports = exports;
