// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import classes from './StringLog.scss'

const StringLog = ({ string }) => (
  <div className={classes.container}>{string}</div>
)

StringLog.propTypes = {
  string: PropTypes.string.isRequired
}

StringLog.defaultProps = {
  string: ''
}

export default StringLog
