// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".XS_LGReGmPjYF5VuSGUK0{background-color:#f1f1f1;min-height:100%;overflow:hidden;padding:0 0 120px 0;width:100%}.XS_LGReGmPjYF5VuSGUK0 .emPCaRp55uwICf-URUCOs{position:relative;width:744;margin:0 auto;padding:0 80px}.XS_LGReGmPjYF5VuSGUK0 ._2J1RH79ZZDCQjPgkhOx6CS{clear:both}", ""]);
// Exports
exports.locals = {
	"container": "XS_LGReGmPjYF5VuSGUK0",
	"innerContainer": "emPCaRp55uwICf-URUCOs",
	"clearBoth": "_2J1RH79ZZDCQjPgkhOx6CS"
};
module.exports = exports;
