// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import classes from './Header.scss'

export default function Header({
  backAddr,
  onClickLink,
  onClickPlay,
  onClickSave,
  onClickSetting,
  permitUpdate
}) {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Link to={backAddr}>
          <div className={classes.backButton} />
        </Link>
        <div className={classes.buttonContainer}>
          <div className={classes.linkButton} onClick={e => onClickSetting(e)}>
            設定
          </div>
          <div className={classes.linkButton} onClick={e => onClickLink(e)}>
            リンク
          </div>
          <div className={classes.playButton} onClick={e => onClickPlay(e)}>
            プレビュー
          </div>
          <div
            className={ClassNames(classes.saveButton, {
              [classes.disabled]: !permitUpdate
            })}
            onClick={e => permitUpdate && onClickSave(e)}
          >
            保存する
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  backAddr: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onClickPlay: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickSetting: PropTypes.func.isRequired,
  permitUpdate: PropTypes.bool.isRequired
}
