// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'

export const ADD_ADMIN_USER = 'admin_user/ADD_ADMIN_USER'
export const DELETE_ADMIN_USER = 'admin_user/DELETE_ADMIN_USER'
export const FETCH_ADMIN_USER = 'admin_user/FETCH_ADMIN_USER'
export const FETCH_ADMIN_USER_LIST = 'admin_user/FETCH_ADMIN_USER_LIST'
export const DETOUCH_ADMIN_USER_LIST = 'admin_user/DETOUCH_ADMIN_USER_LIST'
export const UPDATE_ADMIN_USER = 'admin_user/UPDATE_ADMIN_USER'
export const SAVE_ADMIN_USER = 'admin_user/SAVE_ADMIN_USER'
export const CLEAR_ADMIN_USER = 'admin_user/CLEAR_ADMIN_USER'

export const SET_ADMIN_USER_LIST_ORDER = 'admin_user/SET_ADMIN_USER_LIST_ORDER'
export const ORDER_BY_NAME_ASC = 'admin_user/ORDER_BY_NAME_ASC'
export const ORDER_BY_NAME_DESC = 'admin_user/ORDER_BY_NAME_DESC'
export const ORDER_BY_CREATED_AT_ASC = 'admin_user/ORDER_BY_CREATED_AT_ASC'
export const ORDER_BY_CREATED_AT_DESC = 'admin_user/ORDER_BY_CREATED_AT_DESC'
export const ORDER_BY_LEVEL_ASC = 'admin_user/ORDER_BY_LEVEL_ASC'
export const ORDER_BY_LEVEL_DESC = 'admin_user/ORDER_BY_LEVEL_DESC'

export const FETCH_ADMIN_REQUEST_LIST = 'admin_user/FETCH_ADMIN_REQUEST_LIST'
export const DETOUCH_ADMIN_REQUEST_LIST =
  'admin_user/DETOUCH_ADMIN_REQUEST_LIST'
export const FETCH_ADMIN_REQUEST_EXISTANCE =
  'admin_user/FETCH_ADMIN_REQUEST_EXISTANCE'

export const DEFAULT_ADMIN_USER_STATE = {
  id: '',
  uid: '',
  createdAt: new Date(),
  adminName: '',
  level: 0
}

export const ADMIN_USER_TYPES = {
  id: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  adminName: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired
}

export const DEFAULT_ADMIN_REQUEST_STATE = {
  adminName: '',
  createdAt: new Date(),
  uid: ''
}

export const ADMIN_REQUEST_TYPES = {
  adminName: PropTypes.string.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  uid: PropTypes.string.isRequired
}

export default {}
