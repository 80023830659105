// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Navigator from '../components/Form/Navigator'
import { formOperations, formSelectors } from '../modules/form'
import { recordOperations } from '../modules/record'
import { scriptOperations, scriptSelectors } from '../modules/script'

class FormNavigator extends Component {
  // TODO: Refactoring
  onClickNext() {
    const {
      canNext,
      formOperations,
      isDebugUserMode,
      isDone,
      recordOperations
    } = this.props
    // TODO: Move to operations.
    if (!canNext.flag) {
      if (canNext.recordHasEmptyOthers) {
        formOperations.setRequiredItemAlertVisibility(false)
        formOperations.setRequiredOthersItemAlertVisibility(true)
      } else {
        formOperations.setRequiredItemAlertVisibility(true)
        formOperations.setRequiredOthersItemAlertVisibility(false)
      }
      return
    }
    this.props.scriptOperations.next()
    if (isDone && !isDebugUserMode) {
      recordOperations.saveCurrentRecord()
    }
  }

  render() {
    const { canNext, isDone } = this.props
    return (
      <Navigator
        canNext={canNext.flag}
        done={isDone}
        onClickNext={() => this.onClickNext()}
        onClickPrev={() => this.props.scriptOperations.prev()}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    canNext: formSelectors.canNextSelector(state),
    isDebugUserMode: state.appState.isDebugUserMode,
    isDone: scriptSelectors.isDoneSelector(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    formOperations: bindActionCreators(formOperations, dispatch),
    recordOperations: bindActionCreators(recordOperations, dispatch),
    scriptOperations: bindActionCreators(scriptOperations, dispatch)
  }
}

FormNavigator.propTypes = {
  canNext: PropTypes.shape({
    flag: PropTypes.bool.isRequired,
    recordHasEmptyOthers: PropTypes.bool
  }).isRequired,
  formOperations: PropTypes.objectOf(PropTypes.func).isRequired,
  isDebugUserMode: PropTypes.bool.isRequired,
  isDone: PropTypes.bool.isRequired,
  recordOperations: PropTypes.objectOf(PropTypes.func).isRequired,
  scriptOperations: PropTypes.objectOf(PropTypes.func).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(FormNavigator)
