// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".C_C9VIsiYaZwO-Mfv0kho{display:grid;grid-template-columns:120px 1fr;grid-column-gap:12px}.C_C9VIsiYaZwO-Mfv0kho ._29wbss2noSvKYqmB7xkp3R{background-color:#FFF;border-radius:8px;width:120px;box-sizing:border-box;padding:10px 0}.C_C9VIsiYaZwO-Mfv0kho ._29wbss2noSvKYqmB7xkp3R ._1iBiXGep-p3Cfxmv2r5tYr{box-sizing:border-box;height:30px;line-height:30px;padding:0 20px;color:#919D99}.C_C9VIsiYaZwO-Mfv0kho ._29wbss2noSvKYqmB7xkp3R ._1iBiXGep-p3Cfxmv2r5tYr._3f5MBoK_fBdFRA8dWC7eG_{font-weight:bold;color:#384241}.C_C9VIsiYaZwO-Mfv0kho ._29wbss2noSvKYqmB7xkp3R ._1iBiXGep-p3Cfxmv2r5tYr:hover{background-color:#e2ebea}", ""]);
// Exports
exports.locals = {
	"container": "C_C9VIsiYaZwO-Mfv0kho",
	"tabMenu": "_29wbss2noSvKYqmB7xkp3R",
	"menuItem": "_1iBiXGep-p3Cfxmv2r5tYr",
	"selected": "_3f5MBoK_fBdFRA8dWC7eG_"
};
module.exports = exports;
