import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { messageOperations } from '../../modules/message'
import {
  USER_FILTER_AFTER,
  USER_FILTER_BEFORE,
  USER_FILTER_NONE,
  USER_FILTER_ON_GOING,
  USER_ORDER_ASC,
  USER_ORDER_DESC,
  USER_SORT_CREATED_AT,
  USER_SORT_DAYS_LEFT,
  USER_SORT_LAST_RECORD,
  USER_SORT_NAME,
  USER_SORT_NUM_RECORD_TDAY,
  USER_SORT_NUM_RECORD_YDAY
} from '../../modules/message/types'
import MuiSelect from '../Ui/MuiSelect'

import classes from './UserOrder.scss'

export function UserOrder() {
  const dispatch = useDispatch()
  const sort = useSelector(state => state.messageState.currentUserSort)
  const order = useSelector(state => state.messageState.currentUserOrder)
  const filter = useSelector(state => state.messageState.currentUserFilter)
  const {
    setCurrentUserSort,
    setCurrentUserOrder,
    setCurrentUserFilter
  } = messageOperations

  const onChangeSort = e => {
    dispatch(setCurrentUserSort(e.target.value))
  }
  const onChangeOrder = e => {
    dispatch(setCurrentUserOrder(e.target.value))
  }
  const onChangeFilter = e => {
    dispatch(setCurrentUserFilter(e.target.value))
  }

  const sortItems = [
    { name: '名前', value: USER_SORT_NAME },
    { name: '登録日', value: USER_SORT_CREATED_AT },
    { name: '今日の回答数', value: USER_SORT_NUM_RECORD_TDAY },
    { name: '昨日の回答数', value: USER_SORT_NUM_RECORD_YDAY },
    { name: '最終回答日', value: USER_SORT_LAST_RECORD },
    { name: '残り日数', value: USER_SORT_DAYS_LEFT }
  ]
  const orderItems = [
    { name: '昇順', value: USER_ORDER_ASC },
    { name: '降順', value: USER_ORDER_DESC }
  ]
  const filterItems = [
    { name: 'すべて', value: USER_FILTER_NONE },
    { name: '実施中', value: USER_FILTER_ON_GOING },
    { name: '未実施', value: USER_FILTER_BEFORE },
    { name: '完了', value: USER_FILTER_AFTER }
  ]

  return (
    <div className={classes.container}>
      <MuiSelect
        onChange={onChangeSort}
        items={sortItems}
        value={sort}
        label='Sort'
        fullWidth
      />
      <MuiSelect
        onChange={onChangeOrder}
        items={orderItems}
        value={order}
        label='Order'
        fullWidth
      />{' '}
      <MuiSelect
        onChange={onChangeFilter}
        items={filterItems}
        value={filter}
        label='Filter'
        fullWidth
      />
    </div>
  )
}

export default UserOrder
