// Takram Confidential
// Copyright (C) 2019-Present Takram

import _ from 'lodash'
import { combineReducers } from 'redux'

import * as types from './types'

const user = (state = _.cloneDeep(types.DEFAULT_USER_STATE), action) => {
  switch (action.type) {
    case types.FETCH_USER:
      return { ...state, ...action.user }
    case types.UPDATE_USER:
      return { ...state, ...action.user }
    case types.SAVE_USER:
      return { ...state, ...action.user }
    case types.CLEAR_USER:
    case types.DELETE_USER:
      return _.cloneDeep(types.DEFAULT_USER_STATE)
    default:
      return state
  }
}

const userList = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_USER_LIST:
      return action.userList
    case types.DETOUCH_USER_LIST:
      return []
    default:
      return state
  }
}

const currentLoginUser = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_LOGIN_USER:
      return action.user
    case types.DETOUCH_LOGIN_USER:
      return {}
    default:
      return state
  }
}

const userListOrder = (state = types.ORDER_BY_USER_NAME_ASC, action) => {
  switch (action.type) {
    case types.SET_USER_LIST_ORDER:
      return action.order
    default:
      return state
  }
}

const userReducer = combineReducers({
  currentLoginUser,
  user,
  userList,
  userListOrder
})

export default userReducer
