// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { Component } from 'react'

import * as scriptConfig from '../../../constants/scriptConfig'

import classes from './Item.scss'

export default class ItemList extends Component {
  constructor(props) {
    super(props)
    this.textInput = React.createRef()
    this.beforeKeyDown = null
  }

  focus(idx) {
    const { value } = this.props
    if (idx < 0 || idx > this.textRefs.length - 1) return
    const current = this.textRefs[idx].current
    if (current) current.focus()
  }

  addItem(idx) {
    const { value, onChangeValue } = this.props
    value.splice(idx, 0, { text: '' })
    const othersIdx = value.findIndex(e => e.isOthers)
    const containOthers = othersIdx !== -1
    if (containOthers) {
      value.splice(othersIdx, 1)
      value.splice(value.length, 0, {
        isOthers: true,
        text: scriptConfig.OTHERS_VALUE
      })
      idx--
    }
    onChangeValue('value', value)
    setTimeout(e => this.focus(idx), 10)
  }

  addOthers() {
    const { value, onChangeValue } = this.props
    const containOthers = value.findIndex(e => e.isOthers) !== -1
    if (containOthers) return
    const insertIdx = value.length
    value.splice(insertIdx, 0, {
      isOthers: true,
      text: scriptConfig.OTHERS_VALUE
    })
    onChangeValue('value', value)
  }

  delItem(idx) {
    const { value, onChangeValue } = this.props
    value.splice(idx, 1)
    onChangeValue('value', value)
    setTimeout(e => this.focus(idx - 1), 10)
  }

  onClickSwap(index, num) {
    const { value, onChangeValue } = this.props
    const targetIndex = index + num
    if (targetIndex > value.length - 1 || targetIndex < 0) return
    if (value[index].isOthers) return
    if (value[targetIndex].isOthers) return
    const item = value[targetIndex]
    value[targetIndex] = value[index]
    value[index] = item
    onChangeValue('value', value)
  }

  renderControls = index => {
    const { value } = this.props
    const isOthers = value.findIndex(e => e.isOthers) === index
    return (
      <div className={classes.buttons}>
        {isOthers || (
          <>
            <div
              className={classes.upButton}
              onClick={e => this.onClickSwap(index, -1)}
            />
            <div
              className={classes.downButton}
              onClick={e => this.onClickSwap(index, 1)}
            />
          </>
        )}
        <div
          className={classes.deleteButton}
          onClick={e => this.delItem(index)}
        />
      </div>
    )
  }

  renderAddButtons = () => {
    const { value } = this.props
    const containOthers = value.findIndex(e => e.isOthers) !== -1
    return (
      <>
        <div className={classes.row}>
          <div onClick={e => this.addItem(value.length)}>
            <div className={classes.addLineIcon} />
            <div className={classes.addLine}>選択肢を追加</div>
          </div>
        </div>
        {containOthers || (
          <div className={classes.row}>
            <div onClick={e => this.addOthers()} className={classes.addOthers}>
              「その他」を追加
            </div>
          </div>
        )}
      </>
    )
  }

  render() {
    const { id, onChangeValue, permitUpdate, type, value } = this.props
    this.textRefs = value.map((_, i) => React.createRef())
    return (
      <div className={classes.listContainer}>
        {value.map((v, i) => (
          <div className={classes.row} key={id + i}>
            <div className={classes.selectIcon}>
              {type === scriptConfig.INPUT_RADIO
                ? '○'
                : type === scriptConfig.INPUT_CHECK
                ? '□'
                : ''}
            </div>
            {v.isOthers ? (
              <div className={classes.others}>その他</div>
            ) : (
              <input
                disabled={!permitUpdate}
                type='text'
                value={v.text === undefined ? '' : v.text}
                placeholder={'選択肢' + i}
                onChange={e => {
                  value[i] = { text: e.target.value }
                  onChangeValue('value', value)
                }}
                ref={this.textRefs[i]}
                onKeyDown={e => {
                  this.beforeKeyDown = value[i].text
                }}
                onKeyPress={e => {
                  if (e.which === 13) this.addItem(i + 1)
                }}
                onKeyUp={e => {
                  console.log('UP : ', e.which)
                  if (e.which === 8) {
                    if (
                      (value[i].text === '' && this.beforeKeyDown === '') ||
                      value[i].text == null
                    ) {
                      this.delItem(i)
                    }
                  }
                }}
                tabIndex='0'
              />
            )}
            {permitUpdate && this.renderControls(i)}
          </div>
        ))}
        {permitUpdate && this.renderAddButtons()}
      </div>
    )
  }
}

ItemList.propTypes = {
  ...scriptConfig.ITEM_STYLE,
  onChangeValue: PropTypes.func.isRequired
}

ItemList.defaultProps = {
  ...scriptConfig.INITIAL_ITEM_DATA
}
