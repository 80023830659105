// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._27l1rek96rwGeogRJHkyOb{color:#384241}._27l1rek96rwGeogRJHkyOb ._3lsVIeSb08kDWDs2_yWDPW{text-decoration:none}._27l1rek96rwGeogRJHkyOb ._3lsVIeSb08kDWDs2_yWDPW._2HcAuW3mE-pr2Y9GMqfC2d{pointer-events:none}._27l1rek96rwGeogRJHkyOb ._3lsVIeSb08kDWDs2_yWDPW ._1fL10-ag3gpKuYxXi6aJMU{background-color:#425c5b;border-radius:12px;color:white;font-size:18px;font-weight:bold;height:50px;line-height:50px;margin:10px 0;min-width:200px;text-align:center;width:100%}", ""]);
// Exports
exports.locals = {
	"container": "_27l1rek96rwGeogRJHkyOb",
	"link": "_3lsVIeSb08kDWDs2_yWDPW",
	"disabled": "_2HcAuW3mE-pr2Y9GMqfC2d",
	"button": "_1fL10-ag3gpKuYxXi6aJMU"
};
module.exports = exports;
