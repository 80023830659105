// Takram Confidential
// Copyright (C) 2019-Present Takram

export default function NameSpace(name = undefined) {
  const symbol = Symbol(name)
  return function namespace(object, init = data => data) {
    if (object[symbol] === undefined) {
      object[symbol] = init({})
    }
    return object[symbol]
  }
}
