// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AdminUserForms from './Forms'
import AdminUserFrame from './Frame'
import AdminUserListCatalog from './ListCatalog'
import OverlayFrame from '../App/OverlayFrame'
import { PERMIT_READ } from '../../constants/permission'
import { adminUserOperations } from '../../modules/admin_user'
import { appSelectors, appTypes } from '../../modules/app'

export function Admin() {
  const dispatch = useDispatch()
  const adminUser = useSelector(state => state.adminUserState.adminUser)
  const [visibleAddOverlay, setVisibleAddOverlay] = useState(false)

  const isAdminUserPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_ADMIN_USER)
  )

  const onClickOpenAddFrame = () => {
    dispatch(adminUserOperations.clearAdminUser())
    setVisibleAddOverlay(true)
  }

  const onClickAdd = () => {
    dispatch(adminUserOperations.addAdminUser(adminUser))
    setVisibleAddOverlay(false)
  }

  return (
    <div>
      {isAdminUserPermit(PERMIT_READ) ? (
        <AdminUserFrame>
          <AdminUserListCatalog
            onClickAdd={e => {
              onClickOpenAddFrame()
            }}
          />
          {visibleAddOverlay && (
            <OverlayFrame
              title='管理者追加'
              onClickClose={e => setVisibleAddOverlay(false)}
              buttons={[
                {
                  label: '追加',
                  onClick: onClickAdd,
                  accent: true
                }
              ]}
            >
              <AdminUserForms
                adminUser={adminUser}
                onChangeValue={(key, val) => {
                  dispatch(adminUserOperations.updateAdminUser({ [key]: val }))
                }}
                isEditableUID
              />
            </OverlayFrame>
          )}
        </AdminUserFrame>
      ) : (
        <>Error!</>
      )}
    </div>
  )
}

export default Admin
