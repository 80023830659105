// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import * as systemConfig from '../../constants/systemConfig'

import classes from './Simulator.scss'

const Simulator = ({ scriptId }) => (
  <div className={classes.container}>
    <div className={classes.simulator}>
      <iframe src={'/m/' + systemConfig.ADMIN_USER_HASH + '/' + scriptId} />
    </div>
  </div>
)

Simulator.propTypes = {
  scriptId: PropTypes.string.isRequired
}

export default Simulator
