// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2bgEx7EXtTjDXNoRdLqYyX{color:#919D99;font-size:10px;margin-top:8px;display:inline-block;width:100%}._2bgEx7EXtTjDXNoRdLqYyX._3OPvOT3IL0dHudTRQpzQ40{font-size:12px}._2bgEx7EXtTjDXNoRdLqYyX._2Rw-IlHdvQ3pBFRwzV7bTl{font-size:14px}._2bgEx7EXtTjDXNoRdLqYyX.ahhzQ-tSUMI36-l-L8qwV{color:#384241}._2bgEx7EXtTjDXNoRdLqYyX._2B-vXIVaTQrRu1VYlS5UUh{color:#CE4930}", ""]);
// Exports
exports.locals = {
	"content": "_2bgEx7EXtTjDXNoRdLqYyX",
	"medium": "_3OPvOT3IL0dHudTRQpzQ40",
	"large": "_2Rw-IlHdvQ3pBFRwzV7bTl",
	"black": "ahhzQ-tSUMI36-l-L8qwV",
	"red": "_2B-vXIVaTQrRu1VYlS5UUh"
};
module.exports = exports;
