// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/habireco.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._11AQrdsfqXPXKgPFJNR23{height:64px}._11AQrdsfqXPXKgPFJNR23 ._2XCA51Kh3g4gir4_guNSa6{position:fixed;top:0;z-index:1000;width:100%;height:64px;font-size:20px;font-size:20px;line-height:64px;background-color:#425c5b;color:#fff;padding:0 24px;box-sizing:border-box}._11AQrdsfqXPXKgPFJNR23 ._2XCA51Kh3g4gir4_guNSa6 ._2PwQPmsezuc9h7gnHOci-L{position:absolute;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;width:100px;height:22px;top:24px;left:32px}._11AQrdsfqXPXKgPFJNR23 ._2XCA51Kh3g4gir4_guNSa6 a{color:#fff;text-decoration:none}._11AQrdsfqXPXKgPFJNR23 ._2XCA51Kh3g4gir4_guNSa6 ._3LjGvnRGJdGtSKEMbBVdrB{font-size:16px;position:absolute;right:32px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}", ""]);
// Exports
exports.locals = {
	"container": "_11AQrdsfqXPXKgPFJNR23",
	"header": "_2XCA51Kh3g4gir4_guNSa6",
	"logo": "_2PwQPmsezuc9h7gnHOci-L",
	"displayName": "_3LjGvnRGJdGtSKEMbBVdrB"
};
module.exports = exports;
