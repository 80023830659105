// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'

import Frame from './Frame'
import Editor from './Editor/Editor'
import Header from './Header'
import Simulator from './Simulator'
import { PERMIT_UPDATE } from '../../constants/permission'
import * as systemConfig from '../../constants/systemConfig'
import { appOperations, appSelectors, appTypes } from '../../modules/app'
import { projectOperations } from '../../modules/project'
import { scriptOperations } from '../../modules/script'
import { clipBoardCopy } from '../../common/Util'

import ScriptSettingOverlay from './SettingOverlay'

export function ScriptPage({ projectId, scriptId }) {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_SCRIPT)
  )
  const script = useSelector(state => state.scriptState.script)
  const [settingVisibility, setSettingVisibility] = useState(false)
  const simulatorVisibility = useSelector(
    state => state.appState.simulatorVisibility
  )

  useEffect(() => {
    dispatch(projectOperations.fetchProject(projectId))
    dispatch(scriptOperations.fetchScript(scriptId))
    return () => {
      dispatch(projectOperations.clearProject())
      dispatch(scriptOperations.clearScript())
    }
  }, [])
  const backAddr = '/project/' + projectId

  const onClickSave = e => {
    dispatch(scriptOperations.saveScript(script))
    enqueueSnackbar('変更を保存しました', { variant: 'default' })
  }

  const onClickPlay = e => {
    if (simulatorVisibility === false) {
      dispatch(appOperations.setSimulatorVisibility(true))
    } else {
      dispatch(appOperations.setSimulatorVisibility(false))
    }
  }

  const onClickSetting = e => {
    setSettingVisibility(true)
  }

  const onClickLink = () => {
    const host = 'http://' + window.location.host
    const link =
      host + '/m/' + systemConfig.ADMIN_USER_HASH + '/' + scriptId + '/'
    clipBoardCopy(link)
    enqueueSnackbar(
      'テスト用アドレスをコピーしました。このリンクの入力結果は保存されませんのでご注意ください。',
      { variant: 'default' }
    )
  }

  return (
    <div>
      {simulatorVisibility && <Simulator scriptId={scriptId} />}
      <Header
        backAddr={backAddr}
        onClickLink={e => onClickLink(e)}
        onClickPlay={e => onClickPlay(e)}
        onClickSave={e => onClickSave(e)}
        onClickSetting={e => onClickSetting(e)}
        permitUpdate={isPermit(PERMIT_UPDATE)}
      />
      <Frame simulatorVisibility={simulatorVisibility}>
        <Editor script={script} />
      </Frame>
      {settingVisibility === true && (
        <ScriptSettingOverlay
          onCloseOverlay={e => setSettingVisibility(false)}
        />
      )}
    </div>
  )
}

export default ScriptPage
