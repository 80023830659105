// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userOperations } from '../../modules/user'
import { projectOperations } from '../../modules/project'

import * as projectTypes from '../../modules/project/types'

import classes from './AttrForms.scss'
import TextInput from '../Ui/TextInput'
import MuiButton from '../Ui/MuiButton'
import SectionRow from '../Ui/SectionRow'

import AttrOverlay from '../Project/AttrOverlay'

export function AttrForms({ user, extraUserAttr }) {
  const { extraUserInfo } = user
  const dispatch = useDispatch()
  const [attrSettingVisibility, setAttrSettingVisibility] = useState(false)

  const onChangeValue = (key, value) => {
    dispatch(userOperations.updateUser({ [key]: value }))
  }

  const onChangeUserInfo = (id, value) => {
    onChangeValue('extraUserInfo', { ...extraUserInfo, [id]: value })
  }

  const DeleteButton = ({ onClick = () => {} }) => (
    <div className={classes.deleteButton} onClick={onClick} />
  )

  const AddInfoButton = ({ onClick = () => {} }) => (
    <MuiButton
      text='＋　項目を追加する'
      style={{ height: 20, color: '#919D99' }}
      onClick={onClick}
      fullWidth
    />
  )

  const renderTextAttr = attr => (
    <div className={classes.attrInfoRow}>
      <TextInput
        value={extraUserInfo[attr.id] == null ? [''] : extraUserInfo[attr.id]}
        onChange={e => onChangeUserInfo(attr.id, [e.target.value])}
      />
      <DeleteButton onClick={e => onChangeUserInfo(attr.id, [''])} />
    </div>
  )

  const changeListAttr = (list, id, idx, value) => {
    list[idx] = value
    onChangeUserInfo(id, list)
  }

  const deleteListAttr = (list, id, idx) => {
    list.splice(idx, 1)
    onChangeUserInfo(id, list)
  }

  const addListAttr = (list, id, value) => {
    list.push(value)
    onChangeUserInfo(id, list)
  }

  const renderListAttr = attr => {
    const list = extraUserInfo[attr.id] == null ? [] : extraUserInfo[attr.id]
    return (
      <div className={classes.attrInfoList}>
        {list.map((item, i) => (
          <div key={'listAttr' + i} className={classes.attrInfoRow}>
            <TextInput
              value={item}
              onChange={e => changeListAttr(list, attr.id, i, e.target.value)}
            />
            <DeleteButton onClick={() => deleteListAttr(list, attr.id, i)} />
          </div>
        ))}
        <AddInfoButton onClick={() => addListAttr(list, attr.id, '')} />
      </div>
    )
  }

  const renderMaskingTextAttr = attr => {
    const list = extraUserInfo[attr.id] == null ? [] : extraUserInfo[attr.id]
    return (
      <div className={classes.attrInfoList}>
        {list.map((item, i) => (
          <div key={'listAttr' + i} className={classes.attrInfoRow}>
            <div className={classes.attrInfoDivided}>
              <div>{`${i + 1}日目`}</div>
              <TextInput
                value={item}
                onChange={e => changeListAttr(list, attr.id, i, e.target.value)}
              />
            </div>
            <DeleteButton onClick={() => deleteListAttr(list, attr.id, i)} />
          </div>
        ))}
        <AddInfoButton onClick={() => addListAttr(list, attr.id, '')} />
      </div>
    )
  }

  const renderMaskingListAttr = attr => {
    const id = attr.id
    const list = extraUserInfo[id] == null ? [] : extraUserInfo[id]
    return (
      <div className={classes.attrInfoList}>
        {list.map((item, i) => {
          if (item.split(':').length != 2) return
          const [mask, value] = item.split(':')
          return (
            <div key={'listAttr' + i} className={classes.attrInfoRow}>
              <div className={classes.attrInfoDivided}>
                <TextInput
                  value={mask}
                  onChange={e => {
                    changeListAttr(list, id, i, `${e.target.value}:${value}`)
                  }}
                />
                <TextInput
                  value={value}
                  onChange={e => {
                    changeListAttr(list, id, i, `${mask}:${e.target.value}`)
                  }}
                />
              </div>
              <DeleteButton onClick={() => deleteListAttr(list, id, i)} />
            </div>
          )
        })}
        <AddInfoButton onClick={() => addListAttr(list, id, ':')} />
      </div>
    )
  }

  const renderAttr = attr => (
    <div className={classes.attrRow}>
      <div className={classes.attrLabel}>{attr.title}</div>
      <div className={classes.attrInfo}>
        {attr.type === projectTypes.USER_ATTR_TYPE_TEXT ? (
          renderTextAttr(attr)
        ) : attr.type === projectTypes.USER_ATTR_TYPE_LIST ? (
          renderListAttr(attr)
        ) : attr.type === projectTypes.USER_ATTR_TYPE_MASKING_TEXT ? (
          renderMaskingTextAttr(attr)
        ) : attr.type === projectTypes.USER_ATTR_TYPE_MASKING_LIST ? (
          renderMaskingListAttr(attr)
        ) : (
          <></>
        )}
      </div>
    </div>
  )

  return (
    <div className={classes.container}>
      <SectionRow label='被験者属性'>
        <div className={classes.attrEditButton}>
          <MuiButton
            text='被験者属性を編集'
            style={{ height: 20 }}
            onClick={e => setAttrSettingVisibility(true)}
          />
        </div>
        {extraUserAttr.map(attr => (
          <div key={attr.id}>{renderAttr(attr)}</div>
        ))}
      </SectionRow>
      {attrSettingVisibility === true && (
        <AttrOverlay onClickCancel={e => setAttrSettingVisibility(false)} />
      )}
    </div>
  )
}
export default AttrForms
