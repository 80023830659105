// Takram Confidential
// Copyright (C) 2019-Present Takram

// Run following after build an index
// $ firebase firestore:indexes > firestore.indexes.json

import {
  getLoginLevelFromType,
  isPermitLevel,
  LOGIN_SYSTEM_ADMIN,
  PERMIT_CREATE,
  PERMIT_DELETE,
  PERMIT_READ,
  PERMIT_UPDATE
} from '../../constants/permission'
import firebase from '../../Firebase'
import { appTypes } from '../app'
import * as types from './types'

let unsubProjectList = null

const fetchProject = id => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    const ref = firebase
      .firestore()
      .collection('fave-projects')
      .doc(id)
    ref
      .get()
      .then(doc => {
        if (!doc.exists) return
        dispatch({
          type: types.FETCH_PROJECT,
          project: deriveProjectFromDoc(doc)
        })
      })
      .catch(err => {
        console.log('Error getting document', err)
      })
  }
}

const subscribeProjectList = () => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    let db = firebase.firestore().collection('fave-projects')
    db = db.orderBy('createdAt', 'desc')
    // Filter accessible projects
    const { adminLevel, uid } = getState().appState.auth
    if (adminLevel !== getLoginLevelFromType(LOGIN_SYSTEM_ADMIN)) {
      db = db.where('accessibleAdminUids', 'array-contains', uid)
    }

    unsubProjectList = db.onSnapshot(snapshot => {
      dispatch({
        type: types.FETCH_PROJECT_LIST,
        projectList: snapshot.docs.map(doc => deriveProjectFromDoc(doc))
      })
    })
  }
}

const unsubscribeProjectList = () => {
  return async (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    if (unsubProjectList !== null) unsubProjectList()
    dispatch({ type: types.DETOUCH_PROJECT_LIST })
  }
}

const addProject = project => {
  return async (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_CREATE)) return
    const db = firebase.firestore().collection('fave-projects')
    project.updatedAt = project.createdAt = new Date()
    project.ownerAdminUid = getState().appState.auth.uid
    // Set owner uid for filtering accessible projects
    project.accessibleAdminUids = [project.ownerAdminUid]
    await db
      .add(project)
      .then(docRef => {
        project.id = docRef.id
      })
      .catch(error => console.error('Error adding document: ', error))
    dispatch({ type: types.ADD_PROJECT, project })
  }
}

const duplicateProject = projectId => {
  return async (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_CREATE)) return
    const db = firebase.firestore().collection('fave-projects')
    const doc = await db.doc(projectId).get()
    if (doc.exists) {
      const project = deriveProjectFromDoc(doc)
      project.id = ''
      project.title = 'Copy of ' + project.title
      await db
        .add(project)
        .then(docRef => {
          project.id = docRef.id
        })
        .catch(error => console.error('Error adding document: ', error))
      dispatch({ type: types.DUPLICATE_PROJECT, project })
    }
  }
}

const setVisibilityFilter = filter => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    dispatch({
      type: types.SET_VISIBILITY_FILTER,
      filter
    })
  }
}

const updateProject = params => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    dispatch({
      type: types.UPDATE_PROJECT,
      params
    })
  }
}

const clearProject = () => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    dispatch({
      type: types.CLEAR_PROJECT
    })
  }
}

const saveProject = project => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    project.updatedAt = new Date()

    // Set uid array for filtering accessible projects
    project.accessibleAdminUids = [
      project.ownerAdminUid,
      ...project.sharedAdminEntries
        .map(e => e.uid)
        .filter(uid => uid != null && uid !== '')
    ]

    const ref = firebase
      .firestore()
      .collection('fave-projects')
      .doc(project.id)
    ref.get().then(doc => {
      ref.update(project)
    })
    dispatch({
      type: types.SAVE_PROJECT,
      project: project
    })
  }
}

const deleteProject = id => {
  return async (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_DELETE)) return
    const db = firebase.firestore()
    await db
      .collection('fave-projects')
      .doc(id)
      .delete()
    const sciptQuery = db
      .collection('fave-scripts')
      .where('projectId', '==', id)
    await sciptQuery.get().then(querySnapshot => {
      querySnapshot.forEach(doc => doc.ref.delete())
    })
    const userQuery = db.collection('fave-users').where('projectId', '==', id)
    await userQuery.get().then(querySnapshot => {
      querySnapshot.forEach(doc => doc.ref.delete())
    })
    dispatch({
      type: types.DELETE_PROJECT
    })
  }
}

const addExtraUserAttr = () => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    dispatch({
      type: types.ADD_EXTRA_USER_ATTR
    })
  }
}

const updateExtraUserAttr = attr => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    dispatch({
      type: types.UPDATE_EXTRA_USER_ATTR,
      attr
    })
  }
}

const deleteExtraUserAttr = attrId => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    dispatch({
      type: types.DELETE_EXTRA_USER_ATTR,
      attrId
    })
  }
}

const addSharedAdminEntry = () => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    dispatch({
      type: types.ADD_SHARED_ADMIN_ENTRY
    })
  }
}

const updateSharedAdminEntry = entry => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    dispatch({
      type: types.UPDATE_SHARED_ADMIN_ENTRY,
      entry
    })
  }
}

const deleteSharedAdminEntry = entryId => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    dispatch({
      type: types.DELETE_SHARED_ADMIN_ENTRY,
      entryId
    })
  }
}

export default {
  addProject,
  clearProject,
  deleteProject,
  duplicateProject,
  fetchProject,
  saveProject,
  setVisibilityFilter,
  subscribeProjectList,
  unsubscribeProjectList,
  updateProject,
  addExtraUserAttr,
  updateExtraUserAttr,
  deleteExtraUserAttr,
  addSharedAdminEntry,
  updateSharedAdminEntry,
  deleteSharedAdminEntry
}

// =============================
// ===== Private Functions =====
// =============================

const deriveProjectFromDoc = doc => {
  const data = doc.data()
  const def = types.DEFAULT_PROJECT_STATE
  return {
    id: doc.id,
    title: data.title == null ? def.title : data.title,
    description: data.description == null ? def.description : data.description,
    archived: data.archived == null ? def.archived : data.archived,
    favorite: data.favorite == null ? def.favorite : data.favorite,
    createdAt:
      data.createdAt == null || data.createdAt.seconds == null
        ? def.createdAt
        : new Date(data.createdAt.seconds * 1000),
    updatedAt:
      data.updatedAt == null || data.updatedAt.seconds == null
        ? def.updatedAt
        : new Date(data.updatedAt.seconds * 1000),
    extraUserAttr:
      data.extraUserAttr == null ? def.extraUserAttr : data.extraUserAttr,
    ownerAdminUid:
      data.ownerAdminUid == null ? def.ownerAdminUid : data.ownerAdminUid,
    accessibleAdminUids:
      data.accessibleAdminUids == null
        ? def.accessibleAdminUids
        : data.accessibleAdminUids,
    sharedAdminEntries:
      data.sharedAdminEntries == null
        ? def.sharedAdminEntries
        : data.sharedAdminEntries
  }
}

const isPermit = (state, permit) => {
  const adminLevel = state.appState.auth.adminLevel
  return isPermitLevel(adminLevel, appTypes.MODULE_PROJECT, permit)
}
