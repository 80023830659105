// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2T_OBaH935cI8RHvm4M_Li{height:100%;left:0;position:absolute;top:0;width:100%;z-index:1}._2T_OBaH935cI8RHvm4M_Li ._14UVP17501VVxhSd8u4F7C{background-color:#425c5b;height:100%;left:0;opacity:0.9;position:absolute;top:0;width:100%}._2T_OBaH935cI8RHvm4M_Li ._27ex77-vCfOVK6vHLACTSo{background-color:white;border-radius:24px;box-sizing:border-box;height:190px;left:28px;padding-top:49px;position:absolute;right:28px;text-align:center;top:calc(50% - 68px);font-size:16px}._2T_OBaH935cI8RHvm4M_Li ._27ex77-vCfOVK6vHLACTSo ._2yvsWwRNrgBUlL5sod9WnR{background:#425c5b;border-radius:100px;bottom:24px;color:#fff;height:48px;line-height:48px;position:absolute}._2T_OBaH935cI8RHvm4M_Li ._27ex77-vCfOVK6vHLACTSo ._2yvsWwRNrgBUlL5sod9WnR._1SYLyU4qxvsQdTQHUE4dr2{left:27px;right:calc(50% + 8px)}._2T_OBaH935cI8RHvm4M_Li ._27ex77-vCfOVK6vHLACTSo ._2yvsWwRNrgBUlL5sod9WnR._3XkwdoBOSUlIYN5ucbqYGS{left:calc(50% + 8px);right:27px}._2T_OBaH935cI8RHvm4M_Li._3Y3fkdIiV_kuMP24N6etqb{display:none}", ""]);
// Exports
exports.locals = {
	"container": "_2T_OBaH935cI8RHvm4M_Li",
	"back": "_14UVP17501VVxhSd8u4F7C",
	"item": "_27ex77-vCfOVK6vHLACTSo",
	"button": "_2yvsWwRNrgBUlL5sod9WnR",
	"buttonLeft": "_1SYLyU4qxvsQdTQHUE4dr2",
	"buttonRight": "_3XkwdoBOSUlIYN5ucbqYGS",
	"hidden": "_3Y3fkdIiV_kuMP24N6etqb"
};
module.exports = exports;
