// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ScriptSelector from '../components/Form/Popup/ScriptSelector'
import { formOperations } from '../modules/form'
import * as formTypes from '../modules/form/types'
import { scriptSelectors } from '../modules/script'
import * as scriptTypes from '../modules/script/types'
import { userSelectors } from '../modules/user'

class FormScriptSelector extends Component {
  onClickSelectButton() {
    const {
      formOperations,
      isDebugUserMode,
      popupVisibility,
      scriptId
    } = this.props
    if (isDebugUserMode) {
      formOperations.startScript({ scriptId })
    } else {
      formOperations.setScriptSelectorPopupVisibility(
        !popupVisibility.scriptSelector
      )
    }
  }

  onSelectScript(scriptId) {
    // TODO: Add date check if fetching firebase is not real time.
    const { formOperations } = this.props
    formOperations.setScriptSelectorPopupVisibility(false)
    formOperations.startScript({ scriptId })
  }

  render() {
    const { popupVisibility, isOutOfPeriod, scriptList } = this.props
    return (
      <ScriptSelector
        isOutOfPeriod={isOutOfPeriod}
        onClickSelectButton={() => this.onClickSelectButton()}
        onSelectScript={scriptId => this.onSelectScript(scriptId)}
        scriptList={scriptList}
        visibility={popupVisibility.scriptSelector}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isDebugUserMode: state.appState.isDebugUserMode,
    isOutOfPeriod: userSelectors.isOutOfPeriodSelector(state),
    popupVisibility: state.formState.popupVisibility,
    scriptId: state.scriptState.script.id,
    scriptList: scriptSelectors.freeScriptListSelector(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    formOperations: bindActionCreators(formOperations, dispatch)
  }
}

FormScriptSelector.propTypes = {
  formOperations: PropTypes.objectOf(PropTypes.func).isRequired,
  isDebugUserMode: PropTypes.bool.isRequired,
  isOutOfPeriod: PropTypes.bool.isRequired,
  popupVisibility: PropTypes.shape(formTypes.POPUP_VISIBILITY_TYPES).isRequired,
  scriptId: PropTypes.string.isRequired,
  scriptList: PropTypes.arrayOf(
    PropTypes.shape(scriptTypes.SCRIPT_TYPES).isRequired
  ).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(FormScriptSelector)
