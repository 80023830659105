// Takram Confidential
// Copyright (C) 2019-Present Takram

import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { appOperations, appSelectors, appTypes } from '../../modules/app'
import { scriptOperations } from '../../modules/script'
import OverlayFrame from '../App/OverlayFrame'

import presets from '../../../assets/scripts/script_presets.json'
import classes from './AddPresetOverlay.scss'

export function AddPresetOverlay({ projectId, onCloseOverlay }) {
  const dispatch = useDispatch()
  const script = useSelector(state => state.scriptState.script)
  const [selectIndex, setSelectIndex] = useState(0)
  useEffect(() => {
    dispatch(scriptOperations.clearScript())
    dispatch(scriptOperations.updateScript({ projectId }))
    return () => dispatch(scriptOperations.clearScript())
  }, [])

  const onClickCreate = e => {
    // dispatch(scriptOperations.addScript(script))
    const name = presets.data[selectIndex].path
    console.log('select : ', name)
    dispatch(scriptOperations.addPresetScript(projectId, name))
    onCloseOverlay(e)
  }

  return (
    <div className={classes.container}>
      <OverlayFrame
        title='プリセットからスクリプトを作成する'
        onClickClose={e => onCloseOverlay(e)}
        buttons={[
          {
            label: 'スクリプトの作成',
            onClick: () => onClickCreate(),
            accent: true
          }
        ]}
      >
        種類を選択
        <div className={classes.presets}>
          {presets.data.map((d, i) => (
            <div
              className={classes.preset}
              key={d.title}
              onClick={e => setSelectIndex(i)}
            >
              <div
                className={classNames(classes.radio, {
                  [classes.checked]: i === selectIndex
                })}
              />
              <div>
                <div className={classes.title}>{d.title}</div>
                <div className={classes.description}>{d.description}</div>
              </div>
            </div>
          ))}
        </div>
      </OverlayFrame>
    </div>
  )
}

export default AddPresetOverlay
