// Takram Confidential
// Copyright (C) 2019-Present Takram

import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './InputText.scss'

const InputText = ({ id, inputData, setInputData, formSize }) => (
  <div className={classes.content}>
    {formSize === 'small' ? (
      <input
        className={classes.inputArea}
        type='text'
        onChange={e => setInputData({ [id]: e.target.value })}
        placeholder='テキストを入力してください'
        value={inputData}
      />
    ) : (
      <textarea
        className={classNames(classes.inputArea, {
          [classes.medium]: formSize === 'medium',
          [classes.large]: formSize === 'large'
        })}
        onChange={e => setInputData({ [id]: e.target.value })}
        placeholder='テキストを入力してください'
        value={inputData}
      />
    )}
  </div>
)

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  inputData: PropTypes.string,
  setInputData: PropTypes.func.isRequired,
  formSize: PropTypes.string
}

InputText.defaultProps = {
  inputData: '',
  formSize: 'medium'
}

export default InputText
