// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminUserOperations } from '../../modules/admin_user'
import { useSnackbar } from 'notistack'

import commonCls from '../App/Common.scss'
import classes from './Setting.scss'

import AttrForms from './AttrForms'
import Forms from './Forms'
import ShareForms from './ShareForms'
import { PERMIT_UPDATE } from '../../constants/permission'
import { appSelectors, appTypes } from '../../modules/app'
import { projectOperations } from '../../modules/project'
import MuiButton from '../Ui/MuiButton'

export function Setting() {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const project = useSelector(state => state.projectState.project)
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_PROJECT)
  )

  useEffect(() => {
    dispatch(adminUserOperations.subscribeAdminUserList())
    return () => {
      dispatch(adminUserOperations.unsubscribeAdminUserList())
    }
  }, [])

  const saveSettings = () => {
    dispatch(projectOperations.saveProject(project))
    enqueueSnackbar('設定を保存しました', { variant: 'default' })
  }

  return (
    <div className={classes.container}>
      <Forms project={project} permitUpdate={isPermit(PERMIT_UPDATE)} />
      <AttrForms project={project} permitUpdate={isPermit(PERMIT_UPDATE)} />
      <ShareForms permitUpdate={isPermit(PERMIT_UPDATE)} project={project} />
      {isPermit(PERMIT_UPDATE) && (
        <div className={classes.saveButton}>
          <MuiButton
            color='primary'
            text='保存する'
            onClick={saveSettings}
            fullWidth
          />
        </div>
      )}
    </div>
  )
}

export default Setting
