// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'

export const CLEAR_CURRENT_RECORD = 'record/CLEAR_CURRENT_RECORD'
export const CLEAR_LOG_RECORD = 'record/CLEAR_LOG_RECORD'
export const CLEAR_LOG_SCRIPT = 'record/CLEAR_LOG_SCRIPT'
export const DETACH_RECORD_LIST = 'record/DETACH_RECORD_LIST'
export const FETCH_RECORD_LIST = 'record/FETCH_RECORD_LIST'
export const SAVE_CURRENT_RECORD = 'record/SAVE_CURRENT_RECORD'
export const SET_LOG_RECORD = 'record/SET_LOG_RECORD'
export const SET_LOG_SCRIPT = 'record/SET_LOG_SCRIPT'
export const UPDATE_CREATED_AT = 'record/UPDATE_CREATED_AT'
export const UPDATE_CURRENT_RECORD = 'record/UPDATE_CURRENT_RECORD'

export const SET_FETCH_STATUS = 'record/SET_FETCH_STATUS'
export const FETCH_STATUS_NONE = 'record/FETCH_STATUS_NONE'
export const FETCH_STATUS_IN_PROGRESS = 'record/FETCH_STATUS_IN_PROGRESS'
export const FETCH_STATUS_COMPLETE = 'record/FETCH_STATUS_COMPLETE'
export const FETCH_STATUS_FAILED = 'record/FETCH_STATUS_FAILED'

export const DEFAULT_RECORD_STATE = {
  createdAt: new Date(),
  data: {},
  dataTypes: {},
  id: '',
  projectId: '',
  requiredId: '',
  scriptId: '',
  userId: '',
  // Secret
  coordinates: {}
}

export const RECORD_TYPES = {
  coordinates: PropTypes.object.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  data: PropTypes.object.isRequired,
  dataTypes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  requiredId: PropTypes.string.isRequired,
  scriptId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired
}

export default {}
