// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Y1OMFgHTFmqXAB1ibAOPY{width:calc(100% - 160px);margin:0 auto}._3Y1OMFgHTFmqXAB1ibAOPY ._16Y4OjafwjtWc9OVceXrBd{position:relative;width:300px;height:48px;margin-bottom:8px;background-color:#ECF1F0}._3Y1OMFgHTFmqXAB1ibAOPY ._16Y4OjafwjtWc9OVceXrBd ._3wwZHuVuZe7pSg1R7yEWTZ{position:absolute;left:16px;line-height:48px}._3Y1OMFgHTFmqXAB1ibAOPY ._16Y4OjafwjtWc9OVceXrBd .zcpOuQA-W0GfXVq5U-UhE{position:absolute;right:16px;line-height:48px}._3Y1OMFgHTFmqXAB1ibAOPY ._1RkCNN1kbKXKogUhyobGH4{display:grid}._3Y1OMFgHTFmqXAB1ibAOPY ._1RkCNN1kbKXKogUhyobGH4 ._2of1r7i87HlJk2QwlO75IL{display:grid;grid-template-columns:300px 48px;grid-column-gap:8px;margin-bottom:8px}", ""]);
// Exports
exports.locals = {
	"container": "_3Y1OMFgHTFmqXAB1ibAOPY",
	"ownerEntry": "_16Y4OjafwjtWc9OVceXrBd",
	"ownerName": "_3wwZHuVuZe7pSg1R7yEWTZ",
	"ownerRole": "zcpOuQA-W0GfXVq5U-UhE",
	"sharedEntries": "_1RkCNN1kbKXKogUhyobGH4",
	"entry": "_2of1r7i87HlJk2QwlO75IL"
};
module.exports = exports;
