// Takram Confidential
// Copyright (C) 2019-Present Takram

import NameSpace from '../../../common/NameSpace'
import parserBase from './parserBase'

export const internal = NameSpace('Parser')

export default class ParserV2 extends parserBase {
  constructor(scriptState) {
    super(scriptState)
  }
}
