// Takram Confidential
// Copyright (C) 2019-Present Takram

import * as scriptCondition from '../../constants/scriptCondition'
import * as scriptConfig from '../../constants/scriptConfig'
import * as projectTypes from '../../modules/project/types'
import Moment from 'moment'
import { createSelector } from 'reselect'

const currentEntryDataSelector = state => state.scriptState.currentEntryData
const currentLoginUserSelector = state => state.userState.currentLoginUser
const extraUserAttrSelector = state => state.projectState.project.extraUserAttr
const scriptDataSelector = state => state.scriptState.script.data
const scriptListSelector = state => state.scriptState.scriptList

// TODO: After following, change deriveItemList to itemListSelector.
// state.scriptState.scriptList should not be deleted when 1 script selected.
const deriveItemList = data => {
  const list = []
  data.forEach((entry, index) => {
    if (entry.type === scriptConfig.TYPE_IF || !Array.isArray(entry.value)) {
      return false
    }
    entry.value.forEach((item, itemIdx) => {
      const chars = 'abcdefghijklmnopqrstuvwxyz'
      list.push({
        id: item.id,
        title:
          index +
          1 +
          '-' +
          chars.charAt(itemIdx) +
          ' : ' +
          entry.title +
          ' ' +
          item.title
      })
    })
  })
  return list
}

const entryListSelector = createSelector(scriptDataSelector, scriptData => {
  const list = []
  scriptData.forEach((entry, index) => {
    list.push({
      id: entry.id,
      title:
        entry.type === scriptConfig.TYPE_IF
          ? index + 1 + ' : IF'
          : index + 1 + ' : ' + entry.title
    })
  })
  return list
})

const freeScriptListSelector = createSelector(
  [currentLoginUserSelector, scriptListSelector],
  (currentLoginUser, scriptList) =>
    scriptList.filter(script => {
      const inputTermCondition = {
        ...scriptCondition.INITIAL_CONDITION.inputTerm,
        ...script.condition.inputTerm
      }
      const elapsedDays = Moment().diff(currentLoginUser.startAt, 'days')
      const insideInputTerm =
        elapsedDays >= inputTermCondition.from &&
        elapsedDays <= inputTermCondition.to
      // TODO: ビーコンスクリプトの表示条件検討
      return (
        script.condition.type !== scriptCondition.SCRIPT_TYPE_REQUIRED &&
        (inputTermCondition.type === scriptCondition.INPUT_TERM_TYPE_EVERYDAY ||
          (inputTermCondition.type ===
            scriptCondition.INPUT_TERM_TYPE_ELAPSED_DAYS &&
            insideInputTerm))
      )
    })
)

const isDoneSelector = createSelector(
  currentEntryDataSelector,
  currentEntryData => {
    if (currentEntryData === null) return true // TODO:
    if (currentEntryData.type === scriptConfig.TYPE_DONE) return true
    return false
  }
)

const itemTypeMapSelector = createSelector(scriptDataSelector, scriptData => {
  const map = {}
  scriptData.forEach((entry, index) => {
    if (entry.type === scriptConfig.TYPE_IF || !Array.isArray(entry.value)) {
      return false
    }
    entry.value.forEach((item, itemIdx) => {
      map[item.id] = item.type
    })
  })
  return map
})

const itemValueMapSelector = createSelector(scriptDataSelector, scriptData => {
  const map = {}
  scriptData.forEach((entry, index) => {
    if (entry.type === scriptConfig.TYPE_IF || !Array.isArray(entry.value)) {
      return false
    }
    entry.value.forEach((item, itemIdx) => {
      map[item.id] = []
      if (!Array.isArray(item.value)) return false
      item.value.forEach(v => {
        map[item.id].push(v)
      })
    })
  })
  return map
})

const textAttrSelector = createSelector(
  extraUserAttrSelector,
  extraUserAttr => {
    return extraUserAttr.filter(
      attr =>
        attr.type === projectTypes.USER_ATTR_TYPE_TEXT ||
        attr.type === projectTypes.USER_ATTR_TYPE_MASKING_TEXT
    )
  }
)

// 'その他:'という文字列を接頭辞に持つ選択肢を追加すると、その他として判定される。
const recordTextIsOthers = text =>
  text.includes(scriptConfig.OTHERS_RECORD_PREFIX, 0) &&
  !text.includes(scriptConfig.OTHERS_RECORD_PREFIX, 1)

const recordTextIsEmptyOthers = text =>
  text === scriptConfig.OTHERS_RECORD_PREFIX

const generateOthersRecordText = text =>
  scriptConfig.OTHERS_RECORD_PREFIX + text

const attrApplySelector = createSelector(
  extraUserAttrSelector,
  extraUserAttr => {
    return text => {
      return text + 'aaa'
    }
  }
)

export default {
  deriveItemList,
  entryListSelector,
  freeScriptListSelector,
  generateOthersRecordText,
  isDoneSelector,
  itemTypeMapSelector,
  itemValueMapSelector,
  recordTextIsEmptyOthers,
  recordTextIsOthers,
  textAttrSelector
}
