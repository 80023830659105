import React from 'react'

const inputStyle = {
  margin: 0,
  display: 'inline-block',
  padding: '0 8px',
  background: 'none',
  border: 'solid 1px #E8ECEE',
  borderRadius: 8,
  outline: 'none',
  appearance: 'none',
  height: 48,
  width: 300,
  fontSize: 16
}

export function NumberInput({ value, onChange, disabled, style, min, max }) {
  return (
    <input
      style={{ ...inputStyle, ...style }}
      disabled={disabled}
      onChange={onChange}
      min={min}
      max={max}
      type='number'
      value={value == null ? 0 : value}
    />
  )
}

NumberInput.defaultProps = {
  value: 0,
  disabled: false,
  onChange: () => {},
  style: {},
  min: null,
  max: null
}

export default NumberInput
