// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ScriptAddOverlay from '../Script/AddOverlay'
import ScriptAddPresetOverlay from '../Script/AddPresetOverlay'
import ScriptListCatalog from '../Script/ListCatalog'
import { PERMIT_CREATE } from '../../constants/permission'
import { appSelectors, appTypes } from '../../modules/app'
import { scriptOperations } from '../../modules/script'
import MuiContextMenu from '../Ui/MuiContextMenu'

export function Script({ projectId }) {
  const dispatch = useDispatch()
  const menuRef = useRef()
  const [addScriptVisibility, setAddScriptVisibility] = useState(false)
  const [addPresetVisibility, setAddPresetVisibility] = useState(false)
  const isScriptPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_SCRIPT)
  )

  useEffect(() => {
    dispatch(scriptOperations.subscribeScriptList(projectId))
    return () => {
      dispatch(scriptOperations.unsubscribeScriptList())
    }
  }, [])

  const onClickAdd = e => {
    if (!isScriptPermit(PERMIT_CREATE)) return
    menuRef.current.handleOpen(e)
  }

  const menuItems = [
    { name: '新規作成', onClick: () => setAddScriptVisibility(true) },
    { name: 'プリセットから作成', onClick: () => setAddPresetVisibility(true) },
    {
      name: 'インポート',
      onClick: () => dispatch(scriptOperations.importScript(projectId))
    }
  ]

  return (
    <div>
      <MuiContextMenu ref={menuRef} items={menuItems} />
      <ScriptListCatalog
        projectId={projectId}
        onClickAdd={e => onClickAdd(e)}
        permitCreate={isScriptPermit(PERMIT_CREATE)}
      />
      {addScriptVisibility && (
        <ScriptAddOverlay
          projectId={projectId}
          onCloseOverlay={e => setAddScriptVisibility(false)}
        />
      )}
      {addPresetVisibility && (
        <ScriptAddPresetOverlay
          projectId={projectId}
          onCloseOverlay={e => setAddPresetVisibility(false)}
        />
      )}
    </div>
  )
}

Script.propTypes = {
  projectId: PropTypes.string.isRequired
}

export default Script
