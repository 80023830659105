// Takram Confidential
// Copyright (C) 2019-Present Takram

import * as sctiptCondition from '../../constants/scriptCondition'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { history } from '../../configureStore'
import { PERMIT_CREATE, PERMIT_DELETE } from '../../constants/permission'
import { appSelectors, appTypes } from '../../modules/app'
import { scriptOperations } from '../../modules/script'
import MuiContextMenu from '../Ui/MuiContextMenu'
import MuiDialog from '../Ui/MuiDialog'

import classes from './List.scss'

export function List({ projectId }) {
  const dispatch = useDispatch()
  const menuRef = useRef()
  const dialogRef = useRef()
  const [menuItems, setMenuItems] = useState([])
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_SCRIPT)
  )
  const scriptList = useSelector(state => state.scriptState.scriptList)

  const onClickScript = e => {
    history.push('/script/' + projectId + '/' + e)
  }

  const onDeleteScript = scriptId => {
    dispatch(scriptOperations.deleteScript(scriptId))
  }

  const onOpenDeleteDialog = scriptId => {
    dialogRef.current.handleOpen(scriptId)
  }

  const onClickExport = scriptId => {
    dispatch(scriptOperations.exportScript(scriptId))
  }

  const onClickDuplicate = scriptId => {
    dispatch(scriptOperations.duplicateScript(scriptId))
  }

  const onClickMenu = (e, scriptId) => {
    const items = []
    if (isPermit(PERMIT_DELETE)) {
      items.push({ name: '削除', onClick: arg => onOpenDeleteDialog(arg) })
    }
    if (isPermit(PERMIT_CREATE)) {
      items.push({ name: '複製', onClick: arg => onClickDuplicate(arg) })
    }
    if (isPermit(PERMIT_CREATE)) {
      items.push({ name: 'エクスポート', onClick: arg => onClickExport(arg) })
    }
    setMenuItems(items)
    menuRef.current.handleOpen(e, scriptId)
  }

  const renderCondition = (item, i) => (
    <div className={classes.pushCondition} key={'conditionitem' + i}>
      {item.notify === true && (
        <div className={classes.pushTime}>
          {item.type === sctiptCondition.NOTIFY_TYPE_EVERYDAY && '毎日 '}
          {item.type === sctiptCondition.NOTIFY_TYPE_START_DATE && '開始日 '}
          {item.type === sctiptCondition.NOTIFY_TYPE_MIDDLE_DATE && '途中日 '}
          {item.type === sctiptCondition.NOTIFY_TYPE_END_DATE && '最終日 '}
          {item.type === sctiptCondition.NOTIFY_TYPE_ELAPSED_DAYS &&
            `${item.elapsedDays + 1}日目 `}
          {item.hours}:{('00' + item.minutes).slice(-2)}
        </div>
      )}
    </div>
  )

  return (
    <div>
      <MuiContextMenu ref={menuRef} items={menuItems} />
      <MuiDialog
        title='スクリプト削除'
        text='スクリプトを削除してもよろしいですか？'
        ref={dialogRef}
        buttons={[
          { name: '削除する', value: id => onDeleteScript(id) },
          { name: '削除しない', value: () => {} }
        ]}
      />
      <div className={classes.container}>
        {scriptList.map(script => (
          <div
            onClick={e => {
              onClickScript(script.id)
            }}
            key={script.id}
            className={classes.script}
          >
            <div className={classes.title}>{script.title}</div>
            <div className={classes.description}>
              {script.description === undefined ? '' : script.description}
            </div>
            <div className={classes.states}>
              <div className={classes.scriptType}>
                入力条件：
                {script.condition.type === sctiptCondition.SCRIPT_TYPE_REQUIRED
                  ? '必須入力'
                  : '自由入力'}
              </div>
              <div className={classes.pushState}>
                通知：
                {script.condition.items.map((item, i) =>
                  renderCondition(item, i)
                )}
              </div>
            </div>
            <div
              className={classes.menuIcon}
              onClick={e => {
                e.stopPropagation()
                onClickMenu(e, script.id)
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default List
