// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon/admin/close.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._1STehBfN3v4ZdO_Fz2iZ4k{position:fixed;top:0px;left:0px;background-color:rgba(0,0,0,0.5);width:100%;height:100%;z-index:1000;text-align:left}._1STehBfN3v4ZdO_Fz2iZ4k._2aDET954K6m14dSsKvRLyn{display:none}._1STehBfN3v4ZdO_Fz2iZ4k ._1UFej1FlI3jqZZPLZTX6L2{position:absolute;width:900px;height:70%;top:50%;left:50%;transform:translateY(-50%) translateX(-50%);box-sizing:border-box;background-color:#fff;border-radius:12px;overflow:hidden}._1STehBfN3v4ZdO_Fz2iZ4k ._1UFej1FlI3jqZZPLZTX6L2 ._1YwbFGogU4HTx_BA2-yhxT{position:relative;width:100%;height:100px;border-bottom:solid 1px #E8ECEE}._1STehBfN3v4ZdO_Fz2iZ4k ._1UFej1FlI3jqZZPLZTX6L2 ._1YwbFGogU4HTx_BA2-yhxT ._2qwOc7tMmGJrpS5rUrguJr{font-size:23px;line-height:100px;margin-left:32px}._1STehBfN3v4ZdO_Fz2iZ4k ._1UFej1FlI3jqZZPLZTX6L2 ._1YwbFGogU4HTx_BA2-yhxT .pZvYto-VqsItzi9bQT3w4{position:absolute;top:25px;right:54px;height:100%}._1STehBfN3v4ZdO_Fz2iZ4k ._1UFej1FlI3jqZZPLZTX6L2 ._1YwbFGogU4HTx_BA2-yhxT .pZvYto-VqsItzi9bQT3w4>*{float:right;margin-left:8px}._1STehBfN3v4ZdO_Fz2iZ4k ._1UFej1FlI3jqZZPLZTX6L2 ._1YwbFGogU4HTx_BA2-yhxT ._3D-O0gBKbt6ZHYZj0Rq2NW{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:24px;height:24px;position:absolute;right:14px;top:14px}._1STehBfN3v4ZdO_Fz2iZ4k ._1UFej1FlI3jqZZPLZTX6L2 .kLHzQhADgzoa01t6m4GYD{position:relative;box-sizing:border-box;width:100%;height:calc(100% - 101px);overflow-y:scroll;padding:32px}", ""]);
// Exports
exports.locals = {
	"container": "_1STehBfN3v4ZdO_Fz2iZ4k",
	"hidden": "_2aDET954K6m14dSsKvRLyn",
	"popupContainer": "_1UFej1FlI3jqZZPLZTX6L2",
	"popupHeader": "_1YwbFGogU4HTx_BA2-yhxT",
	"title": "_2qwOc7tMmGJrpS5rUrguJr",
	"buttonContainer": "pZvYto-VqsItzi9bQT3w4",
	"closeButton": "_3D-O0gBKbt6ZHYZj0Rq2NW",
	"popupBody": "kLHzQhADgzoa01t6m4GYD"
};
module.exports = exports;
