// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './DateLog.scss'

const DateLog = ({ date }) => (
  <div className={classes.container}>
    {Moment(new Date(date.seconds * 1000)).format('YYYY.MM.DD')}
  </div>
)

DateLog.propTypes = {
  date: PropTypes.shape({
    nanoseconds: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired
  }).isRequired
}

DateLog.defaultProps = {
  date: {
    nanoseconds: 0,
    seconds: 0
  }
}

export default DateLog
