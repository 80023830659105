// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import classes from './NumberLog.scss'

const NumberLog = ({ number }) => (
  <div className={classes.container}>{number}</div>
)

NumberLog.propTypes = {
  number: PropTypes.number.isRequired
}

NumberLog.defaultProps = {
  number: 0
}

export default NumberLog
