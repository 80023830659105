// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".zXvDd9J-IBDkQjknTE5TL{box-sizing:border-box;border-radius:10px;margin:12px 0;text-align:right}", ""]);
// Exports
exports.locals = {
	"container": "zXvDd9J-IBDkQjknTE5TL"
};
module.exports = exports;
