// Takram Confidential
// Copyright (C) 2019-Present Takr

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import classes from './Header.scss'

export default function Header({ displayName, email, onClickName }) {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Link to='/'>
          <div className={classes.logo} />
        </Link>
        <div className={classes.displayName} onClick={e => onClickName(e)}>
          {displayName || email}
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  displayName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onClickName: PropTypes.func.isRequired
}
