// Takram Confidential
// Copyright (C) 2019-Present Takram

import React from 'react'
import { Route, Switch } from 'react-router'

import FormMobile from './FormMobile'

export function AppForm() {
  return (
    <Switch>
      <Route
        path='/m/:uid/:sid/:reqid'
        render={({ match }) => (
          <FormMobile
            loginUserId={match.params.uid}
            scriptId={match.params.sid}
            requiredId={match.params.reqid}
          />
        )}
      />
      <Route
        path='/m/:uid/:sid'
        render={({ match }) => (
          <FormMobile
            loginUserId={match.params.uid}
            scriptId={match.params.sid}
          />
        )}
      />
      <Route
        path='/m/:uid'
        render={({ match }) => <FormMobile loginUserId={match.params.uid} />}
      />
      <Route path='/m/' render={({ match }) => <FormMobile />} />
    </Switch>
  )
}

export default AppForm
