// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2wAf0fZExN4p9Y1VfzaHS8 ._2_ALeTmxTqcHtEmmFtbdly{width:24px;height:24px;background-color:#EEE;border-radius:12px;text-align:center;line-height:22px;font-size:14px;color:#888;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._2wAf0fZExN4p9Y1VfzaHS8 ._2_ALeTmxTqcHtEmmFtbdly:hover{background-color:#DDD}", ""]);
// Exports
exports.locals = {
	"container": "_2wAf0fZExN4p9Y1VfzaHS8",
	"selector": "_2_ALeTmxTqcHtEmmFtbdly"
};
module.exports = exports;
