// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import FormNavigator from '../../containers/FormNavigator'

import classes from './Pages.scss'

const Pages = ({ hidden, children }) => (
  <div
    className={ClassNames(classes.page, {
      [classes.hidden]: hidden
    })}
  >
    <div
      className={ClassNames(classes.container, {
        [classes.hidden]: hidden
      })}
    >
      <div className={classes.content}>{children}</div>
      <FormNavigator />
    </div>
  </div>
)

Pages.propTypes = { hidden: PropTypes.bool.isRequired }

Pages.defaultProps = { hidden: false }

export default Pages
