// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".-PXv-c1oO9P5GyUJJa5LO{width:100%;background-color:#f1f1f1;overflow:hidden;min-height:calc(100% - 208px)}.-PXv-c1oO9P5GyUJJa5LO ._16epv49iT7YKnvIsygW6XO{position:relative;width:1096px;margin:40px auto;padding:0 80px}.-PXv-c1oO9P5GyUJJa5LO .jBjaiJ8gJKUz5pkzebZeQ{clear:both}", ""]);
// Exports
exports.locals = {
	"container": "-PXv-c1oO9P5GyUJJa5LO",
	"innerContainer": "_16epv49iT7YKnvIsygW6XO",
	"clearBoth": "jBjaiJ8gJKUz5pkzebZeQ"
};
module.exports = exports;
