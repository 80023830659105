// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'

import { projectOperations } from '../../modules/project'

import commonCls from '../App/Common.scss'
import classes from './Forms.scss'
import SectionRow from '../Ui/SectionRow'
import TextInput from '../Ui/TextInput'
import TextArea from '../Ui/TextArea'

import { PROJECT_TYPES, USER_ATTR_TYPES } from '../../modules/project/types'

const attrItems = USER_ATTR_TYPES.map(t => ({ name: t.name, value: t.type }))

export function Forms({ project, permitUpdate }) {
  const dispatch = useDispatch()

  const onChangeValue = (key, value) => {
    dispatch(projectOperations.updateProject({ [key]: value }))
  }

  return (
    <div className={classes.container}>
      <SectionRow label='タイトル' vertical>
        <TextInput
          style={{ width: '100%' }}
          disabled={!permitUpdate}
          onChange={e => onChangeValue('title', e.target.value)}
          value={project.title === undefined ? '' : project.title}
        />
      </SectionRow>
      <SectionRow label='説明' vertical>
        <TextArea
          style={{ width: '100%' }}
          disabled={!permitUpdate}
          onChange={e => onChangeValue('description', e.target.value)}
          value={project.description === undefined ? '' : project.description}
        />
      </SectionRow>
    </div>
  )
}

Forms.propTypes = {
  permitUpdate: PropTypes.bool.isRequired,
  project: PropTypes.shape(PROJECT_TYPES)
}

Forms.defaultProps = {}

export default Forms
