// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import * as scriptConfig from '../../../constants/scriptConfig'
import {
  USER_ATTR_TYPE_TEXT,
  USER_ATTR_TYPE_LIST,
  USER_ATTR_TYPE_MASKING_TEXT,
  USER_ATTR_TYPE_MASKING_LIST,
  getAttrTypeName
} from '../../../modules/project/types'

import classes from './Item.scss'

const ItemUserInfo = ({
  extraUserAttr,
  onChangeValue,
  permitUpdate,
  value
}) => {
  const typeTable = {}
  extraUserAttr.forEach(attr => (typeTable[attr.id] = attr.type))
  return (
    <div className={classes.uaserInfoContainer}>
      <select
        disabled={!permitUpdate}
        onChange={e => {
          value[0].attrId = e.target.value
          value[0].type = ''
          onChangeValue('value', value)
        }}
        value={value[0].attrId}
      >
        <option key='none' value=''>
          選択して下さい
        </option>
        {extraUserAttr.map(attr => (
          <option key={attr.id} value={attr.id}>
            {attr.title} ({getAttrTypeName(attr.type)})
          </option>
        ))}
      </select>

      {typeTable[value[0].attrId] === USER_ATTR_TYPE_TEXT ? (
        <>
          <select
            disabled={!permitUpdate}
            onChange={e => {
              value[0].type = e.target.value
              onChangeValue('value', value)
            }}
            value={value[0].type}
          >
            <option key='tnone' value=''>
              選択して下さい
            </option>
            <option key='t1' value={scriptConfig.INPUT_TEXT}>
              テキスト
            </option>
          </select>
        </>
      ) : typeTable[value[0].attrId] === USER_ATTR_TYPE_LIST ||
        typeTable[value[0].attrId] === USER_ATTR_TYPE_MASKING_LIST ? (
        <>
          <select
            disabled={!permitUpdate}
            onChange={e => {
              value[0].type = e.target.value
              onChangeValue('value', value)
            }}
            value={value[0].type}
          >
            <option key='snone' value=''>
              選択して下さい
            </option>
            <option key='s1' value={scriptConfig.INPUT_RADIO}>
              単一選択
            </option>
            <option key='s2' value={scriptConfig.INPUT_CHECK}>
              複数選択
            </option>
          </select>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

ItemUserInfo.propTypes = {
  ...scriptConfig.ITEM_STYLE,
  onChangeValue: PropTypes.func.isRequired,
  permitUpdate: PropTypes.bool.isRequired
}

ItemUserInfo.defaultProps = {
  ...scriptConfig.INITIAL_ITEM_DATA
}

export default ItemUserInfo
