// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3vzQsRT-KU4K8-Ce59OQJQ{background-color:#fff;width:1096px;margin:48px auto;border-radius:10px;padding:48px;box-sizing:border-box}._3vzQsRT-KU4K8-Ce59OQJQ .o5qCaypAY0F2NzqBZLW7c{text-align:center;margin:32px auto 0 auto;width:300px}", ""]);
// Exports
exports.locals = {
	"container": "_3vzQsRT-KU4K8-Ce59OQJQ",
	"saveButton": "o5qCaypAY0F2NzqBZLW7c"
};
module.exports = exports;
