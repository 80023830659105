// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import classes from './UserTabFrame.scss'

export function UserTabFrame({ children, onClickTab, index = 0 }) {
  return (
    <div className={classes.container}>
      <div>
        <div className={classes.tabMenu}>
          <div
            className={ClassNames(classes.menuItem, {
              [classes.selected]: index === 0
            })}
            onClick={e => onClickTab(0)}
          >
            一覧
          </div>
          <div
            className={ClassNames(classes.menuItem, {
              [classes.selected]: index === 1
            })}
            onClick={e => onClickTab(1)}
          >
            属性
          </div>
        </div>
      </div>
      <div>{children}</div>
    </div>
  )
}

UserTabFrame.propTypes = {}

export default UserTabFrame
