// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './Header.scss'

const dayNames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

const DateList = (currentRecordDate, dateCheckList, onClickDate) => {
  // TODO: scroll dates
  return dateCheckList.map(({ date, done }, i) => (
    <div
      key={i}
      className={ClassNames(classes.date, {
        [classes.future]: Moment(date).isAfter(Moment(), 'date'),
        [classes.selected]: Moment(date).isSame(
          Moment(currentRecordDate),
          'date'
        ),
        [classes.today]: Moment(date).isSame(Moment(), 'date')
      })}
      onClick={() => onClickDate(date)}
    >
      <div
        className={ClassNames(classes.check, {
          [classes.done]: done && Moment(date).isBefore(Moment(), 'date'),
          [classes.notDone]: !done && Moment(date).isBefore(Moment(), 'date')
        })}
      />
      <div className={classes.day}>{date.getDate()}</div>
      <div className={classes.dayOfWeek}>{dayNames[date.getDay()]}</div>
    </div>
  ))
}

const Header = ({
  currentRecordDate,
  dateCheckList,
  isDebugUserMode,
  onClickDate,
  openUserInfo
}) => (
  <div className={classes.container}>
    <div className={classes.dates}>
      {DateList(currentRecordDate, dateCheckList, onClickDate)}
    </div>
    {isDebugUserMode ? (
      <div className={classes.debug}>Debug User</div>
    ) : (
      <div className={classes.info} onClick={openUserInfo}>
        i
      </div>
    )}
  </div>
)

Header.propTypes = {
  currentRecordDate: PropTypes.instanceOf(Date).isRequired,
  dateCheckList: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date).isRequired,
      done: PropTypes.bool.isRequired
    })
  ).isRequired,
  isDebugUserMode: PropTypes.bool.isRequired,
  onClickDate: PropTypes.func.isRequired,
  openUserInfo: PropTypes.func.isRequired
}

Header.defaultProps = {}

export default Header
