// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Whch5woH2ydUM1ed6PLnG{width:100%}._3Whch5woH2ydUM1ed6PLnG ._2DQBmZZmRYDKaO3qIrCxUC{height:59px;padding-bottom:16px;padding:0}._3Whch5woH2ydUM1ed6PLnG .hnlMderMz35lSrthG5mem{background-color:#fff;padding:0 auto;box-sizing:border-box;border-radius:10px;margin:0 0 12px 0;height:calc(90% - 208px - 59px);overflow:auto}._3Whch5woH2ydUM1ed6PLnG .hnlMderMz35lSrthG5mem ._4M0fNSTH8vUiq5noHzLOD{display:inline-block;vertical-align:top;position:absolute;width:220px;height:100%;border-right:solid 1px #E8ECEE;box-sizing:border-box;overflow:auto}._3Whch5woH2ydUM1ed6PLnG .hnlMderMz35lSrthG5mem ._3ZQsdiMeLz21NsFylBCg9w{display:inline-block;vertical-align:top;width:876px;margin:0 0px 0 220px;box-sizing:border-box}", ""]);
// Exports
exports.locals = {
	"container": "_3Whch5woH2ydUM1ed6PLnG",
	"conditions": "_2DQBmZZmRYDKaO3qIrCxUC",
	"messengerContainer": "hnlMderMz35lSrthG5mem",
	"userListColumn": "_4M0fNSTH8vUiq5noHzLOD",
	"messageColumn": "_3ZQsdiMeLz21NsFylBCg9w"
};
module.exports = exports;
