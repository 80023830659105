import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { messageOperations } from '../../modules/message'
import { projectOperations } from '../../modules/project'
import { recordOperations } from '../../modules/record'
import { userOperations } from '../../modules/user'
import { clipBoardCopy } from '../../common/Util'
import { useSnackbar } from 'notistack'
import Forms from './Forms'
import AttrForms from './AttrForms'
import Info from './Info'
import MuiButton from '../Ui/MuiButton'
import classes from './UserPage.scss'
import MuiDialog from '../Ui/MuiDialog'

export function User({ projectId, userId }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const dialogRef = useRef()
  const user = useSelector(state => state.userState.user)
  const recordList = useSelector(state => state.recordState.recordList)
  const extraUserAttr = useSelector(
    state => state.projectState.project.extraUserAttr
  )

  useEffect(() => {
    dispatch(userOperations.fetchUser(userId))
    dispatch(projectOperations.fetchProject(projectId))
    dispatch(recordOperations.subscribeRecordList({ userId }))
    return () => {
      dispatch(userOperations.clearUser())
      dispatch(projectOperations.clearProject())
      dispatch(recordOperations.unsubscribeRecordList())
    }
  }, [])

  const onSaveValue = e => {
    dispatch(userOperations.saveUser(user))
    enqueueSnackbar('変更を保存しました', { variant: 'default' })
  }

  const onClickCancel = e => {
    history.push(`/project/${projectId}/user/`)
  }

  const onClickLink = e => {
    const host = 'http://' + window.location.host
    const link = host + '/m/' + user.id + '/'
    clipBoardCopy(link)
    enqueueSnackbar('アドレスをコピーしました', { variant: 'default' })
  }

  const onClickUser = e => {
    history.push(`/project/${projectId}/message/`)
    dispatch(userOperations.updateMessageReadAt(user.id))
    dispatch(messageOperations.setCurrentMessagingUserIds([user.id]))
  }

  const onClickLineRelease = e => {
    dialogRef.current.handleOpen()
  }

  return (
    <div className={classes.container}>
      <div className={classes.backButton} onClick={e => onClickCancel(e)} />
      <h2>被験者を編集する</h2>
      <MuiDialog
        title='LINE連携を解除'
        text='LINE連携を解除してもよろしいですか？'
        ref={dialogRef}
        buttons={[
          {
            name: '解除する',
            value: id => dispatch(userOperations.releaseLineUserId(user))
          },
          { name: '解除しない', value: () => {} }
        ]}
      />
      <Forms user={user} />
      <AttrForms user={user} extraUserAttr={extraUserAttr} />
      <Info user={user} recordList={recordList} />
      <div className={classes.actions}>
        <MuiButton
          disabled={user.lineUserId === ''}
          text='LINE連携を解除する'
          onClick={e => onClickLineRelease(e)}
        />
        <MuiButton text='メッセージを送る' onClick={e => onClickUser(e)} />
        <MuiButton text='リンクを取得する' onClick={e => onClickLink(e)} />
        <MuiButton
          color='secondary'
          text='保存する'
          onClick={e => onSaveValue(e)}
        />
      </div>
    </div>
  )
}

export default User
