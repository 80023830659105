// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon/admin/backarrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".KVLov3--haltpsO3SxawO{width:800px;margin:16px auto;padding:48px 0;text-align:center;box-sizing:border-box}.KVLov3--haltpsO3SxawO ._3CLhdFBI2WlHaim489lhy7{position:fixed;top:88px;left:24px;width:32px;height:32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;cursor:pointer}.KVLov3--haltpsO3SxawO ._3vcn0ZolkZ0yjx-EbUFk9b{text-align:right}.KVLov3--haltpsO3SxawO ._3vcn0ZolkZ0yjx-EbUFk9b>*{margin:0 0 0 8px}", ""]);
// Exports
exports.locals = {
	"container": "KVLov3--haltpsO3SxawO",
	"backButton": "_3CLhdFBI2WlHaim489lhy7",
	"actions": "_3vcn0ZolkZ0yjx-EbUFk9b"
};
module.exports = exports;
