// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import RecordLog from '../components/Form/RecordLog/RecordLog'
import { formOperations, formSelectors } from '../modules/form'
import * as formTypes from '../modules/form/types'
import { recordOperations } from '../modules/record'
import * as recordTypes from '../modules/record/types'
import * as scriptTypes from '../modules/script/types'

class FormRecordLog extends Component {
  onClose() {
    const { formOperations, recordOperations } = this.props
    formOperations.setRecordLogVisibility(false)
    recordOperations.clearLog()
  }

  render() {
    const { currentLog, popupVisibility, userItems } = this.props
    return (
      <RecordLog
        onClose={() => this.onClose()}
        visibility={popupVisibility.recordLog}
        userItems={userItems}
        {...currentLog}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentLog: state.recordState.currentLog,
    popupVisibility: state.formState.popupVisibility,
    userItems: formSelectors.userItemsSelector(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    formOperations: bindActionCreators(formOperations, dispatch),
    recordOperations: bindActionCreators(recordOperations, dispatch)
  }
}

FormRecordLog.propTypes = {
  currentLog: PropTypes.shape({
    record: PropTypes.shape(recordTypes.RECORD_TYPES),
    script: PropTypes.shape(scriptTypes.SCRIPT_TYPES)
  }),
  formOperations: PropTypes.objectOf(PropTypes.func).isRequired,
  popupVisibility: PropTypes.shape(formTypes.POPUP_VISIBILITY_TYPES).isRequired,
  recordOperations: PropTypes.objectOf(PropTypes.func).isRequired,
  userItems: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(FormRecordLog)
