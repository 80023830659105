import React from 'react'

import classes from './Button.scss'

export const DeleteButton = ({ onClick = () => {} }) => (
  <div className={classes.deleteButton} onClick={onClick} />
)

export const UpButton = ({ onClick = () => {} }) => (
  <div className={classes.upButton} onClick={onClick} />
)

export const DownButton = ({ onClick = () => {} }) => (
  <div className={classes.downButton} onClick={onClick} />
)

export const MenuButton = ({ onClick = () => {} }) => (
  <div className={classes.menuButton} onClick={onClick} />
)
