// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { useState } from 'react'

import classes from './OverView.scss'
import Select from '../Ui/Select'
import MuiButton from '../Ui/MuiButton'
import SectionRow from '../Ui/SectionRow'
import OverlayFrame from '../App/OverlayFrame'
import { history } from '../../configureStore'

/* eslint-disable */
const functionsUrl = __FB_FUNCTIONS_URL__

const encodeItems = [
  { name: 'Mac (UTF-8)', value: 'utf-8' },
  { name: 'Windows (SHIFT-JIS)', value: 'sjis' }
]

const asNumberItems = [
  { name: '文字列で出力', value: 'false' },
  { name: '数値変換して出力', value: 'true' }
]

export function OverView({ projectId }) {
  const [encode, setEncode] = useState('utf-8')
  const [asNumber, setAsNumber] = useState('false')
  const [dlVisibility, setDlVisibility] = useState(false)

  const renderDlOverlay = () => (
    <OverlayFrame
      title='CSVダウンロード'
      onClickClose={e => setDlVisibility(false)}
      buttons={[
        {
          label: 'ダウンロード',
          href: `${functionsUrl}export?projectId=${projectId}&encode=${encode}&asNumber=${asNumber}`,
          accent: true
        }
      ]}
    >
      <SectionRow label='文字コード' vertical>
        <Select
          value={encode}
          items={encodeItems}
          onChange={e => setEncode(e.target.value)}
        />
      </SectionRow>
      <SectionRow label='単一選択変換' vertical>
        <Select
          value={asNumber}
          items={asNumberItems}
          onChange={e => setAsNumber(e.target.value)}
        />
      </SectionRow>
    </OverlayFrame>
  )

  return (
    <div className={classes.container}>
      {dlVisibility && renderDlOverlay()}
      <MuiButton text='CSVダウンロード' onClick={e => setDlVisibility(true)} />
    </div>
  )
}

export default OverView

OverView.propTypes = {
  projectId: PropTypes.string.isRequired
}
