// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MLWs_uzWVNXa4ETozonq9{width:calc(100% - 160px);margin:0 auto}", ""]);
// Exports
exports.locals = {
	"container": "MLWs_uzWVNXa4ETozonq9"
};
module.exports = exports;
