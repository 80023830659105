// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'
import { createSelector } from 'reselect'
import userSelectors from '../user/selectors'

import * as scriptConfig from '../../constants/scriptConfig'

const recordListSelector = state => state.recordState.recordList
const userListSelector = state => state.userState.userList

const userRecordCountSelector = createSelector(
  [userListSelector, recordListSelector],
  (userList, recordList) => {
    const userRecordMap = {}
    recordList.forEach(record => {
      if (!Object.prototype.hasOwnProperty.call(userRecordMap, record.userId)) {
        userRecordMap[record.userId] = []
      }
      userRecordMap[record.userId].push(record)
    })
    userList = userList.filter(user => !user.isExcludedRecord)
    return userList.map(user => {
      const termStatus = userSelectors.deriveTermStatus(user)
      const records = userRecordMap[user.id]
      const today = new Date()
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      const dateStr = date => Moment(date).format('YYYYMMDD')
      let todayCount = 0
      let yesterdayCount = 0
      if (records != null) {
        todayCount = records.filter(
          r => dateStr(r.createdAt) === dateStr(today)
        ).length
        yesterdayCount = records.filter(
          r => dateStr(r.createdAt) === dateStr(yesterday)
        ).length
      }
      return {
        ...user,
        ...termStatus,
        recordCount: records == null ? 0 : records.length,
        lastRecordAt: records == null ? null : records[0].createdAt,
        todayCount,
        yesterdayCount
      }
    })
  }
)

const recordCountSelector = createSelector(
  [userListSelector, recordListSelector],
  (userList, recordList) => {
    const count = {}
    recordList.forEach(record => {
      const user = userList.find(user => user.id === record.userId)
      if (user !== undefined && user.isExcludedRecord) return
      const types = record.dataTypes
      Object.keys(record.data).forEach(itemID => {
        const val = record.data[itemID]
        const values = []
        if (types[itemID] === scriptConfig.INPUT_DATE) {
          const date = new Date(val.seconds * 1000)
          values.push(Moment(date).format('YYYY/MM/DD'))
        } else if (types[itemID] === scriptConfig.INPUT_TIME) {
          const arr = val.split(':')
          values.push(arr[0] + '時台')
        } else if (types[itemID] === scriptConfig.INPUT_CHECK) {
          val.forEach(v => values.push(v))
        } else if (types[itemID] === scriptConfig.INPUT_USER_INFO) {
          const buf = []
          if (Array.isArray(val)) {
            val.forEach(v => buf.push(v))
          } else {
            buf.push(val)
          }
          buf.forEach(v => {
            if (v.includes(':')) {
              values.push(v.split(':')[1])
            } else {
              values.push(v)
            }
          })
        } else {
          values.push(val)
        }
        values.forEach(v => {
          if (!count[itemID]) count[itemID] = {}
          const countMap = count[itemID]
          if (!countMap[v]) countMap[v] = 0
          countMap[v] += 1
        })
      })
    })
    return count
  }
)

const scriptCoordinatesSelector = createSelector(
  recordListSelector,
  recordList => {
    const coords = {}
    recordList.forEach(record => {
      const scriptId = record.scriptId
      if (record.coordinates !== undefined) {
        if (!coords[scriptId]) coords[scriptId] = []
        coords[scriptId].push(record.coordinates)
      }
    })
    return coords
  }
)
export default {
  userRecordCountSelector,
  recordCountSelector,
  scriptCoordinatesSelector
}
