// Takram Confidential
// Copyright (C) 2019-Present Takram

import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'

import configureStore, { history } from './configureStore'
import App from './components/App/App'

import './index.scss'

const store = configureStore()

render(
  <CookiesProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </ConnectedRouter>
    </Provider>
  </CookiesProvider>,
  document.getElementById('content')
)
