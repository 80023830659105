// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import { ENTRY_STYLES } from '../../../constants/scriptConfig'
import EntryIfCondition from './EntryIfCondition'

import classes from './EntryIf.scss'

export function GuiEntryIf({ entry }) {
  return (
    <div className={classes.container}>
      <div className={classes.entryTitle}>IF</div>
      {entry.value.map(condition => (
        <EntryIfCondition key={condition.id} condition={condition} />
      ))}
    </div>
  )
}

GuiEntryIf.propTypes = {
  entry: PropTypes.shape(ENTRY_STYLES).isRequired
}

export default GuiEntryIf
