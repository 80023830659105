// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './Frame.scss'

export default function Frame({ children, simulatorVisibility }) {
  return (
    <div
      className={ClassNames(classes.container, {
        [classes.showSimulator]: simulatorVisibility
      })}
    >
      <div className={classes.innerContainer}>{children}</div>
      <div className={classes.clearBoth} />
    </div>
  )
}

Frame.propTypes = {
  children: PropTypes.node.isRequired,
  simulatorVisibility: PropTypes.bool.isRequired
}
