// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon/admin/backarrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._3SrdJHW23vygx6VavWjH-y{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;position:relative;width:100%;background-color:#fff}._3SrdJHW23vygx6VavWjH-y ._1OS8lXYlI1uKFgb4xv-eYt{position:absolute;top:24px;left:24px;width:32px;height:32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;z-index:10000}._3SrdJHW23vygx6VavWjH-y ._3b-BslRXaFcVunlzH3Ua9o{position:relative;width:1096px;height:144px;margin:0 auto;padding:0 80px}._3SrdJHW23vygx6VavWjH-y ._9NXFQcC6k1RI2b5AoA5u5{position:absolute;top:24px;font-size:24px}._3SrdJHW23vygx6VavWjH-y .Vdl26fYhurZOtq8ieeWNH{position:absolute;top:64px;font-size:12px;color:#919D99}._3SrdJHW23vygx6VavWjH-y ._1pbBELUhFhZIPyeYlJDlQc{position:absolute;font-size:16px;bottom:0px}._3SrdJHW23vygx6VavWjH-y ._1pbBELUhFhZIPyeYlJDlQc ._3VqyHookfDnxJKz_gUaJxU{position:relative;display:inline-block;padding:8px 36px 16px 36px;border-bottom:2px solid #fff}._3SrdJHW23vygx6VavWjH-y ._1pbBELUhFhZIPyeYlJDlQc ._3VqyHookfDnxJKz_gUaJxU ._37vdu3WRFtA8ibRh8dbh2k{position:absolute;right:8px;top:10px;width:20px;height:20px;background-color:#EAAD68;color:white;line-height:20px;border-radius:12px;text-align:center;font-size:12px}._3SrdJHW23vygx6VavWjH-y ._1pbBELUhFhZIPyeYlJDlQc ._3VqyHookfDnxJKz_gUaJxU._33Mjwux_E4BX47vtwx4zSO{border-bottom:2px solid #425c5b}._3SrdJHW23vygx6VavWjH-y ._1pbBELUhFhZIPyeYlJDlQc ._3VqyHookfDnxJKz_gUaJxU:hover{border-bottom:2px solid #425c5b}._3SrdJHW23vygx6VavWjH-y a{color:#000;text-decoration:none}", ""]);
// Exports
exports.locals = {
	"container": "_3SrdJHW23vygx6VavWjH-y",
	"backButton": "_1OS8lXYlI1uKFgb4xv-eYt",
	"headerContainer": "_3b-BslRXaFcVunlzH3Ua9o",
	"title": "_9NXFQcC6k1RI2b5AoA5u5",
	"description": "Vdl26fYhurZOtq8ieeWNH",
	"menu": "_1pbBELUhFhZIPyeYlJDlQc",
	"menuItem": "_3VqyHookfDnxJKz_gUaJxU",
	"badge": "_37vdu3WRFtA8ibRh8dbh2k",
	"current": "_33Mjwux_E4BX47vtwx4zSO"
};
module.exports = exports;
