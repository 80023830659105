// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/icon/user/close-white.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._13RnMu9dgPvt8SXZ_6xGz_{left:0px;position:absolute;right:0px;top:0px;z-index:1;transition:all 400ms cubic-bezier(0, 0.2, 0.2, 1)}._13RnMu9dgPvt8SXZ_6xGz_ ._1h4nIKQpgB9OQ1XFSJI3m8{align-items:center;background-color:#DA957F;display:flex;height:60px}._13RnMu9dgPvt8SXZ_6xGz_ ._1h4nIKQpgB9OQ1XFSJI3m8 .RS-haQWk9goEsLY_8z9Zf{color:white;display:inline;font-size:12px;margin-left:20px}._13RnMu9dgPvt8SXZ_6xGz_ ._1h4nIKQpgB9OQ1XFSJI3m8 ._1XD82h9HjTU6CWxCQrB57g{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;width:17px;height:16px;position:absolute;right:20px}._13RnMu9dgPvt8SXZ_6xGz_.BcGpJSBJHrT3vr3UlMb_n{bottom:-100%;top:-60px;pointer-events:none}", ""]);
// Exports
exports.locals = {
	"container": "_13RnMu9dgPvt8SXZ_6xGz_",
	"item": "_1h4nIKQpgB9OQ1XFSJI3m8",
	"content": "RS-haQWk9goEsLY_8z9Zf",
	"icon": "_1XD82h9HjTU6CWxCQrB57g",
	"hidden": "BcGpJSBJHrT3vr3UlMb_n"
};
module.exports = exports;
