import React, { forwardRef, useImperativeHandle } from 'react'

const textAreaStyle = {
  margin: 0,
  padding: '8px 16px',
  background: 'none',
  border: 'solid 1px #E8ECEE',
  borderRadius: 8,
  outline: 'none',
  appearance: 'none',
  fontSize: 14
}

export function TextArea({ value, onChange, disabled, rows, style }, ref) {
  const textAreaField = React.useRef(null)
  useImperativeHandle(ref, () => ({
    getSelectionRange() {
      return {
        selectionEnd: textAreaField.current.selectionEnd,
        selectionStart: textAreaField.current.selectionStart
      }
    }
  }))
  return (
    <textarea
      disabled={disabled}
      onChange={onChange}
      ref={textAreaField}
      rows={rows}
      style={{ ...textAreaStyle, ...style }}
      value={value == null ? '' : value}
    />
  )
}

TextArea.defaultProps = {
  value: '',
  disabled: false,
  onChange: () => {},
  rows: 5,
  style: {}
}

export default forwardRef(TextArea)
