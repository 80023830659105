// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { scriptSelectors } from '../../modules/script'

import classes from './InputCheck.scss'

const InputCheck = ({
  column: columnCount,
  id,
  inputData,
  isIndivisual,
  setInputData,
  value: choices
}) => {
  const renderItem = (d, i) => (
    <div
      className={ClassNames(classes.button, {
        [classes.isSelected]: inputData.includes(d.text),
        [classes.twoColumn]: columnCount > 1
      })}
      key={id + i}
      onClick={() => {
        const newInputData = {
          [id]: inputData.includes(d.text)
            ? inputData.filter(element => element !== d.text)
            : [...inputData, d.text]
        }
        setInputData(newInputData)
      }}
    >
      <div
        className={ClassNames(classes.icon, {
          [classes.selectedIcon]: inputData.includes(d.text)
        })}
      />
      <div className={classes.inputText}>
        {isIndivisual ? d.text.split(':')[0] : d.text}
      </div>
    </div>
  )

  const [othersText, setOthersText] = useState('')
  const [flagSelectedOthers, setFlagSelectedOthers] = useState(false)
  const othersInputField = React.useRef(null)
  const selectOthers = text => {
    const inputDataWithoutOthers = inputData.filter(
      el => !scriptSelectors.recordTextIsOthers(el)
    )
    const newInputData = {
      [id]: [
        ...inputDataWithoutOthers,
        scriptSelectors.generateOthersRecordText(text)
      ]
    }
    setInputData(newInputData)
    setFlagSelectedOthers(true)
  }
  const unselectOthers = () => {
    const newInputData = {
      [id]: inputData.filter(el => !scriptSelectors.recordTextIsOthers(el))
    }
    setInputData(newInputData)
    setFlagSelectedOthers(false)
  }
  const renderOthers = (d, i) => (
    <div
      className={ClassNames(classes.button, {
        [classes.isSelected]: flagSelectedOthers,
        [classes.twoColumn]: columnCount > 1
      })}
      key={id + i}
      onClick={() => {
        if (flagSelectedOthers) {
          unselectOthers()
        } else {
          selectOthers(othersText)
          othersInputField.current.focus()
        }
      }}
    >
      <div
        className={ClassNames(classes.icon, {
          [classes.selectedIcon]: flagSelectedOthers
        })}
      />
      その他
      <input
        ref={othersInputField}
        className={ClassNames(classes.inputOthers, {
          [classes.isSelected]: flagSelectedOthers
        })}
        type='text'
        onChange={e => {
          setOthersText(e.target.value)
          selectOthers(e.target.value)
        }}
        value={othersText}
      />
    </div>
  )

  return (
    <div className={classes.content}>
      {choices.map((d, i) =>
        d.isOthers ? renderOthers(d, i) : renderItem(d, i)
      )}
    </div>
  )
}

InputCheck.propTypes = {
  column: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  inputData: PropTypes.arrayOf(PropTypes.string).isRequired,
  isIndivisual: PropTypes.bool.isRequired,
  setInputData: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired
    })
  ).isRequired
}

InputCheck.defaultProps = {
  column: 1,
  inputData: [],
  isIndivisual: false,
  value: []
}

export default InputCheck
