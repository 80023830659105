// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3X1gLaxvkYAILkoXb0XVQK{display:block}._3X1gLaxvkYAILkoXb0XVQK ._2I72Vr9A-4y-vOybQ_LlvA{display:block;margin:16px 0px 4px 0}._3X1gLaxvkYAILkoXb0XVQK textarea{display:inline-block;width:560px;height:64px;font-size:14px;color:#384241;resize:vertical}", ""]);
// Exports
exports.locals = {
	"container": "_3X1gLaxvkYAILkoXb0XVQK",
	"word": "_2I72Vr9A-4y-vOybQ_LlvA"
};
module.exports = exports;
