// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'

import {
  isPermitLevel,
  PERMIT_CREATE,
  PERMIT_DELETE,
  PERMIT_READ,
  PERMIT_UPDATE
} from '../../constants/permission'
import firebase from '../../Firebase'
import { appTypes } from '../app'
import * as types from './types'

let unsubLoginUser = null
let unsubUserList = null

const subscribeUserList = projectId => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    let db = firebase.firestore().collection('fave-users')
    db = db.orderBy('endAt', 'desc')
    if (projectId != null) {
      db = db.where('projectId', '==', projectId)
    }
    unsubUserList = db.onSnapshot(snapshot => {
      dispatch({
        type: types.FETCH_USER_LIST,
        userList: snapshot.docs.map(doc => deriveUserFromDoc(doc))
      })
    })
  }
}

const unsubscribeUserList = () => {
  return async (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    if (unsubUserList !== null) unsubUserList()
    dispatch({ type: types.DETOUCH_USER_LIST })
  }
}

const clearUser = userId => {
  return (dispatch, getState) => {
    dispatch({
      type: types.CLEAR_USER
    })
  }
}

const fetchUser = userId => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    const ref = firebase
      .firestore()
      .collection('fave-users')
      .doc(userId)
    ref
      .get()
      .then(doc => {
        if (!doc.exists) return
        dispatch({
          type: types.FETCH_USER,
          user: deriveUserFromDoc(doc)
        })
      })
      .catch(err => {
        console.log('Error getting document', err)
      })
  }
}

const subscribeLoginUser = userId => {
  return (dispatch, getState) => {
    const doc = firebase
      .firestore()
      .collection('fave-users')
      .doc(userId)
    unsubLoginUser = doc.onSnapshot(docSnapshot => {
      dispatch({
        type: types.FETCH_LOGIN_USER,
        user: deriveUserFromDoc(docSnapshot)
      })
    })
  }
}

const asyncSubscribeLoginUser = userId => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let resolveOnce = doc => {
        resolveOnce = () => undefined // XXX: Copied stack overflow.
        resolve(doc)
      }
      const doc = firebase
        .firestore()
        .collection('fave-users')
        .doc(userId)
      unsubLoginUser = doc.onSnapshot(docSnapshot => {
        dispatch({
          type: types.FETCH_LOGIN_USER,
          user: deriveUserFromDoc(docSnapshot)
        })
        resolveOnce(docSnapshot)
      }, reject)
    })
  }
}

const unsubscribeLoginUser = () => {
  return async (dispatch, getState) => {
    if (unsubLoginUser !== null) unsubLoginUser()
    dispatch({ type: types.DETOUCH_LOGIN_USER })
  }
}

const addUser = user => {
  return (dispatch, getState) => {
    dispatch(addUsers([user]))
  }
}

const addUsers = users => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_CREATE)) return
    const db = firebase.firestore().collection('fave-users')
    users.forEach((user, i) => {
      const dt = new Date()
      dt.setSeconds(dt.getSeconds() + i)
      user.createdAt = dt
      user.lineSyncCode = createLineSyncCode(i)
      db.add(user).catch(error => {
        console.error('Error adding document: ', error)
      })
    })
    dispatch({ type: types.ADD_USER })
  }
}

const updateUser = user => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    const { endAt: prevEndAt, startAt: prevStartAt } = getState().userState.user
    let { endAt: newEndAt, startAt: newStartAt } = user
    if (newStartAt !== undefined) {
      newStartAt = Moment(newStartAt)
        .startOf('date')
        .toDate()
      if (Moment(newStartAt).isAfter(prevEndAt, 'date')) {
        newStartAt = Moment(prevEndAt)
          .startOf('date')
          .toDate()
      }
      user = { ...user, startAt: newStartAt }
    }
    if (newEndAt !== undefined) {
      newEndAt = Moment(newEndAt)
        .endOf('date')
        .toDate()
      if (Moment(newEndAt).isBefore(prevStartAt, 'date')) {
        newEndAt = Moment(prevStartAt)
          .endOf('date')
          .toDate()
      }
      user = { ...user, endAt: newEndAt }
    }
    dispatch({
      type: types.UPDATE_USER,
      user
    })
  }
}

const updateMessageReadAt = userId => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    const ref = firebase
      .firestore()
      .collection('fave-users')
      .doc(userId)
    ref
      .get()
      .then(doc => {
        if (!doc.exists) return
        const user = deriveUserFromDoc(doc)
        user.lastMessageReadAt = new Date()
        ref.update(user)
      })
      .catch(err => {
        console.log('Error getting document', err)
      })
  }
}

const saveUser = user => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    const ref = firebase
      .firestore()
      .collection('fave-users')
      .doc(user.id)
    ref.get().then(doc => {
      ref.update(user)
    })
    dispatch({
      type: types.SAVE_USER,
      user: user
    })
  }
}

const deleteUser = userId => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_DELETE)) return
    firebase
      .firestore()
      .collection('fave-users')
      .doc(userId)
      .delete()
    dispatch({
      type: types.DELETE_USER
    })
  }
}

const setUserListOrder = order => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_USER_LIST_ORDER,
      order
    })
  }
}

const releaseLineUserId = user => {
  return (dispatch, getState) => {
    user = { ...user, lineUserId: '' }
    dispatch(saveUser(user))
  }
}

export default {
  addUser,
  addUsers,
  asyncSubscribeLoginUser,
  clearUser,
  deleteUser,
  fetchUser,
  saveUser,
  subscribeLoginUser,
  subscribeUserList,
  unsubscribeLoginUser,
  unsubscribeUserList,
  updateUser,
  updateMessageReadAt,
  setUserListOrder,
  releaseLineUserId
}

// =============================
// ===== Private Functions =====
// =============================

const deriveUserFromDoc = doc => {
  const data = doc.data()
  const def = types.DEFAULT_USER_STATE
  return {
    createdAt:
      data.createdAt == null || data.createdAt.seconds == null
        ? def.createdAt
        : new Date(data.createdAt.seconds * 1000),
    endAt:
      data.endAt == null || data.endAt.seconds == null
        ? def.endAt
        : new Date(data.endAt.seconds * 1000),
    extraUserInfo:
      data.extraUserInfo == null || Array.isArray(data.extraUserInfo)
        ? def.extraUserInfo
        : data.extraUserInfo,
    id: doc.id,
    isExcludedRecord: data.isExcludedRecord || def.isExcludedRecord,
    lastMessageReadAt:
      data.lastMessageReadAt == null || data.lastMessageReadAt.seconds == null
        ? def.lastMessageReadAt
        : new Date(data.lastMessageReadAt.seconds * 1000),
    lineSyncCode:
      data.lineSyncCode == null ? def.lineSyncCode : data.lineSyncCode,
    lineUserId: data.lineUserId == null ? def.lineUserId : data.lineUserId,
    name: data.name == null ? def.name : data.name,
    projectId: data.projectId == null ? def.projectId : data.projectId,
    startAt:
      data.startAt == null || data.startAt.seconds == null
        ? def.startAt
        : new Date(data.startAt.seconds * 1000)
  }
}

const isPermit = (state, permit) => {
  const adminLevel = state.appState.auth.adminLevel
  return isPermitLevel(adminLevel, appTypes.MODULE_USER, permit)
}

const createLineSyncCode = (idx = 0) => {
  const date = new Date()
  const code = date.getTime() + ('000' + idx).slice(-3)
  const codeArr = [
    code.substr(0, 4),
    code.substr(4, 4),
    code.substr(8, 4),
    code.substr(12, 4)
  ]
  return codeArr.join('-')
}
