// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './InputTime.scss'

const InputTime = ({ id, inputData, setInputData }) => {
  if (inputData === undefined) {
    const defaultVaue = Moment().format('HH:mm')
    setInputData({ [id]: defaultVaue })
  }
  return (
    <div className={classes.content}>
      <input
        className={classes.inputArea}
        onChange={e => setInputData({ [id]: e.target.value })}
        type='time'
        value={inputData || '00:00'}
      />
      <div className={classes.pulldown} />
    </div>
  )
}

InputTime.propTypes = {
  id: PropTypes.string.isRequired,
  inputData: PropTypes.string,
  setInputData: PropTypes.func.isRequired
}

InputTime.defaultProps = {}

export default InputTime
