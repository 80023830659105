// Takram Confidential
// Copyright (C) 2019-Present Takram

import _ from 'lodash'
import { combineReducers } from 'redux'
import uuidv4 from 'uuid'

import * as types from './types'

const project = (state = _.cloneDeep(types.DEFAULT_PROJECT_STATE), action) => {
  switch (action.type) {
    case types.ADD_PROJECT:
      return { ...state, ...action.project }
    case types.FETCH_PROJECT:
      return { ...state, ...action.project }
    case types.DETOUCH_PROJECT:
      return types.DEFAULT_PROJECT_STATE
    case types.UPDATE_PROJECT:
      return { ...state, ...action.params }
    case types.SAVE_PROJECT:
      return state
    case types.CLEAR_PROJECT:
    case types.DELETE_PROJECT:
      return _.cloneDeep(types.DEFAULT_PROJECT_STATE)
    case types.ADD_EXTRA_USER_ATTR:
      return {
        ...state,
        ...{ extraUserAttr: addExtraUserAttr(state.extraUserAttr) }
      }
    case types.UPDATE_EXTRA_USER_ATTR:
      return {
        ...state,
        ...{
          extraUserAttr: updateExtraUserAttr(state.extraUserAttr, action.attr)
        }
      }
    case types.DELETE_EXTRA_USER_ATTR:
      return {
        ...state,
        ...{
          extraUserAttr: deleteExtraUserAttr(state.extraUserAttr, action.attrId)
        }
      }
    case types.ADD_SHARED_ADMIN_ENTRY:
      return {
        ...state,
        ...{ sharedAdminEntries: addSharedAdminEntry(state.sharedAdminEntries) }
      }
    case types.UPDATE_SHARED_ADMIN_ENTRY:
      return {
        ...state,
        ...{
          sharedAdminEntries: updateSharedAdminEntry(
            state.sharedAdminEntries,
            action.entry
          )
        }
      }
    case types.DELETE_SHARED_ADMIN_ENTRY:
      return {
        ...state,
        ...{
          sharedAdminEntries: deleteSharedAdminEntry(
            state.sharedAdminEntries,
            action.entryId
          )
        }
      }
    case types.TOGGLE_PROJECT:
      return state // NoP
    case types.DUPLICATE_PROJECT:
      return { ...state, ...action.project }
    default:
      return state
  }
}

const projectList = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_PROJECT_LIST:
      return action.projectList
    case types.DETOUCH_PROJECT_LIST:
      return []
    default:
      return state
  }
}

const visibilityFilter = (state = types.SHOW_ALL, action) => {
  switch (action.type) {
    case types.SET_VISIBILITY_FILTER:
      return action.filter
    default:
      return state
  }
}

const projectReducer = combineReducers({
  project: project,
  projectList: projectList,
  visibilityFilter: visibilityFilter
})

export default projectReducer

// =============================
// ===== Private Functions =====
// =============================

const addExtraUserAttr = (data, type = types.USER_ATTR_TYPE_TEXT) => {
  const attr = {
    ..._.cloneDeep(types.DEFAULT_EXTRA_USER_ATTR),
    ...{ id: uuidv4(), type }
  }
  data.push(attr)
  return _.cloneDeep(data)
}

const updateExtraUserAttr = (data, attr) => {
  data.forEach((e, i) => {
    if (e.id === attr.id) {
      data[i] = { ...e, ...attr }
    }
  })
  return _.cloneDeep(data)
}
const deleteExtraUserAttr = (data, attrId) => {
  data = data.filter(attr => attr.id !== attrId)
  return _.cloneDeep(data)
}

const addSharedAdminEntry = (data, type = types.SHARED_ADMIN_TYPE_CAN_EDIT) => {
  const attr = {
    ..._.cloneDeep(types.DEFAULT_SHARED_ADMIN_ENTRY),
    ...{ id: uuidv4(), type }
  }
  data.push(attr)
  return _.cloneDeep(data)
}

const updateSharedAdminEntry = (data, entry) => {
  data.forEach((e, i) => {
    if (e.id === entry.id) {
      data[i] = { ...e, ...entry }
    }
  })
  return _.cloneDeep(data)
}
const deleteSharedAdminEntry = (data, entryId) => {
  data = data.filter(entry => entry.id !== entryId)
  return _.cloneDeep(data)
}
