// Takram Confidential
// Copyright (C) 2019-Present Takram

import NameSpace from '../../../common/NameSpace'
import ParserV1 from './parserV1'
import ParserV2 from './parserV2'

export const internal = NameSpace('Parser')

const getParser = scriptState => {
  switch (scriptState.script.version) {
    case 1:
      return new ParserV1(scriptState)
    case 2:
      return new ParserV2(scriptState)
    default:
      return new ParserV1(scriptState)
  }
}

export default {
  getParser
}
