// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import classes from './ListHeader.scss'

const ListHeader = ({ onClickNew, permitCreate }) => (
  <div className={classes.container}>
    <h1>プロジェクト一覧</h1>
    {permitCreate && (
      <div onClick={e => onClickNew(e)} className={classes.newButton}>
        新規作成
      </div>
    )}
  </div>
)

ListHeader.propTypes = {
  onClickNew: PropTypes.func.isRequired,
  permitCreate: PropTypes.bool
}

ListHeader.defaultProps = {
  permitCreate: false
}

export default ListHeader
