// Takram Confidential
// Copyright (C) 2019-Present Takram

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PERMIT_UPDATE } from '../../../constants/permission'
import {
  ACTION_END,
  ACTION_JUMP,
  INPUT_CHECK,
  INPUT_NUMBER,
  INPUT_RADIO,
  OPERATOR_EQUAL,
  OPERATOR_NOT_EQUAL,
  OPERATOR_OVER,
  OPERATOR_UNDER,
  TARGET_ID_ANY
} from '../../../constants/scriptConfig'
import Select from '../../Ui/Select'
import NumberInput from '../../Ui/NumberInput'
import classes from './EntryIfCondition.scss'

import { appSelectors, appTypes } from '../../../modules/app'
import { scriptOperations, scriptSelectors } from '../../../modules/script'

const selStyle = { backgroundColor: '#546b68', color: 'FFF' }

export function EntryIfCondition({ condition }) {
  const dispatch = useDispatch()
  const entryList = useSelector(state =>
    scriptSelectors.entryListSelector(state)
  )
  const itemList = useSelector(state =>
    scriptSelectors.deriveItemList(state.scriptState.script.data)
  )
  const itemTypeMap = useSelector(state =>
    scriptSelectors.itemTypeMapSelector(state)
  )
  const itemValueMap = useSelector(state =>
    scriptSelectors.itemValueMapSelector(state)
  )
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_SCRIPT)
  )
  const onChangeValue = value => {
    dispatch(scriptOperations.updateScriptCondition({ ...condition, value }))
  }
  const onChangeTarget = targetId => {
    const itemType = itemTypeMap[targetId]
    const value =
      itemType === INPUT_RADIO
        ? ''
        : itemType === INPUT_CHECK
        ? ''
        : itemType === INPUT_NUMBER
        ? 0
        : ''
    const newCondition = { ...condition, targetId, value }
    dispatch(scriptOperations.updateScriptCondition(newCondition))
  }
  const onChangeAction = value => {
    const newValue = value === 'escape' ? null : value
    const newType = value === 'escape' ? ACTION_END : ACTION_JUMP
    const newCondition = {
      ...condition,
      action: { type: newType, value: newValue }
    }
    dispatch(scriptOperations.updateScriptCondition(newCondition))
  }
  const onChangeOperator = operator => {
    dispatch(scriptOperations.updateScriptCondition({ ...condition, operator }))
  }
  const onClickDelete = () => {
    dispatch(scriptOperations.deleteScriptCondition(condition.id))
  }

  const itemType = itemTypeMap[condition.targetId]
  const visibleValueSelector =
    itemType === INPUT_RADIO || itemType === INPUT_CHECK
  const visibleValueInputs = itemType === INPUT_NUMBER
  const visibleAction =
    visibleValueSelector ||
    visibleValueInputs ||
    condition.targetId === TARGET_ID_ANY

  const renderTargetSelector = () => {
    const items = [
      { name: '選択してください', value: '' },
      { name: 'どんな時でも', value: TARGET_ID_ANY },
      ...itemList.map(e => ({ name: e.title, value: e.id }))
    ]
    const displayMiddleWord =
      condition.targetId !== TARGET_ID_ANY && condition.targetId !== ''
    return (
      <div className={classes.conditionRow}>
        <Select
          disabled={!isPermit(PERMIT_UPDATE)}
          onChange={e => onChangeTarget(e.target.value)}
          items={items}
          value={condition.targetId}
          style={selStyle}
        />
        {displayMiddleWord && <div className={classes.middleWord}>が</div>}
      </div>
    )
  }

  const renderValueSelector = () => {
    const itemValues = itemValueMap[condition.targetId]
    const items = [
      { name: '選択してください', value: '' },
      ...itemValues.map(v => {
        const name = v.isOthers ? 'その他' : v.text
        return { name, value: v.text }
      })
    ]
    const isCheckType = itemType === INPUT_CHECK
    const operatorItems = [
      { name: isCheckType ? 'が含まれる' : 'と同じ', value: OPERATOR_EQUAL },
      {
        name: isCheckType ? 'が含まれない' : '以外の',
        value: OPERATOR_NOT_EQUAL
      }
    ]
    return (
      <div className={classes.conditionRow}>
        <Select
          disabled={!isPermit(PERMIT_UPDATE)}
          onChange={e => onChangeValue(e.target.value)}
          items={items}
          value={condition.value}
          style={{ ...selStyle, marginRight: 8 }}
        />
        <Select
          disabled={!isPermit(PERMIT_UPDATE)}
          onChange={e => onChangeOperator(e.target.value)}
          items={operatorItems}
          value={condition.operator}
          style={{ ...selStyle, width: 150 }}
        />
        <div className={classes.middleWord}>時</div>
      </div>
    )
  }

  const renderValueInput = () => {
    const operatorItems = [
      { name: 'と同じ', value: OPERATOR_EQUAL },
      { name: '以外の', value: OPERATOR_NOT_EQUAL },
      { name: '以上の', value: OPERATOR_OVER },
      { name: '以下の', value: OPERATOR_UNDER }
    ]
    return (
      <div className={classes.conditionRow}>
        <NumberInput
          disabled={!isPermit(PERMIT_UPDATE)}
          onChange={e => onChangeValue(parseInt(e.target.value))}
          value={condition.value}
          style={{ ...selStyle, border: 'none', marginRight: 8 }}
        />
        <Select
          disabled={!isPermit(PERMIT_UPDATE)}
          onChange={e => onChangeOperator(e.target.value)}
          items={operatorItems}
          value={condition.operator}
          style={{ ...selStyle, width: 150 }}
        />
        <div className={classes.middleWord}>時</div>
      </div>
    )
  }

  const renderActionSelector = () => {
    const { value } = condition.action
    const selectVal = value == null ? 'escape' : value
    const items = [
      { name: '選択してください', value: '' },
      { name: 'アンケート終了', value: 'escape' },
      ...entryList.map(e => ({ name: e.title, value: e.id }))
    ]
    return (
      <div>
        <Select
          disabled={!isPermit(PERMIT_UPDATE)}
          onChange={e => onChangeAction(e.target.value)}
          items={items}
          value={selectVal}
          style={selStyle}
        />
        <div className={classes.middleWord}>に遷移する</div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {isPermit(PERMIT_UPDATE) && (
        <div className={classes.delButton} onClick={e => onClickDelete()}>
          x
        </div>
      )}
      {renderTargetSelector()}
      {visibleValueSelector && renderValueSelector()}
      {visibleValueInputs && renderValueInput()}
      {visibleAction && renderActionSelector()}
    </div>
  )
}

export default EntryIfCondition
