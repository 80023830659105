// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './InputDescription.scss'

const InputDescription = ({ color, size, text }) => (
  <div
    className={ClassNames(classes.content, {
      [classes.medium]: size === 'medium',
      [classes.large]: size === 'large',
      [classes.black]: color === 'black',
      [classes.red]: color === 'red'
    })}
  >
    {text}
  </div>
)

InputDescription.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

InputDescription.defaultProps = {
  color: 'gray',
  size: 'small',
  text: ''
}

export default InputDescription
