// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userOperations } from '../../modules/user'
import { useSnackbar } from 'notistack'

import DataGrid from '../Ui/DataGrid'

const AttrTextGrid = forwardRef(({ attrId }, ref) => {
  const dispatch = useDispatch()
  const { userList } = useSelector(state => state.userState)
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setData(
      userList.map(user => ({
        user: user,
        name: user.name,
        value: user.extraUserInfo.hasOwnProperty(attrId)
          ? user.extraUserInfo[attrId][0]
          : ''
      }))
    )
    setColumns(['name', 'value'])
  }, [userList])

  useImperativeHandle(
    ref,
    () => ({
      save() {
        data.forEach(row => {
          const { user, value } = row
          user.extraUserInfo[attrId] = [value]
          dispatch(userOperations.saveUser(user))
        })
        enqueueSnackbar('変更を保存しました', { variant: 'default' })
      }
    }),
    [data, columns]
  )
  return <DataGrid data={data} columns={columns} />
})

AttrTextGrid.propTypes = {
  attrId: PropTypes.string.isRequired
}

export default AttrTextGrid
