// Takram Confidential
// Copyright (C) 2019-Present Takram

import scriptActions from '../script/actions'
import actions from './actions'

const fetchProject = id => {
  return actions.fetchProject(id)
}

const addProject = project => {
  return actions.addProject(project)
}

const subscribeProjectList = () => dispatch => {
  dispatch(actions.unsubscribeProjectList()).then(() => {
    dispatch(actions.subscribeProjectList())
  })
}

const unsubscribeProjectList = () => {
  return actions.unsubscribeProjectList()
}

const duplicateProject = projectId => (dispatch, getState) => {
  dispatch(actions.duplicateProject(projectId)).then(() => {
    const project = getState().projectState.project
    dispatch(scriptActions.duplicateScripts(projectId, project.id))
  })
}

const setVisibilityFilter = filter => {
  return actions.setVisibilityFilter(filter)
}

const clearProject = () => {
  return actions.clearProject()
}

const updateProject = params => {
  return actions.updateProject(params)
}

const saveProject = project => {
  return actions.saveProject(project)
}

const deleteProject = id => {
  return actions.deleteProject(id)
}

export const addExtraUserAttr = () => {
  return actions.addExtraUserAttr()
}

export const updateExtraUserAttr = attr => {
  return actions.updateExtraUserAttr(attr)
}

export const deleteExtraUserAttr = attrId => {
  return actions.deleteExtraUserAttr(attrId)
}

export const addSharedAdminEntry = () => {
  return actions.addSharedAdminEntry()
}

export const updateSharedAdminEntry = entry => {
  return actions.updateSharedAdminEntry(entry)
}

export const deleteSharedAdminEntry = entryId => {
  return actions.deleteSharedAdminEntry(entryId)
}

export default {
  addProject,
  clearProject,
  deleteProject,
  fetchProject,
  duplicateProject,
  saveProject,
  setVisibilityFilter,
  subscribeProjectList,
  unsubscribeProjectList,
  updateProject,
  addExtraUserAttr,
  updateExtraUserAttr,
  deleteExtraUserAttr,
  addSharedAdminEntry,
  updateSharedAdminEntry,
  deleteSharedAdminEntry
}
