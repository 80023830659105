// Takram Confidential
// Copyright (C) 2019-Present Takram

import _ from 'lodash'
import { combineReducers } from 'redux'

import * as types from './types'

const script = (state = _.cloneDeep(types.DEFAULT_SCRIPT_STATE), action) => {
  switch (action.type) {
    case types.FETCH_SCRIPT:
      // if (action.script.data.length === 0) {
      //   action.script.data = addScriptEntry(action.script.data)
      // }
      return { ...state, ...action.script }
    case types.UPDATE_SCRIPT:
      return { ...state, ...action.script }
    case types.SAVE_SCRIPT:
      return { ...state, ...action.script }
    case types.CLEAR_SCRIPT:
    case types.DELETE_SCRIPT:
      return _.cloneDeep(types.DEFAULT_SCRIPT_STATE)
    default:
      return state
  }
}

const scriptEntryNum = (state = 0, action) => {
  switch (action.type) {
    case types.FETCH_SCRIPT:
      return action.script.data.length
    default:
      return state
  }
}

const currentEntryData = (state = null, action) => {
  switch (action.type) {
    case types.MOVE_NEXT_ENTRY:
      return action.entry
    case types.MOVE_PREV_ENTRY:
      return action.entry
    case types.INIT_CURRENT_ENTRY:
      return action.entry
    case types.CLEAR_CURRENT_ENTRY:
      return null
    default:
      return state
  }
}

const scriptEntryHistory = (state = [], action) => {
  switch (action.type) {
    case types.INIT_CURRENT_ENTRY:
      return [action.entry.id]
    case types.MOVE_NEXT_ENTRY:
      if (action.entry === null) return []
      return [...state, action.entry.id]
    case types.MOVE_PREV_ENTRY:
      state.pop()
      return state
    case types.CLEAR_CURRENT_ENTRY:
      return []
    default:
      return state
  }
}

const scriptList = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_SCRIPT_LIST:
      return action.scriptList
    case types.DETOUCH_SCRIPT_LIST:
      return []
    default:
      return state
  }
}

const scriptReducer = combineReducers({
  currentEntryData,
  script,
  scriptEntryHistory,
  scriptEntryNum,
  scriptList
})

export default scriptReducer
