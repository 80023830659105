// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3i84v1ZjHQHk6LU7h_3XWT{background-color:#425c5b;color:#fff;border-radius:10px;margin:24px 0;padding:32px}._3i84v1ZjHQHk6LU7h_3XWT ._1-y0VBi1dLUohleO12CM3j{font-size:18px;width:100%;border-bottom:solid 1px #919D99;padding:0 0 12px 0;margin:0 0 24px 0}", ""]);
// Exports
exports.locals = {
	"container": "_3i84v1ZjHQHk6LU7h_3XWT",
	"entryTitle": "_1-y0VBi1dLUohleO12CM3j"
};
module.exports = exports;
