import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { makeStyles } from '@material-ui/core/styles'
// import classes from './SelectUserList.scss'
import { mergeClasses } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 120,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-root': {
      height: 44,
      borderRadius: 8,
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.1)'
      },
      '&:hover fieldset': {
        borderColor: '#425c5b'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#425c5b'
      },
      '&.MuiPaper-root': {
        zIndex: 10000
      }
    },
    '& .MuiFormControl-marginNormal': {
      margin: '0'
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '0 9px'
    }
  }
}))

export function MuiAutoComplete(props) {
  const classes = useStyles()
  return (
    <div>
      <Autocomplete
        {...props}
        id='free-solo-demo'
        options={props.options}
        onChange={props.onChange}
        classes={mergeClasses({
          baseClasses: classes,
          newClasses: props.classes
        })}
        renderInput={params => (
          <TextField
            {...params}
            label={props.label}
            margin='normal'
            variant='outlined'
          />
        )}
      />
    </div>
  )
}

MuiAutoComplete.defaultProps = {
  color: 'primary',
  fullWidth: false,
  label: '',
  options: [],
  value: '',
  disabled: false,
  onChange: () => {}
}

export default MuiAutoComplete
