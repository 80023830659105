// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tc3YnkkM8GlcTzZjg60dS{display:grid;grid-template-columns:200px 1fr;background-color:#FFF;border-radius:8px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;text-decoration:none;overflow:hidden}.tc3YnkkM8GlcTzZjg60dS ._1nuSiOeuHSJTyPq63YCr07{position:relative;border-right:1px solid #E8ECEE}.tc3YnkkM8GlcTzZjg60dS ._1nuSiOeuHSJTyPq63YCr07 ._372csHDyi0n8WcMxmTQ1q{box-sizing:border-box;height:82px;padding:16px 24px;border-bottom:1px solid #E8ECEE}.tc3YnkkM8GlcTzZjg60dS ._1nuSiOeuHSJTyPq63YCr07 ._372csHDyi0n8WcMxmTQ1q:last-child{border:none}.tc3YnkkM8GlcTzZjg60dS ._1nuSiOeuHSJTyPq63YCr07 ._372csHDyi0n8WcMxmTQ1q .LPy5WQW-N_sf_XXEgKvdK{height:25px;line-height:25px;font-weight:bold}.tc3YnkkM8GlcTzZjg60dS ._1nuSiOeuHSJTyPq63YCr07 ._372csHDyi0n8WcMxmTQ1q ._1oUmY0zMAGROPsdQto6o1m{height:25px;line-height:25px}.tc3YnkkM8GlcTzZjg60dS ._1nuSiOeuHSJTyPq63YCr07 ._372csHDyi0n8WcMxmTQ1q._3GzMskSI5WTy4jFqd74AEH{background-color:#425c5b;color:#FFF}.tc3YnkkM8GlcTzZjg60dS ._1nuSiOeuHSJTyPq63YCr07 ._2nAHka6yQ3mmL7BaYOsuh8{position:absolute;left:20px;bottom:20px}.tc3YnkkM8GlcTzZjg60dS ._1pY0GtiL4-7lCIrt7qIIes{height:calc(100vh - 288px);display:grid;grid-template-rows:84px 1fr}.tc3YnkkM8GlcTzZjg60dS ._1pY0GtiL4-7lCIrt7qIIes ._3Hehqn3IvyAhryWy9br012{position:relative;height:84px}.tc3YnkkM8GlcTzZjg60dS ._1pY0GtiL4-7lCIrt7qIIes ._3Hehqn3IvyAhryWy9br012 ._2yQjrLOOXAiqzdgYJVrC6a{position:absolute;top:20px;right:20px}.tc3YnkkM8GlcTzZjg60dS ._1pY0GtiL4-7lCIrt7qIIes ._3Hehqn3IvyAhryWy9br012 ._2yQjrLOOXAiqzdgYJVrC6a>*{margin-left:8px;float:left}", ""]);
// Exports
exports.locals = {
	"container": "tc3YnkkM8GlcTzZjg60dS",
	"attrList": "_1nuSiOeuHSJTyPq63YCr07",
	"attrRow": "_372csHDyi0n8WcMxmTQ1q",
	"attrTitle": "LPy5WQW-N_sf_XXEgKvdK",
	"attrType": "_1oUmY0zMAGROPsdQto6o1m",
	"selected": "_3GzMskSI5WTy4jFqd74AEH",
	"attrEditButton": "_2nAHka6yQ3mmL7BaYOsuh8",
	"attrContainer": "_1pY0GtiL4-7lCIrt7qIIes",
	"header": "_3Hehqn3IvyAhryWy9br012",
	"actions": "_2yQjrLOOXAiqzdgYJVrC6a"
};
module.exports = exports;
