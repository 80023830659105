// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { history } from '../../configureStore'
import OverlayFrame from '../App/OverlayFrame'
import Forms from './Forms'
import { PERMIT_UPDATE } from '../../constants/permission'
import { appSelectors, appTypes } from '../../modules/app'
import { projectOperations } from '../../modules/project'

import AttrForms from './AttrForms'

export function AttrOverlay({ onClickCancel }) {
  const dispatch = useDispatch()
  const project = useSelector(state => state.projectState.project)
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_PROJECT)
  )

  const onClickSave = () => {
    dispatch(projectOperations.saveProject(project))
    onClickCancel()
  }

  return (
    <OverlayFrame
      title='被験者属性を編集'
      onClickClose={e => onClickCancel()}
      buttons={[
        {
          label: '保存',
          onClick: () => onClickSave(),
          accent: true
        }
      ]}
    >
      <AttrForms project={project} permitUpdate={isPermit(PERMIT_UPDATE)} />
    </OverlayFrame>
  )
}

export default AttrOverlay
