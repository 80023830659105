import PropTypes from 'prop-types'
import React from 'react'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { mergeClasses } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1em',
    borderRadius: 44,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    height: 44
  },
  contained: {
    backgroundColor: '#FFFFFF',
    color: '#222',
    boxShadow: 'none',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#E8ECEE',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#FFF'
    }
  },
  containedPrimary: {
    backgroundColor: '#425c5b',
    color: '#FFF',
    borderWidth: 0,
    '&:hover': {
      backgroundColor: '#425c5b'
    }
  },
  containedSecondary: {
    backgroundColor: '#EAAD68',
    borderColor: '#EAAD68',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#EAAD68'
    }
  },
  sizeSmall: {
    height: 40
  },
  sizeLarge: {
    height: 58
  }
}))

export const MuiButton = props => {
  const classes = useStyles()
  return (
    <Button
      {...props}
      classes={mergeClasses({
        baseClasses: classes,
        newClasses: props.classes
      })}
    >
      {props.text}
    </Button>
  )
}

MuiButton.defaultProps = {
  color: 'default',
  fullWidth: false,
  size: 'medium',
  text: 'Default',
  variant: 'contained',
  onClick: () => {}
}

MuiButton.propTypes = {
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func
}

export default MuiButton
