// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import classes from './TimeLog.scss'

const TimeLog = ({ time }) => <div className={classes.container}>{time}</div>

TimeLog.propTypes = {
  time: PropTypes.string.isRequired
}

TimeLog.defaultProps = {
  time: '00:00'
}

export default TimeLog
