// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'

import { projectOperations } from '../../modules/project'

import classes from './AttrForms.scss'

import SectionRow from '../Ui/SectionRow'
import TextInput from '../Ui/TextInput'
import MuiButton from '../Ui/MuiButton'

import {
  PROJECT_TYPES,
  USER_ATTR_TYPES,
  getAttrTypeDescription
} from '../../modules/project/types'
import Select from '../Ui/Select'

const attrItems = USER_ATTR_TYPES.map(t => ({ name: t.name, value: t.type }))

export function AttrForms({ project, permitUpdate }) {
  const dispatch = useDispatch()

  const onAddUserAttr = () => {
    dispatch(projectOperations.addExtraUserAttr())
  }

  const onUpdateUserAttr = attr => {
    dispatch(projectOperations.updateExtraUserAttr(attr))
  }

  const onDeleteUserAttr = attrId => {
    dispatch(projectOperations.deleteExtraUserAttr(attrId))
  }

  const renderAttr = attr => (
    <div className={classes.attr}>
      <div className={classes.form}>
        <TextInput
          disabled={!permitUpdate}
          style={{ width: '100%' }}
          onChange={e => {
            onUpdateUserAttr({ ...attr, ...{ title: e.target.value } })
          }}
          type='text'
          value={attr.title === undefined ? '' : attr.title}
        />
        <Select
          disabled={!permitUpdate}
          items={attrItems}
          value={attr.type}
          style={{ width: '100%' }}
          onChange={e => {
            onUpdateUserAttr({ ...attr, ...{ type: e.target.value } })
          }}
        />
        {permitUpdate && (
          <div
            className={classes.delButton}
            onClick={e => onDeleteUserAttr(attr.id)}
          />
        )}
      </div>
      <div className={classes.description}>
        ※ {getAttrTypeDescription(attr.type)}
      </div>
    </div>
  )

  return (
    <div className={classes.container}>
      <SectionRow label='被験者属性' vertical>
        {project.extraUserAttr.map(attr => (
          <div key={attr.id}>
            {renderAttr(attr, onUpdateUserAttr, onDeleteUserAttr, permitUpdate)}
          </div>
        ))}
        {permitUpdate && (
          <MuiButton
            text='＋ 被験者属性を追加する'
            style={{ height: 20 }}
            onClick={e => onAddUserAttr(e)}
          />
        )}
      </SectionRow>
    </div>
  )
}

AttrForms.propTypes = {
  permitUpdate: PropTypes.bool.isRequired,
  project: PropTypes.shape(PROJECT_TYPES)
}

AttrForms.defaultProps = {}

export default AttrForms
