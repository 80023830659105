import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CloseImage from '../../../assets/images/icon/admin/close.svg'
import * as messageTypes from '../../modules/message/types'
import FlexMessagePreview from './FlexMessagePreview'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    color: '#384241',
    fontSize: 20
  },
  closeButton: {
    backgroundImage: `url("${CloseImage}")`,
    backgroundSize: '100%',
    cursor: 'pointer',
    float: 'right',
    height: 18,
    width: 18
  },
  paper: {
    borderRadius: 10,
    padding: '30 30 40 30',
    backgroundColor: 'white',
    '&:focus': {
      outline: 'none'
    }
  },
  wrap: {
    marginTop: 20,
    borderRadius: 20,
    border: '1px solid #E8ECEE',
    padding: '20px',
    maxWidth: 438,
    minWidth: 200,
    minHeight: 40,
    wordBreak: 'break-all',
    whiteSpace: 'pre-line',
    textAlign: 'left'
  },
  footer: {
    color: '#919D99',
    fontSize: 12,
    paddingTop: 10
  }
}))

export const FlexMessagPreviewModal = ({
  messageContents,
  openPreview,
  setOpenPreview
}) => {
  const classes = useStyles()
  return (
    <Modal
      open={openPreview}
      onClose={() => setOpenPreview(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className={classes.root}
    >
      <div className={classes.paper}>
        <div className={classes.header}>
          プレビュー
          <div
            onClick={() => setOpenPreview(false)}
            className={classes.closeButton}
          />
        </div>
        <div className={classes.wrap}>
          <FlexMessagePreview messageContents={messageContents} />
        </div>
        <div className={classes.footer}>
          ※ 実際に表示される画面とはサイズや色などが異なります。
        </div>
      </div>
    </Modal>
  )
}

FlexMessagPreviewModal.propTypes = {
  messageContents: messageTypes.FLEX_MESSAGE_TYPES,
  openPreview: PropTypes.bool.isRequired,
  setOpenPreview: PropTypes.func.isRequired
}

export default FlexMessagPreviewModal
