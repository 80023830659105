import React from 'react'

const inputStyle = {
  margin: 0,
  padding: '0 16px',
  background: 'none',
  border: 'solid 1px #E8ECEE',
  borderRadius: 8,
  outline: 'none',
  appearance: 'none',
  height: 44,
  fontSize: 16
}

export function TextInput({ value, placeholder, onChange, disabled, style }) {
  return (
    <input
      style={{ ...inputStyle, ...style }}
      placeholder={placeholder}
      disabled={disabled ? 'disabled' : ''}
      onChange={onChange}
      type='text'
      value={value == null ? '' : value}
    />
  )
}

TextInput.defaultProps = {
  value: '',
  placeholder: '',
  disabled: false,
  style: {},
  onChange: () => {}
}

export default TextInput
