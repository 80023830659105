// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import * as types from '../../modules/project/types'

import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { history } from '../../configureStore'
import { PERMIT_DELETE, PERMIT_UPDATE } from '../../constants/permission'
import { appSelectors, appTypes } from '../../modules/app'
import { projectOperations } from '../../modules/project'
import { userSelectors } from '../../modules/user'
import MuiContextMenu from '../Ui/MuiContextMenu'
import MuiDialog from '../Ui/MuiDialog'

import Thumbnail from './Thumbnail'

import classes from './List.scss'

export function List() {
  const dispatch = useDispatch()
  const projectList = useSelector(state => state.projectState.projectList)
  const menuRef = useRef()
  const dialogRef = useRef()
  const [menuItems, setMenuItems] = useState([])
  const visibilityFilter = useSelector(
    state => state.projectState.visibilityFilter
  )
  const userTermCounts = useSelector(state =>
    userSelectors.deriveUserTermCountByProject(state)
  )
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_PROJECT)
  )
  const hideContextMenu = !isPermit(PERMIT_UPDATE) && !isPermit(PERMIT_DELETE)

  const onClickProject = projectId => {
    history.push(`/project/${projectId}`)
  }

  const onDeleteProject = projectId => {
    dispatch(projectOperations.deleteProject(projectId))
  }

  const onOpenDeleteDialog = projectId => {
    dialogRef.current.handleOpen(projectId)
  }

  const onClickDuplicate = projectId => {
    dispatch(projectOperations.duplicateProject(projectId))
  }

  const onClickArchive = project => {
    project.archived = !project.archived
    dispatch(projectOperations.saveProject(project))
  }

  const onClickContextMenu = (e, project) => {
    const items = [
      { name: '削除', onClick: project => onOpenDeleteDialog(project.id) },
      { name: '複製', onClick: project => onClickDuplicate(project.id) },
      {
        name: project.archived ? 'アクティブ化' : 'アーカイブ',
        onClick: project => onClickArchive(project)
      }
    ]
    setMenuItems(items)
    menuRef.current.handleOpen(e, project)
  }

  const onClickFavorite = (e, project) => {
    project.favorite = !project.favorite
    dispatch(projectOperations.saveProject(project))
  }

  const onClickListMenu = e => {
    dispatch(projectOperations.setVisibilityFilter(e))
  }

  return (
    <div className={classes.container}>
      <MuiContextMenu ref={menuRef} items={menuItems} />
      <MuiDialog
        title='プロジェクト削除'
        text='プロジェクトを削除してもよろしいですか？'
        ref={dialogRef}
        buttons={[
          { name: '削除する', value: id => onDeleteProject(id) },
          { name: '削除しない', value: () => {} }
        ]}
      />
      <div className={classes.listMenu}>
        <p
          className={ClassNames({
            [classes.active]: visibilityFilter === types.SHOW_ALL
          })}
          onClick={e => onClickListMenu(types.SHOW_ALL)}
        >
          Active
        </p>
        <p
          className={ClassNames({
            [classes.active]: visibilityFilter === types.SHOW_FAVORITE
          })}
          onClick={e => onClickListMenu(types.SHOW_FAVORITE)}
        >
          Favorite
        </p>
        <p
          className={ClassNames({
            [classes.active]: visibilityFilter === types.SHOW_ARCHIVED
          })}
          onClick={e => onClickListMenu(types.SHOW_ARCHIVED)}
        >
          Archived
        </p>
      </div>
      <div className={classes.thumbnailAll}>
        {projectList.map(project => (
          <div
            className={ClassNames(classes.project, {
              [classes.hidden]: !(
                (visibilityFilter === types.SHOW_ALL && !project.archived) ||
                (visibilityFilter === types.SHOW_FAVORITE &&
                  project.favorite &&
                  !project.archived) ||
                (visibilityFilter === types.SHOW_ARCHIVED && project.archived)
              )
            })}
            key={project.id}
          >
            <Thumbnail
              {...project}
              userTermCount={userTermCounts[project.id]}
              onClickProject={e => {
                onClickProject(project.id)
              }}
              onClickContextMenu={e => {
                e.stopPropagation()
                onClickContextMenu(e, project)
              }}
              onClickFavorite={e => {
                e.stopPropagation()
                onClickFavorite(e, project)
              }}
              hideContextMenu={hideContextMenu}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default List
