// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/icon/user/calender.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._2Ps1r8tZHevnmJKD8uDReX{position:absolute;bottom:0px;left:0px;right:0px}._2Ps1r8tZHevnmJKD8uDReX .PnlRQwKomNFHKhD_0kiuP{height:66px;background-color:#273B3A;display:flex;justify-content:center;align-items:center}._2Ps1r8tZHevnmJKD8uDReX .PnlRQwKomNFHKhD_0kiuP .gB-9dHFQIKG2nwewKUnsi{color:white;font-size:16px;display:inline}._2Ps1r8tZHevnmJKD8uDReX .PnlRQwKomNFHKhD_0kiuP .gB-9dHFQIKG2nwewKUnsi .TYXL0Af55552H6-8tlZw9{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;width:26px;height:24px;float:left;margin:0 10px 0 0}._2Ps1r8tZHevnmJKD8uDReX._27uUSEB8k-K2NtJAuQENaY{display:none}", ""]);
// Exports
exports.locals = {
	"container": "_2Ps1r8tZHevnmJKD8uDReX",
	"item": "PnlRQwKomNFHKhD_0kiuP",
	"content": "gB-9dHFQIKG2nwewKUnsi",
	"icon": "TYXL0Af55552H6-8tlZw9",
	"hidden": "_27uUSEB8k-K2NtJAuQENaY"
};
module.exports = exports;
