// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2jCj3ZeS60YMSb3T0_YHva{background-color:#425c5b;font-size:15px;height:100%;overflow:hidden;position:fixed;top:0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:100%}._2jCj3ZeS60YMSb3T0_YHva ._1F4aB_vDIkIpBNoGxKm2tQ{width:1px;height:100%;position:fixed;left:40px;background-color:#546b68;z-index:-10}", ""]);
// Exports
exports.locals = {
	"root": "_2jCj3ZeS60YMSb3T0_YHva",
	"border": "_1F4aB_vDIkIpBNoGxKm2tQ"
};
module.exports = exports;
