// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { scriptOperations } from '../../../modules/script'
import { appSelectors, appTypes } from '../../../modules/app'
import { TYPE_PAGE, TYPE_IF } from '../../../constants/scriptConfig'
import { PERMIT_UPDATE } from '../../../constants/permission'
import classes from './ToolBox.scss'
import classNames from 'classnames'

export function ToolBox({ entry, index }) {
  const dispatch = useDispatch()
  const script = useSelector(state => state.scriptState.script)
  const canDelete = script.data.length > 1
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_SCRIPT)
  )
  const toolRef = useRef(null)
  const [isLeftLabel, setIsLeftLabel] = useState(false)
  useEffect(() => {
    const f = () =>
      setIsLeftLabel(toolRef.current.getBoundingClientRect().x < 910)
    f()
    window.addEventListener('resize', f)
    return () => window.removeEventListener('resize', f)
  }, [])

  const onClickAddPage = e => {
    dispatch(scriptOperations.addScriptEntry(TYPE_PAGE, index + 1))
  }

  const onClickAddIf = e => {
    dispatch(scriptOperations.addScriptEntry(TYPE_IF, index + 1))
  }

  const onClickAddItem = e => {
    if (entry.type === TYPE_PAGE) {
      dispatch(scriptOperations.addScriptItem(entry.id))
    } else if (entry.type === TYPE_IF) {
      dispatch(scriptOperations.addScriptCondition(entry.id))
    }
  }

  const onClickDelete = e => {
    dispatch(scriptOperations.deleteScriptEntry(entry.id))
  }

  const onClickSwapPrev = e => {
    dispatch(scriptOperations.swapScriptEntry(entry.id, -1))
  }

  const onClickSwapNext = e => {
    dispatch(scriptOperations.swapScriptEntry(entry.id, +1))
  }
  const labelClass = classNames(classes.label, {
    [classes.leftLabel]: isLeftLabel
  })

  if (!isPermit(PERMIT_UPDATE)) return <></>
  return (
    <div className={classes.container} ref={toolRef}>
      <div
        className={` ${classes.item} ${classes.itemAddPage} `}
        onClick={e => onClickAddPage(e)}
      >
        <div className={labelClass}>ページを追加</div>
      </div>
      <div
        className={` ${classes.item} ${classes.itemAddIf} `}
        onClick={e => onClickAddIf(e)}
      >
        <div className={labelClass}>条件を追加</div>
      </div>
      <div
        className={` ${classes.item} ${classes.itemUp} `}
        onClick={e => onClickSwapPrev(e)}
      >
        <div className={labelClass}>上に移動</div>
      </div>
      <div
        className={` ${classes.item} ${classes.itemDown} `}
        onClick={e => onClickSwapNext(e)}
      >
        <div className={labelClass}>下に移動</div>
      </div>
      <div
        className={` ${classes.item} ${classes.itemAddItem} `}
        onClick={e => onClickAddItem(e)}
      >
        <div className={labelClass}>項目を追加</div>
      </div>
      {canDelete && (
        <div
          className={` ${classes.item} ${classes.itemDelete} `}
          onClick={e => onClickDelete(e)}
        >
          <div className={labelClass}>項目を削除</div>
        </div>
      )}
    </div>
  )
}

export default ToolBox
