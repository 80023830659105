// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import * as scriptCondition from '../../constants/scriptCondition'
import * as scriptTypes from '../../modules/script/types'

import classes from './RecordList.scss'

const RecordList = ({
  onSelectRecordLog,
  onStartRequiredScript,
  dailyRecordAndRequiredList,
  scriptList
}) => (
  <div
    className={ClassNames(classes.container, {
      [classes.alert]: dailyRecordAndRequiredList.some(
        ({ id, requiredDate }) =>
          id === undefined && Moment().isAfter(requiredDate)
      )
    })}
  >
    <div className={classes.content}>
      {dailyRecordAndRequiredList.map(record => {
        const script = scriptList.find(({ id }) => id === record.scriptId)
        if (script === undefined) return ''
        const isRequired =
          script.condition.type === scriptCondition.SCRIPT_TYPE_REQUIRED
        const isStarted = isRequired && record.requiredDate < new Date()
        const notAnswered = isRequired && record.id === undefined
        return (
          <div
            className={ClassNames(classes.record, {
              [classes.isRequired]: isRequired
            })}
            key={isRequired ? script.id + record.requiredId : record.id}
          >
            <div className={classes.background}>
              <div className={classes.time}>
                {record.requiredDate !== undefined
                  ? Moment(record.requiredDate).format('HH:mm')
                  : Moment(record.createdAt).format('HH:mm')}
              </div>
              <div className={classes.timeline}>
                <div className={classes.tick} />
              </div>

              <div
                className={ClassNames(classes.pannel, {
                  [classes.isStarted]: isStarted,
                  [classes.notStarted]: !isStarted,
                  [classes.notAnswered]: notAnswered
                })}
                onClick={() => {
                  if (isStarted && notAnswered) onStartRequiredScript(record)
                  if (!notAnswered) onSelectRecordLog({ record, script })
                }}
              >
                <div
                  className={ClassNames(classes.icon, {
                    [classes.answered]: !notAnswered,
                    [classes.notAnswered]: notAnswered,
                    [classes.notStarted]: !isStarted
                  })}
                />

                <div className={classes.title}>
                  {script.title}
                  <br />
                  <div className={classes.sentence}>
                    {!isRequired
                      ? 'に回答しました'
                      : !isStarted
                      ? ''
                      : !notAnswered
                      ? 'に回答しました'
                      : ''}
                  </div>
                </div>
                <div className={classes.answerBtn}>
                  {!isRequired
                    ? ''
                    : !isStarted
                    ? ''
                    : !notAnswered
                    ? ''
                    : '回答する'}
                </div>
                <div
                  className={ClassNames(classes.arrow, {
                    [classes.answered]: !notAnswered,
                    [classes.notAnswered]: notAnswered,
                    [classes.notStarted]: !isStarted
                  })}
                />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

RecordList.propTypes = {
  onSelectRecordLog: PropTypes.func.isRequired,
  onStartRequiredScript: PropTypes.func.isRequired,
  dailyRecordAndRequiredList: PropTypes.arrayOf(
    PropTypes.shape({
      coordinates: PropTypes.object,
      createdAt: PropTypes.instanceOf(Date),
      data: PropTypes.object,
      dataTypes: PropTypes.object,
      id: PropTypes.string,
      projectId: PropTypes.string,
      requiredDate: PropTypes.instanceOf(Date),
      requiredId: PropTypes.string.isRequired,
      scriptId: PropTypes.string.isRequired,
      userId: PropTypes.string
    })
  ).isRequired,
  scriptList: PropTypes.arrayOf(
    PropTypes.shape(scriptTypes.SCRIPT_TYPES).isRequired
  ).isRequired
}

RecordList.defaultProps = {}

export default RecordList
