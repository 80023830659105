// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon/admin/plus-green-large.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._2g382POd2mHuisn0YWAn0u{width:100%;margin:40px 0}._2g382POd2mHuisn0YWAn0u ._3EBNidGDJxkuFNCtBFL00U{margin-bottom:16px}._2g382POd2mHuisn0YWAn0u ._1YxKi1OUJMoX7uSv3BimmC{position:relative;margin-top:12px;height:60px;background-color:#FFF;text-align:center;line-height:60px;font-size:40px;font-weight:100;color:#BDBDBD;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer;box-sizing:border-box;border-radius:10px}._2g382POd2mHuisn0YWAn0u ._1YxKi1OUJMoX7uSv3BimmC ._1kWbrGJWcbB-s8X50B8GXP{position:absolute;top:50%;left:50%;transform:translateY(-50%) translateX(-50%);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:20px;width:20px;height:20px;margin:auto auto}._2g382POd2mHuisn0YWAn0u ._1YxKi1OUJMoX7uSv3BimmC:hover{background-color:#e2ebea}._2g382POd2mHuisn0YWAn0u ._1YxKi1OUJMoX7uSv3BimmC:active{background-color:#AAA}", ""]);
// Exports
exports.locals = {
	"container": "_2g382POd2mHuisn0YWAn0u",
	"title": "_3EBNidGDJxkuFNCtBFL00U",
	"addButton": "_1YxKi1OUJMoX7uSv3BimmC",
	"addIcon": "_1kWbrGJWcbB-s8X50B8GXP"
};
module.exports = exports;
