// Takram Confidential
// Copyright (C) 2019-Present Takram

import permissions from './permissions.json'

export const LOGIN_SYSTEM_ADMIN = 'systemAdmin'
export const LOGIN_PROJECT_ADMIN = 'projectAdmin'
export const LOGIN_SCRIPT_ADMIN = 'scriptAdmin'
export const LOGIN_OPERATION_ADMIN = 'operationAdmin'
export const LOGIN_USER = 'user'
export const LOGIN_NONE = 'USER_NONE'

export const PERMIT_CREATE = 'create'
export const PERMIT_READ = 'read'
export const PERMIT_UPDATE = 'update'
export const PERMIT_DELETE = 'delete'
export const PERMIT_ALL = 'all'

export const getLoginTypeFromLevel = level => {
  switch (level) {
    case 5:
      return LOGIN_SYSTEM_ADMIN
    case 3:
      return LOGIN_PROJECT_ADMIN
    case 2:
      return LOGIN_SCRIPT_ADMIN
    case 1:
      return LOGIN_OPERATION_ADMIN
    case 0:
      return LOGIN_USER
    default:
      return LOGIN_NONE
  }
}

export const getLoginLevelFromType = type => {
  switch (type) {
    case LOGIN_SYSTEM_ADMIN:
      return 5
    case LOGIN_PROJECT_ADMIN:
      return 3
    case LOGIN_SCRIPT_ADMIN:
      return 2
    case LOGIN_OPERATION_ADMIN:
      return 1
    case LOGIN_USER:
      return 0
    default:
      return 0
  }
}

export const isPermit = (loginType, module, permit) => {
  if (!Object.prototype.hasOwnProperty.call(permissions, loginType)) {
    console.error(`Cannot find loginType '${loginType}' in permissions`)
    return false
  }
  const permission = permissions[loginType].find(elem => elem.module === module)
  if (permission == null) {
    console.error(`Cannot find module '${module}' in permissions`)
    return false
  }
  if (!Object.prototype.hasOwnProperty.call(permission, 'permits')) {
    console.error(
      `Cannot find '${loginType}/permits' in '${module}' module permission`
    )
    return false
  }
  const permits = permission.permits
  return permits.includes('all') || permits.includes(permit)
}

export const isPermitLevel = (adminLevel, module, permit) => {
  return isPermit(getLoginTypeFromLevel(adminLevel), module, permit)
}
