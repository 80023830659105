// Takram Confidential
// Copyright (C) 2019-Present Takram

import { createSelector } from 'reselect'

import * as types from './types'

const adminUserListSelector = state => state.adminUserState.adminUserList
const adminUserListOrderSelector = state =>
  state.adminUserState.adminUserListOrder

const deriveDisplayAdminUserList = createSelector(
  [adminUserListSelector, adminUserListOrderSelector],
  (list, order) => {
    switch (order) {
      case types.ORDER_BY_NAME_ASC:
        return list.sort((a, b) => (a.adminName > b.adminName ? 1 : -1))
      case types.ORDER_BY_NAME_DESC:
        return list.sort((a, b) => (a.adminName < b.adminName ? 1 : -1))
      case types.ORDER_BY_CREATED_AT_ASC:
        return list.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
      case types.ORDER_BY_CREATED_AT_DESC:
        return list.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      case types.ORDER_BY_LEVEL_ASC:
        return list.sort((a, b) => (a.level > b.level ? 1 : -1))
      case types.ORDER_BY_LEVEL_DESC:
        return list.sort((a, b) => (a.level < b.level ? 1 : -1))
      default:
        return list.sort((a, b) => (a.adminName > b.adminName ? 1 : -1))
    }
  }
)

export default {
  deriveDisplayAdminUserList
}
