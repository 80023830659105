// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { messageOperations, messageSelectors } from '../../modules/message'
import { projectOperations } from '../../modules/project'
import { userOperations } from '../../modules/user'
import * as types from '../../modules/project/types'

import classes from './Header.scss'

export default function Header({ projectId }) {
  const dispatch = useDispatch()
  const project = useSelector(state => state.projectState.project)
  const unreadMessageCounts = useSelector(state =>
    messageSelectors.unreadMessageCountsSelector(state)
  )

  useEffect(() => {
    dispatch(projectOperations.fetchProject(projectId))
    dispatch(messageOperations.subscribeMessageList(projectId))
    dispatch(userOperations.subscribeUserList(projectId))
    return () => {
      dispatch(projectOperations.clearProject())
      dispatch(messageOperations.unsubscribeMessageList(projectId))
      dispatch(userOperations.unsubscribeUserList(projectId))
    }
  }, [projectId, dispatch])

  const unReadAmount = unreadMessageCounts.amount
  const location = useLocation()
  const path = location.pathname.includes('/record/')
    ? 'record'
    : location.pathname.includes('/user/')
    ? 'user'
    : location.pathname.includes('/message/')
    ? 'message'
    : location.pathname.includes('/setting/')
    ? 'setting'
    : 'script'

  return (
    <div className={classes.container}>
      <Link to='/'>
        <div className={classes.backButton} />
      </Link>
      <div className={classes.headerContainer}>
        <div className={classes.title}>{project.title}</div>
        <div className={classes.description}>{project.description}</div>
        <div className={classes.menu}>
          <Link to={`/project/${project.id}`}>
            <div
              className={ClassNames(classes.menuItem, {
                [classes.current]: path === 'script'
              })}
            >
              質問
            </div>
          </Link>
          <Link to={`/project/${project.id}/record/`}>
            <div
              className={ClassNames(classes.menuItem, {
                [classes.current]: path === 'record'
              })}
            >
              回答
            </div>
          </Link>
          <Link to={`/project/${project.id}/user/`}>
            <div
              className={ClassNames(classes.menuItem, {
                [classes.current]: path === 'user'
              })}
            >
              被験者
            </div>
          </Link>
          <Link to={`/project/${project.id}/message/`}>
            <div
              className={ClassNames(classes.menuItem, {
                [classes.current]: path === 'message'
              })}
            >
              メッセージ
              {unReadAmount !== 0 && (
                <div className={classes.badge}>{unReadAmount}</div>
              )}
            </div>
          </Link>
          <Link to={`/project/${project.id}/setting/`}>
            <div
              className={ClassNames(classes.menuItem, {
                [classes.current]: path === 'setting'
              })}
            >
              設定
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  project: PropTypes.shape(types.PROJECT_TYPES)
}
