// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './InputCommon.scss'

const InputCommon = ({ children, required, singleItem, title }) => (
  <div
    className={ClassNames(classes.container, { [classes.middle]: singleItem })}
  >
    <div className={classes.item}>
      <div className={classes.label}>
        <div className={classes.labeltitle}>{title}</div>
        <div className={ClassNames({ [classes.required]: required })}>
          {required ? '*' : ''}
        </div>
      </div>
      {children}
    </div>
  </div>
)

InputCommon.propTypes = {
  required: PropTypes.bool.isRequired,
  singleItem: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
}

InputCommon.defaultProps = {
  required: false,
  singleItem: false,
  title: ''
}

export default InputCommon
