// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".KF7dyWIH_3BmOc-UVjrFg{width:100%;height:100%;font-size:14px;box-sizing:border-box;color:#384241}.KF7dyWIH_3BmOc-UVjrFg h2{font-size:32px;font-weight:bold}.KF7dyWIH_3BmOc-UVjrFg h2{font-size:24px;font-weight:500}.KF7dyWIH_3BmOc-UVjrFg h3{font-size:18px;font-weight:500;margin:0 0 16px 0}.KF7dyWIH_3BmOc-UVjrFg br{line-height:16px}", ""]);
// Exports
exports.locals = {
	"root": "KF7dyWIH_3BmOc-UVjrFg"
};
module.exports = exports;
