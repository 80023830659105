// Takram Confidential
// Copyright (C) 2019-Present Takram

import actions from './actions'

const clearCurrentRecord = () => {
  return actions.clearCurrentRecord()
}

const saveCurrentRecord = () => {
  return dispatch => {
    dispatch(actions.setCreatedAt())
    dispatch(actions.saveCurrentRecord())
  }
}

const saveCurrentRecordWithCoord = () => {
  return dispatch => {
    dispatch(actions.setCreatedAt())
    dispatch(actions.saveCurrentRecordWithCoord())
  }
}

const subscribeRecordList = constants => {
  return actions.subscribeRecordList(constants)
}

const asyncSubscribeRecordList = constants => {
  return async dispatch => {
    dispatch(actions.unsubscribeRecordList())
    await dispatch(actions.asyncSubscribeRecordList(constants))
  }
}

const setLog = ({ record, script }) => {
  return dispatch => {
    dispatch(actions.setLogRecord(record))
    dispatch(actions.setLogScript(script))
  }
}

const clearLog = () => {
  return dispatch => {
    dispatch(actions.clearLogRecord())
    dispatch(actions.clearLogScript())
  }
}

const unsubscribeRecordList = () => {
  return actions.unsubscribeRecordList()
}

const updateCurrentRecord = script => {
  return actions.updateCurrentRecord(script)
}

const updateCurrentRecordData = data => {
  return actions.updateCurrentRecordData(data)
}

export default {
  asyncSubscribeRecordList,
  clearCurrentRecord,
  clearLog,
  saveCurrentRecord,
  saveCurrentRecordWithCoord,
  setLog,
  subscribeRecordList,
  unsubscribeRecordList,
  updateCurrentRecord,
  updateCurrentRecordData
}
