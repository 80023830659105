// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1K1UAKpuh7MrwE7dx4mHad{width:100%}._1K1UAKpuh7MrwE7dx4mHad ._3y4VS3SwAavOq8fVo9q5cw{min-height:48px;margin-bottom:16px}._1K1UAKpuh7MrwE7dx4mHad ._3y4VS3SwAavOq8fVo9q5cw input{width:100%}._1K1UAKpuh7MrwE7dx4mHad ._3y4VS3SwAavOq8fVo9q5cw ._2yoy89rHchUDrYwlOlEuHq{width:100%;margin-bottom:16px}._1K1UAKpuh7MrwE7dx4mHad ._3y4VS3SwAavOq8fVo9q5cw ._2yoy89rHchUDrYwlOlEuHq input{width:calc(48% - 16px);margin-right:16px}._1K1UAKpuh7MrwE7dx4mHad ._3y4VS3SwAavOq8fVo9q5cw ._2yoy89rHchUDrYwlOlEuHq select{height:48px;width:calc(48% - 16px);margin-right:16px;padding:0 18px;background:none;border:none;border-radius:8;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;background-color:#e2ebea;font-size:16px}._1K1UAKpuh7MrwE7dx4mHad ._3PRGGrnRhQaH_rF9UEJwak{display:inline-block;text-align:right;box-sizing:border-box;width:100px;vertical-align:top;margin-right:16px}._1K1UAKpuh7MrwE7dx4mHad ._2aF1J2lTmLguBZMuDfCqvL{display:inline-block;width:calc(100% - 160px)}", ""]);
// Exports
exports.locals = {
	"container": "_1K1UAKpuh7MrwE7dx4mHad",
	"row": "_3y4VS3SwAavOq8fVo9q5cw",
	"attr": "_2yoy89rHchUDrYwlOlEuHq",
	"label": "_3PRGGrnRhQaH_rF9UEJwak",
	"body": "_2aF1J2lTmLguBZMuDfCqvL"
};
module.exports = exports;
