// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { userOperations } from '../../modules/user'
import { useDispatch } from 'react-redux'

import AttrGridCatalog from '../User/AttrGridCatalog'
import UserListCatalog from '../User/UserListCatalog'
import UserTabFrame from '../User/UserTabFrame'

export function User({ projectId }) {
  const [tabIndex, setTabIndex] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userOperations.subscribeUserList(projectId))
    return () => dispatch(userOperations.unsubscribeUserList())
  }, [])

  return (
    <div>
      <UserTabFrame onClickTab={i => setTabIndex(i)} index={tabIndex}>
        {tabIndex === 0 ? (
          <UserListCatalog projectId={projectId} />
        ) : (
          <AttrGridCatalog projectId={projectId} />
        )}
      </UserTabFrame>
    </div>
  )
}

User.propTypes = {
  projectId: PropTypes.string.isRequired
}

export default User
