// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import classes from './StringArrayLog.scss'

const StringArrayLog = ({ stringArray }) => (
  <div className={classes.container}>
    {stringArray.map(string => (
      <div key={string}>{string}</div>
    ))}
  </div>
)

StringArrayLog.propTypes = {
  stringArray: PropTypes.arrayOf(PropTypes.string).isRequired
}

StringArrayLog.defaultProps = {
  stringArray: []
}

export default StringArrayLog
