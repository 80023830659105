// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HI1T4qEcn1ooy9rP2EqKR{display:flex;margin-top:14px;text-align:center}.HI1T4qEcn1ooy9rP2EqKR ._20EZQqLoLbP_dMOxdSdDGz{font-size:38px;width:30%}.HI1T4qEcn1ooy9rP2EqKR ._3nP4rldVJ5xTcc0HHAECUq{font-size:42px;width:40%}.HI1T4qEcn1ooy9rP2EqKR ._1rBG9edhqx0oRTgL6ZaG5B{font-size:38px;width:30%}", ""]);
// Exports
exports.locals = {
	"content": "HI1T4qEcn1ooy9rP2EqKR",
	"buttonLeft": "_20EZQqLoLbP_dMOxdSdDGz",
	"centerText": "_3nP4rldVJ5xTcc0HHAECUq",
	"buttonRight": "_1rBG9edhqx0oRTgL6ZaG5B"
};
module.exports = exports;
