// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import EntryIf from './EntryIf'
import EntryPage from './EntryPage'
import ToolBox from './ToolBox'
import { appSelectors, appTypes } from '../../../modules/app'
import { scriptOperations } from '../../../modules/script'
import * as scriptConfig from '../../../constants/scriptConfig'
import { PERMIT_UPDATE } from '../../../constants/permission'

import classes from './Editor.scss'

export function Editor({ script }) {
  const dispatch = useDispatch()

  const [focusEntryId, setFocusEntryId] = useState(null)
  const onClickEntry = id => setFocusEntryId(id)
  const onChangeValue = (key, val) => {
    dispatch(scriptOperations.updateScript({ [key]: val }))
  }
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_SCRIPT)
  )

  const renderEntry = (entry, index) => (
    <>
      {entry.type === scriptConfig.TYPE_PAGE ? (
        <EntryPage entry={entry} index={index} />
      ) : entry.type === scriptConfig.TYPE_IF ? (
        <EntryIf entry={entry} />
      ) : (
        <></>
      )}
    </>
  )

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>
          <input
            disabled={!isPermit(PERMIT_UPDATE)}
            onChange={e => onChangeValue('title', e.target.value)}
            placeholder='スクリプトのタイトル'
            type='text'
            value={script.title === undefined ? '' : script.title}
          />
        </div>
        <div className={classes.description}>
          <input
            disabled={!isPermit(PERMIT_UPDATE)}
            onChange={e => onChangeValue('description', e.target.value)}
            placeholder='スクリプトの説明'
            type='text'
            value={script.description === undefined ? '' : script.description}
          />
        </div>
      </div>
      {script.data.map((entry, index) => (
        <div
          className={classes.entryContainer}
          key={entry.id}
          onClick={e => onClickEntry(entry.id)}
        >
          {renderEntry(entry, index)}
          {focusEntryId === entry.id ||
          (focusEntryId == null && index === 0) ? (
            <ToolBox entry={entry} index={index} />
          ) : (
            <div />
          )}
        </div>
      ))}
    </div>
  )
}

export default Editor
