// Takram Confidential
// Copyright (C) 2019-Present Takram

import * as types from './types'

const setCurrentRecordDate = date => {
  return {
    type: types.SET_CURRENT_RECORD_DATE,
    payload: date
  }
}

const setCancellationPopupVisibility = visibility => {
  return {
    type: types.SET_CANCELLATION_POPUP_VISIBILITY,
    payload: visibility
  }
}

const setRecordLogVisibility = visibility => {
  return {
    type: types.SET_RECORD_LOG_VISIBILITY,
    payload: visibility
  }
}

const setRequiredItemAlertVisibility = visibility => {
  return {
    type: types.SET_REQUIRED_ITEM_ALERT_VISIBILITY,
    payload: visibility
  }
}

const setRequiredOthersItemAlertVisibility = visibility => {
  return {
    type: types.SET_REQUIRED_OTHERS_ITEM_ALERT_VISIBILITY,
    payload: visibility
  }
}

const setScriptSelectorPopupVisibility = visibility => {
  return {
    type: types.SET_SCRIPT_SELECTOR_POPUP_VISIBILITY,
    payload: visibility
  }
}

const setUserInfoPopupVisibility = visibility => {
  return {
    type: types.SET_USER_INFO_POPUP_VISIBILITY,
    payload: visibility
  }
}

export default {
  setCancellationPopupVisibility,
  setCurrentRecordDate,
  setRecordLogVisibility,
  setRequiredItemAlertVisibility,
  setRequiredOthersItemAlertVisibility,
  setScriptSelectorPopupVisibility,
  setUserInfoPopupVisibility
}
