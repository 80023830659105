// Takram Confidential
// Copyright (C) 2019-Present Takram

import MarkdownIt from 'markdown-it'
import { createSelector } from 'reselect'

import recordSelectors from '../record/selectors'
import userSelectors from '../user/selectors'
import {
  USER_FILTER_AFTER,
  USER_FILTER_BEFORE,
  USER_FILTER_NONE,
  USER_FILTER_ON_GOING,
  USER_ORDER_ASC,
  USER_SORT_CREATED_AT,
  USER_SORT_DAYS_LEFT,
  USER_SORT_LAST_RECORD,
  USER_SORT_NAME,
  USER_SORT_NUM_RECORD_TDAY,
  USER_SORT_NUM_RECORD_YDAY
} from './types'

const currentMessagingUserIdsSelector = state =>
  state.messageState.currentMessagingUserIds
const currentUserOrderSelector = state => state.messageState.currentUserOrder
const currentUserSortSelector = state => state.messageState.currentUserSort
const currentUserFilterSelector = state => state.messageState.currentUserFilter
const messageListSelector = state => state.messageState.messageList
const userListSelector = state => state.userState.userList

const displayUserListSelector = createSelector(
  [
    recordSelectors.userRecordCountSelector,
    currentUserOrderSelector,
    currentUserSortSelector,
    currentUserFilterSelector
  ],
  (userList, order, sort, filter) => {
    const now = new Date()
    const timeLeft = user => user.endAt.getTime() - now.getTime()
    const ucName = user => user.name.toUpperCase()
    userList = userList.filter(u => {
      const stat = userSelectors.deriveTermStatus(u)
      if (filter === USER_FILTER_NONE) return true
      else if (filter === USER_FILTER_BEFORE && stat.isBefore) return true
      else if (filter === USER_FILTER_AFTER && stat.isAfter) return true
      else if (filter === USER_FILTER_ON_GOING && stat.isOnGoing) return true
      return false
    })
    userList =
      order === USER_ORDER_ASC
        ? userList.sort((a, b) => (ucName(a) < ucName(b) ? -1 : 1))
        : userList.sort((a, b) => (ucName(a) > ucName(b) ? -1 : 1))
    switch (sort) {
      case USER_SORT_CREATED_AT:
        return order === USER_ORDER_ASC
          ? userList.sort((a, b) => a.createdAt - b.createdAt)
          : userList.sort((a, b) => b.createdAt - a.createdAt)
      case USER_SORT_DAYS_LEFT:
        return order === USER_ORDER_ASC
          ? userList.sort((a, b) => timeLeft(a) - timeLeft(b))
          : userList.sort((a, b) => timeLeft(b) - timeLeft(a))
      case USER_SORT_LAST_RECORD:
        return order === USER_ORDER_ASC
          ? userList.sort((a, b) => a.lastRecordAt - b.lastRecordAt)
          : userList.sort((a, b) => b.lastRecordAt - a.lastRecordAt)
      case USER_SORT_NAME:
        return order === USER_ORDER_ASC
          ? userList.sort((a, b) => (ucName(a) < ucName(b) ? -1 : 1))
          : userList.sort((a, b) => (ucName(a) > ucName(b) ? -1 : 1))
      case USER_SORT_NUM_RECORD_TDAY:
        return order === USER_ORDER_ASC
          ? userList.sort((a, b) => a.todayCount - b.todayCount)
          : userList.sort((a, b) => b.todayCount - a.todayCount)
      case USER_SORT_NUM_RECORD_YDAY:
        return order === USER_ORDER_ASC
          ? userList.sort((a, b) => a.yesterdayCount - b.yesterdayCount)
          : userList.sort((a, b) => b.yesterdayCount - a.yesterdayCount)
      default:
        return userList
    }
  }
)

const displayMessagesSelector = createSelector(
  [currentMessagingUserIdsSelector, messageListSelector, userListSelector],
  (currentMessagingUserIds, messageList, userList) => {
    if (currentMessagingUserIds.length !== 1) return []
    const user = userList.find(user => currentMessagingUserIds[0] === user.id)
    if (user == null) return []
    return messageList
      .filter(
        message =>
          (message.message.type === 'text' ||
            message.message.type === 'flex') &&
          message.lineUserId === user.lineUserId
      )
      .map(message => ({
        contents: message.message.contents,
        createdAt: message.createdAt,
        id: message.id,
        isMyMessage: message.type === 'linebot/send',
        isRecieve: message.type === 'linebot/recieve',
        messageId: message.message.id,
        text: message.message.text,
        type: message.message.type,
        // 既読があるときに、すぐに既読が外れてしまうのをなんとかしないといけない
        unread: user.lastMessageReadAt.getTime() < message.createdAt.getTime()
      }))
  }
)

const unreadMessageCountsSelector = createSelector(
  [messageListSelector, userListSelector],
  (messageList, userList) => {
    const counts = { amount: 0 }
    messageList.forEach(message => {
      const { createdAt, lineUserId } = message
      const user = userList.find(user => user.lineUserId === lineUserId)
      if (user == null) return false
      if (createdAt.getTime() < user.lastMessageReadAt.getTime()) return false
      if (message.type !== 'linebot/recieve') return false
      if (!Object.prototype.hasOwnProperty.call(counts, lineUserId)) {
        counts[lineUserId] = 0
      }
      counts[lineUserId] += 1
      counts.amount += 1
    })
    return counts
  }
)

const generateTextMessage = message => ({
  text: message,
  type: 'text'
})

const addLinkButtonOnFlexMassage = ({ message, url }) => {
  if (message.type !== 'flex') return message
  const newContentsArray = message.contents.body.contents.concat(
    {
      contents: [
        {
          text: '\n',
          type: 'span'
        }
      ],
      type: 'text'
    },
    {
      action: {
        label: '回答する',
        type: 'uri',
        uri: url
      },
      margin: '10px',
      style: 'primary',
      type: 'button'
    }
  )
  return {
    ...message,
    contents: {
      ...message.contents,
      body: {
        ...message.contents.body,
        contents: newContentsArray
      }
    }
  }
}

const findCloseElementInChildrenArray = ({ children, closeType, idx }) => {
  let str = ''
  while (idx < children.length) {
    const { content, level, type } = children[idx]
    if (type === 'text') str += content
    if (level === 0 && type === closeType) break
    idx++
  }
  if (idx >= children.length) throw new Error('Cannot find close element.')
  return { idx, str }
}

const textStyle = { type: 'span' }
const emphasis1Style = { type: 'span', weight: 'bold' }
const emphasis2Style = { color: '#ff0000', type: 'span', weight: 'bold' }
const buttonStyle = {
  action: { type: 'uri' },
  margin: '10px',
  style: 'primary',
  type: 'button'
}

const md = new MarkdownIt('zero', { html: true, xhtmlOut: true }).enable([
  'emphasis',
  'link'
])

const generateFlexMessage = textMessage => {
  const parsedMessage = md.parse(textMessage, false)
  let generatedBoxContents = []
  let altText = ''
  parsedMessage.forEach(paragraph => {
    if (paragraph.type === 'inline') {
      if (paragraph.children == null)
        throw new Error('paragraph.children is null.')
      const { children } = paragraph
      let boxContents = []
      for (let index = 0; index < children.length; index++) {
        if (children[index].level !== 0) continue
        if (children[index].type === 'text' && children[index].content !== '') {
          boxContents.push({ ...textStyle, text: children[index].content })
          altText += children[index].content
        } else if (children[index].type === 'em_open') {
          const { idx, str } = findCloseElementInChildrenArray({
            children,
            closeType: 'em_close',
            idx: index
          })
          index = idx
          boxContents.push({ ...emphasis1Style, text: str })
          altText += str
        } else if (children[index].type === 'strong_open') {
          const { idx, str } = findCloseElementInChildrenArray({
            children,
            closeType: 'strong_close',
            idx: index
          })
          index = idx
          boxContents.push({ ...emphasis2Style, text: str })
          altText += str
        } else if (children[index].type === 'link_open') {
          if (boxContents.length !== 0) {
            generatedBoxContents.push({
              type: 'text',
              wrap: true,
              contents: boxContents
            })
            boxContents = []
          }
          if (children[index].attrs == null)
            throw new Error('children.attrs is null.')
          const uri = children[index].attrs[0][1]
          const { str: label, idx } = findCloseElementInChildrenArray({
            children,
            closeType: 'link_close',
            idx: index
          })
          generatedBoxContents.push({
            ...buttonStyle,
            action: { ...buttonStyle.action, label, uri }
          })
          altText += label
          index = idx
        }
      }
      if (boxContents.length !== 0) {
        generatedBoxContents.push({
          type: 'text',
          wrap: true,
          contents: boxContents
        })
      }
    }
  })
  altText = altText.replace(/\r?\n/g, ' ') // = altText.replaceAll('\n', ' ') node^v15
  altText = altText.substring(0, 200)
  return {
    altText,
    contents: {
      type: 'bubble',
      body: {
        type: 'box',
        layout: 'vertical',
        contents: generatedBoxContents
      }
    },
    text: textMessage,
    type: 'flex'
  }
}

export default {
  addLinkButtonOnFlexMassage,
  displayMessagesSelector,
  displayUserListSelector,
  generateFlexMessage,
  generateTextMessage,
  unreadMessageCountsSelector
}
