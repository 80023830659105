// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './OverlayFrame.scss'
import MuiButton from '../Ui/MuiButton'

const OverlayFrame = ({ buttons, children, onClickClose, title }) => (
  <div className={classes.container} onClick={e => onClickClose(e)}>
    <div
      className={classes.popupContainer}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <div className={classes.popupHeader}>
        <div className={classes.title}>{title}</div>
        <div className={classes.buttonContainer}>
          {buttons.map(button => (
            <MuiButton
              key={button.label}
              text={button.label}
              disabled={button.disabled || false}
              onClick={button.onClick || (() => {})}
              href={button.href || ''}
              color={button.accent ? 'secondary' : 'default'}
            />
          ))}
        </div>
        <div className={classes.closeButton} onClick={e => onClickClose(e)} />
      </div>
      <div className={classes.popupBody}>{children}</div>
    </div>
  </div>
)

OverlayFrame.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.array.isRequired,
  onClickClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default OverlayFrame
