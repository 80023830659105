// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as scriptConfig from '../../../constants/scriptConfig'

import classes from './Item.scss'

const ItemText = ({ value, type, onChangeValue, permitUpdate }) => (
  <div className={classes.dateContainer}>
    <div className={classes.emphasizeContainer}>
      <div className={classes.column}>
        <div className={classes.emphasizeTitle}>回答枠サイズ</div>
        <div className={classes.emphasizeItem}>
          <div
            className={ClassNames(classes.radio, {
              [classes.checked]: value[0].formSize === 'small'
            })}
            onClick={() => {
              value[0].formSize = 'small'
              onChangeValue('value', value)
            }}
          />
          小
        </div>
        <div className={classes.emphasizeItem}>
          <div
            className={ClassNames(classes.radio, {
              [classes.checked]: value[0].formSize === 'medium'
            })}
            onClick={() => {
              value[0].formSize = 'medium'
              onChangeValue('value', value)
            }}
          />
          中
        </div>
        <div className={classes.emphasizeItem}>
          <div
            className={ClassNames(classes.radio, {
              [classes.checked]: value[0].formSize === 'large'
            })}
            onClick={() => {
              value[0].formSize = 'large'
              onChangeValue('value', value)
            }}
          />
          大
        </div>
      </div>
    </div>
  </div>
)

ItemText.propTypes = {
  ...scriptConfig.ITEM_STYLE,
  onChangeValue: PropTypes.func.isRequired,
  permitUpdate: PropTypes.bool.isRequired
}

ItemText.defaultProps = {
  ...scriptConfig.INITIAL_ITEM_DATA
}

export default ItemText
