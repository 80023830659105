// Takram Confidential
// Copyright (C) 2019-Present Takram

import { combineReducers } from 'redux'

import * as types from './types'

const messageList = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_MESSAGE_LIST:
      return action.messageList
    case types.DETOUCH_MESSAGE_LIST:
      return []
    default:
      return state
  }
}
const currentMessagingUserIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_CURRENT_MESSAGING_USER_ID:
      return action.userIds
    default:
      return state
  }
}

const currentInputMessages = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_CURRENT_INPUT_MESSAGE:
      state[action.userId] = action.message
      return state
    default:
      return state
  }
}

const currentUserSort = (state = types.USER_SORT_NAME, action) => {
  switch (action.type) {
    case types.UPDATE_CURRENT_USER_SORT:
      return action.sort
    default:
      return state
  }
}

const currentUserOrder = (state = types.USER_ORDER_ASC, action) => {
  switch (action.type) {
    case types.UPDATE_CURRENT_USER_ORDER:
      return action.order
    default:
      return state
  }
}

const currentUserFilter = (state = types.USER_FILTER_NONE, action) => {
  switch (action.type) {
    case types.UPDATE_CURRENT_USER_FILTER:
      return action.filter
    default:
      return state
  }
}

const messageReducer = combineReducers({
  messageList,
  currentMessagingUserIds,
  currentInputMessages,
  currentUserOrder,
  currentUserSort,
  currentUserFilter
})

export default messageReducer
