// Takram Confidential
// Copyright (C) 2019-Present Takram

import React from 'react'

import * as scriptConfig from '../../../constants/scriptConfig'

import classes from './Item.scss'

const ItemDate = ({ type }) => (
  <div className={classes.dateContainer}>
    {type === scriptConfig.INPUT_DATE
      ? '日付'
      : type === scriptConfig.INPUT_TIME
      ? '時刻'
      : ''}
  </div>
)

ItemDate.propTypes = {
  ...scriptConfig.ITEM_STYLE
}

ItemDate.defaultProps = {
  ...scriptConfig.INITIAL_ITEM_DATA
}

export default ItemDate
