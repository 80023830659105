// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as projectTypes from '../modules/project/types'
import * as scriptConfig from '../constants/scriptConfig'
import InputCheck from '../components/Form/InputCheck'
import InputCommon from '../components/Form/InputCommon'
import InputDate from '../components/Form/InputDate'
import InputDescription from '../components/Form/InputDescription'
import InputNumber from '../components/Form/InputNumber'
import InputRadio from '../components/Form/InputRadio'
import InputSlideSel from '../components/Form/InputSlideSel'
import InputText from '../components/Form/InputText'
import InputTime from '../components/Form/InputTime'
import { formOperations, formSelectors } from '../modules/form'
import { recordOperations } from '../modules/record'
import { scriptOperations } from '../modules/script'

class FormInput extends Component {
  setInputData(value) {
    const { recordOperations } = this.props
    recordOperations.updateCurrentRecordData(value)
  }

  componentDidUpdate() {
    const { canNext, formOperations } = this.props
    if (canNext.flag) {
      formOperations.setRequiredItemAlertVisibility(false)
      formOperations.setRequiredOthersItemAlertVisibility(false)
    }
  }

  buildInput(item) {
    const { currentRecord } = this.props
    const inputData = currentRecord.data[item.id]
    switch (item.type) {
      case scriptConfig.INPUT_CHECK:
        return (
          <InputCheck
            {...item}
            inputData={inputData}
            setInputData={d => this.setInputData(d)}
          />
        )
      case scriptConfig.INPUT_DATE:
        return (
          <InputDate
            {...item}
            inputData={inputData}
            setInputData={d => this.setInputData(d)}
          />
        )
      case scriptConfig.INPUT_DESCRIPTION:
        return <InputDescription {...item.value[0]} />
      case scriptConfig.INPUT_NUMBER:
        return (
          <InputNumber
            {...item}
            inputData={inputData}
            setInputData={d => this.setInputData(d)}
          />
        )
      case scriptConfig.INPUT_RADIO:
        return (
          <InputRadio
            {...item}
            inputData={inputData}
            setInputData={d => this.setInputData(d)}
          />
        )
      case scriptConfig.INPUT_SLIDE_SEL:
        return (
          <InputSlideSel
            {...item}
            inputData={inputData}
            setInputData={d => this.setInputData(d)}
          />
        )
      case scriptConfig.INPUT_TEXT:
        if (item.value.length === 0) return <></>
        return (
          <InputText
            {...item}
            inputData={inputData === undefined ? item.value[0].text : inputData}
            formSize={item.value[0].formSize}
            setInputData={d => this.setInputData(d)}
          />
        )
      case scriptConfig.INPUT_TIME:
        return (
          <InputTime
            {...item}
            inputData={inputData}
            setInputData={d => this.setInputData(d)}
          />
        )
      default:
        return <div>FormInput</div>
    }
  }

  render() {
    let { item } = this.props
    const { userItems, currentEntryDataValue } = this.props
    if (item.type === scriptConfig.INPUT_USER_INFO) {
      if (!Object.keys(userItems).length) return <div />
      const { type, attrId } = item.value[0]
      if (userItems[attrId] === undefined) return <div />
      const isIndivisual =
        userItems[attrId].type === projectTypes.USER_ATTR_TYPE_MASKING_LIST
      item = { ...item, isIndivisual, type, value: userItems[attrId].value }
    }
    return (
      <InputCommon {...item} singleItem={currentEntryDataValue.length === 1}>
        {this.buildInput(item)}
      </InputCommon>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    canNext: formSelectors.canNextSelector(state),
    currentRecord: state.recordState.currentRecord,
    currentEntryDataValue: state.scriptState.currentEntryData.value,
    userItems: formSelectors.userItemsSelector(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    formOperations: bindActionCreators(formOperations, dispatch),
    scriptOperations: bindActionCreators(scriptOperations, dispatch),
    recordOperations: bindActionCreators(recordOperations, dispatch)
  }
}

FormInput.propTypes = {
  canNext: PropTypes.shape({
    flag: PropTypes.bool.isRequired,
    recordHasEmptyOthers: PropTypes.bool
  }).isRequired,
  currentEntryDataValue: PropTypes.arrayOf(
    PropTypes.shape(scriptConfig.ITEM_STYLE).isRequired
  ).isRequired,
  currentRecord: PropTypes.shape({
    coordinates: PropTypes.object.isRequired,
    createdAt: PropTypes.instanceOf(Date).isRequired,
    data: PropTypes.object.isRequired,
    dataTypes: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    projectId: PropTypes.string,
    requiredId: PropTypes.string,
    scriptId: PropTypes.string.isRequired,
    userId: PropTypes.string
  }).isRequired,
  formOperations: PropTypes.objectOf(PropTypes.func).isRequired,
  item: PropTypes.shape(scriptConfig.ITEM_STYLE).isRequired,
  recordOperations: PropTypes.objectOf(PropTypes.func).isRequired,
  scriptOperations: PropTypes.objectOf(PropTypes.func).isRequired,
  userItems: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(FormInput)
