// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._34aaMtRpUvk1Sap4ao95hv{background-color:#f1f1f1;min-height:100%;overflow:hidden;padding:0 0 120px 0;width:100%}._34aaMtRpUvk1Sap4ao95hv.E6iNndXt28sc2D-l6DI4A{width:calc(100% - 420px)}._34aaMtRpUvk1Sap4ao95hv ._3b8O7wDs5KoYBxEE070k_H{position:relative;width:744;margin:0 auto;padding:0 80px}._34aaMtRpUvk1Sap4ao95hv .A-axL8X0GojZc4Lt3eiJl{clear:both}", ""]);
// Exports
exports.locals = {
	"container": "_34aaMtRpUvk1Sap4ao95hv",
	"showSimulator": "E6iNndXt28sc2D-l6DI4A",
	"innerContainer": "_3b8O7wDs5KoYBxEE070k_H",
	"clearBoth": "A-axL8X0GojZc4Lt3eiJl"
};
module.exports = exports;
