// Takram Confidential
// Copyright (C) 2019-Present Takram

import * as config from '../../../constants/scriptConfig'
import * as scriptConfig from '../../../constants/scriptConfig'
import NameSpace from '../../../common/NameSpace'
import _ from 'lodash'
import scriptSelectors from '../selectors'
import uuidv4 from 'uuid'

export const internal = NameSpace('Parser')

export default class Parser {
  constructor(scriptState) {
    const scope = internal(this)
    const { data } = scriptState.script
    scope.scriptState = scriptState
    scope.scriptEntryNum = data.length
    scope.scriptIndexMap = data.reduce((hash, elem, index) => {
      hash[elem.id] = index
      return hash
    }, {})
  }

  //= ==== Logic Functions =====

  getEntryData(entryID) {
    const { scriptState, scriptIndexMap } = internal(this)
    const { data } = scriptState.script
    if (data === undefined) return {}
    const idx = scriptIndexMap[entryID]
    return data[idx]
  }

  getItemData(itemID) {
    const { scriptState } = internal(this)
    let targetItem = null
    scriptState.script.data.forEach(entry => {
      entry.value.forEach(item => {
        if (item.id === itemID) targetItem = item
      })
    })
    return targetItem
  }

  getFirstEntry() {
    const { scriptState } = internal(this)
    const { data } = scriptState.script
    if (data == null || data.length <= 0) return null
    return scriptState.script.data[0]
  }

  getUnderEntry(entry) {
    const { scriptState, scriptIndexMap, scriptEntryNum } = internal(this)
    const nextIndex = parseInt(scriptIndexMap[entry.id]) + 1
    if (nextIndex >= scriptEntryNum) return null
    return scriptState.script.data[nextIndex]
  }

  deriveNextEntry(recordState, entry) {
    const { scriptState } = internal(this)
    if (entry.type === config.TYPE_DONE) return null
    if (scriptState.script.data === undefined) return null
    const nextEntry = this.getUnderEntry(entry)
    if (nextEntry === null) return config.DONE_ENTRY_DATA
    const { type, value } = nextEntry
    if (type === config.TYPE_IF) {
      let nextAction = null
      value.forEach(ifState => {
        if (!this.judgeIfState(recordState, ifState)) return
        nextAction = ifState.action
        return true
      })
      if (nextAction !== null) {
        if (nextAction.type === config.ACTION_JUMP) {
          return this.getEntryData(nextAction.value)
        } else if (nextAction.type === config.ACTION_END) {
          return config.DONE_ENTRY_DATA
        }
      }
      return this.deriveNextEntry(recordState, nextEntry) // TODO
    }
    return nextEntry
  }

  derivePrevEntry() {
    const { scriptState } = internal(this)
    if (scriptState.script.data === undefined) return null
    const { scriptEntryHistory } = scriptState
    if (scriptEntryHistory.length <= 1) return null
    const prevID = scriptEntryHistory[scriptEntryHistory.length - 2]
    return this.getEntryData(prevID)
  }

  judgeIfState(recordState, ifState) {
    const { currentRecord } = recordState
    const { targetId, value, operator } = ifState
    if (targetId === config.TARGET_ID_ANY) return true
    if (currentRecord == null || currentRecord.data == null) return null
    const recordValue = currentRecord.data[targetId]
    const targetItem = this.getItemData(targetId)
    if (recordValue == null || targetItem == null) return null
    if (targetItem.type === config.INPUT_RADIO) {
      switch (operator) {
        case config.OPERATOR_EQUAL:
          if (scriptSelectors.recordTextIsOthers(recordValue)) {
            return scriptConfig.OTHERS_VALUE === value
          } else {
            return recordValue === value
          }
        case config.OPERATOR_NOT_EQUAL:
          if (scriptSelectors.recordTextIsOthers(recordValue)) {
            return scriptConfig.OTHERS_VALUE !== value
          } else {
            return recordValue !== value
          }
        default:
          return null
      }
    } else if (targetItem.type === config.INPUT_CHECK) {
      switch (operator) {
        case config.OPERATOR_EQUAL:
          return (
            recordValue.find(v => {
              if (scriptSelectors.recordTextIsOthers(v)) {
                return scriptConfig.OTHERS_VALUE === value
              } else {
                return v === value
              }
            }) != null
          )
        case config.OPERATOR_NOT_EQUAL:
          return (
            recordValue.find(v => {
              if (scriptSelectors.recordTextIsOthers(v)) {
                return scriptConfig.OTHERS_VALUE === value
              } else {
                return v === value
              }
            }) == null
          )
        default:
          return null
      }
    } else if (targetItem.type === config.INPUT_NUMBER) {
      switch (operator) {
        case config.OPERATOR_EQUAL:
          return recordValue === value
        case config.OPERATOR_NOT_EQUAL:
          return recordValue !== value
        case config.OPERATOR_UNDER:
          return recordValue < value
        case config.OPERATOR_OVER:
          return recordValue > value
        default:
          return null
      }
    }
    return null
  }

  //= ==== Edit Functions =====

  addEntry(type = config.TYPE_PAGE, index = -1) {
    const { data } = internal(this).scriptState.script
    const entry = {
      ..._.cloneDeep(config.INITIAL_ENTRY_DATA),
      ...{ id: uuidv4(), type }
    }
    if (index > data.length) index = data.length
    if (index < 0) {
      data.push(entry)
    } else {
      data.splice(index, 0, entry)
    }
    // Add blank item
    this.addItem(entry.id)
    return _.cloneDeep(data)
  }

  addItem(entryId) {
    const { data } = internal(this).scriptState.script
    data.forEach(entry => {
      if (entry.id === entryId) {
        const item =
          entry.type === config.TYPE_PAGE
            ? _.cloneDeep(config.INITIAL_ITEM_DATA)
            : entry.type === config.TYPE_IF
            ? _.cloneDeep(config.INITIAL_CONDITION_DATA)
            : null
        if (item != null) entry.value.push({ ...item, ...{ id: uuidv4() } })
      }
    })
    return _.cloneDeep(data)
  }

  addCondition(entryId) {
    const { data } = internal(this).scriptState.script
    data.forEach(entry => {
      if (entry.id === entryId && entry.type === config.TYPE_IF) {
        const condition = _.cloneDeep(config.INITIAL_CONDITION_DATA)
        entry.value.push({ ...condition, ...{ id: uuidv4() } })
      }
    })
    return _.cloneDeep(data)
  }

  deleteCondition(conditionId) {
    const { data } = internal(this).scriptState.script
    data.forEach(e => {
      e.value = e.value.filter(condition => condition.id !== conditionId)
    })
    return _.cloneDeep(data)
  }

  updateCondition(newCondition) {
    const { data } = internal(this).scriptState.script
    data.forEach(e => {
      e.value.forEach((condition, i) => {
        if (condition.id === newCondition.id) {
          e.value[i] = { ...condition, ...newCondition }
        }
      })
    })
    return _.cloneDeep(data)
  }

  updateEntry(entry) {
    const { data } = internal(this).scriptState.script
    data.forEach((e, i) => {
      if (e.id === entry.id) {
        data[i] = { ...e, ...entry }
      }
    })
    return _.cloneDeep(data)
  }

  updateItem(newItem) {
    const { data } = internal(this).scriptState.script
    data.forEach(e => {
      e.value.forEach((item, i) => {
        if (item.id !== newItem.id) return false
        if (item.type !== newItem.type) {
          const itemOption = config.getItemOption(item.type)
          const newItemOption = config.getItemOption(newItem.type)
          if (itemOption.valueType !== newItemOption.valueType) {
            newItem.value = config.getInitialValue(newItemOption.valueType)
          }
          if (!newItemOption.canTwoColumn) {
            newItem.column = 1
          }
          newItem.required = newItemOption.canChangeRequired
            ? newItem.required
            : newItemOption.defaultRequired
        }
        e.value[i] = { ...item, ...newItem }
      })
    })
    return _.cloneDeep(data)
  }

  deleteEntry(entryId) {
    const { data } = internal(this).scriptState.script
    const deletedData = data.filter(entry => entry.id !== entryId)
    return _.cloneDeep(deletedData)
  }

  deleteItem(itemId) {
    const { data } = internal(this).scriptState.script
    data.forEach(e => {
      e.value = e.value.filter(item => item.id !== itemId)
    })
    return _.cloneDeep(data)
  }

  swapEntry(entryId, num) {
    const { data } = internal(this).scriptState.script
    const index = data.findIndex(e => e.id === entryId)
    const targetIndex = index + num
    if (
      index === undefined ||
      targetIndex > data.length - 1 ||
      targetIndex < 0
    ) {
      return data
    }
    const e = data[targetIndex]
    data[targetIndex] = data[index]
    data[index] = e
    return _.cloneDeep(data)
  }

  swapItem(itemId, num) {
    const { data } = internal(this).scriptState.script
    data.forEach(e => {
      if (e.value.find(i => i.id === itemId) == null) return
      const index = e.value.findIndex(i => i.id === itemId)
      const targetIndex = index + num
      if (targetIndex > e.value.length - 1 || targetIndex < 0) return
      const item = e.value[targetIndex]
      e.value[targetIndex] = e.value[index]
      e.value[index] = item
    })
    return _.cloneDeep(data)
  }
}
