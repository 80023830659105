// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1IPIobUebEK9qWWKX4vnl ._3EqyUXBnpZjMYXMP8q_Z-j{-webkit-appearance:none;border-radius:14px;border:1px solid #e0e0e0;color:#384241;font-size:16px;margin-top:8px;outline:none;padding:16px;resize:vertical;width:100%}._1IPIobUebEK9qWWKX4vnl ._3EqyUXBnpZjMYXMP8q_Z-j._37_S9I0HMNh8-OGOjmCUJd{height:100px}._1IPIobUebEK9qWWKX4vnl ._3EqyUXBnpZjMYXMP8q_Z-j._1QlUoRfpdgOj49iQH4we-R{height:300px}", ""]);
// Exports
exports.locals = {
	"content": "_1IPIobUebEK9qWWKX4vnl",
	"inputArea": "_3EqyUXBnpZjMYXMP8q_Z-j",
	"medium": "_37_S9I0HMNh8-OGOjmCUJd",
	"large": "_1QlUoRfpdgOj49iQH4we-R"
};
module.exports = exports;
