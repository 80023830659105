// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'

import * as scriptCondition from '../../constants/scriptCondition'
import * as systemConfig from '../../constants/systemConfig'
import { appOperations } from '../app'
import { projectOperations } from '../project'
import { recordOperations } from '../record'
import { scriptOperations, scriptSelectors } from '../script'
import { userOperations, userSelectors } from '../user'
import actions from './actions'

const initializeFormMobile = ({ loginUserId, scriptId, requiredId }) => {
  return async (dispatch, getState) => {
    if (loginUserId === undefined) return
    if (loginUserId === systemConfig.ADMIN_USER_HASH) {
      dispatch(appOperations.setIsDebugUserMode(true))
    } else {
      await dispatch(userOperations.asyncSubscribeLoginUser(loginUserId))
      await dispatch(
        recordOperations.asyncSubscribeRecordList({ userId: loginUserId })
      )
    }
    if (loginUserId !== systemConfig.ADMIN_USER_HASH) {
      // Is NOT Admin.
      const state = getState()
      const { projectId } = state.userState.currentLoginUser
      dispatch(scriptOperations.subscribeScriptList(projectId))
      dispatch(projectOperations.fetchProject(projectId))
    }
    if (scriptId !== undefined) {
      await dispatch(startScript({ scriptId, requiredId }))
    }
  }
}

const startScript = ({ scriptId, requiredId }) => {
  return async (dispatch, getState) => {
    if (scriptId === undefined) return
    await dispatch(scriptOperations.asyncFetchScript(scriptId))
    const state = getState()
    const { projectId, startAt, id: userId } = state.userState.currentLoginUser
    const { script } = state.scriptState
    const { isDebugUserMode } = state.appState
    const { recordList } = state.recordState
    if (script === undefined) return
    const isOutOfPeriod = userSelectors.isOutOfPeriodSelector(state)
    if (!isDebugUserMode) {
      if (script.condition.type === scriptCondition.SCRIPT_TYPE_REQUIRED) {
        if (requiredId === undefined) {
          console.log('requiredId is undefined.')
          return
        }
        // TODO: Check RequiredId is collect.
        const isAnswered = recordList.some(
          record =>
            record.scriptId === scriptId && record.requiredId === requiredId
        )
        if (isAnswered) {
          console.log('The required script is already answered.')
          return
        }
      } else if (script.condition.type === scriptCondition.SCRIPT_TYPE_FREE) {
        if (isOutOfPeriod) {
          console.log('Out of period.')
          return
        }
        const inputTermCondition = {
          ...scriptCondition.INITIAL_CONDITION.inputTerm,
          ...script.condition.inputTerm
        }
        const termIsElapsedDays =
          inputTermCondition.type ==
          scriptCondition.INPUT_TERM_TYPE_ELAPSED_DAYS
        const elapsedDays = Moment().diff(startAt, 'days')
        const insideInputTerm =
          elapsedDays >= inputTermCondition.from &&
          elapsedDays <= inputTermCondition.to
        if (termIsElapsedDays && !insideInputTerm) {
          console.log('Out of term.')
          return
        }
      } else if (script.condition.type === scriptCondition.SCRIPT_TYPE_BEACON) {
        if (isOutOfPeriod) {
          console.log('Out of period.')
          return
        }
      }
    }
    dispatch(scriptOperations.play())
    const dataTypes = scriptSelectors.itemTypeMapSelector(state)
    dispatch(recordOperations.clearCurrentRecord())
    // TODO: Refactoring
    if (requiredId !== undefined) {
      dispatch(recordOperations.updateCurrentRecord({ requiredId }))
    }
    dispatch(
      recordOperations.updateCurrentRecord({
        dataTypes,
        projectId,
        scriptId: script.id,
        userId
      })
    )
  }
}

const setCancellationPopupVisibility = visibility => {
  return actions.setCancellationPopupVisibility(visibility)
}

const setCurrentRecordDate = date => {
  return actions.setCurrentRecordDate(
    Moment(date)
      .startOf('date')
      .toDate()
  )
}

const setRequiredItemAlertVisibility = visibility => {
  return actions.setRequiredItemAlertVisibility(visibility)
}

const setRequiredOthersItemAlertVisibility = visibility => {
  return actions.setRequiredOthersItemAlertVisibility(visibility)
}

const setRecordLogVisibility = visibility => {
  return actions.setRecordLogVisibility(visibility)
}

const setScriptSelectorPopupVisibility = visibility => {
  return actions.setScriptSelectorPopupVisibility(visibility)
}

const setUserInfoPopupVisibility = visibility => {
  return actions.setUserInfoPopupVisibility(visibility)
}

export default {
  initializeFormMobile,
  setCancellationPopupVisibility,
  setCurrentRecordDate,
  setRecordLogVisibility,
  setRequiredItemAlertVisibility,
  setRequiredOthersItemAlertVisibility,
  setScriptSelectorPopupVisibility,
  setUserInfoPopupVisibility,
  startScript
}
