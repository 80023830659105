// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import RequestList from './RequestList'
import List from './List'

import commonCls from '../App/Common.scss'
import classes from './ListCatalog.scss'
import Select from '../Ui/Select'
import * as types from '../../modules/admin_user/types'
import { useDispatch, useSelector } from 'react-redux'
import { adminUserOperations } from '../../modules/admin_user'

export function ListCatalog({ onClickAdd }) {
  const dispatch = useDispatch()
  const adminUserListOrder = useSelector(
    state => state.adminUserState.adminUserListOrder
  )

  const items = [
    { name: '名前昇順', value: types.ORDER_BY_NAME_ASC },
    { name: '名前降順', value: types.ORDER_BY_NAME_DESC },
    { name: '登録日昇順', value: types.ORDER_BY_CREATED_AT_ASC },
    { name: '登録日降順', value: types.ORDER_BY_CREATED_AT_DESC },
    { name: 'レベル昇順', value: types.ORDER_BY_LEVEL_ASC },
    { name: 'レベル降順', value: types.ORDER_BY_LEVEL_DESC }
  ]

  const onChangeOrder = order => {
    dispatch(adminUserOperations.setAdminUserListOrder(order))
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>管理権限申請一覧</div>

      <div className={classes.catalogContainer}>
        <div className={classes.listContainer}>
          <RequestList />
        </div>
      </div>
      <br />

      <div className={classes.title}>管理者一覧</div>
      <div className={classes.sort}>
        <Select
          items={items}
          value={adminUserListOrder}
          onChange={e => onChangeOrder(e.target.value)}
          style={{ backgroundColor: '#FFF', width: 200 }}
        />
      </div>
      <div className={classes.catalogContainer}>
        <div className={classes.listContainer}>
          <List />
        </div>
        <div className={classes.buttonContainer}>
          <div
            className={`
              ${commonCls.roundButton} 
              ${commonCls.buttonThin} 
              ${commonCls.buttonLight} 
              ${classes.button}`}
            onClick={e => onClickAdd(e)}
          >
            手動で管理者を追加する
          </div>
        </div>
      </div>
    </div>
  )
}

ListCatalog.propTypes = {
  onClickAdd: PropTypes.func.isRequired
}

export default ListCatalog
