// Takram Confidential
// Copyright (C) 2019-Present Takram

import React from 'react'
import PropTypes from 'prop-types'

import MessageConfig from '../Ui/MessageConfig'
import TextArea from '../Ui/TextArea'
import classes from './NotificationMessage.scss'
import { messageSelectors } from '../../modules/message'

export function NotificationMessage({
  answerLink,
  isNotify,
  message,
  onChangeMessage,
  permitUpdate
}) {
  const textAreaField = React.useRef(null)

  const insertSymbol = (target, symbol, index) => {
    return target.slice(0, index) + symbol + target.slice(index)
  }

  const encloseSymbol = (target, symbol) => {
    const {
      selectionStart,
      selectionEnd
    } = textAreaField.current.getSelectionRange()
    if (selectionStart === selectionEnd) return target
    let newInputMessage = insertSymbol(target, symbol, selectionEnd)
    return insertSymbol(newInputMessage, symbol, selectionStart)
  }

  const insertLink = (target, { label, url }) => {
    const { selectionStart } = textAreaField.current.getSelectionRange()
    return insertSymbol(target, '[' + label + '](' + url + ')', selectionStart)
  }

  return (
    <div className={classes.container}>
      <div className={classes.word}>通知メッセージ</div>
      <MessageConfig
        flagFlexMessage={message.type === 'flex'}
        messageContents={
          answerLink
            ? messageSelectors.addLinkButtonOnFlexMassage({
                message,
                url: ''
              }).contents
            : message.contents
        }
        onClickEncloseText={symbol =>
          onChangeMessage(
            messageSelectors.generateFlexMessage(
              encloseSymbol(message.text, symbol)
            )
          )
        }
        onClickLinkInsertButton={value =>
          onChangeMessage(
            messageSelectors.generateFlexMessage(
              insertLink(message.text, value)
            )
          )
        }
        onClickRichButton={() =>
          onChangeMessage(messageSelectors.generateFlexMessage(message.text))
        }
        onClickSimpleButton={() =>
          onChangeMessage({ type: 'text', text: message.text })
        }
      />
      <TextArea
        disabled={!permitUpdate || !isNotify}
        onChange={e => {
          message.type === 'flex'
            ? onChangeMessage(
                messageSelectors.generateFlexMessage(e.target.value)
              )
            : onChangeMessage({ type: 'text', text: e.target.value })
        }}
        ref={textAreaField}
        value={message.text}
      />
    </div>
  )
}

NotificationMessage.defaultProps = {
  answerLink: false
}

NotificationMessage.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  isNotify: PropTypes.bool.isRequired,
  onChangeMessage: PropTypes.func.isRequired,
  permitUpdate: PropTypes.bool.isRequired
}

export default NotificationMessage
