// Takram Confidential
// Copyright (C) 2019-Present Takram

import { createSelector } from 'reselect'

import * as permission from '../../constants/permission'

const adminLevelSelector = (state, props) => [
  state.appState.auth.adminLevel,
  props
]

const isPermitSelector = createSelector(
  [adminLevelSelector],
  ([adminLevel, module]) => {
    return permit => permission.isPermitLevel(adminLevel, module, permit)
  }
)

export default {
  isPermitSelector
}
