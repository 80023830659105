// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon/admin/close.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".ckk654LWH49lFeBBorc99{width:calc(100% - 160px);margin:0 auto}.ckk654LWH49lFeBBorc99 ._1aWlFMQ0JkkZHMazguu7UE .ydBvTIRQcC9_7HyuBfjUR{display:grid;grid-template-columns:1fr 1fr 24px;grid-column-gap:16px;margin-bottom:4px}.ckk654LWH49lFeBBorc99 ._1aWlFMQ0JkkZHMazguu7UE ._1vuxAel71orEgArBWgpsk-{box-sizing:border-box;font-size:12px;width:100%;text-align:right;padding-right:40px;color:#919D99;margin-bottom:4px}.ckk654LWH49lFeBBorc99 ._3z2pr-I5qvcR2b-5eKws9S{display:inline-block;width:4%;height:48px;text-align:center;line-height:48px;border-radius:8px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;position:relative;top:6px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:24px;height:24px}.ckk654LWH49lFeBBorc99 ._3z2pr-I5qvcR2b-5eKws9S:hover{background-color:#E8ECEE}", ""]);
// Exports
exports.locals = {
	"container": "ckk654LWH49lFeBBorc99",
	"attr": "_1aWlFMQ0JkkZHMazguu7UE",
	"form": "ydBvTIRQcC9_7HyuBfjUR",
	"description": "_1vuxAel71orEgArBWgpsk-",
	"delButton": "_3z2pr-I5qvcR2b-5eKws9S"
};
module.exports = exports;
