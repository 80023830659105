// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1vZ7OQqi71Bv_JjVuHsaEC{background-color:#fff;box-sizing:border-box;border-radius:10px;padding:24px;margin:12px 0}._1vZ7OQqi71Bv_JjVuHsaEC table{font-size:12px;width:100%}._1vZ7OQqi71Bv_JjVuHsaEC table tr{height:24px}._1vZ7OQqi71Bv_JjVuHsaEC table th{text-align:left;font-weight:0}", ""]);
// Exports
exports.locals = {
	"container": "_1vZ7OQqi71Bv_JjVuHsaEC"
};
module.exports = exports;
