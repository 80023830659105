import React, { useState, useImperativeHandle, forwardRef } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    color: '#425c5b'
  }
}))
export function MuiDialog({ title = '', text = '', buttons = [] }, ref) {
  const [open, setOpen] = useState(false)
  const [args, setArgs] = useState(null)
  const btnClasses = useStyles()

  useImperativeHandle(
    ref,
    () => ({
      handleOpen(args) {
        setArgs(args)
        setOpen(true)
      }
    }),
    []
  )

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons.map(b => (
          <Button
            key={b.name}
            onClick={e => {
              b.value(args)
              handleClose()
            }}
            color='primary'
            classes={btnClasses}
          >
            {b.name}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  )
}

export default forwardRef(MuiDialog)
