// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'

import * as condition from '../../constants/scriptCondition'
import * as config from '../../constants/scriptConfig'

export const FETCH_SCRIPT_LIST = 'script/FETCH_SCRIPT_LIST'
export const DETOUCH_SCRIPT_LIST = 'script/DETOUCH_SCRIPT_LIST'

export const FETCH_SCRIPT = 'script/FETCH_SCRIPT'
export const CLEAR_SCRIPT = 'script/CLEAR_SCRIPT'
export const ADD_SCRIPT = 'script/ADD_SCRIPT'
export const SAVE_SCRIPT = 'script/SAVE_SCRIPT'
export const UPDATE_SCRIPT = 'script/UPDATE_SCRIPT'
export const DELETE_SCRIPT = 'script/DELETE_SCRIPT'

export const MOVE_PREV_ENTRY = 'script/MOVE_PREV_ENTRY'
export const MOVE_NEXT_ENTRY = 'script/MOVE_NEXT_ENTRY'
export const INIT_CURRENT_ENTRY = 'script/INIT_CURRENT_ENTRY'
export const CLEAR_CURRENT_ENTRY = 'script/CLEAR_CURRENT_ENTRY'
export const UPDATE_CURRENT_ENTRY = 'script/UPDATE_CURRENT_ENTRY'

export const DEFAULT_SCRIPT_STATE = {
  condition: condition.INITIAL_CONDITION,
  createdAt: new Date(),
  data: [],
  description: '',
  id: '',
  projectId: '',
  title: '',
  updatedAt: new Date(),
  version: config.SCRIPT_VERSION
}

export const SCRIPT_TYPES = {
  condition: PropTypes.shape(condition.CONDITION_TYPES),
  createdAt: PropTypes.instanceOf(Date).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.string.isRequired,
      index: PropTypes.number,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  ).isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  updatedAt: PropTypes.instanceOf(Date).isRequired,
  version: PropTypes.number.isRequired
}

export default {}
