// Takram Confidential
// Copyright (C) 2019-Present Takram

import _ from 'lodash'
import { combineReducers } from 'redux'

import * as scriptTypes from '../script/types'
import * as types from './types'

const currentRecord = (
  state = _.cloneDeep(types.DEFAULT_RECORD_STATE),
  action
) => {
  switch (action.type) {
    case types.CLEAR_CURRENT_RECORD:
      return _.cloneDeep(types.DEFAULT_RECORD_STATE)
    case types.UPDATE_CREATED_AT:
      return { ...state, createdAt: action.payload }
    case types.UPDATE_CURRENT_RECORD:
      return { ...state, ...action.record }
    case types.SAVE_CURRENT_RECORD:
      return state
    default:
      return state
  }
}

const currentLog = (
  state = {
    record: types.DEFAULT_RECORD_STATE,
    script: scriptTypes.DEFAULT_SCRIPT_STATE
  },
  action
) => {
  switch (action.type) {
    case types.CLEAR_LOG_RECORD:
      return { ...state, record: types.DEFAULT_RECORD_STATE }
    case types.CLEAR_LOG_SCRIPT:
      return { ...state, script: scriptTypes.DEFAULT_SCRIPT_STATE }
    case types.SET_LOG_RECORD:
      return { ...state, record: action.payload }
    case types.SET_LOG_SCRIPT:
      return { ...state, script: action.payload }
    default:
      return state
  }
}

const recordList = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_RECORD_LIST:
      return action.recordList
    case types.DETACH_RECORD_LIST:
      return []
    default:
      return state
  }
}

const recordFetchStatus = (state = types.FETCH_STATUS_NONE, action) => {
  switch (action.type) {
    case types.SET_FETCH_STATUS:
      return action.status
    default:
      return state
  }
}

const recordReducer = combineReducers({
  currentLog,
  currentRecord,
  recordList,
  recordFetchStatus
})

export default recordReducer
