// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './Thumbnail.scss'

const Thumbnail = ({
  title,
  onClickProject,
  onClickContextMenu,
  onClickFavorite,
  favorite,
  userTermCount,
  hideContextMenu
}) => (
  <div className={classes.container}>
    <div className={classes.rect} onClick={e => onClickProject(e)} />
    {!hideContextMenu && (
      <div className={classes.menuIcon} onClick={e => onClickContextMenu(e)} />
    )}
    <div
      className={ClassNames(classes.favoIcon, {
        [classes.on]: favorite
      })}
      onClick={e => onClickFavorite(e)}
    />
    <div className={classes.userTermCount}>
      <div className={ClassNames(classes.count, classes.before)}>
        <p>●</p> {userTermCount.before}
      </div>
      <div className={ClassNames(classes.count, classes.onGoing)}>
        <p>●</p> {userTermCount.onGoing}
      </div>
      <div className={ClassNames(classes.count, classes.after)}>
        <p>●</p> {userTermCount.after}
      </div>
    </div>
    <div className={classes.title}>{title}</div>
  </div>
)

Thumbnail.propTypes = {
  title: PropTypes.string.isRequired,
  onClickProject: PropTypes.func.isRequired,
  onClickContextMenu: PropTypes.func.isRequired,
  onClickFavorite: PropTypes.func.isRequired,
  favorite: PropTypes.bool.isRequired,
  userTermCount: PropTypes.shape({
    onGoing: PropTypes.number,
    before: PropTypes.number,
    after: PropTypes.number
  }),
  hideContextMenu: PropTypes.bool
}

Thumbnail.defaultProps = {
  userTermCount: { before: 0, onGoing: 0, after: 0 },
  hideContextMenu: false
}

export default Thumbnail
