// Takram Confidential
// Copyright (C) 2019-Present Takram

import React from 'react'
import PropTypes from 'prop-types'

import * as types from '../../modules/script/types'
import TextArea from '../Ui/TextArea'
import TextInput from '../Ui/TextInput'
import classes from './Forms.scss'
import Condition from './Condition'

export function Forms({ onChangeValue, permitUpdate, script }) {
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.label}>タイトル</div>
        <div className={classes.body}>
          <TextInput
            onChange={e => onChangeValue('title', e.target.value)}
            disabled={!permitUpdate}
            value={script.title}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.label}>説明</div>
        <div className={classes.body}>
          <TextArea
            onChange={e => onChangeValue('description', e.target.value)}
            disabled={!permitUpdate}
            value={script.description}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.label}>入力条件</div>
        <div className={classes.body}>
          <Condition
            onChangeValue={onChangeValue}
            permitUpdate={permitUpdate}
            script={script}
          />
        </div>
      </div>
    </div>
  )
}

Forms.propTypes = {
  onChangeValue: PropTypes.func.isRequired,
  permitUpdate: PropTypes.bool.isRequired,
  script: PropTypes.shape(types.SCRIPT_TYPES).isRequired
}
export default Forms
