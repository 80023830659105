// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './InputDate.scss'

const InputDate = ({ id, inputData, setInputData }) => {
  if (inputData === undefined) {
    const defaultVaue = Moment()
      .startOf('date')
      .toDate()
    setInputData({ [id]: defaultVaue })
  }
  return (
    <div className={classes.content}>
      <input
        className={classes.inputArea}
        onChange={e => setInputData({ [id]: new Date(e.target.value) })}
        type='date'
        value={Moment(inputData).format('YYYY-MM-DD')}
      />
      <div className={classes.pulldown} />
    </div>
  )
}

InputDate.propTypes = {
  id: PropTypes.string.isRequired,
  inputData: PropTypes.instanceOf(Date),
  setInputData: PropTypes.func.isRequired
}

InputDate.defaultProps = {}

export default InputDate
