// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'

import * as scriptConfig from '../../../constants/scriptConfig'

import classes from './Item.scss'

const ItemListSlider = ({ id, onChangeValue, permitUpdate, value }) => (
  <div className={classes.slideContainer}>
    <div className={classes.sliderBar} />
    <div className={classes.itemContainer}>
      {value.map((v, i) => (
        <div className={classes.col} key={id + i}>
          <input
            disabled={!permitUpdate}
            type='text'
            value={v.text === undefined ? '' : v.text}
            placeholder='未設定'
            onChange={e => {
              value[i] = { text: e.target.value }
              onChangeValue('value', value)
            }}
          />
          <div className={classes.marker} />
          {permitUpdate && (
            <div
              className={classes.deleteBtn}
              onClick={e => {
                value.pop()
                onChangeValue('value', value)
              }}
            >
              x
            </div>
          )}
        </div>
      ))}
    </div>
    {permitUpdate && (
      <div
        onClick={e => {
          value.push({ text: '' })
          onChangeValue('value', value)
        }}
      >
        <div className={classes.addLineIcon} />
        <div className={classes.addLine}>項目を追加</div>
      </div>
    )}
  </div>
)

ItemListSlider.propTypes = {
  ...scriptConfig.ITEM_STYLE,
  onChangeValue: PropTypes.func.isRequired,
  permitUpdate: PropTypes.bool.isRequired
}

ItemListSlider.defaultProps = {
  ...scriptConfig.INITIAL_ITEM_DATA
}

export default ItemListSlider
