// Takram Confidential
// Copyright (C) 2019-Present Takram

import * as types from './types'
import firebase from '../../Firebase'

let unsubMessageList = null

const subscribeMessageList = projectId => {
  return (dispatch, getState) => {
    if (projectId === '') return
    const db = firebase
      .firestore()
      .collection('fave-messages')
      .orderBy('createdAt', 'asc')
      .where('projectIds', 'array-contains', projectId)
    unsubMessageList = db.onSnapshot(snapshot => {
      dispatch({
        type: types.FETCH_MESSAGE_LIST,
        messageList: snapshot.docs.map(doc => deriveMessageFromDoc(doc))
      })
    })
  }
}

const unsubscribeMessageList = () => {
  return async (dispatch, getState) => {
    if (unsubMessageList !== null) unsubMessageList()
    dispatch({
      type: types.DETOUCH_MESSAGE_LIST
    })
  }
}

const updateCurrentInputMessage = (userId, message) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_CURRENT_INPUT_MESSAGE,
      userId,
      message
    })
  }
}

const setCurrentMessagingUserIds = userIds => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_CURRENT_MESSAGING_USER_ID,
      userIds
    })
  }
}

const setCurrentUserOrder = order => {
  return (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_CURRENT_USER_ORDER,
      order
    })
  }
}

const setCurrentUserSort = sort => {
  return (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_CURRENT_USER_SORT,
      sort
    })
  }
}

const setCurrentUserFilter = filter => {
  return (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_CURRENT_USER_FILTER,
      filter
    })
  }
}

const sendTextMessage = (user, message) => {
  return async (dispatch, getState) => {
    const db = firebase.firestore().collection('fave-messages')
    const newMessage = {
      createdAt: new Date(),
      lineUserId: user.lineUserId,
      message,
      projectIds: [user.projectId],
      type: 'linebot/send'
    }
    await db
      .add(newMessage)
      .catch(error => console.error('Error adding document: ', error))
  }
}

const deriveMessageFromDoc = doc => {
  const data = doc.data()
  const def = types.DEFAULT_MESSAGE_STATE
  return {
    id: doc.id,
    createdAt:
      data.createdAt == null || data.createdAt.seconds == null
        ? def.createdAt
        : new Date(data.createdAt.seconds * 1000),
    lineUserId: data.lineUserId == null ? def.lineUserId : data.lineUserId,
    message: data.message == null ? def.message : data.message,
    projectIds: data.projectIds == null ? def.projectIds : data.projectIds,
    type: data.type == null ? def.type : data.type
  }
}

export default {
  subscribeMessageList,
  unsubscribeMessageList,
  setCurrentMessagingUserIds,
  setCurrentUserOrder,
  setCurrentUserSort,
  setCurrentUserFilter,
  updateCurrentInputMessage,
  sendTextMessage
}
