// Takram Confidential
// Copyright (C) 2019-Present Takram

import React from 'react'
import { Route, Switch } from 'react-router'

import AppFrame from './AppFrame'
import AppAdmin from './AppAdmin'
import AppForm from '../../containers/AppForm'

export function App() {
  return (
    <AppFrame>
      <Switch>
        <Route path='/m/' render={() => <AppForm />} />
        <Route path='/' render={() => <AppAdmin />} />
      </Switch>
    </AppFrame>
  )
}

export default App
