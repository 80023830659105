// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { history } from '../../configureStore'
import { PERMIT_UPDATE } from '../../constants/permission'
import { appSelectors, appTypes } from '../../modules/app'
import { userOperations, userSelectors } from '../../modules/user'
import MuiContextMenu from '../Ui/MuiContextMenu'
import MuiDialog from '../Ui/MuiDialog'

import classes from './UserList.scss'
import UserListRow from './UserListRow'

export function UserList({ projectId }) {
  const dispatch = useDispatch()
  const menuRef = useRef()
  const dialogRef = useRef()
  const [menuItems, setMenuItems] = useState([])
  const isPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_USER)
  )
  const userList = useSelector(state =>
    userSelectors.deriveDisplayUserList(state)
  )

  const onClickUser = e => {
    history.push('/user/' + projectId + '/' + e)
  }

  const onDeleteUser = userId => {
    dispatch(userOperations.deleteUser(userId))
  }

  const onOpenDeleteDialog = userId => {
    dialogRef.current.handleOpen(userId)
  }

  const onClickMenu = (e, userId) => {
    setMenuItems([
      { name: '削除', onClick: userId => onOpenDeleteDialog(userId) }
    ])
    menuRef.current.handleOpen(e, userId)
  }

  return (
    <div className={classes.container}>
      <MuiContextMenu ref={menuRef} items={menuItems} />
      <MuiDialog
        title='ユーザー削除'
        text='ユーザーを削除してもよろしいですか？'
        ref={dialogRef}
        buttons={[
          { name: '削除する', value: id => onDeleteUser(id) },
          { name: '削除しない', value: () => {} }
        ]}
      />
      <div className={classes.listContainerTitle}>
        <div className={classes.listTitleID}>ID</div>
        <div className={classes.listTitleDuration}>実施期間</div>
        <div className={classes.listTitleStatus}>実施状態</div>
        <div className={classes.listTitleLine}>LINE連携</div>
        <div className={classes.action}>編集</div>
      </div>
      {userList.map(user => (
        <UserListRow
          key={user.id}
          user={user}
          onClickUser={e => onClickUser(e)}
          onClickMenu={(e, userId) => onClickMenu(e, userId)}
          permitUpdate={isPermit(PERMIT_UPDATE)}
        />
      ))}
    </div>
  )
}

export default UserList
