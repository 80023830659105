// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Range } from 'react-range'

import classes from './InputSlideSel.scss'

const InputSlideSel = ({ id, inputData, setInputData, value }) => {
  if (value.length === 0) return <div /> // TODO: Validate in editor.
  if (inputData === undefined) {
    const defaultVaue = value[Math.floor((value.length - 1) / 2)].text
    setInputData({ [id]: defaultVaue })
  }
  let index = value.findIndex(e => e.text === inputData)
  if (index === -1) index = Math.floor(value.length / 2)
  return (
    <div className={classes.content}>
      <div className={classes.range}>
        <Range
          step={1}
          min={0}
          max={Math.max(1, value.length - 1)}
          values={[index]}
          onChange={indices =>
            setInputData({
              [id]: value.length > indices[0] ? value[indices[0]].text : ''
            })
          }
          renderTrack={({ props, children }) => (
            <div className={classes.trackRoot}>
              <div
                className={classes.track}
                {...props}
                style={{ ...props.style }}
              >
                {children}
              </div>
              <div className={classes.edgeLeft} />
              <div className={classes.edgeRight} />
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              className={classes.thumb}
              {...props}
              style={{ ...props.style }}
            />
          )}
        />
        <div className={classes.scalesRoot}>
          <div className={classes.scales}>
            {value.map((d, i) => (
              <div
                className={ClassNames(classes.scale, {
                  [classes.selected]: i === index
                })}
                key={id + i}
                onClick={() => setInputData({ [id]: d.text })}
              >
                <div className={classes.circle} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.texts}>
        {value.map((d, i) => (
          <div
            className={classes.text}
            key={id + i}
            onClick={() => setInputData({ [id]: d.text })}
          >
            {d.text}
          </div>
        ))}
      </div>
    </div>
  )
}

InputSlideSel.propTypes = {
  id: PropTypes.string.isRequired,
  inputData: PropTypes.string,
  setInputData: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired
    })
  ).isRequired
}

InputSlideSel.defaultProps = {}

export default InputSlideSel
