// Takram Confidential
// Copyright (C) 2019-Present Takram

import recordReducer from './reducer'

export { default as recordOperations } from './operations'
export { default as recordSelectors } from './selectors'
export { default as recordTypes } from './types'

export default recordReducer
