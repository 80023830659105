// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as scriptConfig from '../../../constants/scriptConfig'

import classes from './ItemOption.scss'

const ItemOption = ({
  canTwoColumn,
  canChangeRequired,
  column,
  onChangeValue,
  required
}) => (
  <div className={classes.container}>
    <div
      className={ClassNames(classes.required)}
      onClick={() => canChangeRequired && onChangeValue('required', !required)}
    >
      <div
        className={ClassNames(classes.checkbox, {
          [classes.isChecked]: required,
          [classes.forceRequired]: !canChangeRequired
        })}
      >
        <p>{canChangeRequired ? '必須にする' : '必須の質問'}</p>
      </div>
    </div>
    {canTwoColumn ? (
      <div className={classes.column}>
        カラム
        <div className={classes.selection}>
          <div onClick={() => onChangeValue('column', 1)}>
            <div
              className={ClassNames(classes.radio, {
                [classes.isChecked]: column === 1
              })}
            />
            1列
          </div>
          <div
            onClick={() => {
              if (canTwoColumn) return onChangeValue('column', 2)
            }}
          >
            <div
              className={ClassNames(classes.radio, {
                [classes.isChecked]: column === 2
              })}
            />
            2列
          </div>
        </div>
      </div>
    ) : (
      ''
    )}
  </div>
)

ItemOption.propTypes = {
  ...scriptConfig.ITEM_STYLE,
  onChangeValue: PropTypes.func.isRequired
}

ItemOption.defaultProps = {
  ...scriptConfig.INITIAL_ITEM_DATA
}

export default ItemOption
