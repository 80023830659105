// Takram Confidential
// Copyright (C) 2019-Present Takram

import {
  isPermitLevel,
  PERMIT_CREATE,
  PERMIT_READ,
  PERMIT_UPDATE
} from '../../constants/permission'
import firebase from '../../Firebase'
import { appTypes } from '../app'
import * as types from './types'

let unsubRecordList = null

const clearCurrentRecord = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.CLEAR_CURRENT_RECORD
    })
  }
}

const saveCurrentRecord = (coordinates = null) => {
  return async (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_CREATE)) return
    const { recordState } = getState()
    const { currentRecord } = recordState
    if (
      Object.keys(currentRecord.data).length === 0 ||
      currentRecord.userId === '' ||
      currentRecord.scriptId === ''
    ) {
      return // Return if data / scriptId / userId is blank
    }

    const sdb = firebase.firestore().collection('fave-records-secret')
    const secretRef = await sdb.add({
      coordinates: coordinates == null ? {} : coordinates
    })
    const db = firebase.firestore().collection('fave-records')
    db.add({ ...currentRecord, secretRef }).catch(error => {
      console.error('Error adding record: ', error)
    })
    dispatch({
      type: types.SAVE_CURRENT_RECORD
    })
  }
}

const saveCurrentRecordWithCoord = () => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    const save = async () => {
      try {
        const position = await getCurrentPosition()
        const coordinates = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }
        dispatch(saveCurrentRecord(coordinates))
      } catch {
        dispatch(saveCurrentRecord())
      }
    }
    save()
  }
}

const subscribeRecordList = (constants = {}) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_FETCH_STATUS,
      status: types.FETCH_STATUS_IN_PROGRESS
    })
    if (!isPermit(getState(), PERMIT_READ)) return
    let db = firebase.firestore().collection('fave-records')
    db = db.orderBy('createdAt', 'desc')
    if (constants.projectId !== undefined) {
      db = db.where('projectId', '==', constants.projectId)
    }
    if (constants.scriptId !== undefined) {
      db = db.where('scriptId', '==', constants.scriptId)
    }
    if (constants.userId !== undefined) {
      db = db.where('userId', '==', constants.userId)
    }
    unsubRecordList = db.onSnapshot(
      async snapshot => {
        const recordList = []
        for (const doc of snapshot.docs) {
          const record = deriveRecordFromDoc(doc)
          // Omit for keep loading speed
          // if (doc.data().secretRef != null) {
          //   const secretDoc = await doc.data().secretRef.get()
          //   const secretRecord = deriveSecretRecordFromDoc(secretDoc)
          //   Object.assign(record, secretRecord)
          // }
          recordList.push(record)
        }
        dispatch({
          type: types.FETCH_RECORD_LIST,
          recordList
        })
        dispatch({
          type: types.SET_FETCH_STATUS,
          status: types.FETCH_STATUS_COMPLETE
        })
      },
      error => {
        dispatch({
          type: types.SET_FETCH_STATUS,
          status: types.FETCH_STATUS_FAILED
        })
      }
    )
  }
}

// TODO: Needs refactoring (duplicated codes)
const asyncSubscribeRecordList = (constants = {}) => {
  return async (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    return new Promise((resolve, reject) => {
      let resolveOnce = doc => {
        resolveOnce = () => undefined // XXX: Copied stack overflow.
        resolve(doc)
      }
      let db = firebase.firestore().collection('fave-records')
      db = db.orderBy('createdAt', 'desc')
      if (constants.projectId !== undefined) {
        db = db.where('projectId', '==', constants.projectId)
      }
      if (constants.scriptId !== undefined) {
        db = db.where('scriptId', '==', constants.scriptId)
      }
      if (constants.userId !== undefined) {
        db = db.where('userId', '==', constants.userId)
      }
      unsubRecordList = db.onSnapshot(snapshot => {
        dispatch({
          type: types.FETCH_RECORD_LIST,
          recordList: snapshot.docs.map(doc => deriveRecordFromDoc(doc))
        })
        resolveOnce(snapshot)
      }, reject)
    })
  }
}

const setLogRecord = record => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_LOG_RECORD,
      payload: record
    })
  }
}

const setLogScript = script => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_LOG_SCRIPT,
      payload: script
    })
  }
}

const clearLogRecord = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.CLEAR_LOG_RECORD
    })
  }
}

const clearLogScript = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.CLEAR_LOG_SCRIPT
    })
  }
}

const unsubscribeRecordList = () => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_READ)) return
    if (unsubRecordList !== null) unsubRecordList()
    dispatch({ type: types.DETACH_RECORD_LIST })
    dispatch({
      type: types.SET_FETCH_STATUS,
      status: types.FETCH_STATUS_NONE
    })
  }
}

const setCreatedAt = () => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    dispatch({
      type: types.UPDATE_CREATED_AT,
      payload: new Date()
    })
  }
}

const updateCurrentRecord = record => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    dispatch({
      type: types.UPDATE_CURRENT_RECORD,
      record
    })
  }
}

const updateCurrentRecordData = data => {
  return (dispatch, getState) => {
    if (!isPermit(getState(), PERMIT_UPDATE)) return
    const { recordState } = getState()
    const { currentRecord } = recordState
    const newData = { ...currentRecord.data, ...data }
    const dataKey = Object.keys(data)[0]
    if (Array.isArray(data[dataKey])) {
      if (data[dataKey].length === 0) {
        delete newData[dataKey]
      }
    } else if (typeof data[dataKey] === 'string') {
      if (data[dataKey] === '') {
        delete newData[dataKey]
      }
    }
    dispatch(updateCurrentRecord({ data: newData }))
  }
}

export default {
  asyncSubscribeRecordList,
  clearCurrentRecord,
  clearLogRecord,
  clearLogScript,
  saveCurrentRecord,
  saveCurrentRecordWithCoord,
  setCreatedAt,
  setLogRecord,
  setLogScript,
  subscribeRecordList,
  unsubscribeRecordList,
  updateCurrentRecord,
  updateCurrentRecordData
}

// =============================
// ===== Private Functions =====
// =============================

const deriveRecordFromDoc = doc => {
  const data = doc.data()
  const def = types.DEFAULT_RECORD_STATE
  return {
    createdAt:
      data.createdAt == null || data.createdAt.seconds == null
        ? def.createdAt
        : new Date(data.createdAt.seconds * 1000),
    data: data.data == null ? def.data : data.data,
    dataTypes: data.dataTypes == null ? def.data : data.dataTypes,
    id: doc.id,
    projectId: data.projectId == null ? def.projectId : data.projectId,
    requiredId: data.requiredId == null ? def.requiredId : data.requiredId,
    scriptId: data.scriptId == null ? def.scriptId : data.scriptId,
    userId: data.userId == null ? def.userId : data.userId,
    // Secret
    coordinates: def.coordinates
  }
}

const deriveSecretRecordFromDoc = doc => {
  const data = doc.data()
  const def = types.DEFAULT_RECORD_STATE
  return {
    coordinates: data.coordinates == null ? def.coordinates : data.coordinates
  }
}

const getCurrentPosition = options => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}

const isPermit = (state, permit) => {
  const adminLevel = state.appState.auth.adminLevel
  return isPermitLevel(adminLevel, appTypes.MODULE_RECORD, permit)
}
