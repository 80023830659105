// Takram Confidential
// Copyright (C) 2019-Present Takram

import actions from './actions'

const subscribeAdminUserList = () => {
  return dispatch => {
    dispatch(actions.unsubscribeAdminUserList())
    dispatch(actions.subscribeAdminUserList())
  }
}

const unsubscribeAdminUserList = () => {
  return actions.unsubscribeAdminUserList()
}

const clearAdminUser = () => {
  return actions.clearAdminUser()
}

const fetchAdminUser = adminUserId => {
  return actions.fetchAdminUser(adminUserId)
}

const addAdminUser = adminUser => {
  return actions.addAdminUser(adminUser)
}

const updateAdminUser = adminUser => {
  return actions.updateAdminUser(adminUser)
}

const saveAdminUser = () => {
  return actions.saveAdminUser()
}

const deleteAdminUser = adminUserId => {
  return actions.deleteAdminUser(adminUserId)
}

const subscribeAdminRequestList = () => {
  return actions.subscribeAdminRequestList()
}

const unsubscribeAdminRequestList = () => {
  return actions.unsubscribeAdminRequestList()
}

const addAdminRequest = (uid, adminName) => (dispatch, getState) => {
  dispatch(actions.addAdminRequest(uid, adminName)).then(() => {
    dispatch(fetchAdminRequestExistance(uid))
  })
}

const deleteAdminRequest = uid => {
  return actions.deleteAdminRequest(uid)
}

const registRequestedAdminUser = adminUser => {
  return dispatch => {
    dispatch(actions.deleteAdminRequest(adminUser.uid))
    dispatch(actions.addAdminUser(adminUser))
  }
}

const fetchAdminRequestExistance = uid => {
  return actions.fetchAdminRequestExistance(uid)
}

const setAdminUserListOrder = order => {
  return actions.setAdminUserListOrder(order)
}

export default {
  subscribeAdminUserList,
  unsubscribeAdminUserList,
  clearAdminUser,
  fetchAdminUser,
  addAdminUser,
  updateAdminUser,
  saveAdminUser,
  deleteAdminUser,
  subscribeAdminRequestList,
  unsubscribeAdminRequestList,
  addAdminRequest,
  deleteAdminRequest,
  registRequestedAdminUser,
  fetchAdminRequestExistance,
  setAdminUserListOrder
}
