// Takram Confidential
// Copyright (C) 2019-Present Takram

import React, { useRef, useState } from 'react'
import classes from './AttrSelector.scss'
import MuiContextMenu from '../../Ui/MuiContextMenu'
import { useSelector } from 'react-redux'
import { scriptSelectors } from '../../../modules/script'

export function AttrSelector({ onSelectAttr }) {
  const menuRef = useRef()
  const [menuItems, setMenuItems] = useState([])

  const textAttr = useSelector(state => scriptSelectors.textAttrSelector(state))

  const onClickAttrSelector = e => {
    const items = textAttr.map(attr => ({
      name: attr.title,
      onClick: e => onSelectAttr(attr.title)
    }))

    setMenuItems(items)
    menuRef.current.handleOpen(e)
  }

  return (
    <div className={classes.container}>
      <MuiContextMenu ref={menuRef} items={menuItems} />
      {textAttr.length !== 0 && (
        <div
          className={classes.selector}
          onClick={e => onClickAttrSelector(e)}
        >{`{ }`}</div>
      )}
    </div>
  )
}

export default AttrSelector
