// Takram Confidential
// Copyright (C) 2019-Present Takram

import Moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Header from '../components/Form/Header'
import { formOperations, formSelectors } from '../modules/form'

class FormHeader extends Component {
  onClickDate(date) {
    const { formOperations } = this.props
    const isFuture = Moment(date).isAfter(Moment(), 'date')
    if (!isFuture) formOperations.setCurrentRecordDate(date)
  }

  render() {
    const {
      currentRecordDate,
      dateCheckList,
      formOperations,
      isDebugUserMode
    } = this.props
    return (
      <Header
        currentRecordDate={currentRecordDate}
        dateCheckList={dateCheckList}
        isDebugUserMode={isDebugUserMode}
        onClickDate={e => this.onClickDate(e)}
        openUserInfo={() => formOperations.setUserInfoPopupVisibility(true)}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentRecordDate: state.formState.currentRecordDate,
    dateCheckList: formSelectors.dateCheckListSelector(state),
    isDebugUserMode: state.appState.isDebugUserMode
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    formOperations: bindActionCreators(formOperations, dispatch)
  }
}

FormHeader.propTypes = {
  currentRecordDate: PropTypes.instanceOf(Date).isRequired,
  dateCheckList: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date).isRequired,
      done: PropTypes.bool.isRequired
    })
  ).isRequired,
  formOperations: PropTypes.objectOf(PropTypes.func).isRequired,
  isDebugUserMode: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(FormHeader)
