// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './BeforeStart.scss'

const BeforeStart = ({ visibility }) => (
  <div
    className={ClassNames(classes.container, {
      [classes.hidden]: !visibility
    })}
  >
    <div className={classes.item}>
      <div className={classes.content}>実施期間前です。</div>
    </div>
  </div>
)

BeforeStart.propTypes = {
  visibility: PropTypes.bool.isRequired
}

BeforeStart.defaultProps = {
  visibility: false
}

export default BeforeStart
