// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3oNbjq_evncQd5m42f4atf{position:relative;border:solid 1px #919D99;border-radius:10px;margin:24px 0;padding:24px;font-size:16px}._3oNbjq_evncQd5m42f4atf .LeVvo-MdudCOfXv4FnIGH{display:inline-block;margin:0 8px}._3oNbjq_evncQd5m42f4atf ._3AHhQ2BHLFab91Jhtm1o67{margin-bottom:16px}._3oNbjq_evncQd5m42f4atf ._1TGU50v1oddY--jdTUPdPR{font-size:20px;color:#fff;position:absolute;top:16px;right:16px;width:24px;height:24px;text-align:center;font-weight:100;line-height:20px;border-radius:5px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._3oNbjq_evncQd5m42f4atf ._1TGU50v1oddY--jdTUPdPR:hover{background-color:#546b68}._3oNbjq_evncQd5m42f4atf ._1TGU50v1oddY--jdTUPdPR:active{background-color:#d9b389}", ""]);
// Exports
exports.locals = {
	"container": "_3oNbjq_evncQd5m42f4atf",
	"middleWord": "LeVvo-MdudCOfXv4FnIGH",
	"conditionRow": "_3AHhQ2BHLFab91Jhtm1o67",
	"delButton": "_1TGU50v1oddY--jdTUPdPR"
};
module.exports = exports;
