// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._18PStNvtiNqnxdDulXV8kx{padding:24px 24px;position:absolute;bottom:0;width:828px;background-color:#fff;border-top:1px solid #E8ECEE;border-radius:0 0 12px 0}._18PStNvtiNqnxdDulXV8kx ._24gEKzOLnnwACLvYeRC64O textarea{color:#384241;width:88%;height:72px;resize:none;border:solid 1px #E8ECEE;border-radius:8px;padding:10px 8px 2px 8px;background-color:#f1f1f1}._18PStNvtiNqnxdDulXV8kx ._24gEKzOLnnwACLvYeRC64O ._1O_atMmGYCDjTdX-c-_mob{color:white;background-color:#EAAD68;width:80px;height:42px;line-height:42px;border-radius:21px;text-align:center;float:right;margin:15px 0;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"container": "_18PStNvtiNqnxdDulXV8kx",
	"textAreaRow": "_24gEKzOLnnwACLvYeRC64O",
	"sendButton": "_1O_atMmGYCDjTdX-c-_mob"
};
module.exports = exports;
