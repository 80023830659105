// Takram Confidential
// Copyright (C) 2019-Present Takram

import 'firebase/auth'

import React, { useRef, useState } from 'react'
import { Route, Switch } from 'react-router'

import ProjectFrame from '../Project/Frame'
import ProjectHeader from '../Project/Header'
import Script from '../Script/Script'
import Record from '../Record/Record'
import User from '../User/User'
import Message from '../Message/Message'
import Setting from '../Project/Setting'

export function AppProject({ projectId }) {
  return (
    <div>
      <ProjectHeader projectId={projectId} />
      <ProjectFrame>
        <Switch>
          <Route
            exact
            path='/project/:pid'
            render={() => <Script projectId={projectId} />}
          />
          <Route
            exact
            path='/project/:pid/record/'
            render={() => <Record projectId={projectId} />}
          />
          <Route
            exact
            path='/project/:pid/message/'
            render={() => <Message projectId={projectId} />}
          />
          <Route
            exact
            path='/project/:pid/setting/'
            render={() => <Setting projectId={projectId} />}
          />
          <Route
            exact
            path='/project/:pid/user/'
            render={() => <User projectId={projectId} />}
          />
        </Switch>
      </ProjectFrame>
    </div>
  )
}

export default AppProject
