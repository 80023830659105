// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PERMIT_CREATE } from '../../constants/permission'

import { appSelectors, appTypes } from '../../modules/app'
import { userOperations, userSelectors } from '../../modules/user'
import Select from '../Ui/Select'
import { Link } from 'react-router-dom'

import UserList from './UserList'
import {
  ORDER_BY_CREATED_AT_ASC,
  ORDER_BY_CREATED_AT_DESC,
  ORDER_BY_USER_NAME_ASC,
  ORDER_BY_USER_NAME_DESC
} from '../../modules/user/types'

import commonCls from '../App/Common.scss'
import classes from './UserListCatalog.scss'
import { CSVLink, CSVDownload } from 'react-csv'

const smallSelectStyle = {
  backgroundColor: '#FFF',
  margin: '0px 0px 0px 8px',
  padding: '4px 18px',
  width: '100%',
  height: 38,
  fontSize: 14
}

export function UserListCatalog({ projectId }) {
  const dispatch = useDispatch()
  const userListOrder = useSelector(state => state.userState.userListOrder)
  const isUserPermit = useSelector(state =>
    appSelectors.isPermitSelector(state, appTypes.MODULE_USER)
  )

  const exportUserList = useSelector(state =>
    userSelectors.userListForExport(state)
  )

  const onChangeUserListOrder = order => {
    dispatch(userOperations.setUserListOrder(order))
  }

  const sortItems = [
    { name: '名前昇順', value: ORDER_BY_USER_NAME_ASC },
    { name: '名前降順', value: ORDER_BY_USER_NAME_DESC },
    { name: '登録日昇順', value: ORDER_BY_CREATED_AT_ASC },
    { name: '登録日降順', value: ORDER_BY_CREATED_AT_DESC }
  ]

  return (
    <div className={classes.container}>
      <div className={classes.listHeader}>
        <div className={classes.title}>被験者一覧</div>
        <div className={classes.order}>
          <CSVLink data={exportUserList} className={classes.selectStyle}>
            一覧のダウンロード
          </CSVLink>
          <Select
            items={sortItems}
            value={userListOrder}
            style={smallSelectStyle}
            className={classes.selectStyle}
            onChange={e => {
              onChangeUserListOrder(e.target.value)
            }}
          />
        </div>
      </div>

      <div className={classes.catalogContainer}>
        <div className={classes.listContainer}>
          <UserList projectId={projectId} />
        </div>
        {isUserPermit(PERMIT_CREATE) && (
          <div className={classes.buttonContainer}>
            <Link to={`/adduser/${projectId}`}>
              <div
                className={`
                  ${commonCls.pannelButton}
                  ${classes.button}`}
              >
                <div className={classes.pannelText}>
                  <div className={classes.addLineIcon} />
                  被験者を追加する
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

UserListCatalog.propTypes = {
  projectId: PropTypes.string.isRequired
}

export default UserListCatalog
