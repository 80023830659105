import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    margin: '0',
    height: '38px',
    verticalAlign: 'inherit'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}))

const ITEM_HEIGHT = 38
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export function MuiMultipleSelect(props) {
  const classes = useStyles()
  const nameMap = props.items.reduce(
    (h, i) => ({ ...h, ...{ [i.value]: i.name } }),
    {}
  )
  return (
    <FormControl className={classes.formControl} disabled={props.disabled}>
      <InputLabel id='mutiple-checkbox-label'>{props.label}</InputLabel>
      <Select
        labelId='demo-mutiple-checkbox-label'
        id='demo-mutiple-checkbox'
        multiple
        value={props.value}
        onChange={props.onChange}
        input={<Input />}
        renderValue={selected => selected.map(s => nameMap[s]).join(', ')}
        MenuProps={MenuProps}
      >
        {props.items.map(item => (
          <MenuItem key={item.name} value={item.value}>
            <Checkbox checked={props.value.indexOf(item.value) > -1} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

MuiMultipleSelect.defaultProps = {
  items: [],
  value: [],
  label: '',
  disabled: false,
  onChange: () => {}
}

export default MuiMultipleSelect
