import { useEffect, useState } from 'react'

function useKeyEvent() {
  const [ctrlPressed, setCtrlPressed] = useState(false)
  const [shiftPressed, setShiftPressedd] = useState(false)

  useEffect(() => {
    const down = e => {
      setCtrlPressed(e.metaKey)
      setShiftPressedd(e.shiftKey)
    }
    const up = e => {
      setCtrlPressed(e.metaKey)
      setShiftPressedd(e.shiftKey)
    }
    document.addEventListener('keydown', down)
    document.addEventListener('keyup', up)
    return () => {
      setCtrlPressed(false)
      setShiftPressedd(false)
      document.removeEventListener('keydown', down)
      document.removeEventListener('keyup', up)
    }
  }, [])
  return { ctrlPressed, shiftPressed }
}

export default useKeyEvent
