// Takram Confidential
// Copyright (C) 2019-Present Takram

import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import classes from './RequiredItemAlert.scss'

const RequiredItemAlert = ({ onClose, visibility, message }) => (
  <div
    className={ClassNames(classes.container, {
      [classes.hidden]: !visibility
    })}
    onClick={onClose}
  >
    <div className={classes.item}>
      <div className={classes.content}>{message}</div>
      <div className={classes.icon} />
    </div>
  </div>
)

RequiredItemAlert.propTypes = {
  onClose: PropTypes.func.isRequired,
  visibility: PropTypes.bool.isRequired
}

RequiredItemAlert.defaultProps = {
  visibility: false
}

export default RequiredItemAlert
