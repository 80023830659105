import React from 'react'

const selectStyle = {
  margin: 0,
  padding: '12px 18px',
  background: 'none',
  border: 'none',
  borderRadius: 8,
  outline: 'none',
  appearance: 'none',
  fontSize: 16,
  backgroundColor: '#e2ebea',
  height: 44,
  width: 300
}

export function Select({ value, onChange, items, disabled, style }) {
  return (
    <select
      style={{ ...selectStyle, ...style }}
      disabled={disabled}
      onChange={onChange}
      value={value}
    >
      {items.map(item => (
        <option key={item.name} value={item.value}>
          {item.name}
        </option>
      ))}
    </select>
  )
}

Select.defaultProps = {
  items: [],
  value: '',
  disabled: false,
  onChange: () => {},
  style: {}
}

export default Select
