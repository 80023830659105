// Takram Confidential
// Copyright (C) 2019-Present Takram

import React from 'react'
import PropTypes from 'prop-types'

import * as scriptCondition from '../../constants/scriptCondition'
import * as types from '../../modules/script/types'
import NumberInput from '../Ui/NumberInput'
import Select from '../Ui/Select'
import classes from './Condition.scss'
import ConditionItems from './ConditionItems'

export function Condition({ onChangeValue, permitUpdate, script }) {
  const scriptType = script.condition.type

  const onChange = e => {
    onChangeValue('condition', {
      ..._.cloneDeep(scriptCondition.INITIAL_CONDITION),
      ...{ type: e.target.value }
    })
  }
  const onChangeInputTermType = value => {
    script.condition.inputTerm.type = value
    onChangeValue('condition', script.condition)
  }
  const onChangeInputTermElapsedDaysFrom = from => {
    script.condition.inputTerm.from = from
    script.condition.inputTerm.to = Math.max(
      from,
      script.condition.inputTerm.to
    )
    onChangeValue('condition', script.condition)
  }
  const onChangeInputTermElapsedDaysTo = to => {
    script.condition.inputTerm.from = Math.min(
      script.condition.inputTerm.from,
      to
    )
    script.condition.inputTerm.to = to
    onChangeValue('condition', script.condition)
  }
  script.condition.inputTerm ||= scriptCondition.INITIAL_CONDITION.inputTerm
  return (
    <div className={classes.container}>
      <Select
        items={scriptCondition.SCRIPT_TYPE_LABELS.map(el => ({
          name: el.label,
          value: el.type
        }))}
        value={scriptType}
        onChange={e => onChange(e)}
      />
      {scriptType !== scriptCondition.SCRIPT_TYPE_FREE || (
        <div className={classes.inputTerm}>
          <div className={classes.inputTermTitle}>入力期間</div>
          <Select
            disabled={!permitUpdate}
            items={scriptCondition.INPUT_TERM_TYPE_LABELS.map(el => ({
              name: el.label,
              value: el.type
            }))}
            onChange={e => onChangeInputTermType(e.target.value)}
            style={{ ...{ width: 140 } }}
            value={script.condition.inputTerm.type}
          />
          {script.condition.inputTerm.type !==
            scriptCondition.INPUT_TERM_TYPE_ELAPSED_DAYS || (
            <span className={classes.inputTermElapsedDays}>
              <NumberInput
                disabled={!permitUpdate}
                min={1}
                onChange={e =>
                  onChangeInputTermElapsedDaysFrom(parseInt(e.target.value - 1))
                }
                style={{ ...{ width: 56 } }}
                value={script.condition.inputTerm.from + 1}
              />
              {` 日目 ~ `}
              <NumberInput
                disabled={!permitUpdate}
                min={1}
                onChange={e =>
                  onChangeInputTermElapsedDaysTo(parseInt(e.target.value - 1))
                }
                style={{ ...{ width: 56 } }}
                value={script.condition.inputTerm.to + 1}
              />
              {` 日目`}
            </span>
          )}
        </div>
      )}
      <div className={classes.conditionCaption}>
        {scriptCondition.getCaption(scriptType).description}
      </div>
      <ConditionItems
        onChangeValue={onChangeValue}
        permitUpdate={permitUpdate}
        script={script}
      />
    </div>
  )
}

Condition.propTypes = {
  onChangeValue: PropTypes.func.isRequired,
  permitUpdate: PropTypes.bool.isRequired,
  script: PropTypes.shape(types.SCRIPT_TYPES).isRequired
}
export default Condition
